import useAuth from "./useAuth"
import useSWR from "swr"

export const useGetDummyData = (uuid) => {
  const { fetcher } = useAuth()
  const url = `/dummy-data/${uuid || ""}`

  const response = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )
  return response
}

export const useDummyPayloadPreset = (uuid) => {
  const { fetcher } = useAuth()
  const url = `/dummy-data/preset/${uuid || ""}`

  const response = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )
  return response
}

export const useDummyConnectionPreset = (uuid) => {
  const { fetcher } = useAuth()
  const url = `/dummy-data/conn-type/${uuid || ""}`

  const response = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )
  return response
}



