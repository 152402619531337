import { FunctionalComponent, h } from "preact"
import { Route, Router } from "preact-router"
import { ConfirmProvider } from "../context/useConfirm"
import { FilterContextProvider, useFilter } from "../context/useFilter"
import Dashboard from "./dashboard"
import SignupPage from "../routes/login/signup"
import ChangePassword from "../routes/login/change-password"
import ResetPassword from "../routes/login/reset-password"
import PrivacyPolicy from "../routes/privacy-policy/privacy-policy"
import TermsConditions from "../routes/privacy-policy/terms-conditions"
import { useEffect, useMemo } from "preact/hooks"

import Sucofindo from "../routes/landing/sucofindo"
import Innovasindo from "../routes/landing/innovasindo"
import Envirotama from "../routes/landing/envirotama"

import template from "../utils/template-configs"
import usePlatformApp from "../hooks/usePlatformApp"

const App: FunctionalComponent = () => {
  const { platform } = usePlatformApp()
  const [contextFilter, setContextFilter] = useFilter()
  const FilterContextValue = useMemo(() => ({ contextFilter, setContextFilter }), [contextFilter, setContextFilter])

  const updateFavIcon = () => {
    const logo = template[platform].favicon
    let link = document.querySelector("link[rel='shortcut icon']")
    if (!link) {
      link = document.createElement("link")
      link.rel = "icon"
      document.getElementsByTagName("head")[0].appendChild(link)
    }
    link.href = logo
  }

  useEffect(() => {
    document.title = template[platform].title
    updateFavIcon()
  }, [])

  return (
    <div id="preact_root">
      <FilterContextProvider value={FilterContextValue}>
        <ConfirmProvider>
          <Router>
            <Route path="/" component={SignupPage} />
            <Route path="/login" component={SignupPage} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={TermsConditions} />
            {/* landing */}
            <Route path="/sucofindo" component={Sucofindo} />
            <Route path="/innovasindo" component={Innovasindo} />
            <Route path="/envirotama" component={Envirotama} />

            <Dashboard default />
          </Router>
        </ConfirmProvider>
      </FilterContextProvider>
    </div>
  )
}

export default App
