import { h } from "preact"
import { Chart as ChartJS } from "chart.js"
import { Line as ChartLine } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"

type RecordChartProps = {
  height?: number | string
  avg: string | number
  min: string | number
  max: string | number
  data: number[]
  labels: string[]
  upper: number
  lower: number
  showMin: boolean
  showMax: boolean
  showAvg: boolean
}

const RecordChart = ({
  height = 120,
  upper,
  lower,
  avg,
  min,
  max,
  data,
  labels,
  showMin,
  showMax,
  showAvg,
  showUpper,
  showLower,
}: RecordChart) => {
  ChartJS.register(annotationPlugin)
  const annotation = {
    type: 'line',
    borderWidth: 3,
    drawTime: 'beforeDraw',
    label: {
      display: (ctx) => ctx.hovered,
      drawTime: 'afterDatasetsDraw',
      position: (ctx) => ctx.hoverPosition
    },
    enter(ctx, event) {
      ctx.hovered = true;
      ctx.hoverPosition = `${event.x / ctx.chart.chartArea.width * 100}%`;
      ctx.chart.update();
    },
    leave(ctx) {
      ctx.hovered = false;
      ctx.chart.update();
    }
  }
  return (
    <ChartLine
      height={height}
      options={{
        plugins: {
          annotation: {
            annotations: {
              ...(showLower && {
                lower: {
                  ...annotation,
                  yMin: lower,
                  yMax: lower,
                  borderColor: '#69ce95',
                  value: lower,
                  label: {
                    ...annotation.label,
                    content: "Lower Threshold",
                  },
                }
              }),
              ...(showUpper && {
                upper: {
                  ...annotation,
                  yMin: upper,
                  yMax: upper,
                  borderColor: '#f53e22',
                  value: upper,
                  label: {
                    ...annotation.label,
                    content: "Upper Threshold",
                  },
                },
              }),
              ...(showMin && {
                min: {
                  ...annotation,
                  yMin: min,
                  yMax: min,
                  borderColor: '#9204d4',
                  value: min,
                  label: {
                    ...annotation.label,
                    content: "Minimum",
                  },
                }
              }),
              ...(showMax && {
                max: {
                  ...annotation,
                  yMin: max,
                  yMax: max,
                  borderColor: '#ff08c5',
                  value: max,
                  label: {
                    ...annotation.label,
                    content: "Maximum",
                  },
                },
              }),
              ...(showAvg && {
                avg: {
                  ...annotation,
                  yMin: avg,
                  yMax: avg,
                  borderColor: '#ECB390',
                  value: avg,
                  label: {
                    ...annotation.label,
                    content: "Average",
                  },
                }
              })
            }
          },
          legend: {
            display: false,
          }
        },
        scales: {
          xAxis: {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              maxRotation: 0,
              minRotation: 0
            },
          },
        }
      }}
      data={{
        labels,
        datasets: [{
          data,
          borderColor: "#205796",
          backgroundColor: "#205796",
          tension: 0.4,
        }]
      }}
    />
  )
}
export default RecordChart
