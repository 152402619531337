export enum Permission {
  AccessMenuAdd = "Access-Menu-Add",
  AccessMenuDelete = "Access-Menu-Delete",
  AccessMenuEdit = "Access-Menu-Edit",
  AccessMenuView = "Access-Menu-View",
  AccessFeatureAdd = "Access-Module-Add",
  AccessFeatureDelete = "Access-Module-Delete",
  AccessFeatureEdit = "Access-Module-Edit",
  AccessFeatureView = "Access-Module-View",
  AccessPermissionAdd = "Access-Permission-Add",
  AccessPermissionDelete = "Access-Permission-Delete",
  AccessPermissionEdit = "Access-Permission-Edit",
  AccessPermissionView = "Access-Permission-View",
  AccessRoleAdd = "Access-Role-Add",
  AccessRoleDelete = "Access-Role-Delete",
  AccessRoleEdit = "Access-Role-Edit",
  AccessRoleEditPermission = "Access-Role-EditPermission",
  AccessRoleView = "Access-Role-View",
  AnalyticMultiSiteDownload = "Analytic-MultiSite-Download",
  AnalyticMultiSiteView = "Analytic-MultiSite-View",
  AnalyticPeriodDownload = "Analytic-Period-Download",
  AnalyticPeriodView = "Analytic-Period-View",
  AuthLogin = "Auth-Auth-Login", // BE
  DashboardMapLastDataPagination = "Dashboard-Map-LastDataPagination",
  DashboardMapLastDataSearch = "Dashboard-Map-LastDataSearch",
  DashboardMapView = "Dashboard-Map-View",
  DashboardMapsViewAllMapMarker = "Dashboard-Maps-ViewAllMapMarker",
  DashboardMonitoringView = "Dashboard-Monitoring-View",
  DashboardParameterComplianceView = "Dashboard-ParameterCompliance-View",
  DashboardRealtimeView = "Dashboard-Realtime-View",
  DeviceBatteryStatusView = "Device-BatteryStatus-View",
  DeviceSensorDelayView = "Device-SensorDelay-View",
  ErrorLogExportExcel = "ErrorLog-ErrorLog-ExportExcel",
  ErrorLogView = "ErrorLog-ErrorLog-View",
  FinanceDeactive = "Finance-Finance-Deactive",
  FinanceDetail = "Finance-Finance-Detail",
  FinanceExportExcel = "Finance-Finance-ExportExcel",
  FinanceHistory = "Finance-Finance-History",
  FinanceInvoice = "Finance-Finance-Invoice",
  FinancePaymentConfirmation = "Finance-Finance-PaymentConfirmation",
  FinancePerformanceInvoice = "Finance-Finance-PerformanceInvoice",
  FinanceEdit = "Finance-Finance-Edit",
  FinanceView = "Finance-Finance-View",
  FinanceRenew = "Finance-Finance-Renew",
  HelpAdd = "Help-Help-Add",
  HelpDelete = "Help-Help-Delete",
  HelpDetail = "Help-Help-Detail",
  HelpEdit = "Help-Help-Edit",
  HelpView = "Help-Help-View",
  MaintenanceCalibrationReportAdd = "Maintenance-CalibrationReport-Add",
  MaintenanceCalibrationReportApprove = "Maintenance-CalibrationReport-Approve",
  MaintenanceCalibrationReportDelete = "Maintenance-CalibrationReport-Delete",
  MaintenanceCalibrationReportEdit = "Maintenance-CalibrationReport-Edit",
  MaintenanceCalibrationReportExportExcel = "Maintenance-CalibrationReport-ExportExcel",
  MaintenanceCalibrationReportView = "Maintenance-CalibrationReport-View",
  MaintenanceCalibrationReportViewHistory = "Maintenance-CalibrationReport-ViewHistory",
  MaintenanceNcrAdd = "Maintenance-NCR-Add",
  MaintenanceNcrDelete = "Maintenance-NCR-Delete",
  MaintenanceNcrDetail = "Maintenance-NCR-Detail",
  MaintenanceNcrEdit = "Maintenance-NCR-Edit",
  MaintenanceNcrExport = "Maintenance-NCR-Export",
  MaintenanceNcrFirstApproval = "Maintenance-NCR-FirstApproval",
  MaintenanceNcrSecondApproval = "Maintenance-NCR-SecondApproval",
  MaintenanceNcrView = "Maintenance-NCR-View",
  MaintenanceNcrKlhkAdd = "Maintenance-NCRKLHK-Add",
  MaintenanceNcrKlhkChangeStatus = "Maintenance-NCRKLHK-ChangeStatus",
  MaintenanceNcrKlhkDelete = "Maintenance-NCRKLHK-Delete",
  MaintenanceNcrKlhkEdit = "Maintenance-NCRKLHK-Edit",
  MaintenanceNcrKlhkExport = "Maintenance-NCRKLHK-Export",
  MaintenanceNcrKlhkView = "Maintenance-NCRKLHK-View",
  MobileAppView = "MobileApp-MobileApp-View",
  RecordGraphDownload = "Record-Graph-Download",
  RecordGraphView = "Record-Graph-View",
  RecordParameterAccumulatedLoadExportExcel = "Record-ParameterAccumulatedLoad-ExportExcel",
  RecordParameterAccumulatedLoadView = "Record-ParameterAccumulatedLoad-View",
  RecordPercentageComplianceDataExportExcel = "Record-PercentageComplianceData-ExportExcel",
  RecordPercentageComplianceDataView = "Record-PercentageComplianceData-View",
  RecordSensorDataExport = "Record-SensorData-Export",
  RecordSensorDataView = "Record-SensorData-View",
  RecordSensorDetailExport = "Record-SensorDetail-Export",
  RecordSensorDetailView = "Record-SensorDetail-View",
  RegulationView = "Regulation-Regulation-View",
  SettingsCompanyAdd = "Settings-Company-Add",
  SettingsCompanyDelete = "Settings-Company-Delete",
  SettingsCompanyDetail = "Settings-Company-Detail",
  SettingsCompanyEdit = "Settings-Company-Edit",
  SettingsCompanyView = "Settings-Company-View",
  SettingsModuleAdd = "Settings-Module-Add",
  SettingsModuleDelete = "Settings-Module-Delete",
  SettingsModuleDetail = "Settings-Module-Detail",
  SettingsModuleEdit = "Settings-Module-Edit",
  SettingsModuleView = "Settings-Module-View",
  SettingsPlatformAdd = "Settings-Platform-Add",
  SettingsPlatformDelete = "Settings-Platform-Delete",
  SettingsPlatformDetail = "Settings-Platform-Detail",
  SettingsPlatformEdit = "Settings-Platform-Edit",
  SettingsPlatformView = "Settings-Platform-View",
  SettingsSiteDataAdd = "Settings-SiteData-Add",
  SettingsSiteDataDelete = "Settings-SiteData-Delete",
  SettingsSiteDataDetail = "Settings-SiteData-Detail",
  SettingsSiteDataEdit = "Settings-SiteData-Edit",
  SettingsSiteDataExportDetailToPdf = "Settings-SiteData-ExportDetailToPdf",
  SettingsSiteDataExportExcel = "Settings-SiteData-ExportExcel",
  SettingsSiteDataView = "Settings-SiteData-View",
  SettingsUserAdd = "Settings-User-Add",
  SettingsUserChangeStatus = "Settings-User-ChangeStatus",
  SettingsUserDelete = "Settings-User-Delete",
  SettingsUserEdit = "Settings-User-Edit",
  SettingsUserExportExcel = "Settings-User-ExportExcel",
  SettingsUserView = "Settings-User-View",
  SettingsVendorAdd = "Settings-Vendor-Add",
  SettingsVendorDelete = "Settings-Vendor-Delete",
  SettingsVendorDetail = "Settings-Vendor-Detail",
  SettingsVendorEdit = "Settings-Vendor-Edit",
  SettingsVendorView = "Settings-Vendor-View",
  SubscriptionView = "Subscription-Subscription-View",
  ToolsDummyDataAdd = "Tools-DummyData-Add",
  ToolsDummyDataDelete = "Tools-DummyData-Delete",
  ToolsDummyDataDetail = "Tools-DummyData-Detail",
  ToolsDummyDataStartStop = "Tools-DummyData-StartStop",
  ToolsDummyDataView = "Tools-DummyData-View",
  ToolsMobileVersionView = "Tools-MobileVersion-View",
  ToolsMobileVersionEdit = "Tools-MobileVersion-Edit",
}
