import { h } from "preact"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import { useState } from "preact/hooks"
import { useTranslation } from "react-i18next"

type Props = {
  uuid: string
  show: boolean
  mutate: void
  onClose: void
}
const ReportStatus = ({ uuid, mutate, show, onClose }: Props) => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const [status, setStatus] = useState("")

  const handleSubmit = (event) => {
    event.preventDefault()
    if (status === "Closed") {
      fetcher()
        .put(`/ncr-klhk/validation/${uuid}`)
        .then(() => {
          mutate()
          onClose()
        })
    }
  }

  return (
    <form
      className="modal-backdrop"
      style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("report_status")}</div>
          <div className="card-body">
            <select className="form-control rounded mw-100" onChange={({ currentTarget: e }) => setStatus(e.value)} value={status}>
              <option value="">{t("label.select_status")}</option>
              <option value="Open">OPEN</option>
              <option value="Closed">CLOSE</option>
            </select>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onClose} type="button" variant="tertiary" className="m-1">
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1">
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ReportStatus
