import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { RiBuildingLine } from "react-icons/ri"
import { HiChevronDown, HiChevronUp } from "react-icons/hi"
import { Socket } from "socket.io-client"
import DetailsCompanyContent from "./details-company-content"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type DetailsSummaryProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  company: any
  status?: string
  socket: Socket
}

const DetailsSummaryCompany: FunctionComponent<DetailsSummaryProps> = ({ socket, status, company }) => {
  const { t } = useTranslation()
  const [isCompanyOpen, setIsCompanyOpen] = useState(false)

  const toggleCompanyDetails = () => setIsCompanyOpen((prevState) => !prevState)

  return (
    <details open={isCompanyOpen} onToggle={toggleCompanyDetails} className="mb-4">
      <summary className={style.detail_summary_company}>
        <div className={style.summary_title_company}>
          <RiBuildingLine className="me-1" />
          {company?.name}
          <span>
            {t("site_active")} ({company?.count_active_sites}/{company?.total_sites})
          </span>
          {isCompanyOpen ? <HiChevronUp size="1.5rem" /> : <HiChevronDown size="1.5rem" />}
        </div>
      </summary>
      {company?.modules?.map((module) => (
        <DetailsCompanyContent key={module?.uuid} module={module} status={status} socket={socket} />
      ))}
    </details>
  )
}

export default DetailsSummaryCompany
