import useSWR from "swr"
import useAuth from "./useAuth"
import { ApiResponse } from "../types/api-response"

interface User {
  uuid: string
  name: string
}

interface Subscription {
  uuid: string
  start: string
  end: string
  status: boolean
  created_at: string
  updated_at: string
  deleted_at?: string
  user: User[]
  cem: User[]
}

interface SubscriptionListPaginate {
  platform?: string
  company?: string
  site?: string
  status?: string
  billing_start?: string
  billing_end?: string
  page: number
  limit: number
  orders?: { [field: string]: string }[]
}

export const useSubscription = () => {
  const { fetcher } = useAuth()
  const url = `/subscribtion`
  const output = useSWR(url, (url) => {
    return fetcher()
      .get<ApiResponse<Subscription[]>>(url)
      .then(({ data }) => {
        return data?.data
      })
  })
  return output
}

export const useSubscriptionPaginate = (payload: SubscriptionListPaginate) => {
  const { fetcher } = useAuth()
  const url = `/subscribtion`
  let body = JSON.stringify(payload)

  if (payload?.orders && payload?.orders.length) {
    let orderParams = {}
    const sort = payload.orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    const { orders, ...restparams } = payload
    body = JSON.stringify({ ...restparams, ...orderParams })
  }

  const output = useSWR([url, body], (url, body) => {
    return fetcher()
      .get<ApiResponse<Subscription[]>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data?.data
      })
  })
  return output
}

export const useSubscriptionById = (uuid: string) => {
  const { fetcher } = useAuth()
  const url = `/subscribtion/${uuid}`
  const output = useSWR(url, (url) => {
    return fetcher()
      .get<ApiResponse<Subscription>>(url)
      .then(({ data }) => {
        return data?.data
      })
  })
  return output
}

export const useInvoiceById = (uuid: string) => {
  const { fetcher } = useAuth()
  const url = `/subscribtion/invoice/detail/${uuid}`
  const output = useSWR(url, (url) => {
    return fetcher()
      .get(url)
      .then(({ data }) => {
        return data?.data
      })
  })
  return output
}

export const downloadFile = async (file_url: string, filename: string) => {
  try {
    const response = await fetch(file_url)
    if (!response.ok) {
      throw new Error("Failed to fetch PDF")
    }
    const blob = await response.blob()

    const blobUrl = window.URL.createObjectURL(blob)

    const anchor = document.createElement("a")
    anchor.style.display = "none"
    document.body.appendChild(anchor)

    anchor.href = blobUrl
    anchor.download = filename
    anchor.click()

    document.body.removeChild(anchor)
    window.URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error("Error downloading file:", error)
  }
}
