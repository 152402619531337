import { jsPDF } from "jspdf"
import autoTable from "jspdf-autotable"
import moment from "moment"
import templates from "../../../utils/template-configs"

export const exportPDFDetail = async (data) => {
  const platform = process.env.PLATFORM || "intusi"
  const pdf = new jsPDF()
  const font = undefined

  const { origin } = window.location
  const now = moment().format("dddd, DD MMMM YYYY HH:mm:ss")
  const pageWidth = pdf.internal.pageSize.getWidth()
  const coordinateX = 12
  const coordinateY = 12

  const downloadDateFontSize = 8
  pdf.setFontSize(downloadDateFontSize)
  pdf.text(origin, coordinateX, 6)
  const downloadDate = `Downloaded at ${now}`
  const downloadDateTextWidth = (pdf.getStringUnitWidth(downloadDate) * downloadDateFontSize) / pdf.internal.scaleFactor
  const downloadDateX = pageWidth - downloadDateTextWidth - coordinateX
  pdf.text(downloadDate, downloadDateX, 6)

  const logoX = pageWidth / 2 - 16
  const logo = templates[platform].pdf.logo
  pdf.addImage(logo, "PNG", logoX, coordinateY, 32, 12)

  const titleFontSize = 11
  pdf.setFontSize(titleFontSize)
  pdf.setFont(font, "bold")
  const titleY = coordinateY + 22
  pdf.text("Site Data Detail", coordinateX, titleY)

  pdf.setFont(font, "normal")
  pdf.setFontSize(10)

  const uuid_platform_name = `UUID ${platform.toUpperCase()}`
  const labels = ["Company Name", "Site Name", "Logger ID", "UUID KLHK", uuid_platform_name, "Coordinate", "Contact Person", "Users"]
  let labelY = titleY + 8
  for (let index = 0; index < labels.length; index++) {
    pdf.text(labels[index], coordinateX, labelY)
    labelY += 8
  }

  let colonY = titleY + 8
  const colonX = coordinateX + 30
  for (let index = 0; index < labels.length; index++) {
    pdf.text(":", colonX, colonY)
    colonY += 8
  }

  const values = [
    data?.company?.name,
    data?.name,
    data?.uuid,
    data?.uid_klhk,
    data?.uid_intusi,
    `${data?.coordinate?.lat}, ${data?.coordinate?.long}`,
    `${data?.pic_technical_name} (${data?.pic_technical_phone} / ${data?.pic_technical_email})`,
  ]

  pdf.setFont(font, "bold")
  let valuesY = titleY + 8
  const valuesX = coordinateX + 35
  for (let index = 0; index < values.length; index++) {
    pdf.text(values[index], valuesX, valuesY)
    valuesY += 8
  }

  if (data?.users?.length) {
    data?.users?.forEach((user, index) => {
      const newUser = `${index + 1}. ${user?.name} (${user?.email})`
      pdf.text(newUser, valuesX, valuesY)
      valuesY += 6
    })
  } else {
    pdf.text("-", valuesX, valuesY)
    valuesY += 6
  }

  pdf.setFont(font, "normal")
  const parametersY = valuesY + 2
  pdf.text("Parameters", coordinateX, parametersY)

  const tableHead = [["Name", "Unit", "Lower Threshold", "Upper Threshold", "Has Load"]]
  const params = data?.parameters?.sort((a, b) => a?.name?.localeCompare(b?.name))
  const tableBody = params?.map((item) => [
    { content: item?.name, styles: { halign: "left" } },
    { content: item?.uom, styles: { halign: "left" } },
    { content: item?.lower_threshold, styles: { halign: "left" } },
    { content: item?.upper_threshold, styles: { halign: "left" } },
    { content: item?.has_load ? "Yes" : "No", styles: { halign: "left" } },
  ])

  const tableY = parametersY + 2
  autoTable(pdf, {
    head: tableHead,
    body: tableBody,
    startY: tableY,
    margin: { left: coordinateX, right: coordinateY },
    theme: "grid",
    styles: { lineColor: "#6c757d", lineWidth: 0.1 },
    headStyles: { halign: "center", fillColor: "#51bcda" },
    footStyles: { fillColor: "#ffffff", textColor: "#000000", fontStyle: "normal" },
  })

  const date = moment().format("DD MMM YYYY")
  const subject = `Site Data ${data?.name}`
  const fileTitle = `${subject} ${date}`
  pdf.setProperties({
    title: fileTitle,
    subject,
    author: templates[platform].pdf.author,
    keywords: "site data, titik penaatan",
    creator: templates[platform].pdf.creator,
  })
  pdf.save(`${fileTitle}.pdf`)
}
