import moment from "moment"
import { Period } from "../enum/period.enum"

export const formatDate = (date, format) => {
  if (date) {
    const newDate = new Date(date)
    return moment(newDate).format(format)
  }
  return
}

export const formatUnixDate = (date, format) => {
  if (date) {
    moment.locale("id")
    return moment.unix(date).format(format)
  }
  return
}

export const customFormatDate = (date, incomingFormat, format) => {
  if (date) {
    return moment(date, incomingFormat).format(format)
  }
  return
}

export const formatDateWithPeriod = (date, period) => {
  if (!date) return
  if (period === Period.monthly) {
    return formatDate(date, "MMMM YYYY")
  } else if (period === Period.daily) {
    return formatDate(date, "DD MMMM YYYY")
  }
  return formatDate(date, "DD MMMM YYYY HH:mm")
}

export const dateWithPeriod = ({ date, inFormat = "X", period }) => {
  const timestampDate = customFormatDate(date, inFormat, "DD MMMM YYYY HH:mm")
  return formatDateWithPeriod(timestampDate, period)
}

export const chartDateWithPeriod = (date, period, moreThanOneDay = false) => {
  if (!date) return
  if (period === Period.monthly) {
    return formatDate(date, "MMM")
  } else if (period === Period.daily) {
    return formatDate(date, "DD MMM")
  }
  if (moreThanOneDay) return formatDate(date, "DD/MM/YY HH:mm")
  return formatDate(date, "HH:mm")
}

export const getDateDifference = ({ firstDate, secondDate, measurement = "days" }) => {
  if (!firstDate || !secondDate) return
  return moment(new Date(secondDate)).diff(moment(new Date(firstDate)), measurement)
}

export function toIsoString(date: Date) {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num: number) {
      const norm = Math.floor(Math.abs(num))
      return (norm < 10 ? "0" : "") + norm
    }

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`
}
