import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { formatDate } from "../../../utils/dateformat"
import RealtimeGraph from "./graph"
import RealtimeRecord from "./record"
import RealtimeErrorLog from "./error-log"
import RealtimeNCR from "./ncr"
import RealtimeSiteDetail from "./site-detail"
import CardIcon from "../monitoring/icon"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type DetailsSummaryCardProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  order: string | number
  cem: any
}

const DetailsSummaryCard: FunctionComponent<DetailsSummaryCardProps> = ({ order, cem }) => {
  const { t } = useTranslation()
  const [menu, setMenu] = useState("")

  const onMenuShow = (menu) => {
    setMenu((prevMenu) => (prevMenu === menu ? "" : menu))
  }

  const getMenuButtonStyle = (menu, active) => {
    const buttonStyle = menu === active ? `${style["menu_button_active"]}` : ``
    return `${style["menu_button"]} ${buttonStyle}`
  }

  const getStatusColor = (status) => {
    const colors = [
      { status: "lost", color: "#A4A6B5" },
      { status: "error", color: "#E54C3C" },
      { status: "warning", color: "#EDC30F" },
      { status: "normal", color: "#3CC578" },
    ]
    return colors?.find((color) => color?.status === status)?.color
  }

  return (
    <div className="mt-2">
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex gap-2">
          <div>
            <div className={style.summary_site_number}>{order + 1}</div>
          </div>
          <div className="fw-bold">{cem?.name}</div>
          <div>{formatDate(cem?.timestamp, "DD/MM/YYYY HH:mm")}</div>
        </div>
        <div className="d-flex gap-1">
          <button className={getMenuButtonStyle(`graph-${order}`, menu)} onClick={() => onMenuShow(`graph-${order}`)}>
            {t("page.realtime.graph")}
          </button>
          <button className={getMenuButtonStyle(`record-${order}`, menu)} onClick={() => onMenuShow(`record-${order}`)}>
            {t("page.realtime.record")}
          </button>
          <button className={getMenuButtonStyle(`error_log-${order}`, menu)} onClick={() => onMenuShow(`error_log-${order}`)}>
            {t("page.realtime.error_log")}
          </button>
          <button className={getMenuButtonStyle(`ncr-${order}`, menu)} onClick={() => onMenuShow(`ncr-${order}`)}>
            {t("page.realtime.ncr")}
          </button>
          <button className={getMenuButtonStyle(`site_detail-${order}`, menu)} onClick={() => onMenuShow(`site_detail-${order}`)}>
            {t("page.realtime.sensor_detail")}
          </button>
        </div>
      </div>
      <div className={style.realtime_card_wrapper}>
        {cem?.data?.map((param, index) => (
          <div className={`${param?.is_error ? style.error : style.normal}`} key={index}>
            <div className="d-flex align-items-center gap-4 justify-content-between">
              <div className="d-flex align-items-center">
                <CardIcon color={getStatusColor(param?.is_error ? "error" : "normal")} />
                <div className="ms-2">
                  <div className="fw-bold">{param?.name}</div>
                  <div className="fs-5 fw-bold">
                    {param?.value} <sup className="fw-normal">{param?.unit}</sup>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className={style.device_status}>Status</div>
                <div className={style.device_status_value}>{param?.is_error ? "Error" : "Normal"}</div>
              </div>
            </div>
            <div className={style.bmal_treshold}>
              <div>{t("page.realtime.last_update")}</div>
              <div className={style.bmal_treshold_value}>{formatDate(cem?.timestamp, "DD/MM/YYYY HH:mm") || "-"}</div>
            </div>
            <div className={style.bmal_treshold}>
              <div>BMAL</div>
              <div className={style.bmal_treshold_value}>
                {param?.lower_threshold} - {param?.upper_threshold}
              </div>
            </div>
            <div className={style.calculate}>
              <div>
                <div>{t("page.realtime.min")}:</div>
                <div className={style.calculate_value}>{param?.min}</div>
              </div>
              <div>
                <div>{t("page.realtime.max")}:</div>
                <div className={style.calculate_value}>{param?.max}</div>
              </div>
              <div>
                <div>{t("page.realtime.avg")}:</div>
                <div className={style.calculate_value}>{param?.avg}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4 w-100">
        {menu === `graph-${order}` && <RealtimeGraph uuid={cem?.uuid} params={cem?.data?.map(({ name }) => name)} />}
        {menu === `record-${order}` && <RealtimeRecord uuid={cem?.uuid} />}
        {menu === `error_log-${order}` && <RealtimeErrorLog uuid={cem?.uuid} />}
        {menu === `ncr-${order}` && <RealtimeNCR uuid={cem?.uuid} />}
        {menu === `site_detail-${order}` && <RealtimeSiteDetail uuid={cem?.uuid} />}
      </div>
    </div>
  )
}

export default DetailsSummaryCard
