import { h } from "preact"
import { useState } from "preact/hooks"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"

type Props = {
  uuid: string
  name: string
  show: boolean
  mutate: void
  onClose: void
}

const EditModule = ({ uuid, name, mutate, show, onClose }: Props) => {
  const { fetcher } = useAuth()
  const [newName, setNewName] = useState(name)

  const handleSubmit = (event) => {
    event.preventDefault()
    fetcher()
      .patch(`/list-feature/${uuid}`, { name: newName })
      .then(() => {
        mutate()
        onClose()
      })
  }

  return (
    <form
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
      onSubmit={(event) => handleSubmit(event)}>
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content card px-3 py-3">
          <div className="card-body text-center h5">Edit Feature</div>
          <div className="card-body">
            <div class="mb-4">
              <label class="form-label">Feature Name</label>
              <input
                type="text"
                class="form-control rounded mw-100"
                required
                value={newName}
                onChange={({ currentTarget }) => setNewName(currentTarget.value)} />
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onClose} type="button" variant="tertiary" className="m-1">
              Cancel
            </Button>
            <Button variant="primary" type="submit" className="m-1">
              Save
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditModule