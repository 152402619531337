import { FunctionComponent, h } from "preact"
import { Link } from "preact-router"
import { HiArrowLeft } from "react-icons/hi"
import { FaRegFilePdf } from "react-icons/fa6"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import { useSiteById } from "../../../hooks/useSite"
import Spinner from "../../../components/spinner"
import Container from "../../../parts/container"
import { Button } from "../../../parts/buttons"
import { sortParam } from "../../../utils/sortParam"
import { exportPDFDetail } from "./export-pdf"
import { can } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type SiteDataDetailProps = h.JSX.HTMLAttributes<HTMLDivElement>

const SiteDataDetail: FunctionComponent<SiteDataDetailProps> = () => {
  const { t } = useTranslation()
  const pathname = window.location.pathname
  const uuid = pathname.split("/").length === 5 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""
  const { data } = useSiteById(uuid)
  const parameters = sortParam(data?.parameters)

  return (
    <div>
      <Spinner show={!data} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.settings")} link="" />
        <Breadcrumb name={t("breadcrumb.site_data")} link="/settings/site-data" />
        <Breadcrumb name={t("breadcrumb.site_data_detail")} link={`/settings/site-data/detail/${uuid}`} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <Link className={style.back_button} href="/settings/site-data">
                <HiArrowLeft size="1.2rem" />
                {t("buttons.back")}
              </Link>
              {can(Permission.SettingsSiteDataExportDetailToPdf) && (
                <div>
                  <Button variant="primary" className="btn-sm" icon={FaRegFilePdf} onClick={() => exportPDFDetail(data)}>
                    {t("buttons.download_as_pdf")}
                  </Button>
                </div>
              )}
            </div>
            <p className={`${style.title} mb-4`}>{t("site_data_detail")}</p>
            <div className={style.detail}>
              <div className={style.label}>{t("company_name")}</div>
              <div className={style.value}>{data?.company?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("site_name")}</div>
              <div className={style.value}>{data?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>Logger ID</div>
              <div className={style.value}>{data?.uuid}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>UUID KLHK</div>
              <div className={style.value}>{data?.uid_klhk}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>UUID INTUSI</div>
              <div className={style.value}>{data?.uid_intusi}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.modules")}</div>
              <div className={style.value}>{data?.modules?.map(({ name }) => name)?.join(", ") || "-"}</div>
            </div>
            <div className="row">
              <div className="col-2">
                <div className={style.detail}>
                  <div className={style.label}>{t("label.latitude")}</div>
                  <div className={style.value}>{data?.coordinate?.lat}</div>
                </div>
              </div>
              <div className="col-2">
                <div className={style.detail}>
                  <div className={style.label}>{t("label.longitude")}</div>
                  <div className={style.value}>{data?.coordinate?.long}</div>
                </div>
              </div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.contact_person")}</div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>{t("label.name")}</div>
                <div className={style.sub_value_content}>{data?.pic_technical_name}</div>
              </div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>{t("label.email")}</div>
                <div className={style.sub_value_content}>{data?.pic_technical_email}</div>
              </div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>{t("label.phone")}</div>
                <div className={style.sub_value_content}>{data?.pic_technical_phone}</div>
              </div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.users")}</div>
              <div className={style.value}>
                <ol className={style.list}>
                  {data?.users?.map((user) => (
                    <li key={user?.uuid}>
                      {user?.name} - {user?.email}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>Logo</div>
              <Button
                variant="primary"
                className="btn-sm"
                onClick={() => {
                  window.open(data?.logo)
                }}
                disabled={!data?.logo}
              >
                {data?.logo ? t("view_logo") : t("no_uploaded_logo")}
              </Button>
            </div>
            <div className={style.detail}>
              <div className={style.parameter}>{t("label.parameters")}</div>
              <hr />
              <div className="row mb-2">
                <div className={`${style.label} col`}>{t("label.name")}</div>
                <div className={`${style.label} col`}>{t("label.unit")}</div>
                <div className={`${style.label} col`}>{t("lower_threshold")}</div>
                <div className={`${style.label} col`}>{t("upper_threshold")}</div>
                <div className={`${style.label} col`}>{t("has_load")}</div>
              </div>
              {parameters?.map((item) => (
                <div className="row" key={item?.uuid}>
                  <div className={`${style.value} col mb-2`}>{item?.name}</div>
                  <div className={`${style.value} col`}>{item?.uom || "-"}</div>
                  <div className={`${style.value} col`}>{item?.lower_threshold}</div>
                  <div className={`${style.value} col`}>{item?.upper_threshold}</div>
                  <div className={`${style.value} col`}>{item?.has_load ? t("yes") : t("no")}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default SiteDataDetail
