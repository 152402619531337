import { Fragment, FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import Spinner from "../../../components/spinner"
import { useGetDummyData } from "../../../hooks/useDummyData"
import useSites from "../../../hooks/useSite"
import { formatDate } from "../../../utils/dateformat"
import { generateUniqueId } from "../../../utils/id-generator"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type DummyDataDetailProps = h.JSX.HTMLAttributes<HTMLDivElement>

const DummyDataDetail: FunctionComponent<DummyDataDetailProps> = () => {
  const { t } = useTranslation()
  const pathname = window.location.pathname
  const uuid = pathname.split("/").length === 5 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""
  const { data: newData } = useGetDummyData(uuid)
  const { data: cems } = useSites()
  const [data, setData] = useState()
  const payloads = data?.payload?.map(({ name, status, value, ...payload }) => {
    const customPayload = Object.entries(payload).map(([name, { type, value }]) => ({
      name,
      type,
      min: value?.min,
      max: value?.max,
      id: generateUniqueId(),
      status: status?.value ? t("label.active") : t("label.inactive"),
    }))
    return [
      {
        name: name?.value,
        min: value?.value?.min,
        max: value?.value?.max,
        type: value?.type,
        id: generateUniqueId(),
        status: status?.value ? t("label.active") : t("label.inactive"),
      },
      ...customPayload,
    ]
  })

  useEffect(() => {
    const site = cems?.find(({ uuid }) => uuid === newData?.logger_id)

    setData({
      ...newData,
      company: site?.company?.name,
      site: site?.name,
    })
  }, [newData, cems])

  return (
    <div>
      <TopBar>
        <Breadcrumb name={t("breadcrumb.tools")} link="" />
        <Breadcrumb name={t("breadcrumb.dummy_data")} link="/tools/dummy-data" />
        <Breadcrumb name={t("breadcrumb.dummy_data_detail")} link={`/tools/dummy-data/detail/${uuid}`} />
      </TopBar>
      {data && Array.isArray(cems) ? (
        <Container>
          <div className="card">
            <div className="card-body">
              <h1 className={style.page_title}>{t("Dummy Data Detail")}</h1>
              <div className={style.detail}>
                <div className={style.label}>{t("label.name")}</div>
                <div className={style.value}>{data?.name}</div>
              </div>
              <div className={style.detail}>
                <div className={style.label}>{t("label.protocol")}</div>
                <div className={style.value}>{data?.connection?.name}</div>
              </div>
              {data?.connection?.name === "MQTT" && (
                <Fragment>
                  <div className={style.detail}>
                    <div className={style.label}>{t("label.host")}</div>
                    <div className={style.value}>{data?.connection?.config?.mqtt_host}</div>
                  </div>
                  <div className={style.detail}>
                    <div className={style.label}>{t("label.port")}</div>
                    <div className={style.value}>{data?.connection?.config?.mqtt_port}</div>
                  </div>
                  <div className={style.detail}>
                    <div className={style.label}>{t("label.topic")}</div>
                    <div className={style.value}>{data?.connection?.config?.mqtt_topic}</div>
                  </div>
                  <div className={style.detail}>
                    <div className={style.label}>{t("label.username")}</div>
                    <div className={style.value}>{data?.connection?.config?.mqtt_username}</div>
                  </div>
                  <div className={style.detail}>
                    <div className={style.label}>{t("label.password")}</div>
                    <div className={style.value}>{data?.connection?.config?.mqtt_password}</div>
                  </div>
                </Fragment>
              )}
              {data?.connection?.name === "REST" && (
                <Fragment>
                  <div className={style.detail}>
                    <div className={style.label}>GET URL</div>
                    <div className={style.value}>{data?.connection?.config?.get_url}</div>
                  </div>
                  <div className={style.detail}>
                    <div className={style.label}>POST URL</div>
                    <div className={style.value}>{data?.connection?.config?.post_url}</div>
                  </div>
                </Fragment>
              )}
              <div className={style.detail}>
                <div className={style.label}>{t("company")}</div>
                <div className={style.value}>{data?.company}</div>
              </div>
              <div className={style.detail}>
                <div className={style.label}>{t("site")}</div>
                <div className={style.value}>{data?.site}</div>
              </div>
              <div className={style.detail}>
                <div className={style.label}>Logger UUID</div>
                <div className={style.value}>{data?.logger_id}</div>
              </div>
              <div className={style.detail}>
                <div className={style.label}>{t("label.delivery_interval")}</div>
                <div className={style.value}>{data?.interval} Seconds</div>
              </div>
              <div className={style.detail}>
                <div className={style.label}>{t("label.start_date")}</div>
                <div className={style.value}>{formatDate(data?.start, "DD-MM-YYYY HH:mm:ss")}</div>
              </div>
              <div className={style.detail}>
                <div className={style.label}>{t("label.end_date")}</div>
                <div className={style.value}>{formatDate(data?.end, "DD-MM-YYYY HH:mm:ss")}</div>
              </div>
              <div className={style.detail}>
                <div className={style.label}>Status</div>
                <div className={style.value}>
                  <div className={`${data?.status === "STARTED" ? style.running : style.stopped}`}>
                    {data?.status === "STARTED" ? t("Running") : t("Stopped")}
                  </div>
                </div>
              </div>
              <div className={style.detail}>
                <div className={style.label}>{t("label.description")}</div>
                <div className={style.value}>{data?.description}</div>
              </div>
              <div className="fw-bold mb-2">{t("label.dummy_data_payload")}</div>
              <div className={style.summary}>{t("label.parameter_payload")}</div>
              {payloads?.map((payload, index) => (
                <div key={index} className="border border-primary rounded px-3 py-3 mb-2">
                  <div className="row mb-1">
                    <div className="col-4">
                      <div className={style.label}>{t("label.parameter_name")}</div>
                    </div>
                    <div className="col-2">
                      <div className={style.label}>{t("label.type")}</div>
                    </div>
                    <div className="col-2">
                      <div className={style.label}>{t("min")}</div>
                    </div>
                    <div className="col-2">
                      <div className={style.label}>{t("max")}</div>
                    </div>
                    <div className="col-2">
                      <div className={style.label}>{t("label.status")}</div>
                    </div>
                  </div>
                  {payload?.map((field) => (
                    <div className="row mb-1" key={field?.id}>
                      <div className="col-4">
                        <div className={style.value}>{field?.name}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.value}>{field?.type}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.value}>{field?.min}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.value}>{field?.max}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.value}>{field?.status}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              {Array.isArray(data?.payload_device) && !!data?.payload_device?.length && (
                <Fragment>
                  <div className={style.summary}>{t("label.device_payload")}</div>
                  {data?.payload_device?.map((device, index) => (
                    <div key={index} className="border border-primary rounded px-3 py-3 mb-2">
                      <div className="row mb-1">
                        <div className="col-4">
                          <div className={style.label}>{t("label.parameter_name")}</div>
                        </div>
                        <div className="col-4">
                          <div className={style.label}>{field?.min}</div>
                        </div>
                        <div className="col-4">
                          <div className={style.label}>{field?.max}</div>
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <div className={style.value}>{device?.name}</div>
                        </div>
                        <div className="col-4">
                          <div className={style.value}>{device?.min}</div>
                        </div>
                        <div className="col-4">
                          <div className={style.value}>{device?.max}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
            </div>
          </div>
        </Container>
      ) : (
        <Spinner show={true} />
      )}
    </div>
  )
}

export default DummyDataDetail
