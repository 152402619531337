import { FunctionComponent, h } from "preact"
import { Chart as ChartJS, ChartData, ChartOptions } from "chart.js"
import { Line as ChartLine } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"
import { chartDateWithPeriod, getDateDifference } from "../../utils/dateformat"

export type DatasetType = {
  data: number[]
  label?: string
  color: string
}
type DataType = {
  dataset: DatasetType[]
  threshold: number
}

type LineChartProps = {
  data: DataType[]
  labels: string[]
  period: string
}
  
const DeviceLineChart: FunctionComponent<LineChartProps> = ({ data, labels, period }) => {
  const dataset = data?.map(({ dataset }) => {
    return {
      ...dataset,
      data: [...(dataset?.data || [])]?.reverse()
    }
  }) || []
  const date = labels?.reverse() || []
  const isMoreThanOneDay = !!getDateDifference({ firstDate: date?.[0], secondDate: date?.at(-1) })
  const label = date?.map((item) => chartDateWithPeriod(item, period, isMoreThanOneDay))

  const chartOptions: ChartOptions<"line"> = {
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      xAxis: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          maxRotation: 0,
          minRotation: 0
        }
      },
    }
  }
  const chartData: ChartData<"line", number[], string> = {
    labels: label,
    datasets: [...dataset]
  } 
  ChartJS.register(annotationPlugin)
  return (
    <ChartLine options={chartOptions} data={chartData} />
  )
}

export default DeviceLineChart