import { h, FunctionComponent } from "preact"
import { Button } from "../../../parts/buttons"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type CompanyDetailProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
}

const CompanyDetail: FunctionComponent<CompanyDetailProps> = ({ data, show, onHide }) => {
  const { t } = useTranslation()
  return (
    <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}>
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content card px-2 py-1">
          <div className="card-body text-center h5">{t("company_detail")}</div>
          <div className="card-body">
            <div className={style.detail}>
              <div className={style.label}>{t("label.name")}</div>
              <div className={style.value}>{data?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.address")}</div>
              <div className={style.value}>{data?.address}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.coordinate")} (Latitude, Longitude)</div>
              <div className={style.value}>
                {data?.coordinate?.lat}, {data?.coordinate?.long}
              </div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.platform")}</div>
              <div className={style.value}>{data?.platform?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.vendor")}</div>
              <div className={style.value}>{data?.vendor?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.modules")}</div>
              <div className={style.value}>{data?.modules?.map(({ name }) => name)?.join(", ")}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.sites")}</div>
              {data?.sites?.map(({ name, uuid }) => (
                <div className={style.value} key={uuid}>
                  {name}
                </div>
              ))}
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.finance_contact_person")}</div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>Name</div>
                <div className={style.sub_value_content}>{data?.pic_finance_name}</div>
              </div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>Email</div>
                <div className={style.sub_value_content}>{data?.pic_finance_email}</div>
              </div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>Phone</div>
                <div className={style.sub_value_content}>{data?.pic_finance_phone}</div>
              </div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.technical_contact_person")}</div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>{t("label.name")}</div>
                <div className={style.sub_value_content}>{data?.pic_technical_name}</div>
              </div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>{t("label.email")}</div>
                <div className={style.sub_value_content}>{data?.pic_technical_email}</div>
              </div>
              <div className="d-flex gap-2">
                <div className={style.sub_value}>{t("label.phone")}</div>
                <div className={style.sub_value_content}>{data?.pic_technical_phone}</div>
              </div>
            </div>
            <div>
              <div className={style.label}>Logo</div>
              <div className={style.value}>
                <img src={data?.logo} height={64} />
                {data?.logo && (
                  <Button
                    variant="primary"
                    type="button"
                    className="m-1 ms-4 btn-sm"
                    disabled={!data?.logo}
                    onClick={() => {
                      window.open(data?.logo)
                    }}
                  >
                    {t("buttons.open_in_new_tab")}
                  </Button>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button onClick={onHide} type="button" variant="tertiary" className="m-1">
                {t("buttons.close")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyDetail
