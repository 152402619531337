import { h, FunctionComponent } from "preact"
import { Button } from "../../../parts/buttons"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type VendorDetailProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
}

const VendorDetail: FunctionComponent<VendorDetailProps> = ({ data, show, onHide }) => {
  const { t } = useTranslation()
  return (
    <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}>
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content card px-2 py-1">
          <div className="card-body text-center h5">{t("vendor_detail")}</div>
          <div className="card-body">
            <div className={style.detail}>
              <div className={style.label}>{t("label.name")}</div>
              <div className={style.value}>{data?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.address")}</div>
              <div className={style.value}>{data?.address}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.platform")}</div>
              <div className={style.value}>{data?.platform?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.modules")}</div>
              <div className={style.value}>{data?.modules?.map(({ name }) => name)?.join(", ")}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.contact_person_name")}</div>
              <div className={style.value}>{data?.pic}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.NPWP_number")}</div>
              <div className={style.value}>{data?.npwp}</div>
            </div>
            <div className="fw-bold mb-2">{t("label.account_information")}</div>
            {data?.payment_info?.map((account, index) => (
              <div key={index} className="border border-primary rounded px-3 py-2 mb-2 d-flex justify-content-between gap-4">
                <div className={style.detail}>
                  <div className={style.label}>{t("label.account", { number: index + 1 })}</div>
                  <div className="d-flex gap-2">
                    <div className={style.sub_account}>{t("label.bank_name")}</div>
                    <div className={style.sub_value_content}>{account?.name}</div>
                  </div>
                  <div className="d-flex gap-2">
                    <div className={style.sub_account}>{t("label.branch")}</div>
                    <div className={style.sub_value_content}>{account?.description}</div>
                  </div>
                  <div className="d-flex gap-2">
                    <div className={style.sub_account}>{t("label.account_number")}</div>
                    <div className={style.sub_value_content}>{account?.account_number}</div>
                  </div>
                  <div className="d-flex gap-2">
                    <div className={style.sub_account}>{t("label.account_name")}</div>
                    <div className={style.sub_value_content}>{account?.account_name}</div>
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-end">
              <Button onClick={onHide} type="button" variant="tertiary" className="m-1">
                {t("buttons.close")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorDetail
