import { FunctionComponent, h } from "preact"

type RecordIconProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  className: string
}
const RecordIcon: FunctionComponent<RecordIconProps> = ({ className }) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M11.4327 20H4C3.42615 20 2.93401 19.8834 2.5923 19.6097C2.27183 19.3531 2 18.8902 2 18V4C2 3.10981 2.27183 2.64692 2.5923 2.39027C2.93401 2.11661 3.42615 2 4 2H14.8171C15.3851 2 15.9321 2.11642 16.326 2.40981C16.6935 2.68344 17 3.16468 17 4.07037V9.11204C16.9738 9.12344 16.9417 9.13596 16.9034 9.1489C16.7073 9.2151 16.4899 9.24638 16.4121 9.24638C16.3903 9.24638 16.361 9.24277 16.3295 9.23373C16.3076 9.22747 16.2889 9.21983 16.2741 9.21228V4.08137C16.2741 3.68878 16.1925 3.28933 15.9008 2.9977C15.6092 2.70606 15.2097 2.62438 14.8171 2.62438H4.35614C3.96365 2.62438 3.54399 2.70305 3.20709 2.93248C2.84436 3.17949 2.62414 3.57171 2.62414 4.07037V17.9304C2.62414 18.4369 2.85669 18.8281 3.22275 19.0714C3.56316 19.2977 3.98559 19.3764 4.37814 19.3764H11.5278C11.5289 19.3778 11.53 19.3793 11.5312 19.3809C11.544 19.3987 11.5569 19.4232 11.5662 19.4511C11.5755 19.4794 11.5781 19.502 11.5781 19.5144C11.5781 19.5326 11.573 19.5884 11.5528 19.6737C11.5337 19.7544 11.5059 19.8407 11.4732 19.9166C11.4586 19.9505 11.4448 19.9782 11.4327 20ZM17.0883 9.06353C17.0885 9.06354 17.0869 9.06507 17.0828 9.06807C17.0861 9.06502 17.0882 9.06352 17.0883 9.06353ZM11.3929 20.06C11.3909 20.0623 11.3898 20.0632 11.3898 20.0631C11.3898 20.063 11.3909 20.0619 11.3929 20.06Z" fill="currentColor" stroke="currentColor" />
      <path d="M16.5764 20.1407C19.0844 20.1407 21.0974 18.1167 21.0974 15.6197C21.0974 13.1227 19.0734 11.0987 16.5764 11.0987C14.0794 11.0987 12.0554 13.1227 12.0554 15.6197C12.0554 18.1167 14.0684 20.1407 16.5764 20.1407ZM16.5764 12.0557C18.5014 12.0557 20.1404 13.5957 20.1404 15.6197C20.1404 17.5447 18.6004 19.1837 16.5764 19.1837C14.6514 19.1837 13.0124 17.6437 13.0124 15.6197C13.0124 13.6947 14.6514 12.0557 16.5764 12.0557ZM4.92736 6.47874H13.9694C14.2554 6.47874 14.4534 6.28074 14.4534 5.99474C14.4534 5.70874 14.2554 5.51074 13.9694 5.51074H4.92736C4.64136 5.51074 4.44336 5.70874 4.44336 5.99474C4.44336 6.28074 4.63036 6.47874 4.92736 6.47874ZM8.49136 15.5207H4.92736C4.64136 15.5207 4.44336 15.7187 4.44336 16.0047C4.44336 16.2907 4.64136 16.4887 4.92736 16.4887H8.49136C8.77736 16.4887 8.97536 16.2907 8.97536 16.0047C8.97536 15.7187 8.77736 15.5207 8.49136 15.5207ZM10.1194 12.1547H4.92736C4.64136 12.1547 4.44336 12.3527 4.44336 12.6387C4.44336 12.9247 4.64136 13.1227 4.92736 13.1227H10.1194C10.4054 13.1227 10.6034 12.9247 10.6034 12.6387C10.6034 12.3527 10.4164 12.1547 10.1194 12.1547ZM13.9694 8.87674H4.92736C4.64136 8.87674 4.44336 9.07474 4.44336 9.36074C4.44336 9.64674 4.64136 9.84474 4.92736 9.84474H13.9694C14.2554 9.84474 14.4534 9.64674 14.4534 9.36074C14.4534 9.07474 14.2664 8.87674 13.9694 8.87674Z" fill="currentColor" />
    </svg>
  )
}

export default RecordIcon
