import { useEffect, useState } from "preact/hooks"
import templates from "../utils/template-configs"
import usePlatformApp from "./usePlatformApp"
import { getLocalStorage } from "../utils/local-storage"

const useTheme = () => {
  const { platform } = usePlatformApp()

  const setTheme = () => {
    setColorTheme()
  }

  const setColorTheme = () => {
    const settings = getPlatformSetting()
    const root = document.querySelector(":root")
    root.style.setProperty("--bs-navbar", settings?.menu_background_color || templates[platform].colors.navbar)
    root.style.setProperty("--bs-navbar-rgb", settings?.menu_background_color || templates[platform].colors.navbar)
    root.style.setProperty("--bs-topbar-breadchrumb", settings?.accent_font_color || templates[platform].colors.primary)
    root.style.setProperty("--bs-topbar-breadchrumb-rgb", settings?.accent_font_color || templates[platform].colors.primary)
  }

  const getPlatformSetting = () => {
    const platformSettings = getLocalStorage("x-platform-settings")
    if (platformSettings) {
      const platform = JSON.parse(platformSettings)
      return platform
    }
  }

  return { setTheme, getPlatformSetting }
}

export default useTheme
