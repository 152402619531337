import { FunctionComponent, h } from "preact"
import { BiChevronLeft,  BiChevronRight, BiChevronsLeft, BiChevronsRight } from "react-icons/bi"
import style from "./style.scss"

type ServerPaginationProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  current: number | string
  last: number | string
  onPageChange: void
}

const ServerPagination: FunctionComponent<ServerPaginationProps> = ({ current = 1, last = 0, onPageChange }) => {
  const currentPage = +current
  const lastPage = +last
  const pages = lastPage ? Array.from({ length: lastPage }, (_, i) => i + 1) : []
  
  return (
    <div className={style.pagination}>
      <button className={style.left} onClick={() => onPageChange(1)} disabled={currentPage <= 1} title="First">
        <BiChevronsLeft />
      </button>
      <button className={style.left} onClick={() => onPageChange(currentPage - 1)} disabled={currentPage <= 1} title="Previous">
        <BiChevronLeft />
      </button>
      {pages.map((page) => page > currentPage - 3 && page < currentPage + 3 && (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`${style.item} ${page === currentPage ? style.active : ""}`}>
          {page}
        </button>
      ))}
      <button className={style.right} onClick={() => onPageChange(currentPage + 1)} disabled={currentPage >= lastPage} title="Next">
        <BiChevronRight />
      </button>
      <button className={style.right} onClick={() => onPageChange(lastPage)} disabled={currentPage >= lastPage} title="Last">
        <BiChevronsRight />
      </button>
    </div>
  )
}

export default ServerPagination