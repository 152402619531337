import { FunctionComponent, h } from "preact"
import { Link } from "preact-router"
import style from "./style.scss"

type BreadcrumbLinkProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  name: string
  link: string
}

const BreadcrumbLink: FunctionComponent<BreadcrumbLinkProps> = ({ name, link }) => {
  const pathname = window.location.pathname;
  
  return (
    <Link href={link} className={pathname === link ? style.active : ""}>{name}</Link>
  )
}

export default BreadcrumbLink
