import { Fragment, h } from "preact"
import { useState } from "preact/hooks"
import RecordChart from "../../components/chart/record"
import style from "./style.scss"
import { chartDateWithPeriod, getDateDifference } from "../../utils/dateformat"
import { toFixed } from "../../utils/toFixed"
import { useTranslation } from "react-i18next"

type ChartData = {
  name: string
  lower_threshold: number
  upper_threshold: number
  min?: number
  max?: number
  avg?: number
  last: number
  label: Date[]
  dataset: number[]
}

type Props = {
  data: ChartData
  height?: number | string
  period: string
  calculation: boolean
}

const RecordLineChart = ({ data, height, period, calculation }: Props) => {
  const { t } = useTranslation()
  const dataset = data?.dataset?.map((item) => toFixed(item))?.reverse() || []
  const date = data?.label?.slice()?.reverse() || []
  const isMoreThanOneDay = !!getDateDifference({ firstDate: date?.[0], secondDate: date?.at(-1) })
  const label = date?.map((item) => chartDateWithPeriod(item, period, isMoreThanOneDay))
  const [showMin, setShowMin] = useState(false)
  const [showMax, setShowMax] = useState(false)
  const [showAvg, setShowAvg] = useState(false)
  const [showLower, setShowLower] = useState(false)
  const [showUpper, setShowUpper] = useState(false)

  const onShowMinChange = () => setShowMin((prevState) => !prevState)
  const onShowMaxChange = () => setShowMax((prevState) => !prevState)
  const onShowAvgChange = () => setShowAvg((prevState) => !prevState)
  const onShowLowerChange = () => setShowLower((prevState) => !prevState)
  const onShowUpperChange = () => setShowUpper((prevState) => !prevState)

  return (
    <div class="card h-100">
      <div class="card-title">
        <p class={style.title}>{data?.name}</p>
      </div>
      <div class={`d-flex justify-content-end me-3`}>
        <div class="d-flex">
          {calculation && (
            <Fragment>
              <label className="ms-3">
                <input type="checkbox" class="form-check-input me-2" checked={showAvg} onchange={onShowAvgChange} />
                {t("average")} ({data?.avg ?? 0})
              </label>
              <label className="ms-3">
                <input type="checkbox" class="form-check-input me-2" checked={showMin} onchange={onShowMinChange} />
                {t("min")} ({data?.min ?? 0})
              </label>
              <label className="ms-3">
                <input type="checkbox" class="form-check-input me-2" checked={showMax} onchange={onShowMaxChange} />
                {t("max")} ({data?.max ?? 0})
              </label>
            </Fragment>
          )}
          <label className="ms-3">
            <input type="checkbox" class="form-check-input me-2" checked={showLower} onchange={onShowLowerChange} />
            {t("lower_threshold")} ({data?.lower_threshold ?? 0})
          </label>
          <label className="ms-3">
            <input type="checkbox" class="form-check-input me-2" checked={showUpper} onchange={onShowUpperChange} />
            {t("upper_threshold")} ({data?.upper_threshold ?? 0})
          </label>
        </div>
      </div>
      <div class="card-body">
        <RecordChart
          height={height}
          avg={data?.avg}
          min={data?.min}
          max={data?.max}
          data={dataset}
          labels={label}
          upper={data?.upper_threshold}
          lower={data?.lower_threshold}
          showMin={showMin}
          showMax={showMax}
          showAvg={showAvg}
          showLower={showLower}
          showUpper={showUpper}
        />
      </div>
    </div>
  )
}

export default RecordLineChart
