import { FunctionComponent, h } from "preact"
import { useRef } from "preact/hooks"
import { toPng } from "html-to-image"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import TopFilterMultiSite from "../../components/TopFilter/multi-site"
import Spinner from "../../components/spinner"
import Container from "../../parts/container"
import { Button } from "../../parts/buttons"
import { analyticChartColor } from "../../utils/color"
import { can } from "../../utils/access"
import { toFixed } from "../../utils/toFixed"
import { isAvgSensor } from "../../utils/sortParam"
import { formatDateWithPeriod } from "../../utils/dateformat"
import { Permission } from "../../enum/permissions.enum"
import useSites from "../../hooks/useSite"
import useObjectState from "../../hooks/useObjectState"
import { useAnalyticMultiple } from "../../hooks/useSensor"
import AnalyticLineChart from "./analytic-chart"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type AnalyticsMultiSiteProps = h.JSX.HTMLAttributes<HTMLDivElement>

const defaultValue = {
  site: [],
  sensor: undefined,
  period: undefined,
  startDate: undefined,
  endDate: undefined,
}

const AnalyticsMultiSite: FunctionComponent<AnalyticsMultiSiteProps> = () => {
  const { t } = useTranslation()
  const { data: cems } = useSites()
  const [payload, setPayload] = useObjectState(defaultValue)
  const element = useRef<HTMLDivElement>(null)

  const { data: rawData } = useAnalyticMultiple(payload.site, [payload.startDate, payload.endDate], payload.period, payload.sensor)

  const analyticData = rawData?.map((item, index) => {
    const paramData = item?.parameter?.find(({ name }) => name === payload.sensor)
    const value = item?.record?.map(({ data }) => data?.find(({ name }) => name === payload.sensor)?.value)
    const avg = item?.record?.map(({ data }) => data?.find(({ name }) => name === payload.sensor)?.avg)
    return {
      dataset: {
        data: isAvgSensor(payload.period, payload.sensor) ? avg : value,
        label: cems?.find(({ uuid }) => uuid === item?.record?.[0]?.logger_id)?.name,
        borderColor: analyticChartColor(index),
        backgroundColor: analyticChartColor(index),
        tension: 0.4,
      },
      lower_threshold: toFixed(paramData?.lower_threshold),
      upper_threshold: toFixed(paramData?.upper_threshold),
    }
  })

  const analyticLabels = rawData?.[0]?.record?.map(({ timestamp }) => timestamp)
  const startDate = formatDateWithPeriod(analyticLabels?.at(-1), payload.period)
  const endDate = formatDateWithPeriod(analyticLabels?.[0], payload.period)

  const handleExport = () => {
    if (element.current) {
      toPng(element.current, { cacheBust: true }).then((dataUrl) => {
        const link = document.createElement("a")
        link.download = `site_analytics_${new Date().getTime()}.png`
        link.href = dataUrl
        link.click()
      })
    }
  }

  const handleFilterData = (data) => setPayload(data)

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.analytic")} link="" />
        <Breadcrumb name={t("breadcrumb.multi_site")} link="/analytic/multi-site" />
      </TopBar>
      <Spinner show={!rawData} />
      <Container>
        <TopFilterMultiSite cems={cems} onShow={handleFilterData} />
        {can(Permission.AnalyticMultiSiteDownload) && (
          <div className="d-flex justify-content-end mt-3">
            <Button variant="primary" onClick={() => handleExport()}>
              {t("buttons.download")}
            </Button>
          </div>
        )}
        <div className="row mt-3" ref={element}>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-title">
                <div className="d-flex flex-column">
                  <p class={style.title}>{t("page.multi_site.multi_site_analytics")}</p>
                  {payload.sensor && (
                    <p className="mb-0 mt-2">
                      {payload.sensor} {t("page.multi_site.comparation")}
                    </p>
                  )}
                </div>
                {!!payload.site?.length && (
                  <p className={style.period_legend_text}>
                    {t("page.multi_site.showing_from")} {startDate} {startDate !== endDate && ` ${t("page.multi_site.to")} ${endDate}`}
                  </p>
                )}
              </div>
              <div className="card-body">
                <AnalyticLineChart data={analyticData} labels={analyticLabels} period={payload.period} showAnnotation={false} />
                {!!payload?.site?.length && (
                  <div className="mt-4 d-flex align-items-center gap-5">
                    {analyticData?.map((item, id) => (
                      <div key={`chart-${id}`}>
                        <p className={style.parameter_legend_text} style={{ backgroundColor: item?.dataset?.backgroundColor }}>
                          {item?.dataset?.label}
                        </p>
                        <p class={style.period_legend_text}>
                          BMAL: {item?.lower_threshold} - {item?.upper_threshold}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AnalyticsMultiSite
