import L from "leaflet"

export const iconMap = new L.Icon({
  iconUrl: "/assets/icons/map.png",
  shadowUrl: "/assets/icons/map-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

export const iconMapGreen = new L.Icon({
  iconUrl: "/assets/icons/map-green.png",
  shadowUrl: "/assets/icons/map-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

export const iconMapGray = new L.Icon({
  iconUrl: "/assets/icons/map-gray.png",
  shadowUrl: "/assets/icons/map-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

export const iconMapYellow = new L.Icon({
  iconUrl: "/assets/icons/map-yellow.png",
  shadowUrl: "/assets/icons/map-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})
