import { h, FunctionComponent } from "preact"
import { useState, useRef } from "preact/hooks"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
import { useModule } from "../../../hooks/useModule"
import { useTranslation } from "react-i18next"

type AddPlatformProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  onHide: () => void
  mutate: () => void
}

const defaultBody = {
  name: "",
  code: "",
  logo: "",
  host: "",
  modules: [],
}

const AddPlatform: FunctionComponent<AddPlatformProps> = ({ show, onHide, mutate }) => {
  const { t } = useTranslation()
  const { data: modules } = useModule()
  const { fetcher } = useAuth()
  const [body, setBody] = useObjectState(defaultBody)
  const logoElement = useRef()
  const [disable, setDisable] = useState(false)

  const onModuleChange = (uuid) => {
    const modules = body?.modules
    if (modules?.includes(uuid)) {
      setBody({ modules: modules?.filter((id) => id !== uuid) })
    } else {
      setBody({ modules: [...modules, uuid] })
    }
  }

  const isModuleChecked = (uuid) => body?.modules?.includes(uuid)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    try {
      const image = new FormData()
      image.append("file", logoElement.current.files[0])
      const header = { headers: { "Content-Type": "multipart/form-data" } }
      const { data: img } = await fetcher().post("/platform/file", image, header)
      await fetcher().post("/platform", { ...body, logo: img?.data?.path })
      await mutate()
      await onHide()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  return (
    <form
      className="modal-backdrop"
      style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("add_new_platform")}</div>
          <div className="card-body">
            <div className="mb-4">
              <label className="form-label">{t("label.name")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                onChange={({ currentTarget }) => setBody({ name: currentTarget?.value })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.platform_code")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.code}
                onChange={({ currentTarget }) => setBody({ code: currentTarget?.value?.toUpperCase() })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.modules")}</label>
              {modules?.map((module) => (
                <div class="form-check" key={module?.uuid}>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id={module?.uuid}
                    checked={isModuleChecked(module?.uuid)}
                    onChange={() => onModuleChange(module?.uuid)}
                  />
                  <label class="form-check-label" for={module?.uuid}>
                    {module?.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="mb-4">
              <label className="form-label">Host URL</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                onChange={({ currentTarget }) => setBody({ host: currentTarget?.value })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">Logo</label>
              <input ref={logoElement} className="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" required />
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onHide} type="button" variant="tertiary" className="m-1" disabled={disable}>
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1" disabled={disable}>
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddPlatform
