import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { Link } from "preact-router"
import { BsPencil, BsPlusLg, BsTrash } from "react-icons/bs"
import { IoKeyOutline } from "react-icons/io5"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import useAuth from "../../../hooks/useAuth"
import { useRole } from "../../../hooks/useUsman"
import { useConfirm } from "../../../context/useConfirm"
import Pagination from "../../../components/pagination"
import usePagination from "../../../hooks/usePagination"
import Spinner from "../../../components/spinner"
import SearchBox from "../../../components/TopFilter/search-box"
import useObjectState from "../../../hooks/useObjectState"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import EditRole from "./edit"
import { useTranslation } from "react-i18next"

type AccessRoleProps = h.JSX.HTMLAttributes<HTMLDivElement>

const AccessRole: FunctionComponent<AccessRoleProps> = () => {
  const { t } = useTranslation()
  const { data, mutate } = useRole()
  const [filteredData, setFilteredData] = useState(data)
  const confirmation = useConfirm()
  const { fetcher } = useAuth()
  const [editForm, setEditForm] = useObjectState({ show: false, data: {} })
  const [search, setSearch] = useState("")

  const showActionColumn = canOneOf([Permission.AccessRoleDelete, Permission.AccessRoleEdit, Permission.AccessRoleEditPermission])

  const pages = usePagination({ count: filteredData?.length || 0, step: 20 })
  const roles = filteredData?.slice(pages.output, pages.output + pages.step)

  const handleEdit = (data) => setEditForm({ show: true, data })

  const handleDelete = (id: string, name: string) => {
    confirmation(
      t("delete_role_confirm_msg", { name }),
      (remove) => {
        if (remove)
          fetcher()
            .delete(`/role/${id}`)
            .then(() => mutate())
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }

  useEffect(() => {
    let searchedData
    if (search) {
      searchedData = data?.filter(({ name }) => {
        return name?.toLowerCase()?.includes(search) || !search
      })
    } else {
      searchedData = data
    }
    setFilteredData(searchedData)
  }, [data, search])

  return (
    <div className="min-vh-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.access")} link="" />
        <Breadcrumb name={t("breadcrumb.role")} link="/access/role" />
      </TopBar>
      {editForm.show && <EditRole data={editForm} mutate={() => mutate()} onClose={() => setEditForm({ show: false })} />}
      <Spinner show={!Array.isArray(data)} />
      <Container>
        <div className="card">
          <div className="card-title align-items-center">
            <SearchBox placeholder={t("placeholder.search_role")} onChange={(value) => setSearch(value?.toLowerCase())} />
            {can(Permission.AccessRoleAdd) && (
              <Link href="/access/role/add">
                <Button variant="primary" icon={BsPlusLg}>
                  {t("buttons.add_role")}
                </Button>
              </Link>
            )}
          </div>
          <div className="card-body">
            <table className="table table-data table-center">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>{t("table.role_name")}</th>
                  <th>{t("table.description")}</th>
                  {showActionColumn && <th>{t("table.action")}</th>}
                </tr>
              </thead>
              <tbody>
                {roles?.length ? (
                  roles?.map((item, idx) => (
                    <tr key={item?.uuid}>
                      <td>{idx + 1 + pages.page * pages.step}</td>
                      <td>{item?.name}</td>
                      <td>{item?.description}</td>
                      {showActionColumn && (
                        <td>
                          {can(Permission.AccessRoleEditPermission) && (
                            <Link href={`/access/role/edit-permission/${item?.uuid}`}>
                              <Button variant="primary" title="Edit Permission" className="m-1" icon={IoKeyOutline} />
                            </Link>
                          )}
                          {can(Permission.AccessRoleEdit) && (
                            <Button variant="secondary" title="Edit" onClick={() => handleEdit(item)} className="m-1" icon={BsPencil} />
                          )}
                          {can(Permission.AccessRoleDelete) && (
                            <Button
                              variant="tertiary"
                              title="Delete"
                              onClick={() => handleDelete(item?.uuid, item?.name)}
                              className="m-1"
                              icon={BsTrash}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 4 : 3}>No data.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <Pagination hook={pages} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AccessRole
