import { FunctionComponent, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { NCRResponse, useNCRByID } from "../../../hooks/useNcr"
import { formatDate } from "../../../utils/dateformat"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import Spinner from "../../../components/spinner"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type NCRDetailsProps = h.JSX.HTMLAttributes<HTMLDivElement>

const NCRDetails: FunctionComponent<NCRDetailsProps> = () => {
  const { t } = useTranslation()
  const pathname = window.location.pathname
  const uuid = pathname.split("/").length === 5 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""
  const { data } = useNCRByID(uuid)
  const [ncr, setNcr] = useState<NCRResponse>()
  const [condition, setCondition] = useState({ condition: "", abnormal: "" })

  useEffect(() => {
    setNcr(data)
    if (data?.condition === "Emergency Condition") {
      setCondition({ condition: data?.condition, abnormal: "" })
    } else {
      const condition = data?.condition?.split(" - ")
      setCondition({ condition: condition?.[0], abnormal: condition?.[1] })
    }
  }, [data])

  return (
    <div>
      <Spinner show={!data} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.maintenance")} link="" />
        <Breadcrumb name={t("breadcrumb.ncr")} link="/maintenance/ncr" />
        <Breadcrumb name={t("breadcrumb.ncr_detail")} link={`/maintenance/ncr/detail/${uuid}`} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <p className={`${style.page_title} mb-4`}>{t("page.ncr.ncr_detail")}</p>
            <div className={style.detail}>
              <div className={style.label}>{t("label.lk_number")}</div>
              <div className={style.value}>{ncr?.lk_number}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.date")}</div>
              <div className={style.value}>{formatDate(ncr?.date, "DD MMMM YYYY")}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("site")}</div>
              <div className={style.value}>{ncr?.site.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.parameter")}</div>
              <div className={style.value}>{ncr?.parameter}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.condition")}</div>
              <div className={style.value}>{condition.condition}</div>
            </div>
            {condition.abnormal && (
              <div className={style.detail}>
                <div className={style.label}>{t("label.abnormal_condition_type")}</div>
                <div className={style.value}>{condition.abnormal}</div>
              </div>
            )}
            <div className={style.detail}>
              <div className={style.label}>{t("label.description")}</div>
              <div className={style.value}>{ncr?.description}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.root_problem_analytics")}</div>
              <div className={style.value}>{ncr?.problem}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.root_problem_analytic_image")}</div>
              <Button variant="primary" type="button" className="btn-sm" disabled={!ncr?.pic_problem} onClick={() => window.open(ncr?.pic_problem)}>
                {ncr?.pic_problem ? t("buttons.view_file") : t("page.ncr.no_photo_uploaded")}
              </Button>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.repair_action_prevention")}</div>
              <div className={style.value}>{ncr?.action}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.repair_action_prevention_image")}</div>
              <Button variant="primary" type="button" className="btn-sm" disabled={!ncr?.pic_solve} onClick={() => window.open(ncr?.pic_solve)}>
                {ncr?.pic_solve ? t("buttons.view_file") : t("page.ncr.no_photo_uploaded")}
              </Button>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.refinement_due_date")}</div>
              <div className={style.value}>{formatDate(ncr?.completion_date_target, "DD/MM/YYYY")}</div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NCRDetails
