export default {
  title: "Geovos - Fortuna Argatech",
  favicon: "/assets/favicon/geovos/favicon.ico",
  login: {
    title: "Fortuna Argatech!",
    carousel: [
      "/assets/img/carousel/geovos/geovos-2.png",
      "/assets/img/carousel/geovos/geovos-3.png",
      "/assets/img/carousel/geovos/geovos-4.png",
      "/assets/img/carousel/geovos/geovos-5.png",
    ],
    brand: {
      name: "",
      tagline: "",
      powered: "",
      logo: "/assets/img/geovos/logo.png",
      alt_logo: "GEOVOS",
    },
  },
  navbar: {
    toggle_logo: "/assets/img/geovos/icon.png",
    alt_toggle_logo: "GEOVOS",
    brand: {
      name: "",
      tagline: "",
      powered: "",
      logo: "/assets/img/geovos/navbar_logo.png",
      alt_logo: "GEOVOS",
    },
  },
  colors: {
    navbar: "#00214D",
    primary: "#ecb390",
  },
  pdf: {
    logo: "/assets/img/fortuna.png",
    author: "GEOVOS",
    creator: "GEOVOS DEV",
  },
  mobile_app: {
    dev: "https://drive.google.com/file/d/1-t7tVUl0TzNRmfy7gTiS7MhRNbZSBagm/view?usp=sharing",
    uat: "https://drive.google.com/file/d/1FTaNeJTWrAzE883gPSiBb7JXTQYAU3jq/view?usp=sharing",
    prod: "https://drive.google.com/file/d/1XBuxrJ_yx67YvgTf_4oI5NAkZRBWlIBL/view?usp=sharing",
  },
  manuals: {
    user_manual: {
      file_name: "User Manual Geovos.pdf",
      file: "/assets/manuals/UserManualGEOVOS.pdf",
    },
  },
}
