import { createContext } from 'preact'
import { useContext, useState } from 'preact/hooks'

type FilterType = {
  company?: string
  site?: string
  sensor?: string | string[]
  period?: string
  startDate?: string
  endDate?: string
}
type FilterContextType = {
  contextFilter: FilterType
  setContextFilter: () => void
}

const defaultValue: FilterContextType = {
  contextFilter: {},
  setContextFilter: () => ({}),
}
const defaultState: FilterType = {
  company: "",
  site: "",
  sensor: "",
  period: "",
  startDate: "",
  endDate: "",
}

const FilterContext = createContext<FilterContextType>(defaultValue)

export const useFilterContext = () => useContext(FilterContext)

export const FilterContextProvider = FilterContext.Provider

export const useFilter = (): [FilterType, () => void] => {
  const [contextFilter, setFilter] = useState(defaultState)
  const setContextFilter = (data) => setFilter((prevState) => ({
    ...prevState,
    ...data,
  }))

  return [contextFilter, setContextFilter]
}
