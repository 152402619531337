import { Fragment, FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { FaRegFileExcel } from "react-icons/fa"
import Container from "../../parts/container"
import TopBar from "../../components/topbar"
import TopFilter from "../../components/TopFilter"
import Breadcrumb from "../../components/topbar/breadcrumb"
import ServerPagination from "../../components/pagination/server-pagination"
import { toFixed } from "../../utils/toFixed"
import { useFilterContext } from "../../context/useFilter"
import Spinner from "../../components/spinner"
import { useRecordPaginate } from "../../hooks/useSensor"
import useSites from "../../hooks/useSite"
import { Button } from "../../parts/buttons"
import { formatDateWithPeriod } from "../../utils/dateformat"
import { isAvgSensor } from "../../utils/sortParam"
import useObjectState from "../../hooks/useObjectState"
import { percentageComplianceToExcel } from "./toexcel"
import { can } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import style from "./style.scss"
import { useSortHeader } from "../../hooks/useSortHeader"
import { useTranslation } from "react-i18next"

type RecordComplianceDataProps = h.JSX.HTMLAttributes<HTMLDivElement>

const RecordPercentageComplianceData: FunctionComponent<RecordComplianceDataProps> = () => {
  const { t } = useTranslation()
  const { contextFilter, setContextFilter } = useFilterContext()
  const { data: cems } = useSites()
  const [filtered, setFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const limit = 20

  const getDefalutPeriod = (period) => {
    const avaliabePeriod = ["hour", "day"]
    if (period && avaliabePeriod.includes(period)) return period
    return "day"
  }
  const defaultValue = {
    site: undefined,
    startDate: undefined,
    endDate: undefined,
    ...contextFilter,
    sensor: Array.isArray(contextFilter?.sensor) ? contextFilter?.sensor?.[0] : contextFilter?.sensor,
    period: getDefalutPeriod(contextFilter?.period),
    orders: [],
  }
  const [payload, setPayload] = useObjectState(defaultValue)
  const { data, exportExcel } = useRecordPaginate({
    uuid: payload.site,
    type: payload.period,
    start: payload.startDate,
    end: payload.endDate,
    page: currentPage,
    limit,
    orders: payload.orders,
    parameter_id: payload.sensor_id,
  })

  const getData = (data) => {
    return data?.record?.map((item) => {
      const sensor = item?.data?.find(({ name }) => name === payload?.sensor)
      return {
        timestamp: item.timestamp,
        value: isAvgSensor(payload.period, payload.sensor) ? toFixed(sensor?.avg) : toFixed(sensor?.value),
        ...(payload.period === "day" && { valid: toFixed(sensor?.percentage_valid, 2) }),
        comply: toFixed(sensor?.percentage_comply, 2),
        validity: +sensor?.count_comply >= 20 ? "Valid" : "Invald",
        compliance: +sensor?.count_valid >= 20 ? "Comply" : "Incomply",
      }
    })
  }

  const tableData = getData(data)

  const handleFilterData = (data) => {
    resetSorting()
    setPayload({ ...data, orders: [] })
    setFiltered(true)
    setContextFilter(data)
    setCurrentPage(1)
  }

  const getSiteName = (siteId) => cems?.find(({ uuid }) => uuid === siteId)?.name

  const getExcelData = async () => {
    const exportExcelData = await exportExcel(data?.totalRecords)
    const excelData = getData(exportExcelData)
    if (excelData?.length) {
      percentageComplianceToExcel(excelData, payload, getSiteName(payload.site))
    }
  }

  // order data
  const { sort, onOrder, sortIcon, resetSorting } = useSortHeader({
    initialFieldToSort: {
      timestamp: null,
      value: null,
      ["percentage_comply"]: null,
      ["count-valid"]: null,
      ["count-comply"]: null,
    },
  })

  const onSortField = (field: string) => {
    const sortParamsData = onOrder(field)
    if (sortParamsData[0]["sort_by[0]"] == "value") {
      let sorting = []
      if (isAvgSensor(payload.period, payload.sensor)) {
        sorting = [{ ...sortParamsData[0], ["sort_by[0]"]: "avg" }]
      } else {
        sorting = [{ ...sortParamsData[0], ["sort_by[0]"]: "value" }]
      }
      setPayload({ ...payload, orders: sorting })
      return
    }
    setPayload({ ...payload, orders: sortParamsData })
  }
  // end order data

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.record")} link="" />
        <Breadcrumb name={t("breadcrumb.percentage_compliance_data")} link="/record/percentage-compliance-data" />
      </TopBar>
      <Container>
        {data ? (
          <Fragment>
            <div className="row align-items-stretch">
              <TopFilter cems={cems} interval inputSensor="select" comply defaultValue={payload} onShow={handleFilterData} />
            </div>
            <div className="mt-3">
              <div className="d-flex justify-content-end">
                {can(Permission.RecordPercentageComplianceDataExportExcel) && (
                  <Button
                    variant="primary"
                    className={style.export_btn}
                    icon={FaRegFileExcel}
                    onClick={() => getExcelData()}
                    disabled={!tableData?.length}
                  >
                    {t("buttons.export_to_excel")}
                  </Button>
                )}
              </div>
            </div>
            <div className="mt-3">
              <div className="card mt-3">
                <div className="card-body">
                  <div className="overflow-auto">
                    <table className="table table-data table-center">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th onClick={() => onSortField("timestamp")} className={style.sort_able}>
                            {t("table.time")} {sortIcon("timestamp")}
                          </th>
                          <th onClick={() => onSortField("value")} className={style.sort_able}>
                            {t("table.value")} {sortIcon("value")}
                          </th>
                          {payload.period === "day" && <th>{t("table.precentage_valid_data")} (%)</th>}
                          <th onClick={() => onSortField("percentage_comply")} className={style.sort_able}>
                            <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                              <div>{t("table.precentage_comply_data")} (%)</div>
                              {sortIcon("percentage_comply")}
                            </div>
                          </th>
                          {payload.period == "day" ? (
                            <th onClick={() => onSortField("count-valid")} className={style.sort_able}>
                              {t("table.status")} {sortIcon("count-valid")}
                            </th>
                          ) : (
                            <th onClick={() => onSortField("count-comply")} className={style.sort_able}>
                              {t("table.status")} {sortIcon("count-comply")}
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.length ? (
                          tableData?.map((raw, id) => (
                            <tr key={`raw-${id}`}>
                              <td>{id + 1 + (currentPage - 1) * limit}</td>
                              <td className="text-nowrap">{formatDateWithPeriod(raw.timestamp, payload.period)}</td>
                              <td>{raw?.value}</td>
                              {payload.period === "day" && <td>{raw?.valid}</td>}
                              <td>{raw?.comply}</td>
                              <td>{payload.period === "day" ? raw?.compliance : raw?.validity}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td className="text-center py-4" colSpan={6}>
                              {!filtered ? t("fill_the_filter_first") : t("no_data")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="my-2 d-flex justify-content-end">
                    <ServerPagination current={currentPage} last={data?.totalPages} onPageChange={setCurrentPage} />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <Spinner />
        )}
      </Container>
    </div>
  )
}

export default RecordPercentageComplianceData
