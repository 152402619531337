import { BsEye, BsPencil, BsTrash } from "react-icons/bs"
import { FaRegFilePdf } from "react-icons/fa6"
import { Fragment, FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { Link } from "preact-router"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import Pagination from "../../../components/pagination"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import { useSitePaginate } from "../../../hooks/useSite"
import useAuth from "../../../hooks/useAuth"
import useCompany from "../../../hooks/useCompany"
import { exportPDFDetail } from "./export-pdf"
import { useConfirm } from "../../../context/useConfirm"
import usePagination from "../../../hooks/usePagination"
import Spinner from "../../../components/spinner"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import { siteDataToExcel } from "./export-excel"
import SearchBox from "../../../components/TopFilter/search-box"
import { useSortHeader } from "../../../hooks/useSortHeader"
import style from "../style.scss"
import ServerPagination from "../../../components/pagination/server-pagination"
import { useTranslation } from "react-i18next"

type TechnicalDataPageProps = h.JSX.HTMLAttributes<HTMLDivElement>

const TechnicalDataPage: FunctionComponent<TechnicalDataPageProps> = () => {
  const { t } = useTranslation()
  const { data: mils } = useCompany()
  const limit = 5
  const [currentPage, setCurrentPage] = useState(1)
  const [payload, setPayload] = useState({
    orders: [],
    search: "",
  })
  const [search, setSearch] = useState("")
  const {
    data: originalData,
    mutate,
    exportExcel,
  } = useSitePaginate({
    page: currentPage,
    orders: payload.orders,
    limit: limit,
    mil: payload.mil,
    search: payload.search,
  })
  const data = originalData?.record?.filter((data) => data?.parameters?.length)
  const confirmation = useConfirm()
  const [filteredData, setFilteredData] = useState(data)

  const showActionColumn = canOneOf([Permission.SettingsSiteDataDelete, Permission.SettingsSiteDataDetail, Permission.SettingsSiteDataEdit])

  const handleExportPdf = (id) => {
    const siteData = originalData?.record?.find(({ uuid }) => uuid === id)
    exportPDFDetail(siteData)
  }

  const getExcelData = async () => {
    const exportExcelData = await exportExcel(data?.totalRecords)
    if (exportExcelData?.length) {
      const data = formatParameterSiteData(exportExcelData)
      siteDataToExcel(data)
    }
  }

  const handleDelete = (id: string, name: string) => {
    confirmation(
      t("delete_sitedata_confirm_msg", { module_name: name }),
      (out) => {
        if (out)
          fetcher()
            .delete(`/site/${id}`)
            .then(() => {
              mutate()
            })
      },
      { yes: "Delete", no: "Cancel" }
    )
  }

  const formatParameterSiteData = (data) => {
    const siteData = data?.map((data) => {
      let parameters = data?.parameters
      if (!parameters.length) {
        parameters = [
          {
            uuid: "",
            name: "",
            lower_threshold: "",
            upper_threshold: "",
            uom: "",
            has_load: false,
          },
        ]
      }
      return {
        ...data,
        parameters,
      }
    })
    return siteData
  }

  useEffect(() => {
    const data = formatParameterSiteData(originalData?.record)
    setFilteredData(data)
  }, [originalData])

  useEffect(() => {
    let timer
    const debounceHandler = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        setCurrentPage(1)
        setPayload({ ...payload, search })
      }, 500)
    }
    debounceHandler()
    return () => {
      clearTimeout(timer)
    }
  }, [search])

  useEffect(() => {
    mils?.length === 1 ? setPayload({ mil: mils?.[0]?.uuid }) : setPayload({})
  }, [mils])

  // order
  const { sort, onOrder, sortIcon, resetSorting } = useSortHeader({
    initialFieldToSort: {
      company: null,
      name: null,
    },
  })
  const onSortField = (field: string) => {
    const sortParamsData = onOrder(field)
    setPayload({ ...payload, orders: sortParamsData })
  }
  // end order

  return (
    <div>
      <Spinner show={!Array.isArray(data)} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.settings")} link="" />
        <Breadcrumb name={t("breadcrumb.site_data")} link="/settings/site-data" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title pt-4">
            <SearchBox placeholder={t("placeholder.search_site_or_company")} onChange={(value) => setSearch(value?.toLowerCase())} />
            <div>
              {can(Permission.SettingsSiteDataExportExcel) && (
                <Button variant="lines" className="btn-sm" onClick={() => getExcelData()}>
                  {t("buttons.export_to_excel")}
                </Button>
              )}
              {can(Permission.SettingsSiteDataAdd) && (
                <Link href="/settings/site-data/add">
                  <Button variant="primary" className="btn-sm ms-2">
                    {t("add_new_data")}
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="card-body">
            <table className="table table-center table-data">
              <thead>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th className={style.sort_able} onClick={() => onSortField("company")} rowSpan={2}>
                    {t("company")} {sortIcon("company")}
                  </th>
                  <th className={style.sort_able} onClick={() => onSortField("name")} rowSpan={2}>
                    {t("table.site_name")} {sortIcon("name")}
                  </th>
                  <th rowSpan={2}>{t("table.module")}</th>
                  <th colSpan={5}>{t("table.parameter")}</th>
                  {showActionColumn && <th rowSpan={2}>Menu</th>}
                </tr>
                <tr>
                  <th>{t("table.name")}</th>
                  <th className="text-wrap">{t("lower_threshold")}</th>
                  <th className="text-wrap">{t("upper_threshold")}</th>
                  <th>{t("table.unit")}</th>
                  <th>{t("has_load")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.length ? (
                  filteredData?.map((item, idx) =>
                    item?.parameters?.map((param, id) => (
                      <tr key={item?.uuid + param?.uuid}>
                        {!id && (
                          <Fragment>
                            <td rowSpan={item?.parameters?.length}>{idx + 1 + (currentPage - 1) * limit}</td>
                            <td rowSpan={item?.parameters?.length}>{item?.company?.name}</td>
                            <td rowSpan={item?.parameters?.length}>{item?.name}</td>
                            <td rowSpan={item?.parameters?.length}>
                              <div>
                                {item?.modules.map((module) => (
                                  <div>{module.name}</div>
                                ))}
                              </div>
                            </td>
                          </Fragment>
                        )}
                        <td>{param.name}</td>
                        <td>{param.lower_threshold}</td>
                        <td>{param.upper_threshold}</td>
                        <td>{param.uom}</td>
                        <td>{param.has_load ? "Yes" : "No"}</td>
                        {!id && showActionColumn && (
                          <td rowSpan={item?.parameters?.length} className="align-top">
                            <div className="d-flex flex-column">
                              {can(Permission.SettingsSiteDataDetail) && (
                                <Link href={`/settings/site-data/detail/${item?.uuid}`}>
                                  <Button variant="primary" className="btn-sm m-1" title="Detail" icon={BsEye} />
                                </Link>
                              )}
                              {can(Permission.SettingsSiteDataExportDetailToPdf) && (
                                <div>
                                  <Button
                                    variant="lines"
                                    title="Export PDF"
                                    onClick={() => handleExportPdf(item.uuid)}
                                    className="btn-sm m-1"
                                    icon={FaRegFilePdf}
                                  />
                                </div>
                              )}
                              {can(Permission.SettingsSiteDataEdit) && (
                                <Link href={`/settings/site-data/edit/${item?.uuid}`}>
                                  <Button variant="secondary" className="btn-sm m-1" title="Edit" icon={BsPencil} />
                                </Link>
                              )}
                              {can(Permission.SettingsSiteDataDelete) && (
                                <div>
                                  <Button
                                    variant="tertiary"
                                    title="Delete"
                                    onClick={() => handleDelete(item.uuid, item.name)}
                                    className="btn-sm m-1"
                                    icon={BsTrash}
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))
                  )
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 9 : 8}>No data.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <ServerPagination current={currentPage} last={originalData?.totalPages} onPageChange={setCurrentPage} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TechnicalDataPage
