import { Fragment, FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import moment from "moment"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar/"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import CompanySiteFilter from "../../../components/TopFilter/company-site-filter"
import { sortActiveParam } from "../../../utils/sortParam"
import { toFixed } from "../../../utils/toFixed"
import { useFilterContext } from "../../../context/useFilter"
import { defaultParams } from "../../../utils/defaults"
import Spinner from "../../../components/spinner"
import { useRecordGraph } from "../../../hooks/useSensor"
import useSites from "../../../hooks/useSite"
import useCompany from "../../../hooks/useCompany"
import { formatDate } from "../../../utils/dateformat"
import LineChart from "../lineChart"
import CardIcon from "./icon"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type DashboardMonitoringProps = h.JSX.HTMLAttributes<HTMLDivElement>

const DashboardMonitoringPage: FunctionComponent<DashboardMonitoringProps> = () => {
  const { t } = useTranslation()
  const { contextFilter, setContextFilter } = useFilterContext()
  const params = new URLSearchParams(window.location.search)
  const uuid = params.get("site") || contextFilter?.site
  const { data: cems } = useSites()
  const { data: mils } = useCompany()
  const siteId = cems?.length === 1 ? cems[0].uuid : uuid

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const defaultValue = {
    company: contextFilter?.company,
    site: siteId,
  }

  const [payload, setPayload] = useState(defaultValue)
  const period = "raw"

  const { data } = useRecordGraph({
    uuid: payload.site,
    type: period,
  })

  const columns = sortActiveParam(data?.parameter) || defaultParams
  const label = data?.record?.map(({ timestamp }) => timestamp)

  const getCalculation = (param, type) => {
    const calculate = data?.calculation?.find(({ name }) => name.toLowerCase() === param.toLowerCase())?.[type]
    return toFixed(calculate)
  }

  const chart = columns?.map((item) => {
    const value = data?.record?.map(({ data }) => data?.find(({ name }) => name === item.name)?.value)
    return {
      name: item.name,
      lower_threshold: item?.lower_threshold,
      upper_threshold: item?.upper_threshold,
      min: getCalculation(item?.name, "min"),
      max: getCalculation(item?.name, "max"),
      avg: getCalculation(item?.name, "avg"),
      label,
      dataset: value,
    }
  })

  const getStatus = (date, now) => {
    if (!date) return "-"
    const firstDate = moment(now, "X").subtract(2, "minutes")
    const secondDate = moment(new Date(date))
    return secondDate >= firstDate ? "Normal" : "Error"
  }

  const getLastValue = (param) => {
    const record = data?.record?.[0]?.data
    return record?.find(({ name }) => name === param)?.value
  }

  const handleFilterData = (data) => {
    setPayload(data)
    setContextFilter({
      company: data.company,
      site: data.site,
      ...(!contextFilter?.period && { period }),
      ...(!contextFilter?.startDate && { startDate }),
      ...(!contextFilter?.endDate && { endDate }),
    })
  }

  useEffect(() => {
    const now = moment().format("X")
    const aHourBefore = moment().subtract(60, "minutes").format("X")
    setStartDate(aHourBefore)
    setEndDate(now)
  }, [])

  useEffect(() => {
    if (uuid) {
      const company = cems?.find(({ uuid: cemUuid }) => uuid === cemUuid)?.company?.uuid
      setPayload({ company, site: uuid })
      setContextFilter({ ...contextFilter, company, site: uuid })
    }

    if (cems?.length === 1) {
      const cemId = cems?.length === 1 ? cems[0].uuid : undefined
      setPayload((prevState) => ({ ...prevState, site: cemId }))
      setContextFilter({ ...contextFilter, site: uuid })
    }
    const defaultSensor = cems?.find(({ uuid }) => contextFilter?.site === uuid)?.parameters?.[0]?.name
    const sensor = Array.isArray(contextFilter?.sensor) ? contextFilter?.sensor?.[0] : contextFilter?.sensor || defaultSensor
    setContextFilter({ ...contextFilter, site: uuid, ...(!contextFilter?.sensor && { sensor }) })
  }, [uuid, cems])

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.dashboard")} link="" />
        <Breadcrumb name={t("breadcrumb.monitoring")} link="/dashboard/monitoring" />
      </TopBar>
      <Container>
        {data ? (
          <Fragment>
            <div className="row align-items-stretch">
              {cems?.length > 1 && <CompanySiteFilter mils={mils} cems={cems} defaultValue={payload} onShow={handleFilterData} />}
              <div className={style.bmal_card_wrapper}>
                {columns?.map((item, idx) => (
                  <div key={idx} className={style.bmal_card}>
                    <div className={style.bmal_content}>
                      <div className={style.bmal_icon}>
                        <CardIcon />
                      </div>
                      <div className={style.bmal_desc}>
                        <p class={style.bmal_desc_title}>{item?.name}</p>
                        <p class={style.bmal_desc_value}>{getLastValue(item?.name)}</p>
                      </div>
                      <div>
                        <div className={style.bmal_status}>Status</div>
                        <div className={style.bmal_status_value}>{getStatus(label?.[0], startDate)}</div>
                      </div>
                    </div>
                    <div className={style.last_update}>
                      <div className="text-gray">
                        {t("page.monitoring.last_update")}:{" "}
                        <span className="text-secondary">{formatDate(label?.[0], "DD/MM/YYYY HH:mm") ?? "-"}</span>
                      </div>
                      <div className="text-gray">
                        <span>BMAL: </span>
                        <span className="text-secondary">
                          {toFixed(item?.lower_threshold)}-{toFixed(item?.upper_threshold)}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center gap-4 w-100 border-bottom border-secondary border-2 pt-2 pb-1">
                      <div class={style.bmal_treshold}>
                        <div>{t("page.monitoring.min")}</div>
                        <p>{getCalculation(item?.name, "min")}</p>
                      </div>
                      <div class={style.bmal_treshold}>
                        <div>{t("page.monitoring.max")}</div>
                        <p>{getCalculation(item?.name, "max")}</p>
                      </div>
                      <div class={style.bmal_treshold}>
                        <div>{t("page.monitoring.avg")}</div>
                        <p>{getCalculation(item?.name, "avg")}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {columns?.map((_, index) => (
              <div key={`chart-${index}`} className="row">
                <div className="col-xxl-6 col-12 mb-3">
                  {index % 2 === 0 && <LineChart height={120} data={chart[index]} color="#AB5894" calculation period={period} />}
                </div>
                <div className="col-xxl-6 col-12 mb-3">
                  {(index + 1) % 2 !== 0 && columns.length > index + 1 && (
                    <LineChart height={120} data={chart[index + 1]} color="#E96447" calculation period={period} />
                  )}
                </div>
              </div>
            ))}
          </Fragment>
        ) : (
          <Spinner />
        )}
      </Container>
    </div>
  )
}

export default DashboardMonitoringPage
