import { BsBellFill, BsPencil, BsTrash, BsCheck2Circle } from "react-icons/bs"
import { AiOutlineStop } from "react-icons/ai"
import { FunctionComponent, h } from "preact"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import EditUser from "./edit"
import AddUser from "./add"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import { User } from "../../../types/user"
import useAuth from "../../../hooks/useAuth"
import { useConfirm } from "../../../context/useConfirm"
import useModal from "../../../hooks/useModal"
import { useState, useEffect } from "preact/hooks"
import { useUsersPaginate } from "../../../hooks/useUsers"
import useObjectState from "../../../hooks/useObjectState"
import ServerPagination from "../../../components/pagination/server-pagination"
import usePagination from "../../../hooks/usePagination"
import useSharedState, { loggedinUser } from "../../../state"
import Spinner from "../../../components/spinner"
import SearchBox from "../../../components/TopFilter/search-box"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import { Role } from "../../../enum/roles.enum"
import { usersToExcel } from "./export-excel"
import { useSortHeader } from "../../../hooks/useSortHeader"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type SettingsUserProps = h.JSX.HTMLAttributes<HTMLDivElement>

const SettingsUser: FunctionComponent<SettingsUserProps> = () => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1)
  const limit = 20
  const [payload, setPayload] = useObjectState({ orders: [], search: "" })

  const {
    data: originalData,
    mutate,
    exportExcel,
  } = useUsersPaginate({
    page: currentPage,
    limit,
    orders: payload.orders,
    search: payload.search,
  })
  const [userData] = useSharedState(loggedinUser, undefined)
  const { fetcher } = useAuth()
  const confirmation = useConfirm()
  const [editData, setEditData] = useState<User>()
  const [filteredData, setFilteredData] = useState()
  const [alert, setAlert] = useObjectState({ status: "", msg: "" })
  const [search, setSearch] = useState("")

  const { show: showAddUser, onHide: hideAddUser, onShow: onShowAddUser } = useModal()
  const { show: showEditUser, onHide: hideEditUser, onShow: onShowEditUser } = useModal()

  const users = filteredData

  const showActionColumn = canOneOf([Permission.SettingsUserChangeStatus, Permission.SettingsUserDelete, Permission.SettingsUserEdit])

  const handleEdit = (data) => {
    setEditData(data)
    onShowEditUser()
  }

  const handleValidate = (id: string, name: string, isValid: boolean) => {
    confirmation(
      `${t("confirm_active_deactive_msg")} ${isValid ? t("deactive") : t("active")} ${name} user?`,
      async (isoke) => {
        if (isoke)
          try {
            await fetcher().patch(`/user/validate/${id}`, { is_valid: !isValid })
            mutate()
            setAlert({ status: "primary", msg: `User ${isValid ? "deactived" : "actived"} successfully` })
          } catch (error) {
            const errorResponse = "Request failed with status code 400"
            const errorSubscribe = "User failed to validate, please set subscription first."
            const errorOther = "User failed to change user status."
            const message = error.message === errorResponse ? errorSubscribe : errorOther
            setAlert({ status: "error", msg: message })
          } finally {
            setTimeout(() => {
              setAlert({ status: "", msg: "" })
            }, 5000)
          }
      },
      { yes: `${isValid ? t("deactive") : t("active")}`, no: t("buttons.cancel") }
    )
  }

  const handleDelete = (name, uuid) => () => {
    confirmation(
      t("delete_user_confirm_msg", { name }),
      () => {
        const url = `/user/${uuid}`
        fetcher()
          .delete(url)
          .then(() => {
            mutate()
          })
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }

  const getExcelData = async () => {
    const exportExcelData = await exportExcel(originalData?.totalRecords)
    if (exportExcelData?.length) {
      usersToExcel(exportExcelData)
    }
  }

  useEffect(() => {
    const data = originalData?.data?.filter(({ role }) => {
      if (userData?.role?.name !== Role.SuperAdmin) {
        return ![Role.SuperAdmin, Role.AdminGeovos].includes(role?.name)
      }
      return true
    })
    setFilteredData(data)
  }, [originalData, userData, Role])

  useEffect(() => {
    let timer
    const debounceHandler = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        setCurrentPage(1)
        setPayload({ ...payload, search })
      }, 500)
    }
    debounceHandler()
    return () => {
      clearTimeout(timer)
    }
  }, [search])

  // sort data
  const { sort, onOrder, sortIcon, resetSorting } = useSortHeader({
    initialFieldToSort: {
      vendor: null,
      company: null,
      site: null,
      name: null,
      email: null,
    },
  })
  const onSortField = (field: string) => {
    const sortParamsData = onOrder(field)
    setPayload({ ...payload, orders: sortParamsData })
  }
  // end sort data

  return (
    <div className="min-vh-100">
      <Spinner show={!originalData} />
      <AddUser show={showAddUser} onHide={hideAddUser} />
      {showEditUser && <EditUser userData={editData} show={showEditUser} onHide={hideEditUser} mutate={mutate} />}
      <TopBar>
        <Breadcrumb name={t("breadcrumb.settings")} link="" />
        <Breadcrumb name={t("breadcrumb.user")} link="/settings/user" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title pt-4">
            <SearchBox placeholder={t("placeholder.search")} onChange={(value) => setSearch(value?.toLowerCase())} />
            <div>
              {can(Permission.SettingsUserExportExcel) && (
                <Button onClick={() => getExcelData()} variant="lines" className="btn-sm">
                  {t("buttons.export_to_excel")}
                </Button>
              )}
              {can(Permission.SettingsUserAdd) && (
                <Button onClick={onShowAddUser} variant="primary" className="ms-2 btn-sm">
                  {t("add_new_user")}
                </Button>
              )}
            </div>
          </div>
          {alert.status && (
            <div className="card-body">
              <div className={`alert-${alert.status} px-3 py-2`}>{alert.msg}</div>
            </div>
          )}
          <div className="card-body">
            <table className="table table-data table-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th onClick={() => onSortField("vendor")} className={style.sort_able}>
                    {t("table.vendor")} {sortIcon("vendor")}
                  </th>
                  <th onClick={() => onSortField("company")} className={style.sort_able}>
                    {t("company")} {sortIcon("company")}
                  </th>
                  <th>{t("table.sites")}</th>
                  <th onClick={() => onSortField("name")} className={style.sort_able}>
                    {t("table.user_name")} {sortIcon("name")}
                  </th>
                  <th onClick={() => onSortField("email")} className={style.sort_able}>
                    {t("table.email")} {sortIcon("email")}
                  </th>
                  <th onClick={() => onSortField("role")} className={style.sort_able}>
                    {t("table.role")} {sortIcon("role")}
                  </th>
                  <th onClick={() => onSortField("is_valid")} className={style.sort_able}>
                    {t("table.status")} {sortIcon("is_valid")}
                  </th>
                  <th>{t("table.notification")}</th>
                  {showActionColumn && <th>Menu</th>}
                </tr>
              </thead>
              <tbody>
                {users?.length ? (
                  users?.map((user, idx) => (
                    <tr key={user.uuid}>
                      <td>{idx + 1 + (currentPage - 1) * limit}</td>
                      <td>{user?.vendor?.name || "-"}</td>
                      <td>{user?.company?.name || "-"}</td>
                      <td>
                        <div className="text-start">
                          {user?.sites?.map((site, index) => (
                            <div key={site?.uuid}>{`${index + 1}. ${site?.name}`}</div>
                          ))}
                        </div>
                      </td>
                      <td>{user?.name}</td>
                      <td>{user?.email}</td>
                      <td>{user?.role?.name || "-"}</td>
                      <td>
                        {user?.is_valid ? (
                          <span className="badge bg-primary my-2">{t("label.active")}</span>
                        ) : (
                          <span className="badge bg-error my-2">{t("label.inactive")}</span>
                        )}
                      </td>
                      <td className="text-center">
                        <Button icon={BsBellFill} className="m-1" variant="primary" disabled={!user.notification} />
                      </td>
                      {showActionColumn && (
                        <td className="text-center text-nowrap">
                          {can(Permission.SettingsUserChangeStatus) && (
                            <Button
                              variant={`${user?.is_valid ? "tertiary" : "primary"}`}
                              title={`${user?.is_valid ? "Deactive" : "Active"}`}
                              onClick={() => handleValidate(user?.uuid, user?.name, user?.is_valid)}
                              className="m-1"
                              icon={user?.is_valid ? AiOutlineStop : BsCheck2Circle}
                            />
                          )}
                          {can(Permission.SettingsUserEdit) && (
                            <Button className="m-1" variant="secondary" icon={BsPencil} onClick={() => handleEdit(user)} />
                          )}
                          {can(Permission.SettingsUserDelete) && (
                            <Button className="m-1" variant="tertiary" onClick={handleDelete(user?.name, user?.uuid)} icon={BsTrash} />
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 9 : 8}>No data.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <ServerPagination current={currentPage} last={originalData?.totalPages} onPageChange={setCurrentPage} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default SettingsUser
