import { ApiResponse } from "../types/api-response"
import { Role } from "../enum/roles.enum"
import useAxios from "./useAxios"
import { setLocalStorage, logoPathStorage, siteLogoPath } from "../utils/local-storage"

type ResponseToken = ApiResponse<{
  access_token: string
  user: User
}>

type User = {
  uuid: string
  name: string
  email: string
  password: string
  salt: string
  role: Role
  notification: boolean
  notification_interval: number
  created_at: string
  update_at: string
  company: string
  mil: string
}

type PlatformSettings = {
  accent_font_color: string
  logo: string
  menu_background_color: string
}

const useAuth = () => {
  const key = "x-auth-token"
  const userKey = "x-auth-user"
  const permissionKey = "x-auth-permission"
  const settingsKey = "x-platform-settings"
  const axios = useAxios()
  const url = "/auth/sign-in"

  const getToken = () => {
    if (localStorage) return localStorage.getItem(key)
    return ""
  }

  const setToken = (token: string) => {
    if (localStorage) return localStorage.setItem(key, token)
  }

  const setUserId = (uuid: string) => {
    if (localStorage) return localStorage.setItem(userKey, uuid)
  }

  const setPermission = (permission: string[]) => {
    const data = JSON.stringify(permission)
    localStorage.setItem(permissionKey, data)
  }
  const setPlatformSettings = (settings: PlatformSettings) => {
    localStorage.setItem(settingsKey, JSON.stringify(settings))
  }

  const getUserId = () => {
    if (localStorage) return localStorage.getItem(userKey)
    return ""
  }

  const login = async (email: string, password: string) => {
    return axios.post<ResponseToken>(url, { email, password }).then(({ data }) => {
      setToken(data.data?.access_token)
      setUserId(data.data?.user?.uuid)
      setPermission(data?.data?.user?.permissions)
      setLocalStorage(logoPathStorage, "")
      const user = data?.data?.user
      if (user && user?.vendor) {
        const platform = user.vendor?.platform
        setPlatformSettings(platform)
      }
      return data?.data?.user
    })
  }

  const fetcher = () =>
    useAxios({
      headers: {
        Authorization: getToken(),
      },
    })

  const isLogin = async () => {
    const token = getToken()
    const uuid = getUserId()
    if (!(token && uuid)) return false
    const url = "/user/" + uuid
    try {
      const data = await fetcher()
        .get<ApiResponse<User>>(url)
        .then(({ data }) => data.data)
      if (!data) return false
      return data
    } catch (e) {
      return false
    }
  }

  const logout = () => {
    setToken("")
    setUserId("")
    setPermission([])
    setLocalStorage(logoPathStorage, "")
    setLocalStorage(siteLogoPath, "")
    setLocalStorage(settingsKey, "")
    return true
  }

  return { login, fetcher, isLogin, logout }
}

export default useAuth
