import { FunctionComponent, h } from "preact"
import Brand from "../../components/brand"
import AuthCarousel from "./auth-carousel"
import templates from "../../utils/template-configs"
import style from "./style.scss"
import usePlatformApp from "../../hooks/usePlatformApp"

type AuthTemplateProps = h.JSX.HTMLAttributes<HTMLDivElement>

const AuthTemplate: FunctionComponent<AuthTemplateProps> = ({ children }) => {
  const { platform } = usePlatformApp()
  const carouselImages = templates[platform].login.carousel

  const imageDesc = [
    <div key={1} className={style.parallelogram}>
      Kami Hadir Untuk Membantu Pelaku Industri Melalui Platform Monitoring & Manajemen Data Lingkungan <span style={{ width: "106px" }}>Terpadu</span>
    </div>,
    <div key={2} className={style.parallelogram}>
      Dirancang Oleh Tim Pengembang Berdedikasi <span style={{ width: "184px" }}>Ahli & Lengkap</span>
    </div>,
    <div key={3} className={style.parallelogram}>
      Platform Yang Memberikan <span style={{ width: "244px" }}>Dukungan Pelaporan</span> Sesuai Dengan Peraturan Menteri
    </div>,
    <div key={4} className={style.parallelogram}>
      Beragam Komponen Sensor Dengan <span style={{ width: "248px" }}>Ketangguhan Akurasi</span>
    </div>,
    <div key={5} className={style.parallelogram}>
      Sistem Monitoring Lingkungan Yang Memberikan <span style={{ width: "348px" }}>Banyak Manfaat & Kemudahan</span>
    </div>,
  ]

  return (
    <div className={style.bg}>
      <div className={style.container}>
        <div className={style.form}>
          <div className={style.logo}>
            <Brand
              logo={templates[platform].login.brand.logo}
              alt_logo={templates[platform].login.brand.alt_logo}
              powered={templates[platform].login.brand.powered}
              tagline={templates[platform].login.brand.tagline}
              name={templates[platform].login.brand.name}
            />
          </div>
          {children}
        </div>
        <div className={style.illustrate}>
          <AuthCarousel images={carouselImages} desc={imageDesc} />
        </div>
      </div>
    </div>
  )
}

export default AuthTemplate
