import { FunctionComponent, h } from "preact"
import { useRef, useEffect, useState } from "preact/hooks"
import { route } from "preact-router"
import { BsTrash } from "react-icons/bs"
import Flatpickr from "react-flatpickr"
import { Button } from "../../parts/buttons"
import Container from "../../parts/container"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import useAuth from "../../hooks/useAuth"
import useSites from "../../hooks/useSite"
import useObjectState from "../../hooks/useObjectState"
import { useReportByID } from "../../hooks/useAllReport"
import "flatpickr/dist/flatpickr.css"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type EditReportProps = h.JSX.HTMLAttributes<HTMLDivElement>
type Param = {
  name: string | null
  uom: string | null
  bm: number | null
  value: number | null
  emission_load: number | null
}
type Body = {
  site: string | null
  monitoring_date: Date | null
  operation_time: number | null
  test_lab: string | null
  accreditated_lab: boolean | null
  klhk_approved_envi_lab: boolean | null
  parameters: Param[]
  lab_certificate: string | null
  envi_lab_recomendation_letter: string | null
  sampling_photo: string | null
}

const defaultParam: param = {
  name: null,
  uom: null,
  bm: null,
  value: null,
  emission_load: null,
}
const defaultBody: Body = {
  site: null,
  monitoring_date: null,
  operation_time: null,
  test_lab: null,
  accreditated_lab: null,
  klhk_approved_envi_lab: null,
  parameters: [defaultParam],
  lab_certificate: null,
  envi_lab_recomendation_letter: null,
  sampling_photo: null,
}

const flatpickrOptions = {
  dateFormat: "Z",
  altInput: true,
  altFormat: "d/m/Y",
}

const EditReport: FunctionComponent<EditReportProps> = () => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const { data: cems } = useSites()
  const pathname = window.location.pathname
  const reportId = pathname.split("/").length === 5 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""
  const { data: editData } = useReportByID(reportId)

  const parent = `/maintenance/calibration-report`
  const onBack = () => route(parent)
  const [body, setBody] = useObjectState(defaultBody)

  const lab_certificate = useRef<HTMLInputElement>(null)
  const envi_lab_recomendation_letter = useRef<HTMLInputElement>(null)
  const sampling_photo = useRef<HTMLInputElement>(null)

  const handleEditParam = (
    idx: number,
    value: Partial<{
      name: string
      uom: string
      bm: number
      value: number
      emission_load: number
    }>
  ) => {
    const last = [...body.parameters]
    last[idx] = { ...last[idx], ...value }
    setBody({ parameters: last })
  }
  const handleDelParam = (idx: number) => {
    const last = [...body.parameters]
    last.splice(idx, 1)
    setBody({ parameters: last })
  }
  const handelAddParam = () => {
    const last = [...body.parameters]
    last.push(defaultParam)
    setBody({ parameters: last })
  }
  const setUom = (id, name) => {
    const param = cems?.find(({ uuid }) => uuid === body?.site)?.parameters
    const uom = param?.find(({ name: uomName }) => uomName === name)?.uom
    const currentParam = [...body.parameters]
    currentParam[id] = { ...currentParam[id], name, uom }
    setBody({ parameters: currentParam })
  }

  const [disableButton, setDisableButton] = useState(false)

  const handleSubmit = async (event: KeyboardEvent) => {
    event.preventDefault()
    setDisableButton(true)
    const paths = [lab_certificate, envi_lab_recomendation_letter, sampling_photo]
    const files = paths.map(({ current }) => {
      const form = new FormData()
      if (current?.files?.length) {
        form.append("file", current?.files?.[0])
        return fetcher()
          .post("/manual-report/file", form, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(({ data }) => data.data.path as string)
      }
    })
    try {
      const [lab, envi, sampling] = await Promise.all(files)
      const {
        site,
        monitoring_date,
        operation_time,
        test_lab,
        accreditated_lab,
        klhk_approved_envi_lab,
        parameters,
        lab_certificate,
        envi_lab_recomendation_letter,
        sampling_photo,
      } = body
      await fetcher()
        .patch(`/manual-report/${reportId}`, {
          site,
          monitoring_date,
          operation_time,
          test_lab,
          accreditated_lab,
          klhk_approved_envi_lab,
          parameters,
          lab_certificate: lab ?? lab_certificate,
          envi_lab_recomendation_letter: envi ?? envi_lab_recomendation_letter,
          sampling_photo: sampling ?? sampling_photo,
        })
        .then(() => {
          onBack()
          setDisableButton(false)
        })
    } catch (error) {
      alert("Size File Maximum 1 MB")
    }
  }

  useEffect(() => {
    if (editData) setBody(editData)
  }, [editData])

  return (
    <div className="min-vh-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.maintenance")} link="" />
        <Breadcrumb name={t("breadcrumb.calibration_report")} link="/maintenance/calibration-report" />
        <Breadcrumb name={t("breadcrumb.edit")} link={`/maintenance/calibration-report/edit/${reportId}`} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title pt-4">
            <div className="d-flex gap-4 align-items-center">
              <p className={style.page_title}>{t("page.calibration_report.edit_calibration_report")}</p>
            </div>
          </div>
          <form onSubmit={(event) => handleSubmit(event)} className="card-body">
            <div className="mb-3">
              <div>{t("site")}</div>
              <select className="form-control rounded mw-100" required onChange={({ currentTarget }) => setBody({ site: currentTarget.value })}>
                {cems?.map((cem) => (
                  <option key={cem.uuid} value={cem.uuid} selected={cem.uuid === body.site}>
                    {cem.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <div>{t("label.monitoring_date")}</div>
              <Flatpickr
                value={body.monitoring_date}
                options={flatpickrOptions}
                className="form-control rounded mw-100"
                onChange={(date: Date) => setBody({ monitoring_date: date[0] })}
              />
            </div>
            <div className="mb-3">
              <div>{t("label.operation_time")} (in Hour)</div>
              <input
                type="number"
                className="form-control rounded mw-100"
                required
                value={body.operation_time}
                onChange={({ currentTarget: e }) => setBody({ operation_time: Number(e.value) })}
              />
            </div>
            <div className={style.parameter}>{t("label.parameters")}</div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">{t("label.parameter_name")}</div>
                  <div className="col">{t("label.unit")}</div>
                  <div className="col">BM</div>
                  <div className="col">{t("label.value")}</div>
                  <div className="col">{t("label.emission_load")} (kg/hour)</div>
                </div>
                {body?.parameters?.map((param, idx) => (
                  <div className="row my-2" key={idx}>
                    <div className="col">
                      <select required className="form-control rounded" value={param.name} onChange={({ currentTarget: e }) => setUom(idx, e.value)}>
                        <option value="">Select parameter</option>
                        {cems
                          ?.find(({ uuid }) => uuid === body?.site)
                          ?.parameters?.filter(({ is_active }) => is_active)
                          ?.map((param, idx) => (
                            <option key={idx} value={param.name}>
                              {param.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col">
                      <input className="form-control rounded" type="text" disabled required value={param.uom} />
                    </div>
                    <div className="col">
                      <input
                        className="form-control rounded"
                        type="number"
                        required
                        value={param.bm}
                        onChange={({ currentTarget: e }) => handleEditParam(idx, { bm: Number(e.value) })}
                      />
                    </div>
                    <div className="col">
                      <input
                        className="form-control rounded"
                        type="number"
                        required
                        value={param.value}
                        onChange={({ currentTarget: e }) => handleEditParam(idx, { value: Number(e.value) })}
                      />
                    </div>
                    <div className="col d-flex">
                      <input
                        className="form-control rounded"
                        type="number"
                        value={param.emission_load}
                        onChange={({ currentTarget: e }) =>
                          handleEditParam(idx, {
                            emission_load: Number(e.value),
                          })
                        }
                      />
                      <Button type="button" className="text-tertiary" icon={BsTrash} onClick={() => handleDelParam(idx)} />
                    </div>
                  </div>
                ))}
                <div className="my-4 d-flex justify-content-end">
                  <Button type="button" variant="primary" onClick={handelAddParam}>
                    {t("buttons.add_new_parameter")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="mb-3 mt-4">
              <div>{t("label.testing_lab")}</div>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body.test_lab}
                onChange={({ currentTarget: e }) => setBody({ test_lab: e.value })}
              />
            </div>
            <div className="mb-3">
              <div>{t("label.accreditated_lab")}?</div>
              <select
                className="form-control rounded mw-100"
                required
                value={body.accreditated_lab ? "yes" : "no"}
                onChange={({ currentTarget: e }) => setBody({ accreditated_lab: e.value === "yes" })}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="mb-3">
              <div>KLHK-{t("label.approved_environmental_lab")}?</div>
              <select
                className="form-control rounded mw-100"
                required
                value={body.klhk_approved_envi_lab ? "yes" : "no"}
                onChange={({ currentTarget: e }) => setBody({ klhk_approved_envi_lab: e.value === "yes" })}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className={style.parameter}>{t("label.attached_documents")}</div>
            <div className="mb-3 mt-2">
              <div>
                {t("label.lab_certificate")}
                {body?.lab_certificate && (
                  <Button
                    variant="primary"
                    className="ms-4 btn-sm mb-1"
                    type="button"
                    onClick={() => {
                      window.open(body?.lab_certificate)
                    }}
                  >
                    {t("buttons.uploaded_file")}
                  </Button>
                )}
              </div>
              <input ref={lab_certificate} type="file" accept="application/pdf" className="form-control rounded mw-100" />
              <div className="d-flex justify-content-between">
                <p className={style.file_input_desc}>{t("page.calibration_report.ignore_input_file", { file_name: t("label.lab_certificate") })}</p>
                <p className={style.file_input_desc}>{t("max_attachment", { size: "1 mb" })}</p>
              </div>
            </div>
            <div className="mb-3">
              <div>
                {t("label.environmental_lab_recommendation_letter")}
                {body?.envi_lab_recomendation_letter && (
                  <Button
                    variant="primary"
                    className="ms-4 btn-sm mb-1"
                    type="button"
                    onClick={() => {
                      window.open(body?.envi_lab_recomendation_letter)
                    }}
                  >
                    Uploaded file
                  </Button>
                )}
              </div>
              <input ref={envi_lab_recomendation_letter} type="file" accept="application/pdf" className="form-control rounded mw-100" />
              <div className="d-flex justify-content-between">
                <p className={style.file_input_desc}>
                  {t("page.calibration_report.ignore_input_file", { file_name: t("label.environmental_lab_recommendation_letter") })}
                </p>
                <p className={style.file_input_desc}>{t("max_attachment", { size: "1 mb" })}</p>
              </div>
            </div>
            <div className="mb-3">
              <div>
                {t("label.sampling_photo")}
                {body?.sampling_photo && (
                  <Button
                    variant="primary"
                    className="ms-4 btn-sm mb-1"
                    type="button"
                    onClick={() => {
                      window.open(body?.sampling_photo)
                    }}
                  >
                    Uploaded file
                  </Button>
                )}
              </div>
              <input ref={sampling_photo} type="file" accept=".jpg, .jpeg, .png" className="form-control rounded mw-100" />
              <div className="d-flex justify-content-between">
                <p className={style.file_input_desc}>{t("page.calibration_report.ignore_input_file", { file_name: t("label.sampling_photo") })}</p>
                <p className={style.file_input_desc}>{t("max_attachment", { size: "1 mb" })}</p>
              </div>
            </div>
            <div className="d-flex justify-content-end my-5 mb-4">
              <Button variant="tertiary" className="mx-1" onClick={onBack} type="button" disabled={disableButton}>
                {t("buttons.cancel")}
              </Button>
              <Button variant="primary" className="mx-1" type="submit" disabled={disableButton}>
                {t("buttons.submit_report")}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  )
}

export default EditReport
