import { h } from "preact"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import { useFeature } from "../../../hooks/useUsman"
import useObjectState from "../../../hooks/useObjectState"
import { t } from "i18next"

type Props = {
  show: boolean
  mutate: void
  onClose: void
}

const defaultBody = {
  menu_name: "",
  order: 0,
  list_feature: {
    uuid: "",
  },
}

const AddMenu = ({ mutate, show, onClose }: Props) => {
  const { data: features } = useFeature()
  const { fetcher } = useAuth()
  const [body, setBody] = useObjectState(defaultBody)

  const handleSubmit = (event) => {
    event.preventDefault()
    fetcher()
      .post("/menu-list", body)
      .then(() => {
        mutate()
        onClose()
      })
  }

  return (
    <form
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("Tambah Menu Baru")}</div>
          <div className="card-body">
            <div class="mb-4">
              <label class="form-label">{t("label.feature")}</label>
              <select
                class="form-control rounded mw-100"
                required
                value={body?.list_feature?.uuid}
                onChange={({ currentTarget: e }) => setBody({ list_feature: { uuid: e.value } })}
              >
                <option value="">{t("label.select_feature")}</option>
                {features?.map(({ uuid, name }) => (
                  <option key={uuid} value={uuid}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.nenu_name")}</label>
              <input
                type="text"
                class="form-control rounded mw-100"
                required
                value={body.menu_name}
                onChange={({ currentTarget }) => setBody({ menu_name: currentTarget.value })}
              />
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onClose} type="button" variant="tertiary" className="m-1">
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1">
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddMenu
