import { ApiResponse } from "../types/api-response"
import useAuth from "./useAuth"
import useSWR from "swr"

export interface AlertResponse {
  uuid: string
  date: string
  time: string
  activity: string
  description: string
  created_at: string
  updated_at: string
  deleted_at: any
  mil: Mil
  cem?: Cem
}

interface Mil {
  uuid: string
  name: string
  coordinate: Coordinate
  created_at: string
  updated_at: string
  deleted_at: any
}

interface Coordinate {
  lat: string
  long: string
}

interface Cem {
  uuid: string
  name: string
  code: string
  coordinate: Coordinate2
  area: string
  regulation_references: string[]
  created_at: string
  updated_at: string
  deleted_at: any
}

interface Coordinate2 {
  lat: string
  long: string
}

interface ErrorLogPaginate {
  siteId: string
  type: string
  start: string | number
  end: string | number
  page: string | number
  limit: string | number
  orders?: { [field: string]: string }[]
}

const useErrorLog = ({ site, type, start, end, page, limit, orders = [] }: ErrorLogPaginate) => {
  const { fetcher } = useAuth()
  const url = `/alerts/error-logs`
  let body = JSON.stringify({ site, type, start, end, page, limit })

  if (orders.length) {
    let orderParams = {}
    const sort = orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    body = JSON.stringify({ site, type, start, end, page, limit, ...orderParams })
  }

  const result = useSWR([url, body], (url, body) => {
    if (!site || !type || !start || !end || !page || !limit) return []
    return fetcher()
      .get<ApiResponse<AlertResponse[]>>(url, { params: JSON.parse(body) })
      .then(({ data }) => data.data)
  })

  const exportExcel = async (newLimit, newPage = 1) => {
    const newBody = JSON.stringify({ site, type, start, end, page: newPage, limit: newLimit })
    const { data } = await fetcher().get<ApiResponse<AlertResponse>>(url, { params: JSON.parse(newBody) })
    return data?.data
  }
  return { ...result, exportExcel }
}

export default useErrorLog
