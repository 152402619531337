import { FunctionComponent, h, Fragment } from "preact"
import "./style.scss"

type HelpBadgeProps = {
  value: number
}

export const HelpBadge: FunctionComponent<HelpBadgeProps> = ({ value }) => {
  return (
    <Fragment>
      {!!value &&
        <p className="help_badge">{value}</p>}
    </Fragment>
  )
}
