import { FunctionComponent, h } from "preact"
import { useState, useEffect, useCallback } from "preact/hooks"
import { HiChevronDown, HiChevronUp } from "react-icons/hi"
import { Socket } from 'socket.io-client'
import { toFixed } from "../../../utils/toFixed"
import DetailsSummaryCard from "./details-summary-card"
import style from "./style.scss"

type DetailsModuleContentProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  company: any
  status?: string 
  socket: Socket
}

const DetailsModuleContent: FunctionComponent<DetailsModuleContentProps> = ({ socket, status, company }) => {
  const [isCompanyOpen, setIsCompanyOpen] = useState(false)
  const [data, setData] = useState([])
  const [sites, setSites] = useState([])
  const [filteredSites, setFilteredSites] = useState()
  const toggleModuleDetails = useCallback(() => setIsCompanyOpen((prevState) => !prevState), [])

  useEffect(() => {
    const mergeData = (existingData, newData) => {
      const index = existingData?.findIndex((item) => item?.data?.logger_id === newData?.data?.logger_id)
      if (index !== -1) {
        existingData[index] = newData
      } else {
        existingData.push(newData)
      }
      return existingData
    }

    const mappedData = (site) => {
      const { data, ...restData } = site?.data
      const mappedData = data?.map((item) => {
        const parameter = site?.parameter?.find(({ name }) => name === item?.name)
        const calculation = site?.calculation?.find(({ name }) => name === item?.name)
        return {
          avg: toFixed(calculation?.avg, 2),
          min: toFixed(calculation?.min, 2),
          max: toFixed(calculation?.max, 2),
          lower_threshold: parameter?.lower_threshold,
          upper_threshold: parameter?.upper_threshold,
          name: item?.name,
          unit: parameter?.uom,
          value: toFixed(item?.value, 2),
          is_error: item?.is_error,
        }
      })
      return { data: mappedData, ...restData, status: true }
    }

    if (isCompanyOpen) {
      company?.sites?.forEach(({ uuid }) => {
        socket.on(uuid, (wsData) => {
          const mergedData = mergeData([...data], wsData)
          setData(mergedData)
          const newData = mergedData?.map((cem) => mappedData(cem))

          setSites((prevSite) => {
            return prevSite?.map((site) => {
              const newSiteData = newData?.find((item) => site?.uuid === item?.logger_id)
              return { ...site, ...newSiteData }
            })
          })
        })

        socket.emit("initValueEvent", { logger_id: uuid })
      })
    }
  }, [socket, isCompanyOpen, company])

  useEffect(() => {
    const getParamStatus = (cem, status) => {
      const { data, ...rest } = cem
      let newData
      if (status === "error") {
        newData = data?.filter(({ is_error }) => is_error)
      } else if (status === "good") {
        newData = data?.filter(({ is_error }) => !is_error)
      } else {
        newData = data
      }
  
      return { ...rest, data: newData }
    }
    
    const siteUuid = company?.sites?.map(({ uuid }) => uuid)
    const filteredSite = sites?.filter(({ uuid }) => siteUuid?.includes(uuid))
    const newSites = filteredSite?.map((cem) => getParamStatus(cem, status))
    setFilteredSites(newSites)
  }, [status, sites, company])

  useEffect(() => {
    const originData = company?.sites?.map((site) => {
      const { parameters = [], ...restCem } = site
      const mappedCem = parameters?.map((param) => {
        return {
          avg: "-",
          min: "-",
          max: "-",
          lower_threshold: param?.lower_threshold,
          upper_threshold: param?.upper_threshold,
          name: param?.name,
          unit: param?.uom,
          value: "-",
          is_error: true,
        }
      })
      return {
        data: mappedCem,
        status: false,
        ...restCem
      }
    })
    setSites(originData)

    return () => {
      socket.removeAllListeners()
    }
  }, [])

  return (
    <details open={isCompanyOpen} onToggle={toggleModuleDetails} className="mt-2">
      <summary className={style.detail_summary_company_reverse}>
        <div className={style.summary_title_company}>
          {company?.name}<span>Active Parameter ({company?.count_active_sites}/{company?.total_sites})</span>
          {isCompanyOpen ? <HiChevronUp size="1.5rem" /> : <HiChevronDown size="1.5rem" />}
        </div>
      </summary>
      {filteredSites?.map((cem, index) => (
        <DetailsSummaryCard key={cem?.uuid} cem={cem} order={index} />
      ))}
    </details>
  )
}

export default DetailsModuleContent