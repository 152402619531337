import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { Link } from "preact-router"
import { HiArrowLeft } from "react-icons/hi"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import Spinner from "../../components/spinner"
import Container from "../../parts/container"
import { Button } from "../../parts/buttons"
import { useHelp, useHelpById } from "../../hooks/useHelp"
import useAuth from "../../hooks/useAuth"
import useObjectState from "../../hooks/useObjectState"
import useSharedState, { loggedinUser } from "../../state"
import { formatDate } from "../../utils/dateformat"
import { Role } from "../../enum/roles.enum"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type HelpDetailProps = h.JSX.HTMLAttributes<HTMLDivElement>

const HelpDetail: FunctionComponent<HelpDetailProps> = () => {
  const { t } = useTranslation()
  const pathname = window.location.pathname
  const uuid = pathname.split("/").length === 5 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""

  const { data } = useHelpById(uuid)
  const { mutate } = useHelp()
  const { fetcher } = useAuth()
  const [response, setResponse] = useState()
  const [alert, setAlert] = useObjectState({ status: "", msg: "" })
  const [userData] = useSharedState(loggedinUser, undefined)

  const sendResponse = (event) => {
    event.preventDefault()
    fetcher()
      .patch(`/support-contact/${uuid}`, { response })
      .then(() => {
        setAlert({ status: "primary", msg: "Response sent successfully" })
        setResponse("")
        mutate()
        setTimeout(() => setAlert({ status: "", msg: "" }), 3000)
      })
      .catch(() => {
        setAlert({ status: "error", msg: "Response failed to send" })
      })
  }

  return (
    <div>
      <Spinner show={!data} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.help")} link="" />
        <Breadcrumb name={t("breadcrumb.contact_support")} link="/help/support" />
        <Breadcrumb name={t("breadcrumb.help_detail")} link={`/help/support/detail/${uuid}`} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <Link className={style.back_button} href="/help/support">
              <HiArrowLeft size="1.2rem" />
              {t("buttons.back")}
            </Link>
            <p class={`${style.page_title} mb-4`}>{t("label.help_detail")}</p>
            <div className={style.detail}>
              <div className={style.label}>{t("label.subject")}</div>
              <div className={style.value}>{data?.subject}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.name")}</div>
              <div className={style.value}>{data?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.phone")}</div>
              <div className={style.value}>{data?.phone}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.created_at")}</div>
              <div className={style.value}>{formatDate(data?.created_at, "DD-MM-YYYY HH:mm")}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.message")}</div>
              <div className={style.value}>{data?.message}</div>
            </div>
            <hr />
            <div className="mt-4">
              <p class={`${style.page_title} mb-4`}>{t("Response")}</p>
              {alert.status && <div className={`alert-${alert.status} px-3 py-2 mb-4`}>{alert.msg}</div>}
              <p>{data?.response ?? "-"}</p>
              {userData?.role?.name === Role.SuperAdmin && !data?.response && (
                <form onSubmit={(event) => sendResponse(event)}>
                  <textarea
                    className="form-control mw-100 rounded-3"
                    type="text"
                    required
                    rows={5}
                    value={response}
                    onChange={({ currentTarget }) => setResponse(currentTarget.value)}
                  />
                  <div class="mt-4 d-flex justify-content-end">
                    <Button variant="primary">{t("buttons.send_response")}</Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HelpDetail
