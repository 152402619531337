import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { Link } from "preact-router"
import { BsEye, BsPencil, BsTrash } from "react-icons/bs"
import { BiMapPin } from "react-icons/bi"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import TopFilter from "../../../components/TopFilter"
import ServerPagination from "../../../components/pagination/server-pagination"
import Spinner from "../../../components/spinner"
import { useNcrList } from "../../../hooks/useNcr"
import useAuth from "../../../hooks/useAuth"
import useSites from "../../../hooks/useSite"
import { useConfirm } from "../../../context/useConfirm"
import { useFilterContext } from "../../../context/useFilter"
import usePagination from "../../../hooks/usePagination"
import useObjectState from "../../../hooks/useObjectState"
import { exportNcrExcel } from "../export-excel"
import Approval from "./approval"
import "flatpickr/dist/flatpickr.css"
import style from "../style.scss"
import { useSortHeader } from "../../../hooks/useSortHeader"
import { useTranslation } from "react-i18next"

type NCRListPageProps = h.JSX.HTMLAttributes<HTMLDivElement>

const NCRListPage: FunctionComponent<NCRListPageProps> = () => {
  const { t } = useTranslation()
  const { data: cems } = useSites()
  const { contextFilter, setContextFilter } = useFilterContext()
  const [filtered, setFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const limit = 20
  const defaultValue = {
    site: undefined,
    period: undefined,
    startDate: undefined,
    endDate: undefined,
    ...contextFilter,
    sensor: Array.isArray(contextFilter?.sensor) ? contextFilter?.sensor?.[0] : contextFilter?.sensor,
    orders: [],
  }

  const [payload, setPayload] = useObjectState(defaultValue)
  const { data, mutate } = useNcrList({
    site: payload.site,
    page: currentPage,
    range: [payload.startDate, payload.endDate],
    orders: payload.orders,
    limit,
  })
  const [showStatus, setShowStatus] = useState(false)
  const [statusUuid, setStatusUuid] = useState()
  const [statusType, setStatusType] = useState(0)
  const confirmation = useConfirm()
  const { fetcher } = useAuth()

  const showActionColumn = canOneOf([
    Permission.MaintenanceNcrDelete,
    Permission.MaintenanceNcrDetail,
    Permission.MaintenanceNcrEdit,
    Permission.MaintenanceNcrFirstApproval,
    Permission.MaintenanceNcrSecondApproval,
  ])

  const handleFilterData = (data) => {
    resetSorting()
    setPayload({ ...data, orders: [] })
    setFiltered(true)
    setContextFilter(data)
    setCurrentPage(1)
  }

  const handleDelete = (uuid: string, site: string) => {
    confirmation(
      `${t("delete_report_confirm_msg", { site })}`,
      (isDelete) => {
        if (isDelete)
          fetcher()
            .delete(`/ncr/${uuid}`)
            .then(() => mutate())
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }

  const handleChangeStatus = (uuid: string, type: number) => {
    setStatusUuid(uuid)
    setShowStatus(true)
    setStatusType(type)
  }

  const dataTable = data?.data

  const StatusButton = ({ uuid, firstApproval, secondApproval, validator }) => {
    if (can(Permission.MaintenanceNcrFirstApproval)) {
      return (
        validator === "Open" &&
        secondApproval !== "Approve" && (
          <Button className="m-1" variant="secondary" title="Validation" onClick={() => handleChangeStatus(uuid, 3)} icon={BiMapPin} />
        )
      )
    }
    if (can(Permission.MaintenanceNcrSecondApproval)) {
      const type = firstApproval === "Approve" ? 2 : 1
      return (
        secondApproval !== "Approve" && (
          <Button className="m-1" variant="secondary" title="Approval" onClick={() => handleChangeStatus(uuid, type)} icon={BiMapPin} />
        )
      )
    }
  }

  // sort data
  const { sort, onOrder, sortIcon, resetSorting } = useSortHeader({
    initialFieldToSort: {},
  })

  const onSortField = (field: string) => {
    const sortParamsData = onOrder(field)
    setPayload({ ...payload, orders: sortParamsData })
  }
  // end sort data

  return (
    <div>
      {showStatus && <Approval uuid={statusUuid} type={statusType} mutate={() => mutate()} show={showStatus} onClose={() => setShowStatus(false)} />}
      <Spinner show={!data} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.maintenance")} link="" />
        <Breadcrumb name={t("breadcrumb.ncr")} link="/maintenance/ncr" />
      </TopBar>
      <Container>
        <div className="row align-items-stretch w-100">
          <TopFilter cems={cems} defaultValue={payload} onShow={handleFilterData} />
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex mb-3 justify-content-end">
              {can(Permission.MaintenanceNcrExport) && (
                <Button variant="primary" className="me-2" disabled={!data?.length} onClick={() => exportNcrExcel(data)}>
                  {t("buttons.export_to_excel")}
                </Button>
              )}
              {can(Permission.MaintenanceNcrAdd) && (
                <div>
                  <Link href="/maintenance/ncr/add">
                    <Button variant="primary">{t("buttons.add_new_entry")}</Button>
                  </Link>
                </div>
              )}
            </div>
            <table className="table table-data table-center">
              <thead>
                <tr>
                  <th>No</th>
                  <th>{t("table.lk_number")}</th>
                  <th onClick={() => onSortField("date")} className={style.sort_able}>
                    {t("table.date")} {sortIcon("date")}
                  </th>
                  <th>{t("table.site")}</th>
                  <th>{t("table.first_approval")}</th>
                  <th>{t("table.second_approval")}</th>
                  <th onClick={() => onSortField("validator")} className={style.sort_able}>
                    {t("table.validation_status")} {sortIcon("validator")}
                  </th>
                  {showActionColumn && <th>Menu</th>}
                </tr>
              </thead>
              <tbody>
                {dataTable?.length ? (
                  dataTable?.map((item, id) => (
                    <tr key={item?.uuid}>
                      <td>{id + 1 + (currentPage - 1) * limit}</td>
                      <td>{item?.lk_number}</td>
                      <td>{item?.date}</td>
                      <td>{item?.site?.name}</td>
                      <td> {item?.first_approval}</td>
                      <td>{item?.second_approval}</td>
                      <td>{item?.validator}</td>
                      {showActionColumn && (
                        <td>
                          {can(Permission.MaintenanceNcrDetail) && (
                            <Link href={`/maintenance/ncr/detail/${item?.uuid}`}>
                              <Button variant="primary" className="m-1" title="Detail" icon={BsEye} />
                            </Link>
                          )}
                          {can(Permission.MaintenanceNcrEdit) && (
                            <Link href={`/maintenance/ncr/edit/${item?.uuid}`}>
                              <Button variant="secondary" className="m-1" title="Edit" icon={BsPencil} />
                            </Link>
                          )}
                          {can(Permission.MaintenanceNcrDelete) && (
                            <Button
                              className="m-1"
                              title="Delete"
                              variant="tertiary"
                              onClick={() => handleDelete(item?.uuid, item?.cem?.name)}
                              icon={BsTrash}
                            />
                          )}
                          {canOneOf([Permission.MaintenanceNcrFirstApproval, Permission.MaintenanceNcrSecondApproval]) && (
                            <StatusButton
                              uuid={item?.uuid}
                              firstApproval={item?.first_approval}
                              secondApproval={item?.second_approval}
                              validator={item?.validator}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 8 : 7} className="text-center">
                      {!filtered ? t("select_site_first") : t("no_data")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <ServerPagination current={currentPage} last={data?.totalPages} onPageChange={setCurrentPage} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NCRListPage
