import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { BsPencil, BsTrash, BsEye } from "react-icons/bs"
import useCompany from "../../../hooks/useCompany"
import useAuth from "../../../hooks/useAuth"
import usePagination from "../../../hooks/usePagination"
import useModal from "../../../hooks/useModal"
import { useConfirm } from "../../../context/useConfirm"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import Pagination from "../../../components/pagination"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import Spinner from "../../../components/spinner"
import SearchBox from "../../../components/TopFilter/search-box"
import AddCompany from "./add"
import EditCompany from "./edit"
import CompanyDetail from "./detail"
import { useTranslation } from "react-i18next"

type SettingsCompanyProps = h.JSX.HTMLAttributes<HTMLDivElement>

const SettingsCompany: FunctionComponent<SettingsCompanyProps> = () => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const confirmation = useConfirm()

  const { data, mutate } = useCompany()
  const { show: showAdd, onHide: hideAdd, onShow: onShowAdd } = useModal()
  const { show: showEdit, onHide: hideEdit, onShow: onShowEdit } = useModal()
  const { show: showDetail, onHide: hideDetail, onShow: onShowDetail } = useModal()
  const [modalData, setModalData] = useState()
  const [filteredData, setFilteredData] = useState(data)
  const [search, setSearch] = useState("")

  const showActionColumn = canOneOf([Permission.SettingsCompanyDelete, Permission.SettingsCompanyDetail, Permission.SettingsCompanyEdit])

  filteredData?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))

  const pages = usePagination({ count: filteredData?.length || 0, step: 20 })
  const dataTable = filteredData?.slice(pages.output, pages.output + pages.step)

  const handleEdit = (data) => {
    setModalData(data)
    onShowEdit()
  }
  const handleDetail = (data) => {
    setModalData(data)
    onShowDetail()
  }
  const handleDelete = (id: string, name: string) => {
    confirmation(
      t("delete_company_confirm_msg", { module_name: name }),
      (out) => {
        if (out)
          fetcher()
            .delete(`/company/${id}`)
            .then(() => mutate())
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }

  useEffect(() => {
    let searchedData
    if (search) {
      searchedData = data?.filter(({ name }) => {
        return name?.toLowerCase()?.includes(search) || !search
      })
    } else {
      searchedData = data
    }
    setFilteredData(searchedData)
  }, [data, search])

  return (
    <div>
      <AddCompany show={showAdd} onHide={hideAdd} mutate={mutate} />
      <EditCompany data={modalData} show={showEdit} onHide={hideEdit} mutate={mutate} />
      <CompanyDetail data={modalData} show={showDetail} onHide={hideDetail} />
      <Spinner show={!Array.isArray(data)} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.settings")} link="" />
        <Breadcrumb name={t("breadcrumb.company")} link="/settings/company" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title pt-4">
            <SearchBox placeholder={t("placeholder.search_company")} onChange={(value) => setSearch(value?.toLowerCase())} />
            {can(Permission.SettingsCompanyAdd) && (
              <Button variant="primary" onClick={onShowAdd}>
                {t("add_new_company")}
              </Button>
            )}
          </div>
          <div className="card-body">
            <table className="table table-center table-data">
              <thead>
                <tr>
                  <th rowSpan={2}>No</th>
                  <th rowSpan={2}>{t("table.name")}</th>
                  <th rowSpan={2}>{t("table.platform")}</th>
                  <th rowSpan={2}>{t("table.modules")}</th>
                  <th colSpan={3}>{t("table.technical_contact_person")}</th>
                  {showActionColumn && <th rowSpan={2}>{t("table.action")}</th>}
                </tr>
                <tr>
                  <th>{t("table.name")}</th>
                  <th>{t("table.email")}</th>
                  <th>{t("table.phone")}</th>
                </tr>
              </thead>
              <tbody>
                {dataTable?.length ? (
                  dataTable?.map((company, index) => (
                    <tr key={company?.uuid}>
                      <td>{index + 1 + pages.page * pages.step}</td>
                      <td>{company?.name}</td>
                      <td>{company?.platform?.name}</td>
                      <td>{company?.modules?.map(({ name }) => name)?.join(", ")}</td>
                      <td>{company?.pic_technical_name}</td>
                      <td>{company?.pic_technical_email}</td>
                      <td>{company?.pic_technical_phone}</td>
                      {showActionColumn && (
                        <td>
                          {can(Permission.SettingsCompanyDetail) && (
                            <Button variant="primary" title="Detail" onClick={() => handleDetail(company)} className="m-1" icon={BsEye} />
                          )}
                          {can(Permission.SettingsCompanyEdit) && (
                            <Button variant="secondary" title="Edit" onClick={() => handleEdit(company)} className="m-1" icon={BsPencil} />
                          )}
                          {can(Permission.SettingsCompanyDelete) && (
                            <Button
                              variant="tertiary"
                              title="Delete"
                              onClick={() => handleDelete(company?.uuid, company?.name)}
                              className="m-1"
                              icon={BsTrash}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 7 : 6}>No data.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <Pagination hook={pages} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default SettingsCompany
