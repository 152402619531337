import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    id: {
      translation: require("./locales/id/translation.json"),
    },
    en: {
      translation: require("./locales/en/translation.json"),
    },
    lng: process.env.LOCAL_LANG?.toLowerCase() || "en",
    fallbackLng: ["en", "id"],
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
