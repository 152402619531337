import { Fragment, FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { FaRegFileExcel } from "react-icons/fa"
import Container from "../../parts/container"
import TopBar from "../../components/topbar"
import TopFilter from "../../components/TopFilter"
import Breadcrumb from "../../components/topbar/breadcrumb"
import ServerPagination from "../../components/pagination/server-pagination"
import { sortActiveParam, sortParam, isAvgSensor } from "../../utils/sortParam"
import { toFixed } from "../../utils/toFixed"
import { useFilterContext } from "../../context/useFilter"
import { defaultParams } from "../../utils/defaults"
import Spinner from "../../components/spinner"
import { useRecordAccumulated } from "../../hooks/useSensor"
import useSites from "../../hooks/useSite"
import { Button } from "../../parts/buttons"
import { formatDateWithPeriod } from "../../utils/dateformat"
import useObjectState from "../../hooks/useObjectState"
import { accumulatedLoadToExcel } from "./toexcel"
import { can } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import style from "./style.scss"
import { useSortHeader } from "../../hooks/useSortHeader"
import { useTranslation } from "react-i18next"

type RecordAccumulatedLoadProps = h.JSX.HTMLAttributes<HTMLDivElement>

const RecordParameterAccumulatedLoad: FunctionComponent<RecordAccumulatedLoadProps> = () => {
  const { t } = useTranslation()
  const { contextFilter, setContextFilter } = useFilterContext()
  const { data: cems } = useSites()
  const [filtered, setFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const limit = 20
  const defaultValue = {
    site: undefined,
    period: undefined,
    startDate: undefined,
    endDate: undefined,
    ...contextFilter,
    sensor: Array.isArray(contextFilter?.sensor) ? contextFilter?.sensor?.[0] : contextFilter?.sensor,
    orders: [],
  }
  const [payload, setPayload] = useObjectState(defaultValue)
  const { data, exportExcel } = useRecordAccumulated({
    uuid: payload.site,
    type: payload.period,
    start: payload.startDate,
    end: payload.endDate,
    page: currentPage,
    limit,
    orders: payload.orders,
    parameter_id: payload.parameter_id,
  })
  const columns = sortActiveParam(data?.parameter) || defaultParams

  const getData = (data) => {
    return data?.record?.map((item) => {
      const filterPayloads = item?.data?.filter(({ name }) => columns?.map(({ name: colName }) => colName)?.includes(name))
      return {
        timestamp: item.timestamp,
        payloads: sortParam(filterPayloads),
      }
    })
  }

  const tableData = getData(data)

  const aggregateData = sortParam(data?.calculation) || defaultParams

  const handleFilterData = (data) => {
    resetSorting()
    setPayload({ ...data, orders: [] })
    setFiltered(true)
    setContextFilter(data)
    setCurrentPage(1)
  }

  const getSiteName = (siteId) => cems?.find(({ uuid }) => uuid === siteId)?.name

  const getExcelData = async () => {
    const exportExcelData = await exportExcel(data?.totalRecords)
    const excelData = getData(exportExcelData)
    if (excelData?.length) {
      accumulatedLoadToExcel({
        columns,
        data: excelData,
        period: payload.period,
        name: getSiteName(payload.site),
        aggregate: aggregateData,
      })
    }
  }

  // sort data
  const { sort, onOrder, sortIcon, resetSorting } = useSortHeader({
    initialFieldToSort: {
      timestamp: null,
    },
  })
  const onSortField = (field: string) => {
    const sortParamsData = onOrder(field)

    if (sortParamsData[0]["sort_by[0]"] != "timestamp") {
      //matching parameter id dengan data?.parameter
      const parameterName = sortParamsData[0]["sort_by[0]"]
      const getParameterId = data?.parameter?.find((parameter) => parameter.name == parameterName)
      if (getParameterId) {
        const sensorName = parameterName
        const sortBy = isAvgSensor(payload.period, sensorName) ? "avg" : "value"
        const order = [{ ...sortParamsData[0], ["sort_by[0]"]: sortBy }]
        setPayload({ ...payload, orders: order, parameter_id: getParameterId.uuid })
      }
      return
    }

    setPayload({ ...payload, orders: sortParamsData })
  }
  // sort data

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.record")} link="" />
        <Breadcrumb name={t("breadcrumb.parameter_accumulated_load")} link="/record/parameter-accumulated-load" />
      </TopBar>
      <Container>
        {data ? (
          <Fragment>
            <div className="row align-items-stretch">
              <TopFilter cems={cems} interval defaultValue={payload} onShow={handleFilterData} />
            </div>
            <div className="mt-3">
              <div className="d-flex justify-content-end">
                {can(Permission.RecordParameterAccumulatedLoadExportExcel) && (
                  <Button
                    variant="primary"
                    className={style.export_btn}
                    icon={FaRegFileExcel}
                    onClick={() => getExcelData()}
                    disabled={!tableData?.length}
                  >
                    {t("buttons.export_to_excel")}
                  </Button>
                )}
              </div>
            </div>
            <div className="mt-3">
              <div className="card mt-3">
                <div className="card-body">
                  <div className="overflow-auto">
                    <table className="table table-data table-center">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th onClick={() => onSortField("timestamp")} className={style.sort_able}>
                            {t("table.time")} {sortIcon("timestamp")}
                          </th>
                          {columns?.map((col) => (
                            <th key={col?.name} rowSpan={2} onClick={() => onSortField(col?.name)} className={style.sort_able}>
                              <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                                <div>
                                  {col?.name}
                                  <br />
                                  {col.uom && col.uom !== "-" ? `(${col.uom})` : ""}
                                </div>
                                {sortIcon(col?.name)}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.length ? (
                          tableData?.map((raw, id) => (
                            <tr key={`raw-${id}`}>
                              <td>{id + 1 + (currentPage - 1) * limit}</td>
                              <td className="text-nowrap">{formatDateWithPeriod(raw.timestamp, payload.period)}</td>
                              {raw.payloads.map((value, idx) => (
                                <td key={`payloads-${idx}`}>
                                  {isAvgSensor(payload.period, value?.name) ? toFixed(value?.avg) : toFixed(value?.value)}
                                </td>
                              ))}
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td className="text-center py-4" colSpan={3 * (columns?.length ?? 0) + 2}>
                              {!filtered ? "Please fill the filter first." : "No data."}
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr className={style.avg}>
                          <td rowSpan={3}>{t("table.aggregate")}</td>
                          <td>{t("average").toUpperCase()}</td>
                          {columns?.map((_, idx) => (
                            <td key={`avg-${idx}`}>{toFixed(aggregateData?.[idx]?.avg)}</td>
                          ))}
                        </tr>
                        <tr className={style.max}>
                          <td>{t("max").toUpperCase()}</td>
                          {columns?.map((_, idx) => (
                            <td key={`max-${idx}`}>{toFixed(aggregateData?.[idx]?.max)}</td>
                          ))}
                        </tr>
                        <tr className={style.min}>
                          <td>{t("min").toUpperCase()}</td>
                          {columns?.map((agg, idx) => (
                            <td key={`min-${idx}`}>{toFixed(aggregateData?.[idx]?.min)}</td>
                          ))}
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="my-2 d-flex justify-content-end">
                    <ServerPagination current={currentPage} last={data?.totalPages} onPageChange={setCurrentPage} />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <Spinner />
        )}
      </Container>
    </div>
  )
}

export default RecordParameterAccumulatedLoad
