import moment from "moment"

export const isRenew = (payment_status: string, invoice_date: string) => {
  const date_now = moment().format("YYYY-MM-DD")
  const due_date = moment(invoice_date).format("YYYY-MM-DD")
  const status = payment_status?.toLowerCase()?.replace(/[^a-z]/g, "")
  if (status == "suspend") {
    return true
  }

  if (status == "paid" && [undefined, null].includes(invoice_date)) {
    return false
  }

  if (status == "paid") {
    return due_date <= date_now
  }

  return false
}
