export const camelCase = (string: string): string => {
  if(!string) return ""
  return string.replace(/[^0-9a-zA-Z]+/g, "")
}

export const titleCase = (string) => {
  if (!string || typeof string !== 'string') return;
  // Replace underscore and hypen to space
  const replaceString = string.replace(/[-_]/g, " ");
  // Capitalized each first word
  const capitalized = replaceString.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );
  return capitalized;
};

export const kebabCase = (string: string) => {
  if (!string || typeof string !== 'string') return
  return string.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())
}

export const toFormattedPrice = (price: string | number) => {
  return price?.toString()?.replace(/\./g, "")?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}