import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { Link } from "preact-router"
import { BsPencil, BsTrash, BsFileEarmarkArrowDown } from "react-icons/bs"
import { BiMapPin } from "react-icons/bi"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import TopFilter from "../../../components/TopFilter"
import ServerPagination from "../../../components/pagination/server-pagination"
import Spinner from "../../../components/spinner"
import { useNCRKLHK } from "../../../hooks/useNcr"
import useAuth from "../../../hooks/useAuth"
import useSites from "../../../hooks/useSite"
import useObjectState from "../../../hooks/useObjectState"
import usePagination from "../../../hooks/usePagination"
import { useConfirm } from "../../../context/useConfirm"
import { useFilterContext } from "../../../context/useFilter"
import ReportStatus from "./status"
import { exportNcrKlhkExcel } from "../export-excel"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import { useSortHeader } from "../../../hooks/useSortHeader"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type NCRKLHKPageProps = h.JSX.HTMLAttributes<HTMLDivElement>

const NCRKLHKPage: FunctionComponent<NCRKLHKPageProps> = () => {
  const { t } = useTranslation()
  const { data: cems } = useSites()
  const { contextFilter, setContextFilter } = useFilterContext()
  const [filtered, setFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const limit = 20
  const defaultValue = {
    site: undefined,
    period: undefined,
    startDate: undefined,
    endDate: undefined,
    ...contextFilter,
    sensor: Array.isArray(contextFilter?.sensor) ? contextFilter?.sensor?.[0] : contextFilter?.sensor,
    orders: [],
  }

  const [payload, setPayload] = useObjectState(defaultValue)
  const [showStatus, setShowStatus] = useState(false)
  const [statusId, setSatusId] = useState()
  const { data, mutate } = useNCRKLHK({
    site: payload.site,
    page: currentPage,
    range: [payload.startDate, payload.endDate],
    orders: payload.orders,
    limit,
  })
  const confirmation = useConfirm()
  const { fetcher } = useAuth()

  const showActionColumn = canOneOf([
    Permission.MaintenanceNcrKlhkEdit,
    Permission.MaintenanceNcrKlhkChangeStatus,
    Permission.MaintenanceNcrKlhkDelete,
  ])

  const handleDelete = (id: string, site: string) => {
    confirmation(
      `${t("delete_report_confirm_msg", { site })}`,
      (isDel) => {
        if (isDel)
          fetcher()
            .delete(`/ncr-klhk/${id}`)
            .then(() => mutate())
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }

  const handleChangeStatus = (uuid: string) => {
    setSatusId(uuid)
    setShowStatus(true)
  }

  const handleFilterData = (data) => {
    resetSorting()
    setPayload({ ...data, orders: [] })
    setFiltered(true)
    setContextFilter(data)
    setCurrentPage(1)
  }

  const dataTable = data?.data

  // sort data
  const { sort, onOrder, sortIcon, resetSorting } = useSortHeader({
    initialFieldToSort: {},
  })
  const onSortField = (field: string) => {
    const sortParamsData = onOrder(field)
    setPayload({ ...payload, orders: sortParamsData })
  }
  // end sort data

  return (
    <div>
      {showStatus && <ReportStatus uuid={statusId} mutate={() => mutate()} show={showStatus} onClose={() => setShowStatus(false)} />}
      <Spinner show={!data} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.maintenance")} link="" />
        <Breadcrumb name={t("breadcrumb.ncr_klhk")} link="/maintenance/ncr-klhk" />
      </TopBar>
      <Container>
        <div className="row align-items-stretch w-100">
          <TopFilter cems={cems} defaultValue={payload} onShow={handleFilterData} />
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex mb-3 justify-content-end">
              {can(Permission.MaintenanceNcrKlhkExport) && (
                <Button variant="primary" className="me-2" disabled={!data?.length} onClick={() => exportNcrKlhkExcel(data)}>
                  {t("buttons.export_to_excel")}
                </Button>
              )}
              {can(Permission.MaintenanceNcrKlhkAdd) && (
                <Link href="/maintenance/ncr-klhk/add">
                  <Button variant="primary">{t("buttons.add_new_entry")}</Button>
                </Link>
              )}
            </div>
            <table className="table table-data table-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("table.site")}</th>
                  <th>{t("table.parameter")}</th>
                  <th>{t("table.problem")}</th>
                  <th onClick={() => onSortField("completion_date")} className={style.sort_able}>
                    {t("table.refinement_date")} {sortIcon("completion_date")}
                  </th>
                  <th>{t("table.file")}</th>
                  <th onClick={() => onSortField("status")} className={style.sort_able}>
                    {t("table.status")} {sortIcon("status")}
                  </th>
                  {showActionColumn && <th>{t("table.menu")}</th>}
                </tr>
              </thead>
              <tbody>
                {dataTable?.length ? (
                  dataTable.map((item, id) => (
                    <tr key={item?.uuid}>
                      <td>{id + 1 + (currentPage - 1) * limit}</td>
                      <td>{item?.site?.name}</td>
                      <td>{item?.parameter}</td>
                      <td>{item?.description}</td>
                      <td>{item?.completion_date}</td>
                      <td>
                        <Button
                          variant="primary"
                          className="m-1 btn-sm"
                          disabled={!item?.file}
                          icon={BsFileEarmarkArrowDown}
                          onClick={() => window.open(item?.file)}
                        />
                      </td>
                      <td>{item?.status}</td>
                      {showActionColumn && (
                        <td>
                          {can(Permission.MaintenanceNcrKlhkEdit) && (
                            <Link href={`/maintenance/ncr-klhk/edit/${item?.uuid}`}>
                              <Button variant="primary" className="m-1" title="Edit" icon={BsPencil} />
                            </Link>
                          )}
                          {can(Permission.MaintenanceNcrKlhkDelete) && (
                            <Button className="m-1" variant="tertiary" onClick={() => handleDelete(item?.uuid, item?.site?.name)} icon={BsTrash} />
                          )}
                          {item?.status !== "Closed" && can(Permission.MaintenanceNcrKlhkChangeStatus) && (
                            <Button className="m-1" variant="secondary" onClick={() => handleChangeStatus(item?.uuid)} icon={BiMapPin} />
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 8 : 7} className="text-center">
                      {!filtered ? t("select_site_first") : t("no_data")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <ServerPagination current={currentPage} last={data?.totalPages} onPageChange={setCurrentPage} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NCRKLHKPage
