import { FunctionComponent, h } from "preact"
import { useState, useRef } from "preact/hooks"
import { mutate } from "swr"
import { useRole } from "../../../hooks/useUsman"
import { Button } from "../../../parts/buttons"
import SelectSearch from "../../../components/form/select-search"
import useSites from "../../../hooks/useSite"
import useCompany from "../../../hooks/useCompany"
import useVendor from "../../../hooks/useVendor"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import style from "../style.scss"
import { useTranslation } from "react-i18next"
import { getErrorResponse } from "../../../utils/handler-errors"

type AddUserProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  onHide: () => void
}

const AddUser: FunctionComponent<AddUserProps> = ({ show, onHide }) => {
  const { t } = useTranslation()
  const selectSite = useRef<HTMLDivElement>(null)
  const [avaliCem, setAvaliCem] = useState([])
  const [filteredCompany, setFilteredCompany] = useState([])
  const [siteValue, setSiteValue] = useState("")
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [showAllCheckbox, setShowAllCheckbox] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const { data: companies } = useCompany()
  const { data: sites } = useSites()
  const { data: vendors } = useVendor()
  const { data: roles } = useRole()
  const defaultState = {
    name: "",
    email: "",
    password: "",
    role: { uuid: "" },
    vendor: { uuid: "" },
    company: { uuid: "" },
    sites: [],
    notification: false,
    notification_interval: 6,
  }

  const [user, setUser] = useObjectState(defaultState)
  const { fetcher } = useAuth()

  const handleSubmit = (event) => {
    event.preventDefault()
    const { company, vendor, sites, ...newUser } = user
    const data = {
      ...newUser,
      ...(company?.uuid && { company }),
      ...(vendor?.uuid && { vendor }),
      ...(sites?.length && { sites }),
    }
    fetcher()
      .post("/user", data)
      .then(() => {
        if (onHide) onHide()
        setUser(defaultState)
        mutate("/user")
      })
      .catch((e) => {
        const error = e.response
        alert(getErrorResponse(error))
      })
  }
  const handleCancel = () => {
    setUser(defaultState)
    if (onHide) onHide()
  }

  const getSiteByCompany = (id) => sites?.filter(({ company }) => company?.uuid === id)

  const onSiteInputFocus = () => {
    setShowCheckbox(true)
    const filteredSite = getSiteByCompany(user?.company?.uuid)
    setAvaliCem(filteredSite)
    setShowAllCheckbox(filteredSite?.length > 1)
  }

  const onSiteInputChange = (value) => {
    if (value) {
      setShowAllCheckbox(false)
      setSiteValue(value)
      const lowerValue = value.toLowerCase()
      const result = avaliCem?.filter(({ name }) => {
        const lowerName = name.toLocaleLowerCase()
        return lowerName.includes(lowerValue)
      })
      setAvaliCem(result)
    } else {
      setSiteValue("")
      const filteredCem = getSiteByCompany(user?.company?.uuid)
      setAvaliCem(filteredCem)
      setShowAllCheckbox(filteredCem?.length > 1)
    }
  }

  const checkSite = (uuid) => {
    const filteredCem = getSiteByCompany(user?.company?.uuid)
    setCheckAll(false)
    setSiteValue("")
    setAvaliCem(filteredCem)
    setShowAllCheckbox(filteredCem?.length > 1)
    const isChecked = user?.sites?.find((item) => item === uuid)
    if (isChecked) {
      const prevSites = user?.sites?.filter((item) => item !== uuid)
      setUser({ sites: prevSites })
    } else {
      setUser({ sites: [...user?.sites, uuid] })
    }
  }

  const checkAllSite = (data) => {
    const allUuid = data?.map(({ uuid }) => uuid)

    setCheckAll((prevState) => {
      !prevState ? setUser({ sites: allUuid }) : setUser({ sites: [] })
      return !prevState
    })
  }

  const onVendorChange = (uuid) => {
    setUser({ vendor: { uuid }, company: { uuid: "" }, sites: [] })
    const company = companies?.filter(({ vendor }) => vendor?.uuid === uuid)
    setFilteredCompany(company)
    setCheckAll(false)
  }

  const onCompanyChange = (uuid) => {
    setUser({ company: { uuid }, sites: [] })
    const filteredCem = sites?.filter(({ company }) => company?.uuid === uuid)
    setAvaliCem(filteredCem)
    setCheckAll(false)
  }

  useOnClickOutside(selectSite, () => setShowCheckbox(false))

  return (
    <div
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
    >
      <div class="modal-dialog modal-lg modal-dialog-centered " role="document">
        <div class="modal-content card">
          <div className="card-body d-flex justify-content-between px-5">
            <h5 class="card-title justify-content-center w-100">{t("add_new_user")}</h5>
          </div>
          <form onSubmit={(event) => handleSubmit(event)} className="card-body px-5">
            <div class="form-group mb-4">
              <label class="form-label">{t("label.name")}</label>
              <input
                value={user.name}
                required
                name="name"
                type="text"
                class="form-control rounded mw-100"
                onChange={({ currentTarget }) => setUser({ name: currentTarget.value })}
              />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("label.email")}</label>
              <input
                value={user.email}
                required
                name="email"
                type="email"
                class="form-control rounded mw-100"
                onChange={({ currentTarget }) => setUser({ email: currentTarget.value })}
              />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("label.password")}</label>
              <input
                value={user.password}
                required
                name="password"
                type="text"
                class="form-control rounded mw-100"
                onChange={({ currentTarget }) => setUser({ password: currentTarget.value })}
              />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("label.roles")}</label>
              <SelectSearch
                data={roles}
                required
                defaultValue={user?.role?.uuid}
                placeholder={t("placeholder.search_role")}
                onChange={(uuid) => setUser({ role: { uuid } })}
              />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("label.vendor")}</label>
              <SelectSearch data={vendors} defaultValue={user?.vendor?.uuid} placeholder={t("placeholder.search_vendor")} onChange={onVendorChange} />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("company")}</label>
              <SelectSearch
                data={filteredCompany}
                defaultValue={user?.company?.uuid}
                placeholder={t("placeholder.search_company")}
                onChange={onCompanyChange}
              />
            </div>
            <div className="form-group mb-4">
              <label className="form-label">{t("site")}</label>
              <div ref={selectSite} className="position-relative w-100">
                <input
                  type="text"
                  className="rounded form-control mw-100"
                  onFocus={() => onSiteInputFocus()}
                  onChange={({ currentTarget: t }) => onSiteInputChange(t.value)}
                  value={siteValue}
                  placeholder={user?.sites?.length ? `${user?.sites?.length} ${t("placeholder.site_selected")}` : t("placeholder.select_site")}
                />
                <div class={style.multiselect} style={{ display: showCheckbox ? "inherit" : "none" }}>
                  {showAllCheckbox && (
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="all" checked={checkAll} onChange={() => checkAllSite(avaliCem)} />
                      <label class="form-check-label" for="all">
                        {t("label.all_site")}
                      </label>
                    </div>
                  )}
                  {avaliCem?.length ? (
                    avaliCem.map(({ name, uuid }) => (
                      <div key={uuid} class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id={uuid}
                          value={uuid}
                          checked={user?.sites?.includes(uuid)}
                          onChange={() => checkSite(uuid)}
                        />
                        <label class="form-check-label text-nowrap" for={uuid}>
                          {name}
                        </label>
                      </div>
                    ))
                  ) : (
                    <label class="form-check-label text-nowrap">{siteValue ? t("no_site_with_this_keyword") : t("no_site_available")}</label>
                  )}
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("label.notification")}</label>
              <select
                className="rounded form-control mw-100"
                value={user.notification ? "on" : "off"}
                onChange={({ currentTarget }) => setUser({ notification: currentTarget.value == "on" })}
              >
                <option value="on">On</option>
                <option value="off">Off</option>
              </select>
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("email_notification_interval")}</label>
              <input value={`${user.notification_interval} ${t("hours")}`} required type="text" disabled class="form-control rounded mw-100" />
            </div>
            <div className="d-flex justify-content-end">
              <Button variant="primary">{t("buttons.add_user")}</Button>
              <Button type="button" variant="tertiary" className="ms-2" onClick={handleCancel}>
                {t("buttons.cancel")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddUser
