export enum Role {
  SuperAdmin = "SUPER_ADMIN",
  AdminVendor = "SUPER_ADMIN_VENDOR",
  MultiSite = "MULTI_SITE",
  Operator = "OPERATOR",
}

export const RoleList: Role[] = [
  Role.SuperAdmin,
  Role.AdminVendor,
  Role.MultiSite,
  Role.Operator,
]
