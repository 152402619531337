import { h, FunctionComponent } from "preact"
import { useState, useEffect } from "preact/hooks"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { toFormattedPrice } from "../../utils/string"
import useAuth from "../../hooks/useAuth"
import useObjectState from "../../hooks/useObjectState"
import { Button } from "../../parts/buttons"
import "flatpickr/dist/flatpickr.css"
import "./style.scss"
import { useTranslation } from "react-i18next"

type ProformaModalProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  isEdit: boolean
  data: any
  mutate: () => void
  onHide: () => void
}

const ProformaModal: FunctionComponent<ProformaModalProps> = ({ show, isEdit, data, mutate, onHide }) => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const [disable, setDisable] = useState(true)
  const [price, setPrice] = useState("")
  const [body, setBody] = useObjectState(data)

  const onPriceInput = (event) => {
    const price = event?.currentTarget?.value?.replace(/[^0-9.]/g, "")
    const parsedPrice = parseFloat(price.replace(/\./g, ""))
    if (price) {
      const formattedPrice = toFormattedPrice(price)
      setBody({ price: parsedPrice })
      setPrice(formattedPrice)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    const { invoice_id, uuid, ...rest } = body
    const formData = new FormData()
    formData.append("invoice_number", body?.invoice_number)
    formData.append("invoice_date", body?.inv_date)
    formData.append("po_number", body?.po_number)
    formData.append("po_date", body?.po_date)
    formData.append("start", body?.start_date)
    formData.append("end", body?.end_date)
    formData.append("billing_date", body?.billing_date)
    formData.append("service_plan", body?.service_plan)
    formData.append("price", body?.price)
    formData.append("include_ppn", body?.include_ppn)
    if (body.po_file) {
      formData.append("po_file", body.po_file)
    }
    if (body.invoice_file) {
      formData.append("invoice_file", body.invoice_file)
    }

    try {
      if (isEdit) {
        await fetcher().patch(`/subscribtion/update/performa-invoice/${uuid}/${invoice_id}`, formData)
      } else {
        await fetcher().post(`/subscribtion/performa-invoice/${uuid}`, formData)
      }
      await mutate()
      await onHide()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  const poDateOptions = {
    dateFormat: "Y/m/d",
    onChange([selectedDates]) {
      const po = moment(selectedDates).format("YYYY-MM-DD")
      setBody({ po_date: po })
      if (body?.billing_date) {
        setDisable(false)
      }
    },
  }

  const dateOptions = {
    dateFormat: "Y/m/d",
  }

  const onChangeDate = (selectedDate: Date, field: string) => {
    const date = moment(selectedDate).format("YYYY-MM-DD")
    setBody({ [field]: date })
    if (body?.po_date) {
      setDisable(false)
    }
    if (field == "end_date") {
      const inv_date = moment(date).subtract("45", "days").format("YYYY-MM-DD")
      setBody({ inv_date: inv_date })
    }
  }

  const onChangeLicense = (license: string) => {
    if (["Demo", "Admin"].includes(license)) {
      setBody({ include_ppn: false })
    } else {
      setBody({ include_ppn: true })
    }
  }

  const onFileChanges = (file: File, field: string) => {
    const maxFileSize = 10 * 1024 * 1024 // 10MB in bytes
    const fileSize = file.size
    if (fileSize > maxFileSize) {
      alert(`${field} size is exceeds 10MB limit.`)
      document.querySelector(`input[name="${field}"]`).value = ""
    } else {
      setBody({
        [field]: file,
      })
    }
  }

  useEffect(() => {
    setBody(data)
    const formattedPrice = toFormattedPrice(data?.price)
    setPrice(formattedPrice)
    if (body?.billing_date && body?.po_date) {
      setDisable(false)
    }
    if (data?.include_ppn == undefined) {
      setBody({ include_ppn: true })
    }
  }, [data])

  return (
    <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", zIndex: 99998 }}>
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <form className="modal-content card" onSubmit={(event) => handleSubmit(event)}>
          <div className="modal-header">
            <p className="text-center fw-bolder fs-5">
              {isEdit ? t("buttons.edit") : t("buttons.create")} {t("buttons.proforma_invoice")}
            </p>
          </div>
          <div className="modal-body">
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.po_spk_number")}</label>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  required
                  value={body?.po_number}
                  onChange={({ currentTarget: e }) => setBody({ po_number: e.value })}
                />
              </div>
            </div>
            <div className="row mb-4">
              <label className="form-label col-3">{t("label.po_date")}</label>
              <div className="col-9">
                <Flatpickr options={poDateOptions} value={body?.po_date} className="form-control rounded mw-100" />
              </div>
            </div>
            <div className="row mb-4">
              <label className="form-label col-3">Upload PO</label>
              <div className="col-9">
                <input
                  type="file"
                  name="po_file"
                  className="form-control rounded mw-100"
                  accept="application/pdf"
                  onChange={(e) => {
                    onFileChanges(e.target.files[0], "po_file")
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("company_name")}</label>
              <div className="col-9">: {body?.company?.name || "-"}</div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.company_address")}</label>
              <div className="col-9">: {body?.company?.address || "-"}</div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.finance_contact_person")}</label>
              <div className="col-9">: {body?.finance?.finance_pic || "-"}</div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.technical_contact_person")}</label>
              <div className="col-9">: {body?.finance?.technician_pic || "-"}</div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.site_name")}</label>
              <div className="col-9">: {body?.site_name || "-"}</div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.platform_id")}</label>
              <div className="col-9">: {body?.platform_id || "-"}</div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.license_type")}</label>
              <div className="col-9">
                <select
                  className="w-100 form-select rounded border"
                  onChange={(event) => {
                    setBody({ service_plan: event.currentTarget.value })
                    onChangeLicense(event.currentTarget.value)
                  }}
                  value={body?.service_plan}
                >
                  <option value="" disabled selected hidden>
                    Select License
                  </option>
                  <option value="Demo">Demo</option>
                  <option value="Admin">Admin</option>
                  <option value="Basic">Basic</option>
                  <option value="Standard">Standard</option>
                  <option value="Premium">Premium</option>
                </select>
              </div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.start_date")}</label>
              <div className="col-9">
                <Flatpickr
                  options={dateOptions}
                  value={body?.start_date}
                  onChange={([date]) => {
                    onChangeDate(date, "start_date")
                  }}
                  className="form-control rounded mw-100"
                />
              </div>
            </div>
            <div className="row mb-4">
              <label className="form-label col-3">{t("label.end_date")}</label>
              <div className="col-9">
                <Flatpickr
                  options={dateOptions}
                  value={body?.end_date}
                  onChange={([date]) => {
                    onChangeDate(date, "end_date")
                  }}
                  className="form-control rounded mw-100"
                />
              </div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.invoice_number")}</label>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  required
                  value={body?.invoice_number}
                  onChange={({ currentTarget: e }) => setBody({ invoice_number: e.value })}
                />
              </div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.invoice_date")}</label>
              <div className="col-9">
                <Flatpickr
                  options={dateOptions}
                  value={body?.inv_date}
                  onChange={([date]) => {
                    onChangeDate(date, "inv_date")
                  }}
                  className="form-control rounded mw-100"
                />
              </div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.price")} (IDR)</label>
              <div className="col-9">
                <input type="text" className="form-control rounded mw-100" value={price} required onChange={onPriceInput} />
              </div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.include_ppn")}</label>
              <div className="col-9">
                <select
                  disabled={["Demo", "Admin"].includes(body?.service_plan)}
                  className="w-100 form-select rounded border"
                  onChange={(event) => setBody({ include_ppn: event.currentTarget.value })}
                  value={body?.include_ppn}
                >
                  <option value="" disabled selected hidden>
                    Include PPN
                  </option>
                  <option value={true}>{t("yes")}</option>
                  <option value={false}>{t("no")}</option>
                </select>
              </div>
            </div>
            <div className="row mb-2">
              <label className="form-label col-3">{t("label.invoice_due_date")}</label>
              <div className="col-9">
                <Flatpickr
                  options={dateOptions}
                  value={body?.billing_date}
                  onChange={([date]) => {
                    onChangeDate(date, "billing_date")
                  }}
                  className="form-control rounded mw-100"
                />
              </div>
            </div>
            <div className="row mb-4">
              <label className="form-label col-3">Upload Invoice</label>
              <div className="col-9">
                <input
                  type="file"
                  name="invoice_file"
                  className="form-control rounded mw-100"
                  accept="application/pdf"
                  onChange={(e) => {
                    onFileChanges(e.target.files[0], "invoice_file")
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end">
              <Button type="button" variant="tertiary" onClick={onHide} className="m-1">
                {t("buttons.cancel")}
              </Button>
              <Button type="submit" variant="primary" className="m-1" disabled={disable}>
                {t("buttons.save")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ProformaModal
