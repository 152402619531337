import { FunctionComponent, h } from "preact"
import Container from "../../parts/container"
import TermsAndConditionContent from "../privacy-policy/term-condition-content"

type TermsConditionsProps = h.JSX.HTMLAttributes<HTMLDivElement>

const TermsConditions: FunctionComponent<TermsConditionsProps> = () => {

  return (
    <div className="min-vh-100">
      <Container>
        <h1 className="fs-4 fw-bold mb-5 text-center">Syarat dan Ketentuan Penggunaan Platform INTUSI</h1>
        <TermsAndConditionContent />
      </Container>
    </div>
  )
}

export default TermsConditions
