import { useState, useEffect, useRef } from "preact/hooks"
import { FunctionComponent, h, ComponentChildren } from "preact"
import { Link } from "preact-router/match"
import { useHelp } from "../../hooks/useHelp"
import { HelpBadge } from "../../parts/badge/help-badge"
import { Permission } from "../../enum/permissions.enum"
import { can } from "../../utils/access"
import style from "./index.scss"
import template from "../../utils/template-configs"
import usePlatformApp from "../../hooks/usePlatformApp"

type NavbarItemCollapseProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  item: {
    label: string
    link?: string
    icon: ComponentChildren
    sub: {
      label: string
      link: string
      icon: ComponentChildren
    }[]
  }
}

const NavbarItemCollapse: FunctionComponent<NavbarItemCollapseProps> = ({ item }) => {
  const ParentIcon = item.icon
  const childMenuElement = useRef()
  const { data: help } = useHelp()
  const { platform } = usePlatformApp()
  const [showChild, setShowChild] = useState(false)
  const [unreadByAdmin, setUnreadByAdmin] = useState(0)
  const [unreadByUser, setUnreadByUser] = useState(0)
  const [position, setPosition] = useState({ top: 0 })
  const [parentHeight, setParentHeight] = useState(0)
  const [childHeight, setChildHeight] = useState(0)

  const handleMouseOver = () => {
    const childRect = childMenuElement?.current?.getBoundingClientRect()
    setChildHeight(childRect?.height)
  }
  const handleMouseEnter = (event) => {
    const parentRect = event?.target?.getBoundingClientRect()
    setShowChild(true)
    setParentHeight(parentRect?.bottom)
  }

  const downloadManual = () => {
    const manual = template[platform].manuals.user_manual
    if (manual.file != "" && manual.file_name != "") {
      const link = document.createElement("a")
      link.setAttribute("target", "_blank")
      link.setAttribute("href", template[platform].manuals.user_manual.file)
      link.setAttribute("download", template[platform].manuals.user_manual.file_name)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  useEffect(() => {
    if (parentHeight + childHeight > window?.innerHeight) {
      setPosition({ bottom: 0 })
    } else {
      setPosition({ top: 0 })
    }
    setPosition
  }, [parentHeight, childHeight])

  useEffect(() => {
    const unreadBySender = help?.filter(({ isReadSender }) => !isReadSender)
    const unreadByReceiver = help?.filter(({ isReadReceiver }) => !isReadReceiver)
    setUnreadByAdmin(unreadByReceiver?.length || 0)
    setUnreadByUser(unreadBySender?.length || 0)
  }, [help])

  return (
    <div className={style.item}>
      {item.sub.length ? (
        <div className="position-relative" onMouseEnter={handleMouseEnter} onMouseOver={handleMouseOver} onMouseLeave={() => setShowChild(false)}>
          <div className={style.label}>
            <div className="d-flex w-100 align-items-center">
              <ParentIcon className={style.iconSize} />
            </div>
          </div>
          {showChild && (
            <div className={style.child_menu} ref={childMenuElement} style={position}>
              <div className={style.child_menu_wrapper}>
                <div className={style.child_menu_title}>{item.label}</div>
                {item.sub.map(({ icon: ChildrenIcon, ...sub }, id) => {
                  if (sub.link == "/help/user-manual") {
                    return (
                      <div onClick={() => downloadManual()} key={id} class={style.child_menu_sub} activeClassName={style.active}>
                        <ChildrenIcon className={style.iconSize} />
                        <span className="ms-2">{sub.label}</span>
                      </div>
                    )
                  }
                  return (
                    <Link key={id} href={sub.link} class={style.child_menu_sub} activeClassName={style.active}>
                      <ChildrenIcon className={style.iconSize} />
                      <span className="ms-2">{sub.label}</span>
                    </Link>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="position-relative" onMouseEnter={() => setShowChild(true)} onMouseLeave={() => setShowChild(false)}>
          <Link href={item.link} class={style.label} activeClassName={style.active}>
            <ParentIcon className={style.iconSize} />
            {item.label === "Help" && <>{can(Permission.HelpAdd) ? <HelpBadge value={unreadByUser} /> : <HelpBadge value={unreadByAdmin} />}</>}
          </Link>
          {showChild && (
            <div className={style.child_menu} style={{ top: "50%", transform: "translateY(-50%)" }}>
              <div className={style.child_menu_wrapper}>
                <Link className={style.child_menu_title_single} href={item.link}>
                  {item.label}
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default NavbarItemCollapse
