const permissionStorage = () => {
  const permissionStorageKey = "x-auth-permission";
  const data = localStorage.getItem(permissionStorageKey)
  const permissionList = JSON.parse(data)
  return permissionList
}

export const can = (permission: string) => {
  const permissionList = permissionStorage()
  return permissionList?.includes(permission)
}

export const canOneOf = (permissions: string[]) => {
  const permissionList = permissionStorage()
  if (!Array.isArray(permissions)) return false
  const found = permissions.some((item) => permissionList.includes(item))
  return found
}

export const canAllOf = (permissions: string[]) => {
  const permissionList = permissionStorage()
  if (!Array.isArray(permissions)) return false
  const found = permissions.every((item) => permissionList.includes(item))
  return found
}