import { jsPDF } from "jspdf"
import autoTable from "jspdf-autotable"
import axios from "axios"
import moment from "moment"
import type { InvoiceData } from "./invoice.types"
import templates from "../../utils/template-configs"

const getDataUrl = async (imageUrl: string) => {
  const { data, headers } = await axios(imageUrl, { responseType: "arraybuffer" })
  const contentType = headers?.["content-type"]
  const mime = contentType?.split("/")?.[1]?.toUpperCase()

  const uint8Array = new Uint8Array(data)
  const binary = uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), "")
  const base64Image = btoa(binary)
  const dataUrl = `data:${contentType};base64,${base64Image}`

  return { dataUrl, mime }
}

const getImageDimensions = async (dataUrl, desiredWidth = 0, desiredHeight = 16) => {
  const dimention = await new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve({ width: img.width, height: img.height })
    img.onerror = (error) => reject(error)
    img.src = dataUrl
  })

  let width, height
  if (desiredHeight === 0) {
    width = desiredWidth
    height = (dimention?.height / dimention?.width) * desiredWidth
  } else if (desiredWidth === 0) {
    height = desiredHeight
    width = (dimention?.width / dimention?.height) * desiredHeight
  }
  return { width, height }
}

export const generateInvoice = async ({ isInvoice, platform, vendor, client, data }: InvoiceData) => {
  const platform_name = process.env.PLATFORM || "intusi"
  const pdf = new jsPDF()
  const font = undefined
  const { dataUrl, mime } = await getDataUrl(platform?.logo)
  const { width, height } = await getImageDimensions(dataUrl)
  pdf.roundedRect(8, 8, pdf.internal.pageSize.width - 16, pdf.internal.pageSize.height - 16, 2, 2, "S")
  pdf.addImage(dataUrl, mime, 12, 12, width, height)

  const vendorName = vendor?.name
  const x = 12
  const y = 34
  const vendorFontSize = 11
  const vendorUnderlineY = y + 1
  const vendorTextWidth = (pdf.getStringUnitWidth(vendorName) * vendorFontSize) / pdf.internal.scaleFactor + 3
  pdf.setFontSize(vendorFontSize)
  pdf.setFont(font, "bold")
  pdf.text(vendorName, x, y)
  pdf.setLineWidth(0.3)
  pdf.line(x, vendorUnderlineY, x + vendorTextWidth, vendorUnderlineY)
  pdf.setLineWidth(0)

  pdf.setFont(font, "normal")
  pdf.setFontSize(9)
  const halfPageWidth = pdf.internal.pageSize.width / 2
  const splitVendorAddressText = pdf.splitTextToSize(vendor?.address, halfPageWidth)
  let vendorAddressY = y + 6
  splitVendorAddressText.forEach((line) => {
    pdf.text(line, x, vendorAddressY)
    vendorAddressY += 4
  })

  pdf.setFont(font, "bold")
  pdf.setFontSize(10)
  const billToY = vendorAddressY + 10
  pdf.text("Bill To:", x, billToY)

  pdf.setFontSize(9)
  const clientY = billToY + 5
  pdf.text(client?.name, x, clientY)

  pdf.setFont(font, "normal")
  pdf.setFontSize(9)
  const clientAddressMaxWidth = halfPageWidth - 20
  const splitClientAddressText = pdf.splitTextToSize(client?.address, clientAddressMaxWidth)
  let clientAddressY = clientY + 5
  splitClientAddressText.forEach((line) => {
    pdf.text(line, x, clientAddressY)
    clientAddressY += 4
  })

  pdf.setFont(font, "bold")
  pdf.setFontSize(10)
  const compliancePointY = clientAddressY + 6
  pdf.text("Site Name:", x, compliancePointY)

  pdf.setFont(font, "normal")
  const siteY = compliancePointY + 5
  pdf.text(client?.site_name, x, siteY)

  let siteAddressY = siteY
  if (client?.site_address) {
    pdf.setFontSize(9)
    const splitSiteAddressText = pdf.splitTextToSize(client?.site_address, clientAddressMaxWidth)
    siteAddressY = siteY + 5
    splitSiteAddressText.forEach((line) => {
      pdf.text(line, x, siteAddressY)
      siteAddressY += 4
    })
  }

  pdf.setFont(font, "bold")
  pdf.setFontSize(16)
  const invoiceY = billToY + 2
  const type = isInvoice ? "INVOICE" : "PURCHASE ORDER"
  pdf.text(type, halfPageWidth + 10, invoiceY)

  const boxY = invoiceY + 2
  pdf.roundedRect(halfPageWidth + 10, boxY, halfPageWidth - 22, 23, 1.5, 1.5, "S")

  pdf.setFont(font, "bold")
  pdf.setFontSize(9)
  pdf.text("Date", halfPageWidth + 12, boxY + 5)
  pdf.text("Invoice Number", halfPageWidth + 12, boxY + 10)
  pdf.text("PO/SPK Number", halfPageWidth + 12, boxY + 15)
  pdf.text("PO Date", halfPageWidth + 12, boxY + 20)
  pdf.text(":", halfPageWidth + 38, boxY + 5)
  pdf.text(":", halfPageWidth + 38, boxY + 10)
  pdf.text(":", halfPageWidth + 38, boxY + 15)
  pdf.text(":", halfPageWidth + 38, boxY + 20)

  pdf.setFont(font, "normal")
  pdf.text(data?.date, halfPageWidth + 40, boxY + 5)
  pdf.text(data?.invoice_number, halfPageWidth + 40, boxY + 10)
  pdf.text(data?.po_number, halfPageWidth + 40, boxY + 15)
  pdf.text(data?.po_date, halfPageWidth + 40, boxY + 20)

  const tableHead = [["Item", "Description", "Price", "Total (IDR)"]]
  const tableBody = data?.items?.map((item) => [
    { content: item?.name, styles: { halign: "left" } },
    { content: item?.description, styles: { halign: "left" } },
    { content: item?.price, styles: { halign: "right" } },
    { content: item?.total, styles: { halign: "right" } },
  ])
  const tableFoot = [
    [
      { content: "PPN 11%", colSpan: 3, styles: { halign: "center" } },
      { content: data?.ppn, styles: { halign: "right" } },
    ],
    [
      { content: "Grand Total + PPN 11%", colSpan: 3, styles: { halign: "center", fontStyle: "bold" } },
      { content: data?.grand_total, styles: { halign: "right", fontStyle: "bold" } },
    ],
  ]

  const tableY = siteAddressY + 5
  autoTable(pdf, {
    head: tableHead,
    body: tableBody,
    foot: tableFoot,
    startY: tableY,
    margin: { left: x, right: x },
    theme: "grid",
    styles: { lineColor: "#6c757d", lineWidth: 0.1 },
    headStyles: { halign: "center", fillColor: "#51bcda" },
    footStyles: { fillColor: "#ffffff", textColor: "#000000", fontStyle: "normal" },
  })

  const tableHeight = pdf.autoTable.previous.finalY
  const paymentY = tableHeight + 16
  pdf.setFont(font, "bold")
  pdf.setFontSize(10)
  pdf.text("Payment Information:", x, paymentY)
  let accoutY = paymentY + 5
  pdf.setFontSize(9)
  vendor?.account?.forEach((account) => {
    pdf.setFont(font, "bold")
    pdf.text(account?.bank, x, accoutY)
    accoutY += 5

    pdf.setFont(font, "normal")
    pdf.text(`Branch ${account?.branch}`, x + 5, accoutY)
    accoutY += 5

    pdf.text(`Account Number ${account?.number}`, x + 5, accoutY)
    accoutY += 5

    pdf.text(`Account Name ${account?.name}`, x + 5, accoutY)
    accoutY += 8
  })

  pdf.setFontSize(9)
  const npwpY = accoutY + 5
  pdf.setFont(font, "bold")
  pdf.text("NPWP Number", x, npwpY)

  pdf.setFont(font, "normal")
  pdf.text(vendor?.name, x, npwpY + 5)

  pdf.setFont(font, "bold")
  pdf.text(vendor?.npwp, x, npwpY + 10)

  pdf.setFontSize(10)
  pdf.setFont(font, "bold")
  pdf.text(vendor?.responsible_person, (pdf.internal.pageSize.width / 3) * 2, npwpY + 10)
  pdf.text(vendor?.name, (pdf.internal.pageSize.width / 3) * 2, npwpY - 25)

  if (isInvoice) {
    const pageWidth = pdf.internal.pageSize.getWidth()
    const pageHeight = pdf.internal.pageSize.getHeight()
    const centerX = pageWidth / 2
    const centerY = pageHeight / 2

    pdf.saveGraphicsState()
    pdf.setGState(new pdf.GState({ opacity: 0.1 }))
    pdf.setFontSize(96)
    pdf.setTextColor("#008000")
    pdf.text("Paid", centerX, centerY, { angle: 45, align: "center" })
    pdf.restoreGraphicsState()
  }

  const date = moment(data?.po_date, "YYYY-MM-DD").format("DD MMM YYYY")
  const subject = `Invoice ${client?.name}`
  const title = `${subject} ${date}`
  pdf.setProperties({
    title,
    subject,
    author: templates[platform_name].pdf.author,
    keywords: "invoice, purchase order",
    creator: templates[platform_name].pdf.creator,
  })
  pdf.save(`${title}.pdf`)
}
