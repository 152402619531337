import { Fragment, FunctionComponent, h } from "preact"
import { useRef } from "preact/hooks"
import { toPng } from "html-to-image"
import Container from "../../parts/container"
import TopBar from "../../components/topbar"
import TopFilter from "../../components/TopFilter"
import Breadcrumb from "../../components/topbar/breadcrumb"
import { sortActiveParam, isAvgSensor } from "../../utils/sortParam"
import { toFixed } from "../../utils/toFixed"
import { useFilterContext } from "../../context/useFilter"
import { defaultParams } from "../../utils/defaults"
import Spinner from "../../components/spinner"
import { useRecordGraph } from "../../hooks/useSensor"
import useSites from "../../hooks/useSite"
import RecordChart from "./record-chart"
import useObjectState from "../../hooks/useObjectState"
import { Button } from "../../parts/buttons"
import { can } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import { useTranslation } from "react-i18next"

type RecordGraphProps = h.JSX.HTMLAttributes<HTMLDivElement>

const RecordGraphPage: FunctionComponent<RecordGraphProps> = () => {
  const { t } = useTranslation()
  const { contextFilter, setContextFilter } = useFilterContext()
  const { data: cems } = useSites()
  const graph = useRef<HTMLDivElement>(null)
  const defaultValue = {
    site: undefined,
    period: undefined,
    startDate: undefined,
    endDate: undefined,
    ...contextFilter,
    sensor: cems?.find(({ uuid }) => contextFilter?.site === uuid)?.parameters?.map(({ name }) => name),
  }
  const [payload, setPayload] = useObjectState(defaultValue)

  const { data } = useRecordGraph({
    uuid: payload.site,
    type: payload.period,
    start: payload.startDate,
    end: payload.endDate,
  })
  const columns = sortActiveParam(data?.parameter) || defaultParams
  const label = data?.record?.map(({ timestamp }) => timestamp)

  const getCalculation = (param, type) => {
    const calculate = data?.calculation?.find(({ name }) => name.toLowerCase() === param.toLowerCase())?.[type]
    return toFixed(calculate)
  }

  const chartAvg = columns?.map((item) => {
    const avg = data?.record?.map(({ data }) => data?.find(({ name }) => name === item.name)?.avg)
    return {
      name: item.name,
      lower_threshold: item?.lower_threshold,
      upper_threshold: item?.upper_threshold,
      min: getCalculation(item?.name, "min"),
      max: getCalculation(item?.name, "max"),
      avg: getCalculation(item?.name, "avg"),
      label,
      dataset: avg,
    }
  })
  const chartValue = columns?.map((item) => {
    const value = data?.record?.map(({ data }) => data?.find(({ name }) => name === item.name)?.value)
    return {
      name: item.name,
      lower_threshold: item?.lower_threshold,
      upper_threshold: item?.upper_threshold,
      min: getCalculation(item?.name, "min"),
      max: getCalculation(item?.name, "max"),
      avg: getCalculation(item?.name, "avg"),
      label,
      dataset: value,
    }
  })

  const getChartBySensor = (chartAvg, chartValue, period, sensor) => {
    const chart = isAvgSensor(period, sensor) ? chartAvg : chartValue
    return chart?.find(({ name }) => name === sensor)
  }

  const handleFilterData = (data) => {
    setPayload(data)
    setContextFilter(data)
  }

  const handleDownloadGraph = () => {
    if (graph.current)
      toPng(graph.current, { cacheBust: true }).then((dataUrl) => {
        const link = document.createElement("a")
        link.download = `record-graph-${new Date().getTime()}.png`
        link.href = dataUrl
        link.click()
      })
  }

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.record")} link="" />
        <Breadcrumb name={t("breadcrumb.graph")} link="/record/graph" />
      </TopBar>
      <Container>
        {data ? (
          <Fragment>
            <div className="row align-items-stretch">
              <TopFilter cems={cems} interval inputSensor="multiple" defaultValue={payload} onShow={handleFilterData} />
            </div>
            {can(Permission.RecordGraphDownload) && (
              <div className="d-flex justify-content-end mt-3">
                <Button variant="primary" onClick={() => handleDownloadGraph()}>
                  {t("buttons.download")}
                </Button>
              </div>
            )}
            <div ref={graph}>
              {payload.sensor?.length ? (
                payload.sensor?.map((sensor, index) => (
                  <div className="mt-3" key={`graph-${index}`}>
                    <RecordChart
                      data={getChartBySensor(chartAvg, chartValue, payload.period, sensor)}
                      calculation={payload.period === "raw"}
                      period={payload.period}
                    />
                  </div>
                ))
              ) : (
                <div className="mt-3">
                  <RecordChart data={{}} period={payload.period} />
                </div>
              )}
            </div>
          </Fragment>
        ) : (
          <Spinner />
        )}
      </Container>
    </div>
  )
}

export default RecordGraphPage
