import { FunctionComponent, h } from "preact"
import { route } from "preact-router"
import { HiChevronRight } from "react-icons/hi"
import Spinner from "../../../components/spinner"
import { useSiteById } from "../../../hooks/useSite"
import { useFilterContext } from "../../../context/useFilter"
import { can } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type RealtimeSiteDetailProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  uuid: string
}

const RealtimeSiteDetail: FunctionComponent<RealtimeSiteDetailProps> = ({ uuid }) => {
  const { t } = useTranslation()
  const { data } = useSiteById(uuid)
  const { setContextFilter } = useFilterContext()

  const handleGoTo = () => {
    setContextFilter({ site: uuid })
    route(`/settings/site-data/detail/${uuid}`)
  }

  return (
    <div className="w-100 position-relative">
      <div className="d-flex justify-content-end">
        {can(Permission.SettingsSiteDataView) && (
          <button className={style.menu_link_to_page} onClick={handleGoTo}>
            {t("page.realtime.See site data for more details")}
            <HiChevronRight />
          </button>
        )}
      </div>
      {data ? (
        <div className="overflow-auto">
          <table className="table table-data table-center">
            <thead>
              <tr>
                <th>{t("table.parameter")}</th>
                <th>UUID INTUSI</th>
                <th>{t("table.latitude")}</th>
                <th>{t("table.longitude")}</th>
                <th>{t("table.pic_name")}</th>
                <th>{t("table.name")}</th>
                <th>{t("table.phone")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data?.parameters?.map(({ name }) => name)?.join(", ") || "-"}</td>
                <td>{data?.uid_intusi || "-"}</td>
                <td>{data?.coordinate?.lat || "-"}</td>
                <td>{data?.coordinate?.long || "-"}</td>
                <td>{data?.pic_technical_name || "-"}</td>
                <td>{data?.pic_technical_email || "-"}</td>
                <td>{data?.pic_technical_phone || "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default RealtimeSiteDetail
