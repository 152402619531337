import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { BsPencil, BsPlusLg, BsTrash } from "react-icons/bs"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import useAuth from "../../../hooks/useAuth"
import { useMenu } from "../../../hooks/useUsman"
import { useConfirm } from "../../../context/useConfirm"
import Pagination from "../../../components/pagination"
import usePagination from "../../../hooks/usePagination"
import Spinner from "../../../components/spinner"
import SearchBox from "../../../components/TopFilter/search-box"
import useObjectState from "../../../hooks/useObjectState"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import AddMenu from "./add"
import EditMenu from "./edit"
import { useTranslation } from "react-i18next"

type AccessMenuProps = h.JSX.HTMLAttributes<HTMLDivElement>

const AccessMenu: FunctionComponent<AccessMenuProps> = () => {
  const { t } = useTranslation()
  const { data, mutate } = useMenu()
  const [filteredData, setFilteredData] = useState(data)
  const confirmation = useConfirm()
  const { fetcher } = useAuth()
  const [editForm, setEditForm] = useObjectState({ show: false, data: {} })
  const [showAddForm, setShowAddForm] = useState(false)
  const [search, setSearch] = useState("")

  const showActionColumn = canOneOf([Permission.AccessMenuDelete, Permission.AccessMenuEdit])

  filteredData?.sort((a, b) => a?.list_feature?.name?.localeCompare(b?.list_feature?.name))

  const pages = usePagination({ count: filteredData?.length || 0, step: 20 })
  const menus = filteredData?.slice(pages.output, pages.output + pages.step)

  const handleEdit = (data) => setEditForm({ show: true, data })

  const handleDelete = (id: string, name: string) => {
    confirmation(
      t("delete_menu_confirm_msg", { name }),
      (remove) => {
        if (remove)
          fetcher()
            .delete(`/menu-list/${id}`)
            .then(() => mutate())
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }

  useEffect(() => {
    let searchedData
    if (search) {
      searchedData = data?.filter(({ menu_name, list_feature }) => {
        return menu_name?.toLowerCase()?.includes(search) || list_feature?.name?.toLowerCase()?.includes(search) || !search
      })
    } else {
      searchedData = data
    }
    setFilteredData(searchedData)
  }, [data, search])

  return (
    <div className="min-vh-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.access")} link="" />
        <Breadcrumb name={t("breadcrumb.menu")} link="/access/menu" />
      </TopBar>
      {showAddForm && <AddMenu show={showAddForm} mutate={() => mutate()} onClose={() => setShowAddForm(false)} />}
      {editForm.show && <EditMenu data={editForm} mutate={() => mutate()} onClose={() => setEditForm({ show: false })} />}
      <Spinner show={!Array.isArray(data)} />
      <Container>
        <div className="card">
          <div className="card-title align-items-center">
            <SearchBox placeholder={t("placeholder.search_menu")} onChange={(value) => setSearch(value?.toLowerCase())} />
            {can(Permission.AccessMenuAdd) && (
              <Button variant="primary" className="ms-2" icon={BsPlusLg} onClick={() => setShowAddForm(true)}>
                {t("buttons.add_menu")}
              </Button>
            )}
          </div>
          <div className="card-body">
            <table className="table table-data table-center">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>{t("table.menu")}</th>
                  <th>{t("table.feature")}</th>
                  {showActionColumn && <th>{t("table.action")}</th>}
                </tr>
              </thead>
              <tbody>
                {menus?.length ? (
                  menus?.map((item, idx) => (
                    <tr key={`user-${idx}`}>
                      <td>{idx + 1 + pages.page * pages.step}</td>
                      <td>{item?.menu_name}</td>
                      <td>{item?.list_feature?.name}</td>
                      {showActionColumn && (
                        <td>
                          {can(Permission.AccessMenuEdit) && (
                            <Button variant="secondary" title="Edit" onClick={() => handleEdit(item)} className="m-1" icon={BsPencil} />
                          )}
                          {can(Permission.AccessMenuDelete) && (
                            <Button
                              variant="tertiary"
                              title={t("buttons.delete")}
                              onClick={() => handleDelete(item?.uuid, item?.menu_name)}
                              className="m-1"
                              icon={BsTrash}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 4 : 3}>No data.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <Pagination hook={pages} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AccessMenu
