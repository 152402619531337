import { FunctionComponent, h } from "preact"
import { route } from "preact-router"
import moment from "moment"
import { BsFileEarmarkArrowDown } from "react-icons/bs"
import { HiChevronRight } from "react-icons/hi"
import { Button } from "../../../parts/buttons"
import Spinner from "../../../components/spinner"
import { useNCRKLHK } from "../../../hooks/useNcr"
import { useFilterContext } from "../../../context/useFilter"
import { can } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type RealtimeNCRProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  uuid: string
}

const RealtimeNCR: FunctionComponent<RealtimeNCRProps> = ({ uuid }) => {
  const { t } = useTranslation()
  const { setContextFilter } = useFilterContext()
  const startDate = moment().startOf("day").format("X")
  const endDate = moment().startOf("hour").format("X")
  const { data } = useNCRKLHK(uuid, [startDate, endDate])

  const handleGoTo = () => {
    setContextFilter({ site: uuid, startDate, endDate })
    route("/maintenance/ncr-klhk")
  }

  return (
    <div className="w-100 position-relative">
      <div className="d-flex justify-content-end">
        {can(Permission.MaintenanceNcrKlhkView) && (
          <button className={style.menu_link_to_page} onClick={handleGoTo}>
            {t("page.realtime.See NCR for more details")}
            <HiChevronRight />
          </button>
        )}
      </div>
      <Spinner show={!Array.isArray(data)} />
      <table className="table table-data table-center">
        <thead>
          <tr>
            <th>No</th>
            <th>{t("table.site")}</th>
            <th>{t("table.parameter")}</th>
            <th>{t("table.problem")}</th>
            <th>{t("table.refinement_date")}</th>
            <th>{t("table.file")}</th>
            <th>{t("table.status")}</th>
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data.map((item, id) => (
              <tr key={item?.uuid}>
                <td>{id + 1}</td>
                <td>{item?.cem?.name}</td>
                <td>{item?.parameter}</td>
                <td>{item?.description}</td>
                <td>{item?.completion_date}</td>
                <td>
                  <Button
                    variant="primary"
                    className="m-1 btn-sm"
                    disabled={!item?.file}
                    icon={BsFileEarmarkArrowDown}
                    onClick={() => window.open(item?.file)}
                  />
                </td>
                <td>{item?.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} className="text-center">
                {t("no_data")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default RealtimeNCR
