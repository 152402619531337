import { useState, useEffect } from "preact/hooks"

export type PaginationData = {
  page: number
  setPage: (n: number) => void
  output: number
  step: number
  count: number
  maxPage: number
  next: () => void
  prev: () => void
}

type PaginationProps = {
  count: number
  step: number
}
const usePagination = ({ count, step }: PaginationProps): PaginationData => {
  const [page, changePage] = useState(0)

  const fitPage = Math.floor(count / step)
  const remaining = count % step ? 1 : 0
  const maxPage = fitPage + remaining

  const setPage = (n: number) =>
    n > maxPage ? changePage(maxPage) : n < 0 ? changePage(0) : changePage(n)

  const next = () => setPage(page + 1)
  const prev = () => setPage(page - 1)

  useEffect(() => {
    const pageCount = (page * step) + (count % step)
    if (pageCount !== count) setPage(0)
  }, [count])
  

  return {
    page,
    setPage,
    step,
    count,
    maxPage,
    next,
    prev,
    output: page * step,
  }
}

export default usePagination
