import { h } from "preact"
import { useState } from "preact/hooks"
import { Chart as ChartJS } from "chart.js"
import { Line as ChartLine } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"

type LineProps = {
  height?: number | string
  bmal: string | number
  data: number[]
  labels: string[]
  color: string
  upper: number
  lower: number
  isZoom: boolean
}

const Line = ({ height = 120, upper, lower, bmal, data, labels, color, isZoom }: LineProps) => {
  const [showMin, setShowMin] = useState(true)
  const [showMax, setShowMax] = useState(true)
  const min = Math.min(...data)
  const max = Math.max(...data)

  const customizedAxesTicks = (event) => {
    const stepValue = Math.abs(event?.ticks?.[0]?.value - event?.ticks?.[1]?.value)
    const shouldShowLower = min - stepValue
    const shouldShowUpper = max + stepValue
    setShowMin(lower >= shouldShowLower)
    setShowMax(upper <= shouldShowUpper)
  }
  ChartJS.register(annotationPlugin)
  const annotation = {
    type: 'line',
    borderWidth: 3,
    drawTime: 'beforeDraw',
    label: {
      display: (ctx) => ctx.hovered,
      drawTime: 'afterDatasetsDraw',
      position: (ctx) => ctx.hoverPosition
    },
    enter(ctx, event) {
      ctx.hovered = true;
      ctx.hoverPosition = `${event.x / ctx.chart.chartArea.width * 100}%`;
      ctx.chart.update();
    },
    leave(ctx) {
      ctx.hovered = false;
      ctx.chart.update();
    }
  }
  return (
    <ChartLine
      height={height}
      options={{
        plugins: {
          annotation: {
            annotations: {
              ...((showMin || !isZoom) && {
                min: {
                  ...annotation,
                  yMin: lower,
                  yMax: lower,
                  borderColor: '#8F989A',
                  value: lower,
                  label: {
                    ...annotation.label,
                    content: "Lower Threshold",
                  },
                }
              }),
              ...((showMax || !isZoom) && {
                max: {
                  ...annotation,
                  yMin: upper,
                  yMax: upper,
                  borderColor: '#8F989A',
                  value: upper,
                  label: {
                    ...annotation.label,
                    content: "Upper Threshold",
                  },
                },
              }),
              ...(bmal && {
                bmal: {
                  ...annotation,
                  yMin: bmal,
                  yMax: bmal,
                  borderColor: '#51BCDA',
                  value: bmal,
                  label: {
                    ...annotation.label,
                    content: "BMAL",
                  },
                }
              }),
            }
          },
          legend: {
            display: false,
          }
        },
        scales: {
          xAxis: {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              maxRotation: 0,
              minRotation: 0
            },
          },
          yAxis: {
            afterBuildTicks: customizedAxesTicks,
          }
        }
      }}
      data={{
        labels,
        datasets: [{
          data,
          borderColor: color,
          backgroundColor: color,
          tension: 0.4,
        }]
      }}
    />
  )
}
export default Line
