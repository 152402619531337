import { FunctionComponent, h } from "preact"
import { useState, useRef, useEffect } from "preact/hooks"
import useObjectState from "../../hooks/useObjectState"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import { sortActiveParam } from "../../utils/sortParam"
import { Cems } from "../../types/cems"
import { HourlyDatePicker, DailyDatePicker, MonthlyDatePicker } from "./period-date-picker"
import { useFilterContext } from "../../context/useFilter"
import { Button } from "../../parts/buttons"
import SelectSearch from "../form/select-search"
import PeriodDateList from "./period-date-list"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type TopFilterProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  cems: Cems
  inputSensor?: "multiple" | "select" | undefined
  interval?: boolean
  comply: boolean
  period: boolean
  defaultValue: any
  onShow: viod
}

const TopFilter: FunctionComponent<TopFilterProps> = ({ cems, inputSensor, interval, comply, period, defaultValue, onShow }) => {
  const { t } = useTranslation()
  const selectSensor = useRef<HTMLDivElement>(null)
  const [filteredSite, setFilteredSite] = useState(cems)
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [sensors, setSensor] = useState([])
  const [selectedSensor, setSelectedSensor] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [filter, setFilter] = useObjectState(defaultValue)
  const { setContextFilter } = useFilterContext()

  const intervalData = [
    ...(comply ? [] : [{ name: "Raw", key: "raw" }]),
    ...(comply ? [] : [{ name: t("two_minutes"), key: "two-minutes" }]),
    { name: t("hourly"), key: "hour" },
    { name: t("daily"), key: "day" },
    ...(comply ? [] : [{ name: t("monthly"), key: "month" }]),
  ]

  const onDateChange = (date, type) => {
    setContextFilter({ periodType: 0 })
    type === "start" ? setFilter({ startDate: date }) : setFilter({ endDate: date })
  }

  const checkAllSensor = (data) => {
    setCheckAll((prevState) => {
      !prevState ? setSelectedSensor(data) : setSelectedSensor([])
      !prevState ? setFilter({ sensor: data }) : setFilter({ sensor: [] })
      return !prevState
    })
  }

  const checkSensor = (name: string) => {
    setCheckAll(false)
    const isChecked = selectedSensor?.find((item) => item === name)
    if (isChecked) {
      setSelectedSensor((prevState) => {
        const result = prevState.filter((item) => item !== name)
        setFilter({ sensor: result })
        return result
      })
    } else {
      setSelectedSensor((prevState) => {
        const result = [...prevState, name]
        setFilter({ sensor: result })
        return result
      })
    }
  }

  const onSiteChange = (uuid) => {
    setFilter({ site: uuid })
    const parameter = cems?.find(({ uuid: cemsUuid }) => cemsUuid === uuid)?.parameters
    const param = sortActiveParam(parameter)?.map(({ name }) => name)
    setSensor(param)
    const sensor = inputSensor === "multiple" ? [] : ""
    setFilter({ sensor })
    setSelectedSensor(sensor)
    setCheckAll(false)
  }

  const onChangeSensor = (sensor_name: string) => {
    // temp function
    const uuid = filter?.site
    const parameter = cems?.find(({ uuid: cemsUuid }) => cemsUuid === uuid)?.parameters
    const selectedSensor = parameter?.find(({ name }) => name == sensor_name)
    if (selectedSensor) {
      setFilter({ sensor_id: selectedSensor.uuid })
    }
  }

  const onPeriodListChange = (value) => {
    const { start, end } = value
    setFilter({ startDate: start, endDate: end })
  }

  const renderDatePicker = ({ period, date, type, onDateChange }) => {
    if (period === "month") return <MonthlyDatePicker date={date} type={type} setDate={(newDate) => onDateChange(newDate, type)} />
    else if (period === "day") return <DailyDatePicker date={date} type={type} setDate={(newDate) => onDateChange(newDate, type)} />
    return <HourlyDatePicker date={date} type={type} setDate={(newDate) => onDateChange(newDate, type)} />
  }

  const onShowHandler = () => onShow(filter)

  useOnClickOutside(selectSensor, () => setShowCheckbox(false))

  useEffect(() => {
    const cem = cems?.find(({ uuid }) => uuid === filter?.site)?.parameters
    const param = sortActiveParam(cem)?.map(({ name }) => name)
    setSensor(param)
    if (defaultValue.sensor) {
      const sensor = Array.isArray(defaultValue.sensor) ? defaultValue.sensor : [defaultValue.sensor]
      setSelectedSensor(sensor)
    }
  }, [])

  useEffect(() => setFilteredSite(cems), [cems])

  return (
    <div className={style.filter_input}>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("site")}</label>
        <SelectSearch placeholder={t("placeholder.search_site")} data={filteredSite} defaultValue={filter?.site} onChange={onSiteChange} />
      </div>
      {inputSensor && (
        <div className={style.filter_input_group}>
          <label className={style.filter_label}>{t("label.sensor")}</label>
          {inputSensor === "select" && (
            <select
              className="w-100 form-select rounded border"
              onChange={(event) => {
                onChangeSensor(event.currentTarget.value)
                setFilter({ sensor: event.currentTarget.value })
              }}
              value={filter.sensor}
            >
              <option value="" disabled selected hidden>
                {t("label.select_sensor")}
              </option>
              {sensors?.map((sensor) => (
                <option key={sensor} value={sensor}>
                  {sensor}
                </option>
              ))}
            </select>
          )}
          {inputSensor === "multiple" && (
            <div ref={selectSensor} className="position-relative w-100">
              <div
                className="w-100 form-select rounded d-inline-block user-select-none border"
                role="button"
                onClick={() => setShowCheckbox(!showCheckbox)}
              >
                {selectedSensor?.length ? `${selectedSensor?.length} sensor` : t("label.select_sensor")}
              </div>
              <div class={style.multiselect} style={{ display: showCheckbox ? "inherit" : "none" }}>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="all" checked={checkAll} onChange={() => checkAllSensor(sensors)} />
                  <label class="form-check-label" for="all">
                    {t("label.all_sensor")}
                  </label>
                </div>
                {sensors?.map((sensor) => (
                  <div key={sensor} class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id={sensor}
                      value={sensor}
                      checked={selectedSensor?.includes(sensor)}
                      onChange={() => checkSensor(sensor)}
                    />
                    <label class="form-check-label" for={sensor}>
                      {sensor}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {interval && (
        <div className={style.filter_input_group}>
          <label className={style.filter_label}>{t("label.interval")}</label>
          <select
            className="w-100 form-select rounded border"
            onChange={(event) => setFilter({ period: event.currentTarget.value })}
            value={filter.period}
          >
            <option value="" disabled selected hidden>
              {t("label.select_interval")}
            </option>
            {intervalData.map(({ name, key }) => (
              <option value={key} key={key}>
                {name}
              </option>
            ))}
          </select>
        </div>
      )}
      {period && <PeriodDateList type={filter?.period} onSelect={onPeriodListChange} />}
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.start_date")}</label>
        {renderDatePicker({ period: filter.period, date: filter.startDate, type: "start", onDateChange })}
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.end_date")}</label>
        {renderDatePicker({ period: filter.period, date: filter.endDate, type: "end", onDateChange })}
      </div>
      <div className="d-flex align-items-end">
        <Button variant="primary" className="py-2 w-100" onClick={() => onShowHandler()}>
          {t("buttons.show")}
        </Button>
      </div>
    </div>
  )
}

export default TopFilter
