import { h, FunctionComponent } from "preact"
import { useState } from "preact/hooks"
import { Button } from "../../parts/buttons"
import { useTranslation } from "react-i18next"

type ConfirmModalProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  onHide: () => void
  onConfirm: () => void
}

const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({ show, onHide, onConfirm }) => {
  const { t } = useTranslation()
  const [check, setCheck] = useState(false)

  return (
    <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", zIndex: 999999 }}>
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content card">
          <div className="card-body">
            <p className="text-center fw-bolder text-primary">{t("Make sure all data is in accordance with the payment procedure")}.</p>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" checked={check} onChange={() => setCheck((prev) => !prev)} />
              <label class="form-check-label">{t("It complies with the procedure")}</label>
            </div>
          </div>
          <div className="card-body text-center">
            <Button className="mx-2" onClick={onHide} variant="tertiary">
              {t("buttons.cancel")}
            </Button>
            <Button className="mx-2" onClick={onConfirm} variant="primary" disabled={!check}>
              {t("buttons.confirm")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ConfirmModal
