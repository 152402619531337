import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { Cems, Mil } from "../../types/cems"
import SelectSearch from "../form/select-search"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type CompanySiteFilterProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  mils: Mil
  cems: Cems
  defaultValue: {
    company?: string
    site?: string
  }
  onShow: viod
}

const CompanySiteFilter: FunctionComponent<CompanySiteFilterProps> = ({ mils, cems, defaultValue, onShow }) => {
  const { t } = useTranslation()
  const [filteredSite, setFilteredSite] = useState(cems)
  const [filter, setFilter] = useState(defaultValue)

  const onCompanyChange = (uuid) => {
    setFilter({ company: uuid, site: undefined })
    const sites = cems?.filter(({ company }) => company?.uuid === uuid) || cems
    setFilteredSite(sites)
  }

  const onSiteChange = (uuid) => {
    setFilter((prevState) => {
      const state = { ...prevState, site: uuid }
      onShow(state)
      return state
    })
  }

  useEffect(() => setFilter(defaultValue), [defaultValue])

  return (
    <div className={style.filter_input}>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("company")}</label>
        <SelectSearch placeholder={t("placeholder.search_company")} data={mils} defaultValue={filter?.company} onChange={onCompanyChange} />
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("site")}</label>
        <SelectSearch placeholder={t("placeholder.search_site_name")} data={filteredSite} defaultValue={filter?.site} onChange={onSiteChange} />
      </div>
    </div>
  )
}

export default CompanySiteFilter
