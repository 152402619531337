import Excel, { Borders, Alignment, Font } from "exceljs"
import moment from "moment"
import { download } from "../../../utils/excel"

const NOW = () => moment().format("x")

// STYLES
const alignmentCenter: Partial<Alignment> = { vertical: "middle", horizontal: "center" }
const boldFontHeader: Partial<Font> = { bold: true, color: { argb: "FFFFFFFF" } }
const border: Partial<Borders> = {
  top: { style: "thin" },
  left: { style: "thin" },
  bottom: { style: "thin" },
  right: { style: "thin" },
}
const backgroundHeader: Partial<Fill> = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FF51BCDA" },
}

export const usersToExcel = (data: any[]) => {
  const book = new Excel.Workbook()
  const sheet = book.addWorksheet("Users")
  const title = `Users - ${NOW()}`

  const headers = ["No", "Vendor", "Company", "Sites", "Name", "Email", "Role", "Status", "Notification"]

  headers?.forEach((header, index) => {
    sheet.getCell(1, index + 1).value = header
    sheet.getCell(1, index + 1).alignment = alignmentCenter
    sheet.getCell(1, index + 1).border = border
    sheet.getCell(1, index + 1).font = boldFontHeader
    sheet.getCell(1, index + 1).fill = backgroundHeader
  })

  let prevTopSite = 0
  let prevBottomSite = 0
  let prevSite = 0
  data?.forEach((user, idx) => {
    const siteCount = user?.sites?.length || 1
    const sites = user?.sites?.length ? user?.sites : ["-"]
    prevBottomSite += siteCount - 1

    sheet.mergeCells(2 + prevTopSite + idx, 1, 2 + prevBottomSite + idx, 1)
    sheet.getCell(2 + idx + prevTopSite, 1).value = idx + 1
    sheet.getCell(2 + idx + prevTopSite, 1).border = border
    sheet.getCell(2 + idx + prevTopSite, 1).alignment = alignmentCenter

    sheet.mergeCells(2 + prevTopSite + idx, 2, 2 + prevBottomSite + idx, 2)
    sheet.getCell(2 + idx + prevTopSite, 2).value = user?.vendor?.name
    sheet.getCell(2 + idx + prevTopSite, 2).border = border

    sheet.mergeCells(2 + prevTopSite + idx, 3, 2 + prevBottomSite + idx, 3)
    sheet.getCell(2 + idx + prevTopSite, 3).value = user?.company?.name
    sheet.getCell(2 + idx + prevTopSite, 3).border = border

    sites?.forEach((site, id) => {
      sheet.getCell(2 + prevSite + id, 4).value = site?.name
      sheet.getCell(2 + prevSite + id, 4).border = border
    })

    sheet.mergeCells(2 + prevTopSite + idx, 5, 2 + prevBottomSite + idx, 5)
    sheet.getCell(2 + idx + prevTopSite, 5).value = user?.name
    sheet.getCell(2 + idx + prevTopSite, 5).border = border

    sheet.mergeCells(2 + prevTopSite + idx, 6, 2 + prevBottomSite + idx, 6)
    sheet.getCell(2 + idx + prevTopSite, 6).value = user?.email
    sheet.getCell(2 + idx + prevTopSite, 6).border = border

    sheet.mergeCells(2 + prevTopSite + idx, 7, 2 + prevBottomSite + idx, 7)
    sheet.getCell(2 + idx + prevTopSite, 7).value = user?.role?.name
    sheet.getCell(2 + idx + prevTopSite, 7).border = border

    sheet.mergeCells(2 + prevTopSite + idx, 8, 2 + prevBottomSite + idx, 8)
    sheet.getCell(2 + idx + prevTopSite, 8).value = user?.is_valid ? "Active" : "Inactive"
    sheet.getCell(2 + idx + prevTopSite, 8).border = border

    sheet.mergeCells(2 + prevTopSite + idx, 9, 2 + prevBottomSite + idx, 9)
    sheet.getCell(2 + idx + prevTopSite, 9).value = user?.notification ? "On" : "Off"
    sheet.getCell(2 + idx + prevTopSite, 9).border = border

    prevTopSite += siteCount - 1
    prevSite += siteCount
  })

  sheet?.columns?.forEach((column) => {
    let maxLength = 0
    column?.eachCell?.({ includeEmpty: true }, (cell) => {
      const columnLength = cell?.value ? cell?.value?.toString()?.length : 10
      if (columnLength > maxLength) {
        maxLength = columnLength
      }
    })
    column.width = maxLength + 5
  })
  download(book, title)
}
