import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { BsPencil, BsPlusLg, BsTrash } from "react-icons/bs"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import useAuth from "../../../hooks/useAuth"
import { usePermission } from "../../../hooks/useUsman"
import { useConfirm } from "../../../context/useConfirm"
import Pagination from "../../../components/pagination"
import usePagination from "../../../hooks/usePagination"
import Spinner from "../../../components/spinner"
import SearchBox from "../../../components/TopFilter/search-box"
import useObjectState from "../../../hooks/useObjectState"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import AddPermission from "./add"
import EditPermission from "./edit"
import { useTranslation } from "react-i18next"

type AccessPermissionProps = h.JSX.HTMLAttributes<HTMLDivElement>

const AccessPermission: FunctionComponent<AccessPermissionProps> = () => {
  const { t } = useTranslation()
  const { data, mutate } = usePermission()
  const [filteredData, setFilteredData] = useState(data)
  const confirmation = useConfirm()
  const { fetcher } = useAuth()
  const [editForm, setEditForm] = useObjectState({ show: false, data: {} })
  const [showAddForm, setShowAddForm] = useState(false)
  const [search, setSearch] = useState("")

  const showActionColumn = canOneOf([Permission.AccessPermissionDelete, Permission.AccessPermissionEdit])

  filteredData?.sort(
    (a, b) =>
      a?.menu_list?.list_feature?.name?.localeCompare(b?.menu_list?.list_feature?.name) ||
      a?.menu_list?.menu_name?.localeCompare(b?.menu_list?.menu_name)
  )

  const pages = usePagination({ count: filteredData?.length || 0, step: 20 })
  const permission = filteredData?.slice(pages.output, pages.output + pages.step)

  const handleEdit = (data) => setEditForm({ show: true, data })

  const handleDelete = (id: string, name: string) => {
    confirmation(
      t("delete_permission_confirm_msg", { name }),
      (remove) => {
        if (remove)
          fetcher()
            .delete(`/permission/${id}`)
            .then(() => mutate())
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }

  useEffect(() => {
    let searchedData
    if (search) {
      searchedData = data?.filter((item) => {
        return (
          item?.name?.toLowerCase()?.includes(search) ||
          item?.key?.toLowerCase()?.includes(search) ||
          item?.menu_list?.menu_name?.toLowerCase()?.includes(search) ||
          item?.menu_list?.list_feature?.name?.toLowerCase()?.includes(search) ||
          !search
        )
      })
    } else {
      searchedData = data
    }
    setFilteredData(searchedData)
  }, [data, search])

  return (
    <div className="min-vh-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.access")} link="" />
        <Breadcrumb name={t("breadcrumb.permission")} link="/access/permission" />
      </TopBar>
      {showAddForm && <AddPermission show={showAddForm} mutate={() => mutate()} onClose={() => setShowAddForm(false)} />}
      {editForm.show && <EditPermission data={editForm} mutate={() => mutate()} onClose={() => setEditForm({ show: false })} />}
      <Spinner show={!Array.isArray(data)} />
      <Container>
        <div className="card">
          <div className="card-title align-items-center">
            <SearchBox placeholder={t("placeholder.search_permission")} onChange={(value) => setSearch(value?.toLowerCase())} />
            {can(Permission.AccessPermissionAdd) && (
              <Button variant="primary" className="ms-2" icon={BsPlusLg} onClick={() => setShowAddForm(true)}>
                {t("buttons.add_permission")}
              </Button>
            )}
          </div>
          <div className="card-body">
            <table className="table table-data table-center">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>{t("table.feature")}</th>
                  <th>{t("table.menu")}</th>
                  <th>{t("table.permission")}</th>
                  <th>{t("table.key")}</th>
                  {showActionColumn && <th>{t("table.action")}</th>}
                </tr>
              </thead>
              <tbody>
                {permission?.length ? (
                  permission?.map((item, idx) => (
                    <tr key={`user-${idx}`}>
                      <td>{idx + 1 + pages.page * pages.step}</td>
                      <td>{item?.menu_list?.list_feature?.name}</td>
                      <td>{item?.menu_list?.menu_name}</td>
                      <td>{item?.name}</td>
                      <td>{item?.key}</td>
                      {showActionColumn && (
                        <td>
                          {can(Permission.AccessPermissionEdit) && (
                            <Button variant="secondary" title="Edit" onClick={() => handleEdit(item)} className="m-1" icon={BsPencil} />
                          )}
                          {can(Permission.AccessPermissionDelete) && (
                            <Button
                              variant="tertiary"
                              title={t("buttons.delete")}
                              onClick={() => handleDelete(item?.uuid, item?.key)}
                              className="m-1"
                              icon={BsTrash}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 6 : 5}>No data.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <Pagination hook={pages} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AccessPermission
