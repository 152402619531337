import { FunctionComponent, h } from "preact"
import { Chart as ChartJS, ChartData, ChartOptions } from "chart.js"
import { Bar as ChartBar } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"
import { chartDateWithPeriod, getDateDifference } from "../../utils/dateformat"
import { toFixed } from "../../utils/toFixed"

export type DatasetType = {
  data: number[]
  label?: string
  borderColor: string
  backgroundColor: string
}

type BarChartProps = {
  data: DatasetType[]
  labels: string[]
  height?: number | string
  period: string
  showLegend?: boolean
}
  
const BarChart: FunctionComponent<BarChartProps> = ({ data, labels, height, period, showLegend = true }) => {
  const chartOptions: ChartOptions<"bar"> = {
    plugins: {
      legend: {
        display: showLegend,
        position: "bottom",
        align: "start"
      },
      tooltip: {
        callbacks: {
          label(context) {
            const { dataset } = context
            let label = dataset.label || ''
            if (label && context.parsed.y) {
              label += `: ${dataset.count[context.dataIndex]} (${toFixed(context.parsed.y, 3)}%)`
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5,
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        stacked: true
      },
    }
  }

  const newLabels = labels?.slice()?.reverse()

  const isMoreThanOneDay = !!getDateDifference({ firstDate: newLabels?.[0], secondDate: newLabels?.at(-1) })
  const label = newLabels?.map((item) => chartDateWithPeriod(item, period, isMoreThanOneDay))
  const newData = data?.map((item) => {
    return {
      ...item,
      data: item?.data?.slice()?.reverse(),
      count: item?.count?.slice()?.reverse(),
    }
  })
  const chartData: ChartData<"bar", number[], string> = {
    labels: label,
    datasets: [...newData]
  } 
  ChartJS.register(annotationPlugin)
  return (
    <ChartBar height={height} options={chartOptions} data={chartData} />
  )
}

export default BarChart
