import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { Link } from "preact-router"
import { AiOutlineFilePdf } from "react-icons/ai"
import { HiArrowLeft } from "react-icons/hi"
import { generateInvoice } from "./invoice-pdf"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import Spinner from "../../components/spinner"
import Container from "../../parts/container"
import { Button } from "../../parts/buttons"
import { toFormattedPrice } from "../../utils/string"
import { can } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import { downloadFile, useInvoiceById } from "../../hooks/useSubscription"
import { useConfirm } from "../../context/useConfirm"
import useAuth from "../../hooks/useAuth"
import ProformaModal from "./proforma"
import ConfirmationModal from "./confirmation"
import style from "./style.scss"
import EditModal from "./edit"
import moment from "moment"
import { isRenew } from "../../utils/finance"
import RenewSubscription from "./renew_subscription"
import useParams from "../../hooks/useParams"
import { useTranslation } from "react-i18next"

type FinanceDetailProps = h.JSX.HTMLAttributes<HTMLDivElement>

const FinanceDetail: FunctionComponent<FinanceDetailProps> = () => {
  const { t } = useTranslation()
  const pathname = window.location.pathname
  const uuid = pathname.split("/").length === 4 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""
  const { data, mutate } = useInvoiceById(uuid)
  const confirmation = useConfirm()
  const { fetcher } = useAuth()
  const { params, clearParams } = useParams()

  const [showProforma, setShowProforma] = useState({
    edit: false,
    editInvoice: false,
  })
  const [showRenew, setShowRenew] = useState(false)

  const [showPayment, setShowPayment] = useState(false)
  const [deactiveate, setDeactiveate] = useState(false)
  const [canAddProforma, setCanAddProforma] = useState(false)
  const [canConfirmPayment, setCanConfirmPayment] = useState(false)
  const [canDownloadProforma, setCanDownloadProforma] = useState(false)
  const [canDownloadInvoice, setCanDownloadInvoice] = useState(false)
  const [isEditProfrma, setIsEditProfrma] = useState(false)
  const [invoiceHistory, setInvoiceHistory] = useState({})
  const [invoiceData, setInvoiceData] = useState({})
  const [proformaData, setProformaData] = useState({})
  const [renewData, setRenewData] = useState({})

  const financePIC = `${data?.site?.company?.pic_finance_name} (${data?.site?.company?.pic_finance_phone} / ${data?.site?.company?.pic_finance_email})`
  const technicianPIC = `${data?.site?.company?.pic_technical_name} (${data?.site?.company?.pic_technical_phone} / ${data?.site?.company?.pic_technical_email})`
  const recept = (
    <Button
      type="button"
      variant="primary"
      className="btn-sm m-1"
      disabled={!invoiceHistory?.receipt}
      onClick={() => window.open(invoiceHistory?.receipt)}
    >
      {t("buttons.preview")}
    </Button>
  )
  const contents = [
    { label: t("label.po_spk_number"), value: invoiceHistory?.po_number },
    { label: t("label.po_date"), value: invoiceHistory?.po_date },

    { label: t("company_name"), value: data?.site?.company?.name },
    { label: t("label.company_address"), value: data?.site?.company?.address },
    { label: t("label.finance_contact_person"), value: financePIC },
    { label: t("label.technical_contact_person"), value: technicianPIC },

    { label: t("label.site_name"), value: data?.site?.name },
    { label: t("label.platform_id"), value: data?.site?.uid_intusi },
    { label: t("label.license_type"), value: invoiceHistory?.service_plan },
    { label: t("label.start_date"), value: data?.start },
    { label: t("label.end_date"), value: data?.end },

    { label: t("label.invoice_number"), value: invoiceHistory?.invoice_number },
    { label: t("label.invoice_date"), value: invoiceHistory?.inv_date },
    { label: t("label.price"), value: toFormattedPrice(invoiceHistory?.price || 0) },
    { label: t("label.include_ppn"), value: invoiceHistory?.include_ppn ? "Yes" : "NO" },
    { label: t("label.invoice_due_date"), value: invoiceHistory?.billing_date },

    { label: t("label.payment_status"), value: data?.status },
    { label: t("label.payment_date"), value: invoiceHistory?.payment_date },
    { label: t("label.payment_receipt"), value: recept },
  ]

  const handleDeactive = () => {
    confirmation(
      t("deactive_site_confirm_msg", { site: data?.site?.name, company: data?.site?.company?.name }),
      (deactive) => {
        if (deactive)
          fetcher()
            .patch(`/subscribtion/deactive/${uuid}`)
            .then(() => mutate())
      },
      { yes: t("deactive"), no: t("buttons.cancel") }
    )
  }

  const downloadInvoice = async (field: string, isInvoice: boolean) => {
    const history = data?.histories[0]
    if (history && history[field]) {
      const file_url = history[field]

      const date = moment(history?.po_date, "YYYY-MM-DD").format("DD MMM YYYY")
      const invoce_name = isInvoice ? "Invoice" : "Invoice Purchase Order"
      const filename = `${invoce_name} ${date}.pdf`

      await downloadFile(file_url, filename)
    } else {
      generateInvoice({ ...invoiceData, isInvoice: isInvoice })
    }
  }

  useEffect(() => {
    const deactiveStatus = data?.status?.toLowerCase() === "deactive"
    const history = data?.histories[0]
    setCanAddProforma(["draft", "awaiting payment", "suspend"].includes(data?.status?.toLowerCase()))
    setCanConfirmPayment(["awaiting payment", "suspend"].includes(data?.status?.toLowerCase()))
    setCanDownloadProforma(() => {
      const status = ["awaiting payment", "paid", "suspend", "deactive"].includes(data?.status?.toLowerCase())
      return status && data?.billing_date
    })
    setCanDownloadInvoice(() => {
      const status = ["paid", "deactive"].includes(data?.status?.toLowerCase())
      return status && data?.histories?.[0]?.payment_date
    })
    setIsEditProfrma(!!data?.billing_date)
    setDeactiveate(deactiveStatus)
    setInvoiceHistory(history)
    let proforma = {
      po_number: history?.po_number || "",

      po_date: history?.po_date || "",
      company: {
        name: data?.site?.company?.name || "",
        address: data?.site?.company?.address || "",
      },
      finance: {
        finance_pic: financePIC || "",
        technician_pic: technicianPIC || "",
      },

      site_name: data?.site?.name || "",
      platform_id: data?.site?.uid_intusi || "",
      service_plan: history?.service_plan || "Premium", //license type
      start_date: data?.start,
      end_date: data?.end,

      invoice_number: history?.invoice_number || "",
      inv_date: history?.inv_date || "",
      price: history?.price || "",
      include_ppn: history?.include_ppn,
      billing_date: history?.billing_date || "", //due date
      invoice_id: history?.uuid || "",
      uuid,
      payment_status: data?.status,
      payment_date: history?.payment_date,
    }
    setProformaData(proforma)

    const renew = isRenew(data?.status, history?.inv_date) && can(Permission.FinanceRenew)
    if (params.form == "renew" && renew) {
      const renew_data = getRenewSubscription()
      setRenewData({
        ...proforma,
        ...renew_data,
        po_number: "",
        po_date: "",
        billing_date: "",
        invoice_number: "",
      })
      setShowRenew(true)
    }
  }, [data, params])

  const getRenewSubscription = () => {
    const history = data?.histories[0]
    const old_end_date = moment(history?.end)
    const subscription_duration = Math.abs(moment(history?.start).diff(old_end_date, "days")) || 0 //days

    const datenow = moment().format("YYYY-MM-DD")
    let start_date = moment(history?.end).add(1, "day").format("YYYY-MM-DD")
    if (start_date <= datenow) {
      //new start_date not on present
      start_date = moment().format("YYYY-MM-DD")
    }

    const end_date = moment(start_date).add(subscription_duration, "days").format("YYYY-MM-DD")
    let renew_data = {
      service_plan: history?.service_plan || "Premium",
      start_date,
      end_date,
      price: history?.price,
      include_ppn: history?.include_ppn,
    }
    return renew_data
  }

  useEffect(() => {
    setInvoiceData((prev) => {
      const client = data?.site?.company
      const history = data?.histories?.[0]
      const grand_total = +history?.price + +history?.ppn
      const account = client?.vendor?.payment_info?.map((account) => ({
        bank: account?.name,
        branch: account?.description,
        number: account?.account_number,
        name: account?.account_name,
      }))
      return {
        ...prev,
        platform: {
          logo: client?.platform?.logo,
        },
        vendor: {
          name: client?.vendor?.name,
          address: client?.vendor?.address,
          npwp: client?.vendor?.npwp,
          responsible_person: client?.vendor?.pic,
          account,
        },
        client: {
          name: client?.name,
          address: client?.address,
          site_name: data?.site?.name,
          site_address: data?.site?.address,
        },
        data: {
          date: history?.inv_date,
          invoice_number: history?.invoice_number,
          po_number: history?.po_number,
          po_date: history?.po_date,
          total: `Rp. ${toFormattedPrice(history?.price)}`,
          ppn: `Rp. ${toFormattedPrice(history?.ppn)}`,
          grand_total: `Rp. ${toFormattedPrice(grand_total)}`,
          items: [
            {
              name: history?.service_plan,
              description: client?.platform?.name,
              price: `Rp. ${toFormattedPrice(history?.price)}`,
              total: `Rp. ${toFormattedPrice(history?.price)}`,
            },
          ],
        },
      }
    })
  }, [data])

  return (
    <div>
      <Spinner show={!data} />
      {showProforma.editInvoice && (
        <ProformaModal
          show={showProforma.editInvoice}
          data={proformaData}
          isEdit={isEditProfrma}
          onHide={() => setShowProforma({ ...showProforma, editInvoice: false })}
          mutate={mutate}
        />
      )}
      {showRenew && (
        <RenewSubscription
          show={showRenew}
          data={renewData}
          mutate={mutate}
          onHide={() => {
            clearParams()
            setShowRenew(false)
          }}
        />
      )}
      {showProforma.edit && (
        <EditModal show={showProforma.edit} data={proformaData} onHide={() => setShowProforma({ ...showProforma, edit: false })} mutate={mutate} />
      )}
      {showPayment && (
        <ConfirmationModal
          show={showPayment}
          subcription_uuid={uuid}
          invoice_uuid={invoiceHistory?.uuid}
          mutate={mutate}
          onHide={() => setShowPayment(false)}
        />
      )}
      <TopBar>
        <Breadcrumb name={t("breadcrumb.finance")} link="/finance" />
        <Breadcrumb name={t("breadcrumb.detail")} link={`/finance/detail/${uuid}`} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <Link className={style.back_button} href="/finance">
              <HiArrowLeft size="1.2rem" />
              {t("buttons.back")}
            </Link>
            <div className="d-flex justify-content-between mb-4">
              <p class={style.title}>{t("invoice_detail")}</p>
              <div>
                {can(Permission.FinanceDeactive) && (
                  <Button variant="tertiary" className="btn-sm mx-1 px-2" disabled={deactiveate} onClick={handleDeactive}>
                    {t("buttons.deactivate")}
                  </Button>
                )}
                {can(Permission.FinanceRenew) && isRenew(data?.status, invoiceHistory?.inv_date) && (
                  <Button
                    variant="warning"
                    className="btn-sm mx-1 px-2"
                    onClick={() => {
                      setShowRenew(true)
                      setRenewData({
                        ...proformaData,
                        ...getRenewSubscription(),
                        po_number: "",
                        po_date: "",
                        billing_date: "",
                        invoice_number: "",
                      })
                    }}
                  >
                    Renew
                  </Button>
                )}
                {can(Permission.FinanceRenew) && isRenew(data?.status, invoiceHistory?.inv_date) && (
                  <Button
                    variant="warning"
                    className="btn-sm mx-1 px-2"
                    onClick={() => {
                      setShowRenew(true)
                      setRenewData({
                        ...proformaData,
                        ...getRenewSubscription(),
                        po_number: "",
                        po_date: "",
                        billing_date: "",
                        invoice_number: "",
                      })
                    }}
                  >
                    Renew
                  </Button>
                )}
                {can(Permission.FinancePerformanceInvoice) && (
                  <Button
                    variant="secondary"
                    className="btn-sm mx-1 px-2"
                    disabled={!canAddProforma}
                    onClick={() => setShowProforma({ ...showProforma, editInvoice: canAddProforma })}
                  >
                    {isEditProfrma ? t("buttons.edit") : t("buttons.create")} {t("buttons.proforma_invoice")}
                  </Button>
                )}
                {can(Permission.FinancePaymentConfirmation) && (
                  <Button
                    variant="primary"
                    className="btn-sm mx-1 px-2"
                    disabled={!canConfirmPayment}
                    onClick={() => setShowPayment(canConfirmPayment)}
                  >
                    {t("buttons.confirm_payment")}
                  </Button>
                )}
                {can(Permission.FinanceEdit) && (
                  <Button
                    variant="primary"
                    className="btn-sm mx-1 px-2"
                    onClick={() => {
                      setShowProforma({ ...showProforma, edit: true })
                    }}
                  >
                    {t("buttons.edit")}
                  </Button>
                )}
              </div>
            </div>
            <div className="d-flex gap-2 mb-4">
              <Button
                variant="primary"
                className="btn-sm px-2"
                icon={AiOutlineFilePdf}
                disabled={!canDownloadProforma}
                onClick={() => {
                  downloadInvoice("po_file", false)
                }}
              >
                {t("buttons.download_proforma_invoice")}
              </Button>
              <Button
                variant="primary"
                className="btn-sm px-2"
                icon={AiOutlineFilePdf}
                disabled={!canDownloadInvoice}
                onClick={() => {
                  downloadInvoice("invoice_file", true)
                }}
              >
                {t("buttons.download_invoice")}
              </Button>
            </div>
            {contents.map(({ label, value }, index) => (
              <div key={index} className="row mb-2">
                <div className="col-2 d-flex justify-content-between align-items-start">
                  <div className={style.label}>{label}</div>
                  <div>:</div>
                </div>
                <div className="col-10">
                  <div className={style.value}>{value || "-"}</div>
                </div>
                {[t("label.po_date"), t("label.technical_contact_person"), t("label.end_date"), t("label.invoice_due_date")].includes(label) && (
                  <div class="mb-4"></div>
                )}
              </div>
            ))}
            {!!data?.histories?.length && (
              <div className="overflow-auto mt-4">
                <table className="table table-data table-center">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>{t("table.service_plan")}</th>
                      <th>{t("table.platform")}</th>
                      <th>{t("table.price")} (IDR)</th>
                      <th>{t("table.total")} (IDR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{invoiceHistory?.service_plan}</td>
                      <td>{data?.site?.company?.platform?.name}</td>
                      <td>{toFormattedPrice(invoiceHistory?.price)}</td>
                      <td>{toFormattedPrice(invoiceHistory?.price)}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={4} className="text-end fw-bold">
                        PPN 11%
                      </td>
                      <td className="text-center">{toFormattedPrice(invoiceHistory?.ppn)}</td>
                    </tr>
                    <tr>
                      <td colSpan={4} className="text-end fw-bold">
                        {t("table.grand_total")}
                      </td>
                      <td className="fw-bold text-center">{toFormattedPrice(`${+invoiceHistory?.price + +invoiceHistory?.ppn}`)}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
            <div className="d-flex justify-content-end my-2">
              <Link href="/finance">
                <Button type="button" variant="primary">
                  {t("buttons.back")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FinanceDetail
