import Excel from "exceljs"

export function colName(n: number) {
  const ordA = "a".charCodeAt(0)
  const ordZ = "z".charCodeAt(0)
  const len = ordZ - ordA + 1

  let s = ""
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s
    n = Math.floor(n / len) - 1
  }
  return s
}

export const download = (book: Excel.Workbook, name: string) =>
  book.xlsx
    .writeBuffer({})
    .then((xls64) => {
      // build anchor tag and attach file (works in chrome)
      const a = document.createElement("a")
      const data = new Blob([xls64], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })

      const url = URL.createObjectURL(data)
      a.href = url
      a.download = `${name  }.xlsx`
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    })
    .catch((error) => {
      console.log(error.message)
    })