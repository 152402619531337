import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { Link } from "preact-router"
import { FaChevronRight } from "react-icons/fa"
import moment from "moment"
import Container from "../../parts/container"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import CompanySiteFilter from "../../components/TopFilter/company-site-filter"
import { sortActiveParam } from "../../utils/sortParam"
import { defaultParams } from "../../utils/defaults"
import Spinner from "../../components/spinner"
import { useFilterContext } from "../../context/useFilter"
import { useRecordGraph } from "../../hooks/useSensor"
import useSites from "../../hooks/useSite"
import useCompany from "../../hooks/useCompany"
import DeviceLineChart from "./device-chart"
import { analyticChartColor } from "../../utils/color"
import { formatDate } from "../../utils/dateformat"
import { SuccessDeviceIcon, WarningDeviceIcon, ErrorDeviceIcon } from "./device-icon"
import { can } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type DeviceSensorDelayProps = h.JSX.HTMLAttributes<HTMLDivElement>

const DeviceSensorDelay: FunctionComponent<DeviceSensorDelayProps> = () => {
  const { t } = useTranslation()
  const { contextFilter, setContextFilter } = useFilterContext()
  const { data: cems } = useSites()
  const { data: mils } = useCompany()
  const siteId = cems?.length === 1 ? cems[0].uuid : contextFilter?.site

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const defaultValue = { company: contextFilter?.company, site: siteId }

  const [selectedParam, setSelectedParam] = useState([])
  const [payload, setPayload] = useState(defaultValue)
  const period = "raw"

  const { data } = useRecordGraph({
    uuid: payload.site,
    type: period,
  })
  const columns = sortActiveParam(data?.parameter) || defaultParams
  const lastData = data?.record?.[0]?.data?.filter((active) => columns?.map(({ name }) => name)?.includes(active?.name)) || defaultParams
  const label = data?.record?.map(({ timestamp }) => timestamp)
  const chart = columns?.map((param, index) => {
    const delay = data?.record?.map(({ data }) => data?.find(({ name }) => name === param?.name)?.delay)
    return {
      dataset: {
        data: delay,
        label: param?.name,
        borderColor: analyticChartColor(index),
        backgroundColor: analyticChartColor(index),
        tension: 0.4,
      },
      threshold: 150,
    }
  })

  const chartData = chart.filter(({ dataset }) => selectedParam.includes(dataset?.label))
  const tableData = data?.record?.slice(0, 10)

  const onSelectParamChange = (param) => {
    setSelectedParam((prevState) => {
      return prevState.includes(param) ? prevState.filter((item) => item !== param) : [...prevState, param]
    })
  }

  const isChecked = (param) => selectedParam?.includes(param)
  const getDelayValue = (param, data) => data?.find(({ name }) => name === param)?.delay ?? "-"

  const delayIcon = (param, data) => {
    const delay = +getDelayValue(param, data)
    if (delay < 150) {
      return <SuccessDeviceIcon />
    } else if (delay < 200) {
      return <WarningDeviceIcon />
    }
    return <ErrorDeviceIcon />
  }

  const handleFilterData = (data) => {
    const defaultSensor = cems?.find(({ uuid }) => data?.site === uuid)?.parameters?.[0]?.name || ""
    const sensor = Array.isArray(contextFilter?.sensor) ? contextFilter?.sensor?.[0] : contextFilter?.sensor || defaultSensor
    setPayload(data)
    setContextFilter({
      company: data.company,
      site: data.site,
      ...(!contextFilter?.period && { period }),
      ...(!contextFilter?.startDate && { startDate }),
      ...(!contextFilter?.endDate && { endDate }),
      ...(!contextFilter?.sensor && { sensor }),
    })
  }

  useEffect(() => {
    const cemId = cems?.length === 1 ? cems[0].uuid : undefined
    setPayload((prevState) => ({ ...prevState, site: cemId }))
  }, [cems])

  useEffect(() => {
    if (contextFilter?.site) {
      const company = cems?.find(({ uuid: cemUuid }) => contextFilter?.site === cemUuid)?.company?.uuid
      setPayload({ company, site: contextFilter?.site })
      setContextFilter({ ...contextFilter, company })
    }

    const now = moment().format("X")
    const aHourBefore = moment().subtract(60, "minutes").format("X")
    setStartDate(aHourBefore)
    setEndDate(now)

    const availParam = columns?.map(({ name }) => name)
    setSelectedParam(availParam)
  }, [])

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.device")} link="" />
        <Breadcrumb name={t("breadcrumb.sensor_delay")} link="/device/sensor-delay" />
      </TopBar>
      <Spinner show={!data} />
      <Container>
        <div className="row align-items-stretch">
          {cems?.length > 1 && <CompanySiteFilter mils={mils} cems={cems} defaultValue={payload} onShow={handleFilterData} />}
          <div className={style.bmal_card_wrapper}>
            {columns?.map((item, idx) => (
              <div key={idx} className={style.bmal_card}>
                <div className={style.bmal_content}>
                  {delayIcon(item?.name, lastData)}
                  <div className={style.bmal_desc}>
                    <p class={style.bmal_desc_title}>{item?.name}</p>
                    <p class={style.bmal_desc_status}>Delay:</p>
                    <div className={style.bmal_desc_value}>{getDelayValue(item?.name, lastData)} ms</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div class="card h-100">
          <div class="card-title">
            <p class={style.title}>{t("page.sensor_delay.sensor_delay_status")}</p>
          </div>
          <div class="d-flex justify-content-end me-3">
            <div class="d-flex flex-wrap">
              {columns?.map(({ name }, index) => (
                <label key={`param-${index}`} className="ms-3 d-flex align-items-center">
                  <input type="checkbox" class="form-check-input mt-0 me-2" checked={isChecked(name)} onChange={() => onSelectParamChange(name)} />
                  <div className={style.chart_check_box_color} style={{ backgroundColor: analyticChartColor(index) }} />
                  {name}
                </label>
              ))}
            </div>
          </div>
          <div class="card-body">
            <DeviceLineChart data={chartData} labels={label} period="raw" />
          </div>
        </div>
        <div className="card h-100 mt-4">
          <div className="card-title">
            <p class={style.title}>{t("page.sensor_delay.last_sensor_delay_update")}</p>
          </div>
          <div className="card-body overflow-auto h-100">
            <table class={`table table-center ${style.table}`}>
              <thead>
                <tr>
                  <th>{t("table.time")}</th>
                  {columns?.map(({ name }, index) => (
                    <th key={`sensor-${index}`}>{name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData?.length ? (
                  tableData.map((last) => (
                    <tr key={last?.uuid_data}>
                      <td>{formatDate(last?.timestamp, "DD-MM-YYYY HH:mm")}</td>
                      {columns?.map((param, index) => (
                        <td key={`last-param-${index}`}>{last?.data?.[index]?.delay} ms</td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns?.length + 1} className="text-center">
                      No data.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {can(Permission.RecordSensorDataView) && (
              <div className="d-flex justify-content-end">
                <Link href="/record/sensor-data" className={style.link}>
                  {t("page.sensor_delay.see_more_detail")}
                  <FaChevronRight className="ms-2" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default DeviceSensorDelay
