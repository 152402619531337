import { h } from "preact"
import { useState } from "preact/hooks"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import { useFeature, useMenu } from "../../../hooks/useUsman"
import useObjectState from "../../../hooks/useObjectState"
import { camelCase } from "../../../utils/string"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type Props = {
  show: boolean
  mutate: void
  onClose: void
}

const defaultBody = {
  name: "",
  key: "",
  menu_list: { uuid: "" },
}

const AddPermission = ({ mutate, show, onClose }: Props) => {
  const { t } = useTranslation()
  const { data: features } = useFeature()
  const { data: menus } = useMenu()
  const [AvailableMenu, setAvailableMenu] = useState([])
  const [selectedModule, setSelectedModule] = useState()
  const { fetcher } = useAuth()
  const [body, setBody] = useObjectState(defaultBody)
  const [keyName, setKeyName] = useObjectState({ module: "", menu: "" })

  const onModuleChange = (event) => {
    const moduleUuid = event.currentTarget.value
    const selectedModuleName = features?.find(({ uuid }) => uuid === moduleUuid)?.name
    setSelectedModule(moduleUuid)
    setBody({ menu_list: { uuid: "" }, key: camelCase(selectedModuleName), name: "" })
    setKeyName({ module: camelCase(selectedModuleName) })
    const filteredMenu = menus?.filter(({ list_feature }) => list_feature?.uuid === moduleUuid)
    setAvailableMenu(filteredMenu)
  }

  const onMenuChange = (event) => {
    const menuUuid = event.currentTarget.value
    const selectedMenuName = menus?.find(({ uuid }) => uuid === menuUuid)?.menu_name
    const newMenuName = `${keyName?.module}-${camelCase(selectedMenuName)}`
    setKeyName({ menu: camelCase(selectedMenuName) })
    setBody({ menu_list: { uuid: menuUuid }, key: newMenuName, name: "" })
  }

  const onPermissionChange = (event) => {
    const permissionName = event.currentTarget.value
    const newKey = `${keyName?.module}-${keyName?.menu}-${camelCase(permissionName)}`
    setBody({ name: permissionName, key: newKey })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetcher()
      .post("/permission", body)
      .then(() => {
        mutate()
        onClose()
      })
  }

  return (
    <form
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("Add New Permission")}</div>
          <div className="card-body">
            <div class="mb-4">
              <label class="form-label">{t("label.feature")}</label>
              <select class="form-control rounded mw-100" required value={selectedModule} onChange={onModuleChange}>
                <option value="">{t("label.select_feature")}</option>
                {features?.map(({ uuid, name }) => (
                  <option key={uuid} value={uuid}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.menu")}</label>
              <select class="form-control rounded mw-100" required value={body.menu_list.uuid} onChange={onMenuChange}>
                <option value="">{t("label.select_menu")}</option>
                {AvailableMenu?.map(({ uuid, menu_name }) => (
                  <option key={uuid} value={uuid}>
                    {menu_name}
                  </option>
                ))}
              </select>
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.permission_name")}</label>
              <input type="text" class="form-control rounded mw-100" required value={body.name} onChange={onPermissionChange} />
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.permission_key")}</label>
              <input
                type="text"
                class="form-control rounded mw-100"
                required
                value={body.key}
                onChange={({ currentTarget }) => setBody({ key: currentTarget.value })}
              />
              <small className={style.file_input_desc}>
                {t("Key should be in Pascalcase and separated with hyphen")}
                <br />
                (e.g: TechnicalData-SiteData-View)
              </small>
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onClose} type="button" variant="tertiary" className="m-1">
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1">
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddPermission
