import { ComponentChildren, Fragment, FunctionComponent, h } from "preact"

import { Button } from "../parts/buttons"
import { createContext } from "preact"
import { useContext } from "preact/hooks"
import useModal from "../hooks/useModal"
import useObjectState from "../hooks/useObjectState"

const confirmCtx = createContext({
  setMessage: (_: ComponentChildren) => {},
  setCb: (_?: (__: boolean) => void) => {},
  setLabel: (_: { yes: string; no: string }) => {},
})

type ConfirmProviderProps = h.JSX.HTMLAttributes<HTMLDivElement>

export const ConfirmProvider: FunctionComponent<ConfirmProviderProps> = ({ children }) => {
  const { show, onShow, onHide } = useModal()
  const [label, setLabel] = useObjectState({
    yes: "Confirm",
    no: "Cancel",
  })
  const [data, setData] = useObjectState<{
    msg: ComponentChildren
    cb: (out: boolean) => void
  }>({
    msg: "",
    cb: (_: boolean) => {},
  })

  const setMessage = (msg: ComponentChildren) => {
    onShow()
    setData({ msg })
  }
  const setCb = (fc?: (out: boolean) => void) => {
    setData({ cb: fc })
  }

  const handleConfirm = () => {
    data.cb(true)
    onHide()
  }
  const handleCancel = () => onHide()

  return (
    <confirmCtx.Provider value={{ setMessage, setCb, setLabel }}>
      <div
        className="modal-backdrop"
        style={{
          display: show ? "inherit" : "none",
          backgroundColor: "#00000099",
          zIndex: 9999999,
        }}
      >
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content card">
            <div className="card-title fw-bold"></div>
            <div className="card-body d-flex justify-content-between">{data.msg}</div>
            <div className="card-body text-center">
              <Button className="mx-2" onClick={handleConfirm} variant="primary">
                {label.yes}
              </Button>
              <Button className="mx-2" onClick={handleCancel} variant="tertiary">
                {label.no}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Fragment>{children}</Fragment>
    </confirmCtx.Provider>
  )
}

export const useConfirm = () => {
  const { setMessage, setCb, setLabel } = useContext(confirmCtx)
  return (msg: ComponentChildren, cb?: (out: boolean) => void, label?: { yes: string; no: string }) => {
    setMessage(msg)
    setCb(cb)
    if (label) setLabel(label)
  }
}
