import { ApiResponse } from "../types/api-response"
import useAuth from "./useAuth"
import useSWR from "swr"

export type RawDataResponse = {
  uuid: string
  name: string
  created_at: string
  updated_at: string
  deleted_at: any
  mils: Mil[]
}[]

export interface Mil {
  uuid: string
  name: string
  coordinate: Coordinate
  created_at: string
  updated_at: string
  deleted_at: any
  cems: Cem[]
}

interface Coordinate {
  lat: string
  long: string
}

export interface Cem {
  uuid: string
  name: string
  code: string
  coordinate: Coordinate2
  area: string
  regulation_references: string[]
  created_at: string
  updated_at: string
  deleted_at: any
  parameters: (Parameter | OverviewParameter)[]
  payloads: Payload[]
}

interface Coordinate2 {
  lat: string
  long: string
}

export interface Parameter {
  uuid: string
  name: string
  uom: string
  type: string
  upper_threshold: number
  lower_threshold: number
  created_at: string
  updated_at: string
  deleted_at: any
  has_load?: boolean
}

interface Payload {
  measured_at: string
  total_data: number
  total_valid: number
  total_comply: number
  total_percentage_comply?: number
  percentage_valid: number
  payloads: (PayloadPercentage | PayloadEmission)[]
}

export interface PayloadPercentage {
  measured_at: string
  name: string
  value?: number
  value_condition_override?: number
  avg_raw: number
  avg_valid?: number
  avg_load?: number
  avg_comply?: number
  percentage_comply?: number
  len_data: number
  len_valid: number
  len_comply: number
  load?: number
}

export interface PayloadEmission {
  measured_at: string
  name: string
  avg_valid: number
  avg_load?: number
  value?: number
}

export interface OverviewParameter {
  uuid: string
  name: string
  uom: string
  type: string
  upper_threshold: number
  lower_threshold: number
  created_at: string
  updated_at: string
  deleted_at: any
  last: number
  avg: number
  max: number
  min: number
  data_valid: DataValid
  data_invalid: number
  data_comply: DataComply
  data_incomply: number
  has_load?: boolean
  ncr: any
  payloads: ParameterPayload[]
}

interface DataValid {
  amount: number
  percentage: number
}

interface DataComply {
  amount: number
  percentage: number
}

interface ParameterPayload {
  uuid: string
  cems: string
  name: string
  is_comply: boolean
  is_valid?: boolean
  value: number
  value_measured: string
  value_corrected: string
  value_condition_override: number
  valid_data: boolean
  measured_at: string
  created_at: string
  updated_at: string
  deleted_at: any
}

interface RecordGraph {
  uuid: string
  type: string
  start: string | number
  end: string | number
}
interface RecordPaginate extends RecordGraph {
  page: string | number
  limit: string | number
  parameter_id?: string
  orders?: { [field: string]: string }[]
}

export const useRecord = (siteId: string, range: [string, string], period: string) => {
  const { fetcher } = useAuth()

  const url = `/sensor/record`
  const body = JSON.stringify({
    site: siteId,
    type: period,
    start: range[0],
    end: range[1],
  })

  const output = useSWR([url, body], (url, body) => {
    if (!siteId || !range[0] || !range[1] || !period) return []
    return fetcher()
      .get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  })

  return output
}

export const useGeovosRecord = (siteId: string, range: [string, string], period: string) => {
  const { fetcher } = useAuth()
  const url = `/sensor/geovos/record`
  const body = JSON.stringify({
    site: siteId,
    type: period,
    start: range?.[0],
    end: range?.[1],
  })

  const result = useSWR([url, body], (url, body) => {
    if (!siteId) return []
    return fetcher()
      .get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  })

  return result
}

export const useAllLastRecord = () => {
  const { fetcher } = useAuth()
  const url = `/sensor/geovos/last-data`

  const result = useSWR(
    url,
    (url) => {
      return fetcher()
        .get<ApiResponse<RawDataResponse>>(url)
        .then(({ data }) => {
          return data.data
        })
    },
    { refreshInterval: 30000 }
  )

  return result
}

export const usePercentage = (siteId: string, range: [string, string], period: string) => {
  const { fetcher } = useAuth()

  const url = `/sensor/percentage`
  const body = JSON.stringify({
    site: siteId,
    type: period,
    start: range[0],
    end: range[1],
  })

  const output = useSWR([url, body], (url, body) => {
    if (!siteId) return []
    return fetcher()
      .get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  })

  return output
}

export const useAnalyticMultiple = (siteId: string[], range: [string, string], period: string, sensor: string) => {
  const { fetcher } = useAuth()

  const url = `/sensor/analytic-multiple`
  const body = JSON.stringify({
    site: siteId?.join(","),
    type: period,
    start: range[0],
    end: range[1],
    sensor,
  })
  // if (!siteId?.length) return []
  const output = useSWR([url, body], (url, body) => {
    if (!siteId?.length) return []
    return fetcher()
      .get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  })

  return output
}

export const useRecordPaginate = ({ uuid, type, start, end, page, limit, parameter_id, orders = [] }: RecordPaginate) => {
  const { fetcher } = useAuth()

  const url = `/sensor/record/pagination/${uuid}/${type}`
  let body = JSON.stringify({ start, end, page, limit })

  if (orders.length) {
    let orderParams = {}
    const sort = orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    body = JSON.stringify({ start, end, page, limit, parameter_id, ...orderParams })
  }

  const output = useSWR([url, body], (url, body) => {
    if (!uuid || !type || !start || !end || !page || !limit) return []
    return fetcher()
      .get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  })

  const exportExcel = async (newLimit, newPage = 1) => {
    const newBody = JSON.stringify({ start, end, page: newPage, limit: newLimit })
    const { data } = await fetcher().get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(newBody) })
    return data?.data
  }

  return { ...output, exportExcel }
}

export const useRecordGraph = ({ uuid, type, start, end }: RecordGraph) => {
  const { fetcher } = useAuth()

  const url = `sensor/record/graph/${uuid}/${type}`
  const body = JSON.stringify({ start, end })

  const output = useSWR(
    [url, body],
    (url, body) => {
      if (!uuid || !type) return []
      return fetcher()
        .get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(body) })
        .then(({ data }) => {
          return data.data
        })
    },
    { refreshInterval: 30000 }
  )

  return output
}

export const useRecordAccumulated = ({ uuid, type, start, end, page, limit, parameter_id, orders = [] }: RecordPaginate) => {
  const { fetcher } = useAuth()

  const url = `/sensor/accumulate-load/pagination/${uuid}/${type}`
  let body = JSON.stringify({ start, end, page, limit })

  if (orders.length) {
    let orderParams = {}
    const sort = orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    body = JSON.stringify({ start, end, page, limit, parameter_id, ...orderParams })
  }

  const output = useSWR([url, body], (url, body) => {
    if (!uuid || !type || !start || !end || !page || !limit) return []
    return fetcher()
      .get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  })

  const exportExcel = async (newLimit, newPage = 1) => {
    const newBody = JSON.stringify({ start, end, page: newPage, limit: newLimit })
    const { data } = await fetcher().get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(newBody) })
    return data?.data
  }

  return { ...output, exportExcel }
}
