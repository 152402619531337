import axios, { AxiosRequestConfig } from "axios"

const useAxios = (config: AxiosRequestConfig = {}) => {
  return axios.create({
    baseURL: process.env.BASE_URL,
    ...config,
  })
}

export default useAxios
