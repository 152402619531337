import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { HiChevronDown, HiChevronUp } from "react-icons/hi"
import { Socket } from "socket.io-client"
import DetailsModuleContent from "./details-module-content"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type DetailsSummaryModuleProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  module: any
  status?: string
  socket: Socket
}

const DetailsSummaryModule: FunctionComponent<DetailsSummaryModuleProps> = ({ socket, status, module }) => {
  const { t } = useTranslation()
  const [isModuleOpen, setIsModuleOpen] = useState(false)

  const toggleModuleDetails = () => setIsModuleOpen((prevState) => !prevState)

  return (
    <details open={isModuleOpen} onToggle={toggleModuleDetails} className="mb-4">
      <summary className={style.detail_summary_module_reverse}>
        <div className={style.summary_title_module}>
          {module?.name}
          <span>
            {t("page.realtime.active_parameter")} ({module?.count_active_params}/{module?.total_params})
          </span>
          {isModuleOpen ? <HiChevronUp size="1.5rem" /> : <HiChevronDown size="1.5rem" />}
        </div>
      </summary>
      {module?.companies?.map((company) => (
        <DetailsModuleContent key={company?.uuid} company={company} status={status} socket={socket} />
      ))}
    </details>
  )
}

export default DetailsSummaryModule
