import { FunctionComponent, h } from "preact"
import LoginForm from "../login/login-form"
import { setLocalStorage, landingPage } from "../../utils/local-storage"
import style from "./style.scss"

type SucofindoProps = h.JSX.HTMLAttributes<HTMLDivElement>

const Sucofindo: FunctionComponent<SucofindoProps> = () => {
  const { pathname } = window.location
  setLocalStorage(landingPage, pathname)

  return (
    <div className={style.bg_sucofindo}>
      <div className={style.container}>
        <div className={style.form}>
          <div className={style.logo}>
            <img src="/assets/img/logo-sucofindo.png" alt="sucofindo" />
          </div>
          <div className={style.login_card}>
            <div className={style.login_title}>
              <div>Welcome to <p className={style.sucofindo}>Sucofindo!</p></div>
              <div className={style.desc}>Please sign in to access admin panel</div>
            </div>
            <LoginForm buttonClass={style.sucofindo_login} forgotClass={style.sucofindo_forgot_password} />
          </div>
          <div className={style.poweredBy}>
              <p>Powered By</p>
              <img src="/assets/img/logo-intusi.png" alt="intusi" />
            </div>
        </div>
        <div className={style.illustrate}>
          <img className={style.image} src="/assets/img/bg-sucofindo.png" alt="sucofindo" />
          <div className={style.desc_text}>
            <p>Kami menyediakan berbagai jasa</p>
            <p><span className={style.sucofindo_polygon}>testing, inspeksi, dan sertifikasi</span></p>
            <p>yang aman dan terpercaya.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sucofindo
