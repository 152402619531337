import { FunctionComponent, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { RiBuildingLine } from "react-icons/ri"
import useSites from "../../hooks/useSite"
import useSharedState, {loggedinUser} from "../../state"
import { setLocalStorage, getLocalStorage, siteLogoPath } from "../../utils/local-storage"
import { Role } from "../../enum/roles.enum"
import style from "./style.scss"

type SiteLogoProps = h.JSX.HTMLAttributes<HTMLDivElement>

const SiteLogo: FunctionComponent<SiteLogoProps> = () => {
  const [site, setSite] = useState()
  const localLogoPath = getLocalStorage(siteLogoPath)
  const [path, setPath] = useState(localLogoPath)
  const [userData] = useSharedState(loggedinUser, undefined)
  const { data: cems } = useSites()

  useEffect(() => {
    if (localLogoPath) setPath(localLogoPath)
  }, [localLogoPath])

  useEffect(() => {
    const roleOperator = Role.Operator === userData?.role?.name
    if (cems && cems?.length === 1 && roleOperator ) {
      const { logo, name } = cems[0]
      if (logo) {
        setLocalStorage(siteLogoPath, logo)
        setPath(logo)
      }
      setSite(name)
    }
  }, [cems, Role, userData])

  return (
    <>
      {path ?
        <div className="d-flex justify-content-center">
          <img className={style.site_logo} src={path} alt={site} />
        </div>
        : 
        <RiBuildingLine size="3rem" className="text-white opacity-50" />
        }
      </>
  )
}

export default SiteLogo