import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { Link } from "preact-router"
import { HiArrowLeft } from "react-icons/hi"
import { Button } from "../../parts/buttons"
import Container from "../../parts/container"
import { generateInvoice } from "./invoice-pdf"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import Spinner from "../../components/spinner"
import { toFormattedPrice } from "../../utils/string"
import { can, canOneOf } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import { downloadFile, useSubscriptionById } from "../../hooks/useSubscription"
import HistoryModal from "./history-modal"
import style from "./style.scss"
import moment from "moment"
import { useTranslation } from "react-i18next"

type FinanceHistoryProps = h.JSX.HTMLAttributes<HTMLDivElement>

const FinanceHistory: FunctionComponent<FinanceHistoryProps> = () => {
  const { t } = useTranslation()
  const pathname = window.location.pathname
  const uuid = pathname.split("/").length === 4 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""
  const { data } = useSubscriptionById(uuid)
  const [showDetail, setShowDetail] = useState(false)
  const [modalData, setModalData] = useState({})
  const histories = data?.histories

  const showActionColumn = canOneOf([
    Permission.FinanceDetail,
    Permission.FinanceInvoice,
    Permission.FinancePaymentConfirmation,
    Permission.FinancePerformanceInvoice,
  ])

  const getInvoiceData = (index, isInvoice) => {
    const client = data?.site?.company
    const history = data?.histories?.[index]
    const grand_total = +history?.price + +history?.ppn
    const account = client?.vendor?.payment_info?.map((account) => ({
      bank: account?.name,
      branch: account?.description,
      number: account?.account_number,
      name: account?.account_name,
    }))
    const newData = {
      isInvoice,
      platform: {
        logo: client?.platform?.logo,
      },
      vendor: {
        name: client?.vendor?.name,
        address: client?.vendor?.address,
        npwp: client?.vendor?.npwp,
        responsible_person: client?.vendor?.pic,
        account,
      },
      client: {
        name: client?.name,
        address: client?.address,
        site_name: data?.site?.name,
        site_address: data?.site?.address,
      },
      data: {
        date: history?.inv_date,
        invoice_number: history?.invoice_number,
        po_number: history?.po_number,
        po_date: history?.po_date,
        total: `Rp. ${toFormattedPrice(history?.price)}`,
        ppn: `Rp. ${toFormattedPrice(history?.ppn)}`,
        grand_total: `Rp. ${toFormattedPrice(grand_total)}`,
        items: [
          {
            name: history?.service_plan,
            description: client?.platform?.name,
            price: `Rp. ${toFormattedPrice(history?.price)}`,
            total: `Rp. ${toFormattedPrice(history?.price)}`,
          },
        ],
      },
    }
    generateInvoice(newData)
  }

  const onShowModal = (index) => {
    const historyData = histories?.[index]
    setModalData(historyData)
    setShowDetail(true)
  }

  const downloadInvoice = async (index: number, field: string, isInvoice: boolean) => {
    const history = histories[index]

    if (history && history[field]) {
      const file_url = history[field]
      const date = moment(history?.po_date, "YYYY-MM-DD").format("DD MMM YYYY")
      const invoce_name = isInvoice ? "Invoice" : "Invoice Purchase Order"
      const filename = `${invoce_name} ${date}.pdf`
      await downloadFile(file_url, filename)
    } else {
      getInvoiceData(index, isInvoice)
    }
  }

  return (
    <div className="min-vh-100">
      <Spinner show={!data} />
      {showDetail && <HistoryModal data={modalData} show={showDetail} onHide={() => setShowDetail(false)} />}
      <TopBar>
        <Breadcrumb name={t("breadcrumb.finance")} link="/finance" />
        <Breadcrumb name={t("breadcrumb.history")} link={`/finance/history/${uuid}`} />
      </TopBar>
      <Container>
        <div className="card mt-3">
          <div className="card-body">
            <Link className={style.back_button} href="/finance">
              <HiArrowLeft size="1.2rem" />
              {t("buttons.back")}
            </Link>
            <div className="overflow-auto mt-4">
              <table className="table table-data table-center">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("table.invoice_number")}</th>
                    <th>{t("table.po_spk_number")}</th>
                    <th>{t("table.service_plan")}</th>
                    <th>{t("table.price")} (IDR)</th>
                    <th>{t("table.payment_date")}</th>
                    <th>{t("table.receipt")}</th>
                    {showActionColumn && <th>{t("table.menu")}</th>}
                  </tr>
                </thead>
                <tbody>
                  {histories?.length ? (
                    histories?.map((history, index) => (
                      <tr key={history?.uuid}>
                        <td>{index + 1}</td>
                        <td>{history?.invoice_number}</td>
                        <td>{history?.po_number}</td>
                        <td>{history?.service_plan}</td>
                        <td>{toFormattedPrice(history?.price)}</td>
                        <td>{history?.payment_date}</td>
                        <td>
                          <Button
                            variant="primary"
                            disabled={!history?.receipt}
                            className="btn-sm my-1"
                            onClick={() => window.open(history?.receipt)}
                          >
                            {t("buttons.receipt")}
                          </Button>
                        </td>
                        <td>
                          <Button variant="primary" className="btn-sm my-1 me-2" onClick={() => onShowModal(index)}>
                            Detail
                          </Button>
                          {can(Permission.FinancePerformanceInvoice) && (
                            <Button variant="secondary" className="btn-sm my-1 me-2" onClick={() => getInvoiceData(index, false)}>
                              {t("buttons.proforma")}
                            </Button>
                          )}
                          {can(Permission.FinanceInvoice) && (
                            <Button
                              variant="tertiary"
                              className="btn-sm my-1"
                              disabled={!history?.payment_date}
                              onClick={() => getInvoiceData(index, true)}
                            >
                              {t("buttons.invoice")}
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={showActionColumn ? 8 : 7} className="text-center">
                        No data.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FinanceHistory
