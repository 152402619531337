import { FunctionComponent, h } from "preact"
import Container from "../../parts/container"
import style from "./style.scss"

type PrivacyPolicyContentProps = h.JSX.HTMLAttributes<HTMLDivElement>

const PrivacyPolicyContent: FunctionComponent<PrivacyPolicyContentProps> = () => {
  const { hostname } = window.location

  return (
    <div className="min-vh-100">
      <Container>
        <div className={style.tc}>
          <h2>KEBIJAKAN PRIVASI</h2>
          <p>
            INTUSI mengakui bahwa perlindungan atas privasi anda adalah hal yang utama bagi INTUSI. Tujuan dari kebijakan privasi ini untuk menjelaskan
            kepada Anda bagaimana INTUSI mengumpulkan, menyimpan dan menggunakan informasi pribadi Pengguna. Kebijakan Privasi dan S&K Layanan
            ini adalah satu kesatuan yang tidak terpisahkan yang harus Anda pahami dan setujui sebelum anda dapat membuat Akun dan menggunakan
            Layanan. "Informasi Pengguna" adalah setiap informasi yang diterima oleh INTUSI baik yang didapatkan pada saat pendaftaran diri dan
            pembukaan Akun dan setiap informasi yang diterima melalui penggunaan Layanan oleh Pengguna.
          </p>
          <h2 className={style.mt}>DATA PENGGUNA DI LAYANAN</h2>
          <p>
            Data dapat diunggah, disimpan, dikirim dan diterima ke Layanan.
          </p>
          <p>
            Pelanggan mengakui dan menyetujui bahwa Data Pelanggan apa pun yang diberikan kepada atau dihosting oleh Perusahaan dapat di-host oleh
            Perusahaan atau pemasok Perusahaan pada perangkat keras atau infrastruktur yang berlokasi di atau di luar Indonesia.
          </p>
          <p>
            Seperti antara Perusahaan dan Pelanggan, Pelanggan pemilik semua Data Pelanggan.
          </p>
          <p>
            Pelanggan bertanggung jawab penuh atas keakuratan, legalitas, dan kualitas Data Pelanggan dan untuk memperoleh segala izin, lisensi, hak, dan
            otorisasi yang diperlukan bagi Perusahaan untuk menggunakan, menyelenggarakan, mentransmisikan, menyimpan, dan mengungkapkan Data
            Pelanggan.
          </p>
          <p>
            Pelanggan setuju bahwa Perusahaan tidak bertanggung jawab atas kehilangan, korupsi, atau peretasan Data Pelanggan apa pun.
          </p>
          <p>
            Pelanggan mengganti rugi Perusahaan dan pemasoknya sehubungan dengan kerugian dan kerusakan yang dialami Perusahaan dan/atau
            pemasoknya sehubungan dengan kerugian, korupsi atau tidak tersedianya Data Pelanggan atau sehubungan dengan transmisi, penyimpanan,
            pengungkapan, peretasan, peretasan Data Pelanggan atau di mana akses Data Pelanggan mana pun melanggar Hak Kekayaan Intelektual
            seseorang atau melanggar hukum, peraturan, kode, atau standar apa pun.
          </p>
          <p>
            Ketersediaan Layanan kepada Pelanggan akan tunduk, di samping ketentuan lain yang diatur dalam Kontrak, dengan batasan bandwidth, batasan
            ukuran basis data, batasan throughput dan batasan atau batasan teknis dan nonteknis yang mungkin berlaku dari waktu untuk waktu.
          </p>
          <p>
            Semua kekayaan intelektual dan Hak Kekayaan Intelektual dalam Layanan, Situs Web, dan dokumentasi apa pun yang berkaitan dengan Layanan,
            logo {hostname} tetap menjadi milik Perusahaan.
          </p>
          <p>
            Pengguna yang Diundang mungkin juga dapat menyimpan, mengirim dan menerima Data Pelanggan ke Layanan.
          </p>
          <p>
            Pelanggan menyatakan dan menjamin bahwa ia dan setiap Pengguna yang Diundang memiliki atau sebaliknya memiliki izin untuk menggunakan
            semua Data Pelanggan yang dikirimkan ke Layanan.
          </p>
          <p>
            Pelanggan setuju untuk memberikan kepada Perusahaan lisensi di seluruh dunia untuk menggunakan, menyelenggarakan, menyimpan,
            mereproduksi, memodifikasi, membuat karya turunan (seperti yang dihasilkan oleh pemrosesan pos atau visualisasi agregasi) untuk tujuan
            memungkinkan Pelanggan mengakses dan menggunakan Layanan dan untuk tujuan lain apa pun yang terkait dengan penyediaan layanan kepada
            Pelanggan.
          </p>
          <p>
            Pelanggan mengakui dan menyetujui bahwa sistem otomatis Perusahaan menganalisis Data Pelanggan dan penggunaan layanan untuk tujuan
            penilaian kepatuhan terhadap kebijakan penggunaan wajar Perusahaan, meningkatkan kinerja layanan, dan memantau distribusi geografis sumber
            Data.
          </p>
          <p>
            Pelanggan harus menyimpan salinan semua Data yang dimasukkan ke dalam Layanan. Perusahaan dapat menyediakan metode bagi Pelanggan
            untuk mencadangkan data termasuk API, dan unduhan berkala manual. Perusahaan mematuhi kebijakan dan prosedur praktik terbaik untuk
            mencegah kehilangan data, sebagaimana diuraikan dalam Kepatuhan. Namun, tidak satu pun dari Perusahaan, pejabat, atau karyawannya yang
            membuat pernyataan atau jaminan bahwa tidak akan ada kehilangan Data.
          </p>
          <h2 className={style.mt}>PENGUMPULAN INFORMASI PENGGUNA</h2>
          <p>
            Kami menyimpan Informasi Pengguna dalam database kami selama Anda memiliki Akun di INTUSI. Kami juga menyimpan Informasi Pengguna
            untuk jangka waktu yang dipersyaratkan berdasarkan ketentuan perundang-undangan yang berlaku setelah Anda membatalkan dan menghapus
            Akun anda. Jenis informasi yang kami kumpulkan dan kelola ketika berinteraksi dengan Anda sehubungan dengan Layanan, yakni mencakup:
          </p>
          <ol>
            <li>
              Informasi Dasar Pengguna, seperti: Nama lengkap, alamat lengkap, nama perusahaan, alamat perusahaan, email, nomor telepon, nama akun dan
              kata sandi dan informasi lain yang diperlukan.
            </li>
            <li>
              Data Sensor, termasuk data yang dikirimkan oleh sensor melalui perangkat Data Logger yang dikirimkan ke server INTUSI, termasuk Data Sensor
              lainnya yang dihasilkan dari komputasi di server INTUSI.
            </li>
            <li>
              Data dan Informasi apapun yang diunggah oleh Anda dalam penggunaan Layanan;
            </li>
            <li>
              Informasi yang melekat pada Anda saat Anda mengakses Situs, Aplikasi dan/atau Layanan;
            </li>
          </ol>
          <p>
            INTUSI berhak untuk, dari waktu ke waktu, melakukan verifikasi terhadap Informasi Pengguna yang anda sediakan untuk INTUSI, dengan mengirim
            verifikasi melalui e-mail, atau meminta anda untuk mengirimkan dokumen pendukung, atau dalam bentuk lainnya yang diminta.
          </p>
          <h2 className={style.mt}>PENGUNGKAPAN INFORMASI DASAR PENGGUNA</h2>
          <p>
            Pengguna memahami dan menyetujui bahwa pengungkapan Informasi Dasar Pengguna kepada pihak ketiga dapat dilakukan oleh INTUSI terkait
            dengan hal-hal sebagai berikut:
          </p>
          <ol>
            <li>
              Kepada rekanan yang menjalin hubungan kerjasama dengan INTUSI, dibawah perjanjian kerahasiaan, yang membantu INTUSI untuk
              berkomunikasi mengenai seluruh penawaran-penawaran INTUSI, kepada rekan pemasaran serta penyedia jasa lainnya bagi INTUSI.
            </li>
            <li>
              Secara langsung telah menjadi milik umum (public domain) bukan karena pelanggaran atas syarat dan ketentuan ini; atau telah berada di dalam
              penguasaan INTUSI yang menerima sebelum informasi yang bersangkutan diberikan oleh Pengguna.
            </li>
            <li>
              Kepada afiliasi, anak usaha, induk usaha, agen, dan sub-kontraktor;
            </li>
            <li>
              Atas permintaan dan/atau perintah pengadilan, otoritas atau lembaga hukum yang berwenang, dan tujuan lain yang dianggap sah menurut
              penegakan hukum;
            </li>
            <li>
              Dalam rangka penyelidikan, pencegahan, atau pengambilan tindakan terhadap dugaan kegiatan yang tidak sah, dugaan penipuan, serta dugaan
              adanya pelanggaran atas sistem INTUSI dan/atau kewajiban-kewajiban hukum lainnya.
            </li>
            <li>
              Kepada pihak yang berpotensi untuk menerima pengalihan hak dan kewajiban dalam hal terjadinya penjualan atau pengalihan atas Layanan yang
              dimiliki INTUSI kepada pihak ketiga.
            </li>
          </ol>
          <h2 className={style.mt}>PENGGUNAAN INFORMASI PENGGUNA</h2>
          <p>
            INTUSI menggunakan Informasi Pengguna untuk menyediakan, mempertahankan, dan meningkatkan layanan kami dan untuk menyampaikan
            informasi dan mendukung segala permintaan Anda, termasuk memberikan peringatan dan memberikan pesan terkait hal administratif.
          </p>
          <p>
            Selanjutnya, Pengguna memahami dan menyetujui bahwa INTUSI dapat menggunakan Informasi Pengguna untuk hal-hal sebagai berikut:
          </p>
          <ol>
            <li>
              mengirimkan berita dan informasi mengenai layanan-layanan dan untuk memberi informasi tentang produk-produk, layanan-layanan, promosi,
              dan pembaharuan yang INTUSI tawarkan;
            </li><li>
              meningkatkan produk-produk dan layanan-layanan dan untuk memenuhi permintaan Anda;
            </li>
            <li>
              menyimpan data internal INTUSI;
            </li>
            <li>
              untuk memberikan respon atau pertanyaan dan komentar Anda, untuk menyediakan Anda akses kepada produk-produk dan layanan-layanan
              dan untuk memberi informasi mengenai produk-produk dan layanan-layanan INTUSI kepada Anda;
            </li>
            <li>
              melakukan investigasi atas penipuan, potensi penipuan, kekerasan, ancaman kekerasan, atau pelanggaran hukum apapun atau pelanggaran
              aturan dari S&K Layanan, atau pelanggaran hak dari pihak ketiga, atau untuk menginvestigasi segala sesuatu yang menurut INTUSI tidak layak
              untuk dilakukan.
            </li>
            <li>
              untuk tujuan internal INTUSI seperti audit, analisa, dan riset yang bertujuan meningkatkan produk, layanan-layanan, dan komunikasi kepada
              Pengguna;
            </li>
            <li>
              untuk pengungkapan yang diperbolehkan sebagaimana dijelaskan pada "Pengungkapan Informasi Pengguna" di dalam Kebijakan Privasi ini; dan
            </li>
            <li>
              tujuan apapun lainnya yang dapat meningkatkan produk-produk dan layanan yang bertujuan untuk menyediakan layanan-layanan yang lebih
              baik untuk Anda.
            </li>
          </ol>
          <h2 className={style.mt}>PERLINDUNGAN INFORMASI</h2>
          <p>
            INTUSI selalu berusaha untuk melindungi Informasi Pengguna dari kehilangan, penyalahgunaan, dan pengungkapan atau akses oleh pihak yang
            tidak berwenang.
          </p>
          <p>
            Dalam menjaga keamanan Informasi Pengguna kami menggunakan prosedur keamanan kami secara hati-hati, mematuhi hukum dan standar
            keamanan yang berlaku, memastikan bahwa Informasi Pengguna terlindungi dengan aman.
          </p>
          <p>
            Kami akan mengambil langkah-langkah yang diperlukan untuk mempertahankan privasi dan keamanan dari seluruh Informasi Pengguna. Dalam
            hal terjadi peretasan yang berada di luar kendali kami, Anda setuju untuk membebaskan INTUSI dari seluruh klaim, tanggung jawab hukum
            ataupun pengeluaran apapun yang muncul dari peretasan tersebut.
          </p>
        </div>
      </Container>
    </div>
  )
}

export default PrivacyPolicyContent
