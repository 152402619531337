import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
} from "react-icons/bi"
import { FunctionComponent, h } from "preact"

import { PaginationData } from "../../hooks/usePagination"
import style from "./style.scss"

type PaginationProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  hook: PaginationData
  simple?: boolean
}

const Pagination: FunctionComponent<PaginationProps> = ({ hook, simple = false }) => {
  const step = simple ? 1 : 3
  
  return (
    <div className={style.pagination}>
      {!simple && <button onClick={() => hook.setPage(0)} className={style.left} disabled={hook.page === 0}>
        <BiChevronsLeft />
      </button>}
      <button onClick={hook.prev} className={style.left} disabled={hook.page === 0}>
        <BiChevronLeft />
      </button>
      {Array.from({ length: hook.maxPage }).map(
        (_, idx) =>
          idx > hook.page - step &&
          idx < hook.page + step && (
            <button
              key={idx}
              onClick={() => hook.setPage(idx)}
              className={
                `${style.item  } ${  idx == hook.page ? style.active : ""}`
              }
            >
              {idx + 1}
            </button>
          )
      )}
      <button onClick={hook.next} className={style.right} disabled={hook.maxPage === hook.page + 1}>
        <BiChevronRight />
      </button>
      {!simple && <button
        onClick={() => hook.setPage(hook.maxPage - 1)}
        className={style.right}
        disabled={hook.maxPage === hook.page + 1}
      >
        <BiChevronsRight />
      </button>}
    </div>
  )
}

export default Pagination
