import Excel, { Borders, Alignment, Font } from "exceljs"
import moment from "moment"
import { download } from "../../../utils/excel"

const NOW = () => moment().format("x")

// STYLES
const alignmentCenter: Partial<Alignment> = { vertical: "middle", horizontal: "center" }
const boldFontHeader: Partial<Font> = { bold: true, color: { argb: "FFFFFFFF" } }
const border: Partial<Borders> = {
  top: { style: "thin" },
  left: { style: "thin" },
  bottom: { style: "thin" },
  right: { style: "thin" },
}
const backgroundHeader: Partial<Fill> = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FF51BCDA" },
}

export const siteDataToExcel = (data: any[]) => {
  const book = new Excel.Workbook()
  const sheet = book.addWorksheet("Site Data")
  const title = `Site Data - ${NOW()}`

  sheet.mergeCells(1, 1, 2, 1)
  sheet.getCell(1, 1).value = "No"
  sheet.getCell(1, 1).alignment = alignmentCenter
  sheet.getCell(1, 1).border = border
  sheet.getCell(1, 1).font = boldFontHeader
  sheet.getCell(1, 1).fill = backgroundHeader

  sheet.mergeCells(1, 2, 2, 2)
  sheet.getCell(1, 2).value = "Company"
  sheet.getCell(1, 2).alignment = alignmentCenter
  sheet.getCell(1, 2).border = border
  sheet.getCell(1, 2).font = boldFontHeader
  sheet.getCell(1, 2).fill = backgroundHeader

  sheet.mergeCells(1, 3, 2, 3)
  sheet.getCell(1, 3).value = "Site Name"
  sheet.getCell(1, 3).alignment = alignmentCenter
  sheet.getCell(1, 3).border = border
  sheet.getCell(1, 3).font = boldFontHeader
  sheet.getCell(1, 3).fill = backgroundHeader

  sheet.mergeCells(1, 4, 2, 4)
  sheet.getCell(1, 4).value = "Logger ID"
  sheet.getCell(1, 4).alignment = alignmentCenter
  sheet.getCell(1, 4).border = border
  sheet.getCell(1, 4).font = boldFontHeader
  sheet.getCell(1, 4).fill = backgroundHeader

  sheet.mergeCells(1, 5, 2, 5)
  sheet.getCell(1, 5).value = "Module"
  sheet.getCell(1, 5).alignment = alignmentCenter
  sheet.getCell(1, 5).border = border
  sheet.getCell(1, 5).font = boldFontHeader
  sheet.getCell(1, 5).fill = backgroundHeader

  sheet.mergeCells(1, 6, 1, 8)
  sheet.getCell(1, 6).value = "Conact Person"
  sheet.getCell(1, 6).alignment = alignmentCenter
  sheet.getCell(1, 6).border = border
  sheet.getCell(1, 6).font = boldFontHeader
  sheet.getCell(1, 6).fill = backgroundHeader

  sheet.getCell(2, 6).value = "Name"
  sheet.getCell(2, 6).alignment = alignmentCenter
  sheet.getCell(2, 6).border = border
  sheet.getCell(2, 6).font = boldFontHeader
  sheet.getCell(2, 6).fill = backgroundHeader

  sheet.getCell(2, 7).value = "Email"
  sheet.getCell(2, 7).alignment = alignmentCenter
  sheet.getCell(2, 7).border = border
  sheet.getCell(2, 7).font = boldFontHeader
  sheet.getCell(2, 7).fill = backgroundHeader

  sheet.getCell(2, 8).value = "Phone"
  sheet.getCell(2, 8).alignment = alignmentCenter
  sheet.getCell(2, 8).border = border
  sheet.getCell(2, 8).font = boldFontHeader
  sheet.getCell(2, 8).fill = backgroundHeader

  sheet.mergeCells(1, 9, 1, 13)
  sheet.getCell(1, 9).value = "Parameter"
  sheet.getCell(1, 9).alignment = alignmentCenter
  sheet.getCell(1, 9).border = border
  sheet.getCell(1, 9).font = boldFontHeader
  sheet.getCell(1, 9).fill = backgroundHeader

  sheet.getCell(2, 9).value = "Name"
  sheet.getCell(2, 9).alignment = alignmentCenter
  sheet.getCell(2, 9).border = border
  sheet.getCell(2, 9).font = boldFontHeader
  sheet.getCell(2, 9).fill = backgroundHeader

  sheet.getCell(2, 10).value = "Lower Threshold"
  sheet.getCell(2, 10).alignment = alignmentCenter
  sheet.getCell(2, 10).border = border
  sheet.getCell(2, 10).font = boldFontHeader
  sheet.getCell(2, 10).fill = backgroundHeader

  sheet.getCell(2, 11).value = "Upper Threshold"
  sheet.getCell(2, 11).alignment = alignmentCenter
  sheet.getCell(2, 11).border = border
  sheet.getCell(2, 11).font = boldFontHeader
  sheet.getCell(2, 11).fill = backgroundHeader

  sheet.getCell(2, 12).value = "Unit"
  sheet.getCell(2, 12).alignment = alignmentCenter
  sheet.getCell(2, 12).border = border
  sheet.getCell(2, 12).font = boldFontHeader
  sheet.getCell(2, 12).fill = backgroundHeader

  sheet.getCell(2, 13).value = "Has Load"
  sheet.getCell(2, 13).alignment = alignmentCenter
  sheet.getCell(2, 13).border = border
  sheet.getCell(2, 13).font = boldFontHeader
  sheet.getCell(2, 13).fill = backgroundHeader

  let prevTopParam = 0
  let prevBottomParam = 0
  let prevParam = 0
  data?.forEach((site, idx) => {
    prevBottomParam += site?.parameters?.length - 1
    sheet.mergeCells(3 + prevTopParam + idx, 1, 3 + prevBottomParam + idx, 1)
    sheet.getCell(3 + idx + prevTopParam, 1).value = idx + 1
    sheet.getCell(3 + idx + prevTopParam, 1).border = border
    sheet.getCell(3 + idx + prevTopParam, 1).alignment = alignmentCenter

    sheet.mergeCells(3 + prevTopParam + idx, 2, 3 + prevBottomParam + idx, 2)
    sheet.getCell(3 + idx + prevTopParam, 2).value = site?.company?.name
    sheet.getCell(3 + idx + prevTopParam, 2).border = border
    sheet.getCell(3 + idx + prevTopParam, 2).alignment = alignmentCenter

    sheet.mergeCells(3 + prevTopParam + idx, 3, 3 + prevBottomParam + idx, 3)
    sheet.getCell(3 + idx + prevTopParam, 3).value = site?.name
    sheet.getCell(3 + idx + prevTopParam, 3).border = border
    sheet.getCell(3 + idx + prevTopParam, 3).alignment = alignmentCenter

    sheet.mergeCells(3 + prevTopParam + idx, 4, 3 + prevBottomParam + idx, 4)
    sheet.getCell(3 + idx + prevTopParam, 4).value = site?.uuid
    sheet.getCell(3 + idx + prevTopParam, 4).border = border
    sheet.getCell(3 + idx + prevTopParam, 4).alignment = alignmentCenter

    sheet.mergeCells(3 + prevTopParam + idx, 5, 3 + prevBottomParam + idx, 5)
    sheet.getCell(3 + idx + prevTopParam, 5).value = site?.modules?.map((module) => module.name).join(" ")
    sheet.getCell(3 + idx + prevTopParam, 5).border = border
    sheet.getCell(3 + idx + prevTopParam, 5).alignment = alignmentCenter

    sheet.mergeCells(3 + prevTopParam + idx, 6, 3 + prevBottomParam + idx, 6)
    sheet.getCell(3 + idx + prevTopParam, 6).value = site?.pic_technical_name
    sheet.getCell(3 + idx + prevTopParam, 6).border = border
    sheet.getCell(3 + idx + prevTopParam, 6).alignment = alignmentCenter

    sheet.mergeCells(3 + prevTopParam + idx, 7, 3 + prevBottomParam + idx, 7)
    sheet.getCell(3 + idx + prevTopParam, 7).value = site?.pic_technical_email
    sheet.getCell(3 + idx + prevTopParam, 7).border = border
    sheet.getCell(3 + idx + prevTopParam, 7).alignment = alignmentCenter

    sheet.mergeCells(3 + prevTopParam + idx, 8, 3 + prevBottomParam + idx, 8)
    sheet.getCell(3 + idx + prevTopParam, 8).value = site?.pic_technical_phone
    sheet.getCell(3 + idx + prevTopParam, 8).border = border
    sheet.getCell(3 + idx + prevTopParam, 8).alignment = alignmentCenter

    site?.parameters?.forEach((param, id) => {
      sheet.getCell(3 + prevParam + id, 9).value = param?.name
      sheet.getCell(3 + prevParam + id, 9).border = border
      sheet.getCell(3 + prevParam + id, 9).alignment = alignmentCenter

      sheet.getCell(3 + prevParam + id, 10).value = param?.lower_threshold
      sheet.getCell(3 + prevParam + id, 10).border = border
      sheet.getCell(3 + prevParam + id, 10).alignment = alignmentCenter

      sheet.getCell(3 + prevParam + id, 11).value = param?.upper_threshold
      sheet.getCell(3 + prevParam + id, 11).border = border
      sheet.getCell(3 + prevParam + id, 11).alignment = alignmentCenter

      sheet.getCell(3 + prevParam + id, 12).value = param?.uom
      sheet.getCell(3 + prevParam + id, 12).border = border
      sheet.getCell(3 + prevParam + id, 12).alignment = alignmentCenter

      sheet.getCell(3 + prevParam + id, 13).value = param?.has_load ? "Yes" : "No"
      sheet.getCell(3 + prevParam + id, 13).border = border
      sheet.getCell(3 + prevParam + id, 13).alignment = alignmentCenter
    })

    prevTopParam += site?.parameters?.length - 1
    prevParam += site?.parameters?.length
  })

  sheet?.columns?.forEach((column) => {
    let maxLength = 0
    column?.eachCell?.({ includeEmpty: true }, (cell) => {
      const columnLength = cell?.value ? cell?.value?.toString()?.length : 10
      if (columnLength > maxLength) {
        maxLength = columnLength
      }
    })
    column.width = maxLength + 5
  })
  download(book, title)
}
