import { h, FunctionComponent } from "preact"
import { toFormattedPrice } from "../../utils/string"
import { Button } from "../../parts/buttons"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type HistoryModalProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
}

const HistoryModal: FunctionComponent<HistoryModalProps> = ({ data, show, onHide }) => {
  const { t } = useTranslation()
  const name = data?.responsible_person?.name || ""
  const email = data?.responsible_person?.email || "-"
  const confirmed = data?.payment_date ? `${name} (${email})` : "-"
  const recept = (
    <Button type="button" variant="primary" className="btn-sm" disabled={!data?.receipt} onClick={() => window.open(data?.receipt)}>
      {t("buttons.preview")}
    </Button>
  )

  const total = +data?.price + +data?.ppn

  const contents = [
    { label: t("label.invoice_number"), value: data?.invoice_number },
    { label: t("label.po_spk_number"), value: data?.po_number },
    { label: t("label.invoice_date"), value: data?.inv_date },
    { label: t("label.po_spk_date"), value: data?.po_date },
    { label: t("label.billing_date"), value: data?.billing_date },
    { label: t("label.period"), value: `${data?.start} ${t("label.until")} ${data?.end}` },
    { label: t("label.service_plan"), value: data?.service_plan },
    { label: t("label.price"), value: `Rp. ${toFormattedPrice(data?.price)}` },
    { label: "PPN", value: `Rp. ${toFormattedPrice(data?.ppn)}` },
    { label: t("label.total_price"), value: `Rp. ${toFormattedPrice(total)}` },
    { label: t("label.payment_date"), value: data?.payment_date },
    { label: t("label.confirmed_by"), value: confirmed },
    { label: t("label.payment_receipt"), value: recept },
  ]

  return (
    <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", zIndex: 999999 }}>
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content card">
          <div className="card-body">
            <p className="text-center fw-bolder text-primary mb-4">{t("History Invoice Detail")}</p>
            {contents.map(({ label, value }, index) => (
              <div key={index} className="row mb-2">
                <div className="col-4 d-flex justify-content-between align-items-start">
                  <div className={style.label}>{label}</div>
                  <div>:</div>
                </div>
                <div className="col-8">
                  <div className={style.value}>{value || "-"}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="card-body text-end pt-0">
            <Button className="mx-2" onClick={onHide} variant="tertiary">
              {t("buttons.close")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HistoryModal
