export default {
  title: "INTUSI",
  favicon: "/assets/favicon/intusi/favicon.ico",
  login: {
    title: "INTUSI!",
    carousel: [
      "/assets/img/carousel/intusi-1.png",
      "/assets/img/carousel/intusi-2.png",
      "/assets/img/carousel/intusi-3.png",
      "/assets/img/carousel/intusi-4.png",
      "/assets/img/carousel/intusi-5.png",
    ],
    brand: {
      name: "INTUSI",
      tagline: "BEYOND MONITORING",
      logo: "/assets/img/intusi/logo.png",
      alt_logo: "INTUSI",
      powered: "Powered by INTUSI",
    },
  },
  navbar: {
    toggle_logo: "/assets/img/intusi/logo.png",
    alt_toggle_logo: "INTUSI",
    brand: {
      name: "INTUSI",
      tagline: "BEYOND MONITORING",
      logo: "/assets/img/intusi/logo.png",
      alt_logo: "INTUSI",
      powered: "Powered by INTUSI",
    },
  },
  colors: {
    navbar: "#272929",
    primary: "#51bcda",
  },
  pdf: {
    logo: "/assets/img/mitramega.png",
    author: "INTUSI",
    creator: "INTUSI DEV",
  },
  mobile_app: {
    dev: "https://drive.google.com/file/d/1gf775K1iDIXLezh5UfF23FWN2iHzz6HF/view?usp=sharing",
    uat: "https://drive.google.com/file/d/1EkFVWoy7VIdc9WGCu_OH4aeXFXUb9xup/view?usp=sharing",
    prod: "https://drive.google.com/file/d/1w4GfLpGzcIAjeDf6za-LfuhGTETtmRhH/view?usp=sharing",
  },
  manuals: {
    user_manual: {
      // file_name: "User Manual Intusi.pdf",
      // file: "/assets/manuals/UserManualINTUSI.pdf",
      file_name: "",
      file: "",
    },
  },
}
