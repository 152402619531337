import { FunctionComponent, h } from "preact"
import Router, { Route, route } from "preact-router"
import { useEffect, useState } from "preact/hooks"
import useAuth from "../hooks/useAuth"
import useSharedState, { toggleNavbar, loggedinUser } from "../state"
import Spinner from "../components/spinner"
import { can } from "../utils/access"
import { Permission } from "../enum/permissions.enum"
import Navbar from "./navbar"
import Screen from "../parts/screen"
import { getLocalStorage, landingPage } from "../utils/local-storage"

import DashboardMap from "../routes/dashboard/map"
import DashboardMonitoring from "../routes/dashboard/monitoring"
import DashboardRealtime from "../routes/dashboard/realtime"
import DashboardParameterCompliance from "../routes/dashboard/parameter-compliance"
import RecordGraph from "../routes/record/graph"
import RecordSensorData from "../routes/record/sensor-data"
import RecordSensorDetail from "../routes/record/sensor-detail"
import RecordPercentageComplianceData from "../routes/record/percentage-compliance-data"
import RecordParameterAccumulatedLoad from "../routes/record/parameter-accumulated-load"
import ErrorLog from "../routes/error-log"
import DeviceSensorDelay from "../routes/device/sensor-delay"
import DeviceBatteryStatus from "../routes/device/battery-status"
import SettingsUser from "../routes/setting/user"
import SettingsCompany from "../routes/setting/company"
import SettingsModule from "../routes/setting/module"
import SettingsPlatform from "../routes/setting/platform"
import SettingsVendor from "../routes/setting/vendor"
import SettingsSiteData from "../routes/setting/site-data"
import SettingsSiteDataAdd from "../routes/setting/site-data/add"
import SettingsSiteDataEdit from "../routes/setting/site-data/edit"
import SettingsSiteDataDetail from "../routes/setting/site-data/detail"
import Subscription from "../routes/subscription"
import AccessFeature from "../routes/access/feature"
import AccessMenu from "../routes/access/menu"
import AccessPermission from "../routes/access/permission"
import AccessRole from "../routes/access/role"
import AccessRoleAdd from "../routes/access/role/add"
import AccessRoleEditPermission from "../routes/access/role/edit-permission"
import Regulation from "../routes/regulation"
import CalibrationReport from "../routes/calibration-report"
import CalibrationReportAdd from "../routes/calibration-report/add"
import CalibrationReportEdit from "../routes/calibration-report/edit"
import NcrKlhk from "../routes/ncr/klhk"
import NcrKlhkAdd from "../routes/ncr/klhk/add"
import NcrKlhkEdit from "../routes/ncr/klhk/edit"
import NcrList from "../routes/ncr/list"
import NcrListAdd from "../routes/ncr/list/add"
import NcrListEdit from "../routes/ncr/list/edit"
import NcrListDetail from "../routes/ncr/list/detail"
import Help from "../routes/help"
import HelpAdd from "../routes/help/add"
import HelpDetail from "../routes/help/detail"
import AnalyticMultiSite from "../routes/analytic/multi-site"
import AnalyticPeriod from "../routes/analytic/period"
import Finance from "../routes/finance"
import FinanceDetail from "../routes/finance/detail"
import FinanceHistory from "../routes/finance/history"
import Notfound from "../routes/notfound"
import ToolsDummyData from "../routes/tools/dummy-data"
import ToolsDummyDataAdd from "../routes/tools/dummy-data/add"
import ToolsDummyDataDetail from "../routes/tools/dummy-data/detail"
import MobileVersion from "../routes/tools/mobile-version"
import MobileApp from "../routes/mobile-app"
import useTheme from "../hooks/useTheme"
import { NotificationProvider } from "../context/useNotification"

type DashboardProps = h.JSX.HTMLAttributes<HTMLDivElement>

const Dashboard: FunctionComponent<DashboardProps> = () => {
  const { isLogin, logout } = useAuth()
  const [show, setShow] = useState(false)
  const [hideNavbar] = useSharedState(toggleNavbar, false)
  const [, setUserData] = useSharedState(loggedinUser, undefined)
  const { setTheme } = useTheme()

  useEffect(() => {
    const authPage = getLocalStorage(landingPage) || "/login"
    isLogin().then((data) => {
      if (data) {
        setUserData(data)
        return setShow(true)
      }
      logout()
      return route(authPage)
    })
  }, [isLogin])

  useEffect(() => {
    setTheme()
  }, [])

  if (!show) return <Spinner />
  return (
    <NotificationProvider>
      <Screen>
        <Navbar />
        <div
          className="w-100 position-relative"
          style={{ maxWidth: hideNavbar ? "calc(100vw - 60px)" : "calc(100vw - 260px)", transition: "max-width 0.3s" }}
        >
          <Router>
            {can(Permission.DashboardMapView) && <Route path="/dashboard/map" component={DashboardMap} />}
            {can(Permission.DashboardMonitoringView) && <Route path="/dashboard/monitoring" component={DashboardMonitoring} />}
            {can(Permission.DashboardRealtimeView) && <Route path="/dashboard/realtime" component={DashboardRealtime} />}
            {can(Permission.DashboardParameterComplianceView) && (
              <Route path="/dashboard/parameter-compliance" component={DashboardParameterCompliance} />
            )}
            {can(Permission.RecordGraphView) && <Route path="/record/graph" component={RecordGraph} />}
            {can(Permission.RecordSensorDataView) && <Route path="/record/sensor-data" component={RecordSensorData} />}
            {can(Permission.RecordSensorDetailView) && <Route path="/record/sensor-detail" component={RecordSensorDetail} />}
            {can(Permission.RecordPercentageComplianceDataView) && (
              <Route path="/record/percentage-compliance-data" component={RecordPercentageComplianceData} />
            )}
            {can(Permission.RecordParameterAccumulatedLoadView) && (
              <Route path="/record/parameter-accumulated-load" component={RecordParameterAccumulatedLoad} />
            )}
            {can(Permission.ErrorLogView) && <Route path="/error-log" component={ErrorLog} />}
            {can(Permission.DeviceSensorDelayView) && <Route path="/device/sensor-delay" component={DeviceSensorDelay} />}
            {can(Permission.DeviceBatteryStatusView) && <Route path="/device/battery-status" component={DeviceBatteryStatus} />}
            {can(Permission.SettingsUserView) && <Route path="/settings/user" component={SettingsUser} />}
            {can(Permission.SettingsPlatformView) && <Route path="/settings/platform" component={SettingsPlatform} />}
            {can(Permission.SettingsModuleView) && <Route path="/settings/module" component={SettingsModule} />}
            {can(Permission.SettingsVendorView) && <Route path="/settings/vendor" component={SettingsVendor} />}
            {can(Permission.SettingsCompanyView) && <Route path="/settings/company" component={SettingsCompany} />}
            {can(Permission.SettingsSiteDataView) && <Route path="/settings/site-data" component={SettingsSiteData} />}
            {can(Permission.SettingsSiteDataAdd) && <Route path="/settings/site-data/add" component={SettingsSiteDataAdd} />}
            {can(Permission.SettingsSiteDataDetail) && <Route path="/settings/site-data/detail/:uuid" component={SettingsSiteDataDetail} />}
            {can(Permission.SettingsSiteDataEdit) && <Route path="/settings/site-data/edit/:uuid" component={SettingsSiteDataEdit} />}
            {can(Permission.SubscriptionView) && <Route path="/subscription" component={Subscription} />}
            {can(Permission.AccessFeatureView) && <Route path="/access/feature" component={AccessFeature} />}
            {can(Permission.AccessMenuView) && <Route path="/access/menu" component={AccessMenu} />}
            {can(Permission.AccessPermissionView) && <Route path="/access/permission" component={AccessPermission} />}
            {can(Permission.AccessRoleView) && <Route path="/access/role" component={AccessRole} />}
            {can(Permission.AccessRoleAdd) && <Route path="/access/role/add" component={AccessRoleAdd} />}
            {can(Permission.AccessRoleEditPermission) && <Route path="/access/role/edit-permission/:uuid" component={AccessRoleEditPermission} />}
            {can(Permission.ToolsDummyDataView) && <Route path="/tools/dummy-data" component={ToolsDummyData} />}
            {can(Permission.ToolsMobileVersionView) && <Route path="/tools/mobile-version" component={MobileVersion} />}
            {can(Permission.ToolsDummyDataAdd) && <Route path="/tools/dummy-data/add" component={ToolsDummyDataAdd} />}
            {can(Permission.ToolsDummyDataDetail) && <Route path="/tools/dummy-data/detail/:uuid" component={ToolsDummyDataDetail} />}
            {can(Permission.RegulationView) && <Route path="/regulation" component={Regulation} />}
            {can(Permission.MaintenanceCalibrationReportView) && <Route path="/maintenance/calibration-report" component={CalibrationReport} />}
            {can(Permission.MaintenanceCalibrationReportAdd) && <Route path="/maintenance/calibration-report/add" component={CalibrationReportAdd} />}
            {can(Permission.MaintenanceCalibrationReportEdit) && (
              <Route path="/maintenance/calibration-report/edit/:id" component={CalibrationReportEdit} />
            )}
            {can(Permission.MaintenanceNcrView) && <Route path="/maintenance/ncr" component={NcrList} />}
            {can(Permission.MaintenanceNcrAdd) && <Route path="/maintenance/ncr/add" component={NcrListAdd} />}
            {can(Permission.MaintenanceNcrEdit) && <Route path="/maintenance/ncr/edit/:uuid" component={NcrListEdit} />}
            {can(Permission.MaintenanceNcrDetail) && <Route path="/maintenance/ncr/detail/:uuid" component={NcrListDetail} />}
            {can(Permission.MaintenanceNcrKlhkView) && <Route path="/maintenance/ncr-klhk" component={NcrKlhk} />}
            {can(Permission.MaintenanceNcrKlhkAdd) && <Route path="/maintenance/ncr-klhk/add" component={NcrKlhkAdd} />}
            {can(Permission.MaintenanceNcrKlhkEdit) && <Route path="/maintenance/ncr-klhk/edit/:uuid" component={NcrKlhkEdit} />}
            {can(Permission.HelpView) && <Route path="/help/support" component={Help} />}
            {can(Permission.HelpAdd) && <Route path="/help/support/add" component={HelpAdd} />}
            {can(Permission.HelpDetail) && <Route path="/help/support/detail/:uuid" component={HelpDetail} />}
            {can(Permission.AnalyticMultiSiteView) && <Route path="/analytic/multi-site" component={AnalyticMultiSite} />}
            {can(Permission.AnalyticPeriodView) && <Route path="/analytic/period" component={AnalyticPeriod} />}
            {can(Permission.FinanceView) && <Route path="/finance" component={Finance} />}
            {can(Permission.FinanceDetail) && <Route path="/finance/detail/:uuid" component={FinanceDetail} />}
            {can(Permission.FinanceHistory) && <Route path="/finance/history/:uuid" component={FinanceHistory} />}
            {can(Permission.MobileAppView) && <Route path="/mobile-app" component={MobileApp} />}
            <Notfound default />
          </Router>
        </div>
      </Screen>
    </NotificationProvider>
  )
}

export default Dashboard
