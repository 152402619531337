import { FunctionComponent, h } from "preact"

type CardIconProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  color: string
}

const CardIcon: FunctionComponent<CardIconProps> = ({ color = "#52BDDA" }) => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5 0C31.5456 0 40.5 8.9543 40.5 20C40.5 31.0456 31.5456 40 20.5 40C9.4543 40 0.5 31.0456 0.5 20C0.5 8.9543 9.4543 0 20.5 0ZM20.5 4C11.6634 4 4.5 11.1634 4.5 20C4.5 28.8366 11.6634 36 20.5 36C29.3366 36 36.5 28.8366 36.5 20C36.5 11.1634 29.3366 4 20.5 4ZM20.5 6C22.536 6 24.4704 6.43462 26.2158 7.21612L23.0908 10.3389C22.2644 10.1178 21.396 10 20.5 10C14.9772 10 10.5 14.4772 10.5 20C10.5 22.7614 11.6193 25.2614 13.4289 27.071L10.6005 29.8994L10.2889 29.5778C7.93914 27.0736 6.5 23.705 6.5 20C6.5 12.268 12.768 6 20.5 6ZM33.2848 14.2862C34.0658 16.031 34.5 17.9648 34.5 20C34.5 23.866 32.933 27.366 30.3994 29.8994L27.571 27.071C29.3808 25.2614 30.5 22.7614 30.5 20C30.5 19.104 30.3822 18.2356 30.1612 17.4092L33.2848 14.2862ZM28.9852 8.6863L31.8138 11.5147L24.365 18.966C24.453 19.2958 24.5 19.6424 24.5 20C24.5 22.2092 22.7092 24 20.5 24C18.2908 24 16.5 22.2092 16.5 20C16.5 17.7908 18.2908 16 20.5 16C20.8576 16 21.2042 16.047 21.534 16.135L28.9852 8.6863Z" fill={color} />
    </svg>
  )
}

export default CardIcon
