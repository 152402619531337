import useAuth from "./useAuth"
import useSWR from "swr"

export const usePlatform = (uuid = "") => {
  const { fetcher } = useAuth()
  const url = `/platform/${uuid}`
  const response = useSWR(url, (url) => fetcher().get(url).then(({ data }) => data.data))
  return response
}

