import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { Chart as ChartJS, ChartData, ChartOptions } from "chart.js"
import { Line as ChartLine } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"
import { chartDateWithPeriod, getDateDifference } from "../../utils/dateformat"

export type DatasetType = {
  data: number[]
  label?: string
  borderColor: string
  backgroundColor: string
  tension: number
}
type DataType = {
  dataset: DatasetType[]
  avg: number
  min: number
  max: number
  lower_threshold: number
  upper_threshold: number
}

type LineChartProps = {
  data: DataType[]
  labels: string[]
  height?: number | string
  showLegend?: boolean
  showAnnotation?: boolean
  period: string
  zoom: boolean
}
  
const AnalyticLineChart: FunctionComponent<LineChartProps> = ({ data, labels, height, showLegend = false, showAnnotation = true, period, zoom }) => {
  const dataset = data?.map(({ dataset }) => {
    return {
      ...dataset,
      data: [...dataset?.data]?.reverse() || []
    }
  }) || []
  const date = labels?.reverse() || []
  const isMoreThanOneDay = !!getDateDifference({ firstDate: date?.[0], secondDate: date?.at(-1) })
  const label = date?.map((item) => chartDateWithPeriod(item, period, isMoreThanOneDay))

  const lower = data?.[0]?.lower_threshold
  const upper = data?.[0]?.upper_threshold
  const bmal = data?.[0]?.avg
  const [showMin, setShowMin] = useState(true)
  const [showMax, setShowMax] = useState(true)
  const min = data?.length ? Math.min(...data?.map(({ dataset }) => dataset?.data)) : 0
  const max = data?.length ? Math.max(...data?.map(({ dataset }) => dataset?.data)) : 0

  const customizedAxesTicks = (event) => {
    const stepValue = Math.abs(event?.ticks?.[0]?.value - event?.ticks?.[1]?.value)
    const shouldShowLower = min - stepValue
    const shouldShowUpper = max + stepValue
    setShowMin(lower >= shouldShowLower)
    setShowMax(upper <= shouldShowUpper)
  }

  const annotation = {
    type: 'line',
    borderWidth: 3,
    drawTime: 'beforeDraw',
    label: {
      display: (ctx) => ctx.hovered,
      drawTime: 'afterDatasetsDraw',
      position: (ctx) => ctx.hoverPosition
    },
    enter(ctx, event) {
      ctx.hovered = true;
      ctx.hoverPosition = `${event.x / ctx.chart.chartArea.width * 100}%`;
      ctx.chart.update();
    },
    leave(ctx) {
      ctx.hovered = false;
      ctx.chart.update();
    }
  }
  const pluginAnnotation = {
    annotation: {
      annotations: {
        ...((showMin || !zoom) && {
          lower: {
            ...annotation,
            yMin: lower,
            yMax: lower,
            borderColor: '#8F989A',
            value: lower,
            label: {
              ...annotation.label,
              content: "Lower Threshold",
            },
          }
        }),
        ...((showMax ||  !zoom) && {
          upper: {
            ...annotation,
            yMin: upper,
            yMax: upper,
            borderColor: '#8F989A',
            value: upper,
            label: {
              ...annotation.label,
              content: "Upper Threshold",
            },
          }
        }),
        ...(bmal && {
          bmal: {
            ...annotation,
            yMin: bmal,
            yMax: bmal,
            borderColor: '#51BCDA',
            value: bmal,
            label: {
              ...annotation.label,
              content: "BMAL",
            },
          },
        })
      }
    }
  }
  const chartOptions: ChartOptions<"line"> = {
    plugins: {
      ...(showAnnotation && pluginAnnotation),
      legend: {
        display: showLegend,
        position: "bottom",
        align: "start"
      }
    },
    scales: {
      xAxis: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          maxRotation: 0,
          minRotation: 0
        }
      },
      yAxis: {
        afterBuildTicks: customizedAxesTicks,
      }
    }
  }
  const chartData: ChartData<"line", number[], string> = {
    labels: label,
    datasets: [...dataset]
  } 
  ChartJS.register(annotationPlugin)
  return (
    <ChartLine height={height} options={chartOptions} data={chartData} />
  )
}

export default AnalyticLineChart