import { useEffect, useState } from "preact/hooks"

const useParams = () => {
  const [params, setParams] = useState({})
  useEffect(() => {
    const url = window.location.href
    const queryParams = {}
    if (url) {
      const queryString = url.split("?")[1]

      if (queryString) {
        const pairs = queryString.split("&")
        pairs.forEach((pair) => {
          const [key, value] = pair.split("=")
          queryParams[key] = decodeURIComponent(value || "")
        })
      }
      setParams(queryParams)
    }
  }, [])

  const clearParams = () => {
    setParams({})
  }

  return {
    params,
    clearParams,
  }
}
export default useParams
