import { FunctionComponent, h } from "preact"
import { route } from "preact-router"
import moment from "moment"
import { HiChevronRight } from "react-icons/hi"
import Spinner from "../../../components/spinner"
import useErrorLog from "../../../hooks/useErrorLog"
import { useFilterContext } from "../../../context/useFilter"
import { formatDateWithPeriod } from "../../../utils/dateformat"
import { can } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import { toFixed } from "../../../utils/toFixed"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type RealtimeErrorLogProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  uuid: string
}

const RealtimeErrorLog: FunctionComponent<RealtimeErrorLogProps> = ({ uuid }) => {
  const { t } = useTranslation()
  const { setContextFilter } = useFilterContext()
  const period = "hour"
  const startDate = moment().startOf("day").format("X")
  const endDate = moment().startOf("hour").format("X")

  const { data } = useErrorLog({
    site: uuid,
    type: period,
    start: startDate,
    end: endDate,
    page: 1,
    limit: 30,
  })

  const alerts = data?.record

  const handleGoTo = () => {
    setContextFilter({ site: uuid, period, startDate, endDate })
    route("/error-log")
  }

  return (
    <div className="w-100 position-relative">
      <div className="d-flex justify-content-end">
        {can(Permission.ErrorLogView) && (
          <button className={style.menu_link_to_page} onClick={handleGoTo}>
            {t("page.realtime.See error log for more details")}
            <HiChevronRight />
          </button>
        )}
      </div>
      {data ? (
        <div className="overflow-auto">
          <table className="table table-data table-center">
            <thead>
              <tr>
                <th>No</th>
                <th>{t("table.time")}</th>
                <th>{t("table.activity")}</th>
                <th>{t("table.parameter")}</th>
                <th>{t("table.unit")}</th>
                <th>{t("table.error_value")}</th>
                <th>{t("table.lower_value")}</th>
                <th>{t("table.upper_value")}</th>
              </tr>
            </thead>
            <tbody>
              {alerts?.length ? (
                alerts?.map((alert, idx) => (
                  <tr key={`alert-${idx}`}>
                    <td>{idx + 1}</td>
                    <td className="text-nowrap">{formatDateWithPeriod(alert?.timestamp, period)}</td>
                    <td>{alert?.description}</td>
                    <td>{alert?.name}</td>
                    <td>{alert?.parameter?.uom}</td>
                    <td>{toFixed(alert?.value, 2)}</td>
                    <td>{alert?.parameter?.lower_threshold}</td>
                    <td>{alert?.parameter?.upper_threshold}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    {t("no_data")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default RealtimeErrorLog
