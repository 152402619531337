import { FunctionComponent, h } from "preact"
import { BsTrash } from "react-icons/bs"
import Container from "../../../parts/container"
import { route } from "preact-router"
import { Button } from "../../../parts/buttons"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import SelectSearch from "../../../components/form/select-search"
import useCompany from "../../../hooks/useCompany"
import useAuth from "../../../hooks/useAuth"
import useSites from "../../../hooks/useSite"
import useUsers from "../../../hooks/useUsers"
import { useRef, useState } from "preact/hooks"
import useObjectState from "../../../hooks/useObjectState"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import { defaultParameters as paramName } from "../../../utils/parameters"
import Spinner from "../../../components/spinner"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type AddSiteDataProps = h.JSX.HTMLAttributes<HTMLDivElement>
const defaultParam = {
  name: "",
  uom: "",
  lower_threshold: 0,
  upper_threshold: 0,
  type: "Compulsory",
  has_load: false,
  is_active: true,
}

const defaultBody = {
  name: "",
  coordinate: { lat: "", long: "" },
  area: "-",
  parameter: [
    { ...defaultParam, name: paramName[0] },
    { ...defaultParam, name: paramName[1] },
    { ...defaultParam, name: paramName[2] },
    { ...defaultParam, name: paramName[3] },
    { ...defaultParam, name: paramName[4] },
    { ...defaultParam, name: paramName[5] },
    { ...defaultParam, name: paramName[6] },
  ],
  uid_klhk: "",
  logo: "",
  users: [],
  pic_technical_name: "",
  pic_technical_email: "",
  pic_technical_phone: "",
  company: { uuid: "" },
  modules: [],
}

const AddSiteData: FunctionComponent<AddSiteDataProps> = () => {
  const { t } = useTranslation()
  const parent = `/settings/site-data`
  const onBack = () => route(parent)
  const { data: companies } = useCompany()
  const { fetcher } = useAuth()
  const { mutate } = useSites()
  const { data: users } = useUsers()
  const selectUser = useRef<HTMLDivElement>(null)
  const [avaliUser, setAvaliUser] = useState([])
  const [userValue, setUserValue] = useState("")
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [showAllCheckbox, setShowAllCheckbox] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [body, setBody] = useObjectState(defaultBody)
  const [disable, setDisable] = useState(false)
  const [modules, setModules] = useState([])
  const logoElement = useRef()

  const onModuleChange = (uuid) => {
    const modules = body?.modules
    setBody({ modules: [uuid] })
  }

  const isModuleChecked = (uuid) => body?.modules?.includes(uuid)

  const addParam = () => {
    const arr = [...body.parameter]
    arr.push(defaultParam)
    setBody({ parameter: arr })
  }

  const deleteParam = (idx: number) => {
    const arr = [...body.parameter]
    arr.splice(idx, 1)
    setBody({ parameter: arr })
  }

  const editParam = (
    idx: number,
    data: Partial<{
      uuid: string
      name: string
      uom: string
      lower_threshold: number
      upper_threshold: number
      type: string
      has_load: boolean
    }>
  ) => {
    const arr = [...body.parameter]
    arr[idx] = { ...arr[idx], ...data }
    setBody({ parameter: arr })
  }

  const handleCoordinate = (target, field) => {
    const value = target.value.replace(/,/g, ".")
    setBody({
      coordinate: { ...body.coordinate, [field]: value },
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    const { parameter } = body
    const newParams = parameter?.map((item) => ({ ...item, name: item?.name?.trim() }))
    const newBody = { ...body, parameter: newParams }
    try {
      const image = new FormData()
      image.append("file", logoElement.current.files[0])
      const header = { headers: { "Content-Type": "multipart/form-data" } }
      const { data: img } = await fetcher().post("/site/file", image, header)
      await fetcher().post("/site", { ...newBody, logo: img?.data?.path })
      await mutate()
      await onBack()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  const getUserByCompany = (id) => users?.filter(({ company }) => company?.uuid === id)

  const onSiteInputFocus = () => {
    setShowCheckbox(true)
    const filteredUser = getUserByCompany(body?.company?.uuid)
    setAvaliUser(filteredUser)
    setShowAllCheckbox(filteredUser?.length > 1)
  }

  const onSiteInputChange = (value) => {
    if (value) {
      setShowAllCheckbox(false)
      setUserValue(value)
      const lowerValue = value.toLowerCase()
      const result = avaliUser?.filter(({ name, email }) => {
        const lowerName = name.toLocaleLowerCase()
        const lowerEmail = email.toLocaleLowerCase()
        return lowerName.includes(lowerValue) || lowerEmail.includes(lowerValue)
      })
      setAvaliUser(result)
    } else {
      setUserValue("")
      const filteredUser = getUserByCompany(body?.company?.uuid)
      setAvaliUser(filteredUser)
      setShowAllCheckbox(filteredUser?.length > 1)
    }
  }

  const checkUser = (uuid) => {
    const filteredUser = getUserByCompany(body?.company?.uuid)
    setCheckAll(false)
    setUserValue("")
    setAvaliUser(filteredUser)
    setShowAllCheckbox(filteredUser?.length > 1)
    const isChecked = body?.users?.find((item) => item === uuid)
    if (isChecked) {
      const prevUsers = body?.users?.filter((item) => item !== uuid)
      setBody({ users: prevUsers })
    } else {
      setBody({ users: [...body?.users, uuid] })
    }
  }

  const checkAllUser = (data) => {
    const allUuid = data?.map(({ uuid }) => uuid)

    setCheckAll((prevState) => {
      !prevState ? setBody({ users: allUuid }) : setBody({ users: [] })
      return !prevState
    })
  }

  const onCompanyChange = (id) => {
    setBody({ company: { uuid: id }, users: [], modules: [] })
    const filteredUser = users?.filter(({ company }) => company?.uuid === id)
    const modules = companies?.find(({ uuid }) => uuid === id)?.modules
    setAvaliUser(filteredUser)
    setModules(modules)
    setCheckAll(false)
  }

  useOnClickOutside(selectUser, () => setShowCheckbox(false))

  return (
    <div>
      <TopBar>
        <Breadcrumb name={t("breadcrumb.settings")} link="" />
        <Breadcrumb name={t("breadcrumb.site_data")} link="/settings/site-data" />
        <Breadcrumb name={t("breadcrumb.add_site_data")} link="/settings/site-data/add" />
      </TopBar>
      <Spinner show={!(Array.isArray(modules) && Array.isArray(companies) && Array.isArray(users))} />
      <Container>
        <div className="card">
          <div className="card-body">
            <p className={style.title}>{t("add_new_site_data")}</p>
            <form onSubmit={(event) => handleSubmit(event)} className="mt-4">
              <div className="mb-4">
                <label className="form-label">{t("company_name")}</label>
                <SelectSearch
                  data={companies}
                  defaultValue={body?.company?.uuid}
                  placeholder={t("placeholder.search_company")}
                  required
                  onChange={onCompanyChange}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">{t("site_name")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  required
                  value={body.name}
                  onChange={({ currentTarget: e }) => setBody({ name: e.value })}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">{t("label.modules")}</label>
                <div className="mb-4">
                  <SelectSearch
                    data={modules}
                    defaultValue={body?.modules[0]}
                    placeholder={t("placeholder.search_module")}
                    required
                    onChange={(uuid) => {
                      onModuleChange(uuid)
                    }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label">UUID KLHK</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  required
                  value={body?.uid_klhk}
                  onChange={({ currentTarget: e }) => setBody({ uid_klhk: e.value })}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">{t("label.latitude")}</label>
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      required
                      value={body.coordinate.lat}
                      onChange={({ currentTarget }) => handleCoordinate(currentTarget, "lat")}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">{t("label.longitude")}</label>
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      required
                      value={body.coordinate.long}
                      onChange={({ currentTarget }) => handleCoordinate(currentTarget, "long")}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label">{t("label.contact_person_name")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  value={body.pic_technical_name}
                  onChange={({ currentTarget: e }) => setBody({ pic_technical_name: e.value })}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">{t("label.contact_person_email")}</label>
                <input
                  type="email"
                  className="form-control rounded mw-100"
                  value={body.pic_technical_email}
                  onChange={({ currentTarget: e }) => setBody({ pic_technical_email: e.value })}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">{t("label.contact_person_phone")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  value={body.pic_technical_phone}
                  onChange={({ currentTarget: e }) => setBody({ pic_technical_phone: e.value })}
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label">{t("label.users")}</label>
                <div ref={selectUser} className="position-relative w-100">
                  <input
                    type="text"
                    className="rounded form-control mw-100"
                    onFocus={() => onSiteInputFocus()}
                    onChange={({ currentTarget: t }) => onSiteInputChange(t.value)}
                    value={userValue}
                    placeholder={body?.users?.length ? `${body?.users?.length} ${t("placeholder.user_selected")}` : t("placeholder.select_user")}
                  />
                  <div className={style.multiselect} style={{ display: showCheckbox ? "inherit" : "none" }}>
                    {showAllCheckbox && (
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="all" checked={checkAll} onChange={() => checkAllUser(avaliUser)} />
                        <label className="form-check-label" for="all">
                          {t("label.all_user")}
                        </label>
                      </div>
                    )}
                    {avaliUser?.length ? (
                      avaliUser.map(({ name, email, uuid }) => (
                        <div key={uuid} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={uuid}
                            value={uuid}
                            checked={body?.users?.includes(uuid)}
                            onChange={() => checkUser(uuid)}
                          />
                          <label className="form-check-label text-nowrap" for={uuid}>
                            {name} - {email}
                          </label>
                        </div>
                      ))
                    ) : (
                      <label className="form-check-label text-nowrap">{userValue ? t("no_user_with_this_keyword") : t("no_user_available")}</label>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label className="form-label">Logo</label>
                <input ref={logoElement} className="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" required />
              </div>
              <hr />
              <div className="mb-4">
                <div className={style.param_title}>{t("label.parameters")}</div>
                <div className={style.param_label}>
                  {t("label.as_regulated_by")} <strong>{t("label.regulation_reference")}</strong>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col form-label">{t("label.parameter_name")}</div>
                <div className="col form-label">{t("lower_threshold")}</div>
                <div className="col form-label">{t("upper_threshold")}</div>
                <div className="col form-label">{t("label.unit")}</div>
                <div className="col form-label">{t("has_load")}</div>
              </div>
              {body.parameter.map((param, idx) => (
                <div className="row mb-2" key={idx}>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      required
                      value={param.name}
                      onChange={({ currentTarget: e }) => editParam(idx, { name: e.value })}
                    />
                  </div>
                  <div className="col">
                    <input
                      className="form-control rounded"
                      required
                      type="number"
                      step={0.0000001}
                      onChange={({ currentTarget: e }) => editParam(idx, { lower_threshold: e.value })}
                      value={param.lower_threshold}
                    />
                  </div>
                  <div className="col">
                    <input
                      className="form-control rounded"
                      required
                      type="number"
                      step={0.0000001}
                      onChange={({ currentTarget: e }) => editParam(idx, { upper_threshold: e.value })}
                      value={param.upper_threshold}
                    />
                  </div>
                  <div className="col">
                    <input
                      className="form-control rounded"
                      required={param.is_active}
                      disabled={!param.is_active}
                      type="text"
                      onChange={({ currentTarget: e }) => editParam(idx, { uom: e.value })}
                      value={param.uom}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <div className="form-check d-flex align-items-center h-100">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled={!param.is_active}
                        id={`has-load-${param.name}`}
                        onChange={() => editParam(idx, { has_load: !param.has_load })}
                        checked={param.has_load}
                      />
                      <label className="form-check-label ms-2" for={`has-load-${param.name}`}>
                        {param.has_load ? t("yes") : t("no")}
                      </label>
                    </div>
                    {body?.parameter?.length > 1 && (
                      <Button
                        variant="tertiary"
                        title={t("buttons.delete")}
                        type="button"
                        onClick={() => deleteParam(idx)}
                        className="ms-2"
                        icon={BsTrash}
                      />
                    )}
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-end">
                <Button type="button" variant="primary" onClick={() => addParam()}>
                  {t("buttons.add_new_parameter")}
                </Button>
              </div>
              <hr />
              <div className="d-flex justify-content-end">
                <Button type="submit" variant="primary" className="m-1" disabled={disable}>
                  {t("buttons.save")}
                </Button>
                <Button type="button" variant="tertiary" onClick={onBack} className="m-1" disabled={disable}>
                  {t("buttons.cancel")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AddSiteData
