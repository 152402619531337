import { FunctionComponent, h, Fragment } from "preact"
import { useState } from "preact/hooks"
import Flatpickr from "react-flatpickr"
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate"
import moment from "moment"
import { BsTrash } from "react-icons/bs"
import { BiPlus, BiMinus } from "react-icons/bi"
import Container from "../../../parts/container"
import { route } from "preact-router"
import { Button } from "../../../parts/buttons"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import useObjectState from "../../../hooks/useObjectState"
import useSites from "../../../hooks/useSite"
import useCompany from "../../../hooks/useCompany"
import useAuth from "../../../hooks/useAuth"
import { useDummyPayloadPreset, useDummyConnectionPreset } from "../../../hooks/useDummyData"
import { generateUniqueId } from "../../../utils/id-generator"
import PayloadForm from "./payload"
import "flatpickr/dist/flatpickr.css"
import "flatpickr/dist/plugins/confirmDate/confirmDate.css"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type AddDummyDataProps = h.JSX.HTMLAttributes<HTMLDivElement>

const flatpickrOptions = {
  dateFormat: "Y/m/d H:i:S",
  enableTime: true,
  enableSeconds: true,
  defaultHour: 0,
  time_24hr: true,
  plugins: [
    new confirmDatePlugin({
      showAlways: true,
      confirmIcon: "",
    }),
  ],
}

const defaultField = {
  id: generateUniqueId(),
  name: "",
  type: "",
  min: "",
  max: "",
  status: true,
}

const defaultPayload = {
  ...defaultField,
  custom: [],
}

const defaultRest = { post_url: "", get_url: "" }
const defaultMqtt = { mqtt_host: "", mqtt_port: "", mqtt_username: "", mqtt_password: "", mqtt_topic: "" }

const AddDummyData: FunctionComponent<AddDummyDataProps> = () => {
  const { t } = useTranslation()
  const { data: mils } = useCompany()
  const { data: cems } = useSites()
  const { data: payloadPresets } = useDummyPayloadPreset()
  const { data: connectionPresets } = useDummyConnectionPreset()
  const { fetcher } = useAuth()
  const parent = `/tools/dummy-data`
  const onBack = () => route(parent)
  const [selectedPreset, setSelectedPreset] = useState("")
  const [presetName, setPresetName] = useState("")
  const [isNewPayloadPreset, setIsNewPayloadPreset] = useState(false)
  const [body, setBody] = useObjectState({})
  const [parameters, setParameters] = useState([defaultPayload])
  const [devices, setDevices] = useState([defaultPayload])
  const [protocol, setProtocol] = useState("")
  const [logger, setLogger] = useObjectState({})
  const [disable, setDisable] = useState(false)
  const [sites, setSites] = useState([])

  const addPayload = (onSet) => {
    const newPayload = { ...defaultPayload, id: generateUniqueId() }
    onSet((prevState) => [...prevState, newPayload])
  }

  const deletePayload = (id, onSet) => {
    onSet((prevFields) => prevFields.filter((field) => field?.id !== id))
  }

  const editPayload = (oldData, editData, onSet) => {
    onSet((fields) => fields?.map((field) => (field?.id === oldData?.id ? { ...field, ...editData } : field)))
  }

  const addCustomField = (parent, onSet) => {
    const newPayload = { ...defaultField, id: generateUniqueId() }
    onSet((prevState) => {
      return prevState?.map((field) => {
        if (field?.id === parent) {
          return { ...field, custom: [...field?.custom, newPayload] }
        }
        return field
      })
    })
  }

  const deleteCustomField = (oldData, fieldData, onSet) => {
    onSet((prevFields) =>
      prevFields.map((field) => {
        if (field?.id === oldData?.id) {
          const updatedCustom = field?.custom.filter((customField) => {
            return customField?.id !== fieldData?.id
          })
          return { ...field, custom: updatedCustom }
        }
        return field
      })
    )
  }

  const editCustomField = (oldData, fieldData, editData, onSet) => {
    onSet((prevFields) =>
      prevFields.map((field) => {
        if (field?.id === oldData?.id) {
          const updatedCustom = field?.custom?.map((custom) => {
            if (custom?.id === fieldData?.id) {
              return { ...custom, ...editData }
            }
            return custom
          })
          return { ...field, custom: updatedCustom }
        }
        return field
      })
    )
  }

  const onIntervalChange = (event) => {
    const value = event?.currentTarget?.value
    const interval = value?.replace(/[^0-9]+/g, "")
    setBody({ interval })
  }

  const onIntervalButtonClick = (action = "plus") => {
    const currentInterval = isNaN(body?.interval) ? 0 : body.interval
    if (action === "plus") {
      setBody({ interval: currentInterval + 1 })
    } else if (action === "minus" && currentInterval > 0) {
      setBody({ interval: currentInterval - 1 })
    }
  }

  const onMilChange = (event) => {
    const milUuid = event.currentTarget.value
    setLogger({ company: milUuid, site: "" })
    const filteredCem = cems?.filter(({ company }) => company?.uuid === milUuid)
    setSites(filteredCem)
    setBody({ logger_id: "" })
  }

  const onCemChange = (event) => {
    const cemUuid = event.currentTarget.value
    const siteName = cems?.find(({ uuid }) => uuid === cemUuid)?.name
    const name = `${siteName} - ${generateUniqueId()}`
    setLogger({ site: cemUuid })
    setBody({ logger_id: cemUuid, name })
  }

  const onConnectionPresetChange = (event) => {
    const connectionPresetUuid = event?.currentTarget?.value
    setBody({ ...defaultMqtt, ...defaultRest, connection_type: "" })
    if (connectionPresetUuid) {
      const preset = connectionPresets?.find(({ uuid }) => uuid === connectionPresetUuid)
      setProtocol(preset?.name)
      setBody({ connection_type: connectionPresetUuid, ...preset?.config })
    }
  }

  const onPayloadPresetChange = (event) => {
    const payloadPresetUuid = event?.currentTarget?.value
    if (payloadPresetUuid && payloadPresetUuid !== "new") {
      setParameters([])
      setDevices([])
      const preset = payloadPresets?.find(({ uuid }) => uuid === payloadPresetUuid)
      const payloadPreset = preset?.structure_sensor?.map((payload) => {
        const { name, status, value, ...restPayload } = payload
        const custom = Object.entries(restPayload).map(([name, { value, type }]) => ({
          name,
          min: value?.min,
          max: value?.max,
          type,
          id: generateUniqueId(),
        }))
        return {
          name: name?.value,
          type: value?.type,
          status: status?.value,
          min: value?.value?.min,
          max: value?.value?.max,
          id: generateUniqueId(),
          custom,
        }
      })
      const devicePreset = preset?.structure_device?.map((device) => {
        const { name, status, value } = device
        return { name: name?.value, type: value?.type, status: status?.value, min: value?.value?.min, max: value?.value?.max, id: generateUniqueId() }
      })
      setParameters(payloadPreset)
      setDevices(devicePreset)
    } else {
      setParameters([defaultPayload])
      setDevices([defaultPayload])
    }
    setIsNewPayloadPreset(payloadPresetUuid === "new")
    setSelectedPreset(payloadPresetUuid)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    const parameter = parameters.map(({ name, type, min, max, status, custom }) => {
      const newName = { name: { value: name, type: "String" } }
      const newStatus = { status: { value: status, type: "Boolean" } }
      const result = { ...newName, ...newStatus, value: { type, value: { min, max } } }
      custom.forEach(({ name: customName, type: customType, min: customMin, max: customMax }) => {
        result[customName] = { type: customType, value: { min: customMin, max: customMax } }
      })
      return result
    })
    const device = devices.map(({ name, type, min, max, status }) => {
      const newName = { name: { value: name, type: "String" } }
      const newStatus = { status: { value: status, type: "Boolean" } }
      const result = { ...newName, ...newStatus, value: { type, value: { min, max } } }
      return result
    })
    const newBody = { ...body, payload: parameter, payload_device: device }
    const cleanObject = Object.fromEntries(Object.entries(newBody).filter(([, value]) => value !== "" && value !== null && value !== undefined))
    const cleanBody = { ...cleanObject }
    for (const key in cleanBody) {
      if (Array.isArray(cleanBody[key]) && cleanBody[key].length === 0) {
        delete cleanBody[key]
      }
    }

    try {
      await fetcher().post("/dummy-data", cleanBody)
      if (isNewPayloadPreset) {
        const presetData = {
          name: presetName,
          // default_parameter: parameters?.map(({ name }) => name),
          structure_sensor: parameter,
          structure_device: device,
        }
        await fetcher().post("/dummy-data/preset", presetData)
      }
      await onBack()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  const startDate = {
    ...flatpickrOptions,
    defaultDate: moment().startOf("day").toISOString(),
    onReady([selectedDates]) {
      const start = moment(selectedDates).format("YYYY-MM-DD HH:mm:ss")
      setBody({ start })
    },
    onChange([selectedDates]) {
      const start = moment(selectedDates).format("YYYY-MM-DD HH:mm:ss")
      setBody({ start })
    },
  }
  const endDate = {
    ...flatpickrOptions,
    defaultDate: moment().add(1, "day").startOf("day").toISOString(),
    onReady([selectedDates]) {
      const end = moment(selectedDates).format("YYYY-MM-DD HH:mm:ss")
      setBody({ end })
    },
    onChange([selectedDates]) {
      const end = moment(selectedDates).format("YYYY-MM-DD HH:mm:ss")
      setBody({ end })
    },
  }

  return (
    <div>
      <TopBar>
        <Breadcrumb name={t("breadcrumb.tools")} link="" />
        <Breadcrumb name={t("breadcrumb.dummy_data")} link="/tools/dummy-data" />
        <Breadcrumb name={t("breadcrumb.add_dummy_data")} link="/tools/dummy-data/add" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <h1 className={style.page_title}>{t("Add New Dummy Data")}</h1>
            <form onSubmit={(event) => handleSubmit(event)}>
              {/* <div className="row mb-4">
                <label className="form-label col-2">Connection Preset</label>
                <div className="col-10">
                  <select
                    required
                    onChange={onConnectionPresetChange}
                    className="form-control rounded mw-100">
                    <option value="">No need preset</option>
                    <option value="new">Create new preset</option>
                    {connectionPresets?.map((connection) => (
                      <option value={connection?.uuid}  key={connection?.uuid}>{connection?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              {isNewConectionPreset &&
                <div className="row mb-4">
                  <label className="form-label col-2">Connection Preset Name</label>
                  <div className="col-10">
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      required
                      value={presetName?.connection}
                      onChange={({ currentTarget: e }) => setPresetName(e.value)}
                    />
                  </div>
                </div>
              } */}
              <div className="row mb-4">
                <label className="form-label col-2">{t("label.protocol")}</label>
                <div className="col-10">
                  <select required value={body?.connection_type} className="form-control rounded mw-100" onChange={onConnectionPresetChange}>
                    <option value="">{t("label.select_protocol")}</option>
                    {connectionPresets?.map((connection) => (
                      <option value={connection?.uuid} key={connection?.uuid}>
                        {connection?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {protocol === "MQTT" && (
                <Fragment>
                  <div className="row mb-4">
                    <label className="form-label col-2">{t("label.host")}</label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control rounded mw-100"
                        required
                        value={body?.mqtt_host}
                        onChange={({ currentTarget: e }) => setBody({ mqtt_host: e.value })}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="form-label col-2">{t("label.port")}</label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control rounded mw-100"
                        required
                        value={body?.mqtt_port}
                        onChange={({ currentTarget: e }) => setBody({ mqtt_port: e.value })}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="form-label col-2">{t("label.username")}</label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control rounded mw-100"
                        required
                        value={body?.mqtt_username}
                        onChange={({ currentTarget: e }) => setBody({ mqtt_username: e.value })}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="form-label col-2">{t("label.password")}</label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control rounded mw-100"
                        required
                        value={body?.mqtt_password}
                        onChange={({ currentTarget: e }) => setBody({ mqtt_password: e.value })}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="form-label col-2">Topic/Module</label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control rounded mw-100"
                        required
                        value={body?.mqtt_topic}
                        onChange={({ currentTarget: e }) => setBody({ mqtt_topic: e.value })}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
              {protocol === "REST" && (
                <Fragment>
                  <div className="row mb-4">
                    <label className="form-label col-2">POST URL</label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control rounded mw-100"
                        required
                        value={body?.post_url}
                        onChange={({ currentTarget: e }) => setBody({ post_url: e.value })}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="form-label col-2">GET URL</label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control rounded mw-100"
                        required
                        value={body?.get_url}
                        onChange={({ currentTarget: e }) => setBody({ get_url: e.value })}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
              <div className="row mb-4">
                <label className="form-label col-2">{t("company")}</label>
                <div className="col-10">
                  <select className="rounded form-control mw-100" value={logger?.company} required onChange={onMilChange}>
                    <option value="">{t("label.select_company")}</option>
                    {mils?.map(({ uuid, name }) => (
                      <option key={uuid} value={uuid}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <label className="form-label col-2">{t("site")}</label>
                <div className="col-10">
                  <select className="rounded form-control mw-100" value={logger?.site} required onChange={onCemChange}>
                    <option value="">{t("label.select_site")}</option>
                    {sites?.map(({ uuid, name }) => (
                      <option key={uuid} value={uuid}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <label className="form-label col-2">Logger UUID</label>
                <div className="col-10">
                  <input
                    type="text"
                    className="form-control rounded mw-100"
                    readOnly
                    value={body?.logger_id}
                    onChange={({ currentTarget: e }) => setBody({ logger_id: e.value })}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label className="form-label col-2">{t("label.delivery_interval")}</label>
                <div className="col-10 d-flex gap-2 align-items-center">
                  <Button type="button" variant="primary" className="px-1" icon={BiMinus} onClick={() => onIntervalButtonClick("minus")} />
                  <div className={style.interval_input}>
                    <input
                      type="text"
                      className="form-control rounded text-center px-2"
                      required
                      value={body?.interval}
                      onChange={onIntervalChange}
                    />
                  </div>
                  <Button type="button" variant="primary" className="px-1" icon={BiPlus} onClick={() => onIntervalButtonClick("plus")} />
                  <div>{t("label.in_seconds")}</div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="form-label col-2">{t("label.start_date")}</label>
                <div className="col-10">
                  <Flatpickr options={startDate} className="form-control rounded mw-100" />
                </div>
              </div>
              <div className="row mb-4">
                <label className="form-label col-2">{t("label.end_date")}</label>
                <div className="col-10">
                  <Flatpickr options={endDate} className="form-control rounded mw-100" />
                </div>
              </div>
              <div className="row mb-4">
                <label className="form-label col-2">{t("label.payload_preset")}</label>
                <div className="col-10">
                  <select value={selectedPreset} onChange={onPayloadPresetChange} className="form-control rounded mw-100">
                    <option value="">Select preset</option>
                    <option value="new">Create new preset</option>
                    {payloadPresets?.length &&
                      payloadPresets?.map((preset) => (
                        <option key={preset?.uuid} value={preset?.uuid}>
                          {preset?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {isNewPayloadPreset && (
                <div className="row mb-4">
                  <label className="form-label col-2">{t("label.payload_preset_name")}</label>
                  <div className="col-10">
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      required
                      value={presetName}
                      onChange={({ currentTarget: e }) => setPresetName(e.value)}
                    />
                  </div>
                </div>
              )}
              <div className="row mb-4">
                <label className="form-label col-2">{t("label.description")}</label>
                <div className="col-10">
                  <textarea
                    rows="3"
                    className="form-control rounded mw-100"
                    required
                    onChange={({ currentTarget: e }) => setBody({ description: e.value })}
                  >
                    {body?.description}
                  </textarea>
                </div>
              </div>
              <hr />
              <div className="fw-bold mb-2">{t("label.dummy_data_payload")}</div>
              <details open className={style.details}>
                <summary className={style.summary}>{t("label.parameter_payload")}</summary>
                {parameters?.map((parameter, index) => (
                  <div className="border border-primary rounded px-3 py-3 mt-2" key={parameter?.id}>
                    <div className="d-flex">
                      <div className={style.payload_title}>Payload {index + 1}</div>
                      <div className="ms-3">
                        {parameters?.length > 1 && (
                          <Button
                            type="button"
                            variant="tertiary"
                            className="btn-sm px-2 py-1"
                            icon={BsTrash}
                            onClick={() => deletePayload(parameter?.id, setParameters)}
                          >
                            {t("buttons.delete")}
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-3">
                        <div className={style.payload_label}>{t("label.name")}</div>
                      </div>
                      <div className="col-3">
                        <div className={style.payload_label}>{t("label.type")}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.payload_label}>{t("min")}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.payload_label}>{t("max")}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.payload_label}>{t("label.status")}</div>
                      </div>
                    </div>
                    <PayloadForm data={parameter} onDataChange={(editData) => editPayload(parameter, editData, setParameters)} />
                    {!!parameter?.custom?.length &&
                      parameter?.custom?.map((field) => (
                        <PayloadForm
                          className="w-100"
                          data={field}
                          key={field?.id}
                          showDelete
                          onDataChange={(editData) => editCustomField(parameter, field, editData, setParameters)}
                          onDelete={() => deleteCustomField(parameter, field, setParameters)}
                        />
                      ))}
                    <div className="d-flex justify-content-end mt-3">
                      <Button type="button" className="btn-sm" onClick={() => addCustomField(parameter?.id, setParameters)} variant="lines">
                        {t("Add Custom Field")}
                      </Button>
                    </div>
                  </div>
                ))}
                <div className="d-flex justify-content-end mt-3">
                  <Button type="button" variant="primary" onClick={() => addPayload(setParameters)}>
                    {t("buttons.add_payload")}
                  </Button>
                </div>
              </details>
              <details open={!!devices?.length} className={style.details}>
                <summary className={style.summary}>{t("label.device_payload")}</summary>
                {devices?.map((device, index) => (
                  <div className="border border-primary rounded px-3 py-3 mt-2" key={device?.id}>
                    <div className="d-flex">
                      <div className={style.payload_title}>Payload {index + 1}</div>
                      <div className="ms-3">
                        <Button
                          type="button"
                          variant="tertiary"
                          className="btn-sm px-2 py-1"
                          icon={BsTrash}
                          onClick={() => deletePayload(device?.id, setDevices)}
                        >
                          {t("buttons.delete")}
                        </Button>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-3">
                        <div className={style.payload_label}>{t("label.name")}</div>
                      </div>
                      <div className="col-3">
                        <div className={style.payload_label}>{t("label.type")}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.payload_label}>{t("label.min")}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.payload_label}>{t("label.max")}</div>
                      </div>
                      <div className="col-2">
                        <div className={style.payload_label}>{t("label.status")}</div>
                      </div>
                    </div>
                    <PayloadForm data={device} onDataChange={(editData) => editPayload(device, editData, setDevices)} />
                  </div>
                ))}
                <div className="d-flex justify-content-end mt-3">
                  <Button type="button" variant="primary" onClick={() => addPayload(setDevices)}>
                    {t("buttons.add_payload")}
                  </Button>
                </div>
              </details>
              <hr />
              <div className="d-flex justify-content-end mt-3 gap-4">
                <Button type="button" variant="tertiary" onClick={onBack} disabled={disable}>
                  {t("buttons.cancel")}
                </Button>
                <Button type="submit" variant="primary" disabled={disable}>
                  {t("buttons.save")} {isNewPayloadPreset && "payload & preset"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AddDummyData
