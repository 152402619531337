import { FunctionComponent, h } from "preact"
import moment from "moment"
import Flatpickr from "react-flatpickr"
import monthSelect from "flatpickr/dist/plugins/monthSelect"
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate"
import "flatpickr/dist/flatpickr.css"
import "flatpickr/dist/plugins/monthSelect/style.css"
import "flatpickr/dist/plugins/confirmDate/confirmDate.css"
import { useTranslation } from "react-i18next"

type DatePickerProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  date: string
  type?: string
  setDate: void
}

const flatpickrOptions = {
  dateFormat: "U",
  altInput: true,
  altFormat: "d/m/Y",
}

export const MonthlyDatePicker: FunctionComponent<DatePickerProps> = ({ date, type = "start", setDate }) => {
  const { t } = useTranslation()
  const options = {
    ...flatpickrOptions,
    plugins: [new monthSelect({ dateFormat: "U" })],
  }

  const onDateChange = (date) => {
    const newDate = new Date(date[0])
    const timestamp = type === "start" ? moment(newDate).startOf("month").format("X") : moment(newDate).endOf("month").format("X")
    setDate(timestamp)
  }

  return (
    <Flatpickr
      options={options}
      placeholder={t("placeholder.select_date")}
      value={date}
      onChange={onDateChange}
      className="w-100 form-control rounded border"
    />
  )
}

export const DailyDatePicker: FunctionComponent<DatePickerProps> = ({ date, type = "start", setDate }) => {
  const { t } = useTranslation()
  const options = { ...flatpickrOptions }

  const onDateChange = (date) => {
    const newDate = new Date(date[0])
    const timestamp = type === "start" ? moment(newDate).startOf("day").format("X") : moment(newDate).endOf("day").format("X")
    setDate(timestamp)
  }

  return (
    <Flatpickr
      options={options}
      placeholder={t("placeholder.select_date")}
      value={date}
      onChange={onDateChange}
      className="w-100 form-control rounded border"
    />
  )
}

export const HourlyDatePicker: FunctionComponent<DatePickerProps> = ({ date, setDate }) => {
  const { t } = useTranslation()
  const options = {
    ...flatpickrOptions,
    altFormat: "d/m/Y H:i",
    enableTime: true,
    time_24hr: true,
    defaultHour: 0,
    plugins: [
      new confirmDatePlugin({
        showAlways: true,
        confirmIcon: "",
      }),
    ],
  }

  const onDateChange = (date) => {
    const newDate = new Date(date[0])
    const timestamp = moment(newDate).format("X")
    setDate(timestamp)
  }

  return (
    <Flatpickr
      options={options}
      placeholder={t("placeholder.select_date")}
      value={date}
      onChange={onDateChange}
      className="w-100 form-control rounded border"
    />
  )
}
