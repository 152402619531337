import { FunctionComponent, h } from "preact"
import LoginForm from "../login/login-form"
import { setLocalStorage, landingPage } from "../../utils/local-storage"
import style from "./style.scss"

type InnovasindoProps = h.JSX.HTMLAttributes<HTMLDivElement>

const Innovasindo: FunctionComponent<InnovasindoProps> = () => {
  const { pathname } = window.location
  setLocalStorage(landingPage, pathname)

  return (
    <div className={style.bg_innovasindo}>
      <div className={style.container}>
        <div className={style.form}>
          <div className={style.logo}>
            <img src="/assets/img/logo-innovasindo.png" alt="innovasindo" />
          </div>
          <div className={style.login_card}>
            <div className={style.login_title}>
              <div>Welcome to <p className={style.innovasindo}>innovasindo!</p></div>
              <div className={style.desc}>Please sign in to access admin panel</div>
            </div>
            <LoginForm buttonClass={style.innovasindo_login} forgotClass={style.innovasindo_forgot_password} />
          </div>
          <div className={style.poweredBy}>
              <p>Powered By</p>
              <img src="/assets/img/logo-intusi.png" alt="intusi" />
            </div>
        </div>
        <div className={style.illustrate}>
          <img className={style.image} src="/assets/img/bg-innovasindo.png" alt="innovasindo" />
          <div className={style.desc_text}>
            <p>Kami menawarkan sebuah sistem pemantauan secara</p>
            <p><span className={style.innovasindo_polygon}>otomatis, terus menerus, dan online</span> menggunakan</p>
            <p>koneksi internet yang aman dan terpercaya.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Innovasindo
