import { FunctionComponent, h, ComponentChildren } from "preact"
import { useState, useEffect, useRef } from 'preact/hooks'
import style from "./style.scss"

type AuthCarouselProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  images: string[]
  desc: ComponentChildren[]
}

const AuthCarousel: FunctionComponent<AuthCarouselProps> = ({ images, desc }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const elementWidth = useRef()

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = (currentIndex + 1) % images.length
      setCurrentIndex(newIndex)
    }, 5000)

    return () => {
      clearInterval(intervalId)
    }
  }, [currentIndex])

  return (
    <div className={style.carousel_container}>
      <img src={images[currentIndex]} alt={`slide-${currentIndex + 1}`} />
      <div ref={elementWidth}>{desc[currentIndex]}</div>
    </div>
  )
}

export default AuthCarousel