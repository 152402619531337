import { FunctionComponent, h } from "preact"

type SiteDataIconProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  className: string
}
const SiteDataIcon: FunctionComponent<SiteDataIconProps> = ({ className }) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M11.4327 19.9995H4C3.42615 19.9995 2.93401 19.8829 2.5923 19.6092C2.27183 19.3526 2 18.8897 2 17.9995V3.99951C2 3.10932 2.27183 2.64643 2.5923 2.38978C2.93401 2.11612 3.42615 1.99951 4 1.99951H14.8171C15.3851 1.99951 15.9321 2.11593 16.326 2.40932C16.6935 2.68295 17 3.16419 17 4.06989V9.11155C16.9738 9.12295 16.9417 9.13547 16.9034 9.14841C16.7073 9.21461 16.4899 9.24589 16.4121 9.24589C16.3903 9.24589 16.361 9.24228 16.3295 9.23324C16.3076 9.22698 16.2889 9.21934 16.2741 9.21179V4.08089C16.2741 3.68829 16.1925 3.28885 15.9008 2.99721C15.6092 2.70557 15.2097 2.62389 14.8171 2.62389H4.35614C3.96365 2.62389 3.54399 2.70256 3.20709 2.93199C2.84436 3.179 2.62414 3.57122 2.62414 4.06989V17.9299C2.62414 18.4364 2.85669 18.8276 3.22275 19.0709C3.56316 19.2972 3.98559 19.3759 4.37814 19.3759H11.5278C11.5289 19.3773 11.53 19.3788 11.5312 19.3804C11.544 19.3982 11.5569 19.4227 11.5662 19.4507C11.5755 19.4789 11.5781 19.5015 11.5781 19.5139C11.5781 19.5321 11.573 19.5879 11.5528 19.6732C11.5337 19.7539 11.5059 19.8402 11.4732 19.9161C11.4586 19.9501 11.4448 19.9777 11.4327 19.9995ZM17.0883 9.06304C17.0885 9.06305 17.0869 9.06458 17.0828 9.06759C17.0861 9.06453 17.0882 9.06303 17.0883 9.06304ZM11.3929 20.0595C11.3909 20.0618 11.3898 20.0627 11.3898 20.0627C11.3898 20.0626 11.3909 20.0614 11.3929 20.0595Z" fill="currentColor" stroke="currentColor" />
      <path d="M4.92736 6.47923H13.9694C14.2554 6.47923 14.4534 6.28123 14.4534 5.99523C14.4534 5.70923 14.2554 5.51123 13.9694 5.51123H4.92736C4.64136 5.51123 4.44336 5.70923 4.44336 5.99523C4.44336 6.28123 4.63036 6.47923 4.92736 6.47923ZM8.49136 15.5212H4.92736C4.64136 15.5212 4.44336 15.7192 4.44336 16.0052C4.44336 16.2912 4.64136 16.4892 4.92736 16.4892H8.49136C8.77736 16.4892 8.97536 16.2912 8.97536 16.0052C8.97536 15.7192 8.77736 15.5212 8.49136 15.5212ZM10.1194 12.1552H4.92736C4.64136 12.1552 4.44336 12.3532 4.44336 12.6392C4.44336 12.9252 4.64136 13.1232 4.92736 13.1232H10.1194C10.4054 13.1232 10.6034 12.9252 10.6034 12.6392C10.6034 12.3532 10.4164 12.1552 10.1194 12.1552ZM13.9694 8.87723H4.92736C4.64136 8.87723 4.44336 9.07523 4.44336 9.36123C4.44336 9.64723 4.64136 9.84523 4.92736 9.84523H13.9694C14.2554 9.84523 14.4534 9.64723 14.4534 9.36123C14.4534 9.07523 14.2664 8.87723 13.9694 8.87723Z" fill="currentColor" />
      <g clip-path="url(#clip0_1751_30287)">
        <path d="M16.9994 12.4165C17.2295 12.4165 17.4161 12.603 17.4162 12.8331L17.4162 13.0941C17.4163 13.4359 17.6743 13.7169 18.0003 13.8194C19.0363 14.1452 19.8543 14.9632 20.1799 15.9993C20.2824 16.3253 20.5634 16.5832 20.9052 16.5832H21.1659C21.3961 16.5832 21.5827 16.7697 21.5827 16.9999C21.5827 17.23 21.3962 17.4166 21.1661 17.4166L20.9051 17.4167C20.5633 17.4168 20.2823 17.6748 20.1798 18.0008C19.8541 19.0367 19.0362 19.8546 18.0003 20.1803C17.6743 20.2828 17.4163 20.5638 17.4162 20.9056L17.4162 21.1666C17.4161 21.3967 17.2295 21.5832 16.9994 21.5832C16.7693 21.5832 16.5827 21.3966 16.5827 21.1664V20.9057C16.5827 20.5639 16.3248 20.2829 15.9988 20.1804C14.9627 19.8548 14.1447 19.0368 13.8189 18.0008C13.7164 17.6748 13.4354 17.4168 13.0936 17.4167L12.8326 17.4166C12.6025 17.4166 12.416 17.23 12.416 16.9999C12.416 16.7697 12.6026 16.5832 12.8328 16.5832H13.0935C13.4353 16.5832 13.7163 16.3253 13.8188 15.9992C14.1445 14.963 14.9625 14.145 15.9988 13.8193C16.3248 13.7168 16.5827 13.4358 16.5827 13.094V12.8332C16.5827 12.6031 16.7693 12.4165 16.9994 12.4165ZM16.9993 14.4998C15.6186 14.4998 14.4993 15.6191 14.4993 16.9998C14.4993 18.3805 15.6186 19.4998 16.9993 19.4998C18.3801 19.4998 19.4993 18.3805 19.4993 16.9998C19.4993 15.6191 18.3801 14.4998 16.9993 14.4998ZM16.9993 16.1665C17.4596 16.1665 17.8327 16.5396 17.8327 16.9998C17.8327 17.4601 17.4596 17.8332 16.9993 17.8332C16.5391 17.8332 16.166 17.4601 16.166 16.9998C16.166 16.5396 16.5391 16.1665 16.9993 16.1665Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1751_30287">
          <rect width="10" height="10" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SiteDataIcon
