import { FunctionComponent, h, Fragment } from "preact"
import { useState, useEffect } from "preact/hooks"
import useSites from "../../../hooks/useSite"
import useCompany from "../../../hooks/useCompany"
import { useModule } from "../../../hooks/useModule"
import RealtimeFilter from "../../../components/TopFilter/realtime"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import Spinner from "../../../components/spinner"
import Container from "../../../parts/container"
import DetailsSummaryCompany from "./details-summary-company"
import DetailsSummaryModule from "./details-summary-module"
// import { realtimes, realtimeData } from "../../../ws"
import style from "./style.scss"
import useSocket from "../../../hooks/useSocket"
import { useTranslation } from "react-i18next"

type DashboardRealtimeProps = h.JSX.HTMLAttributes<HTMLDivElement>

const DashboardRealtime: FunctionComponent<DashboardRealtimeProps> = () => {
  const { t } = useTranslation()
  const { data: cems } = useSites()
  const { data: companies } = useCompany()
  const { data: modules } = useModule()
  const [lists, setLists] = useState([])
  const [filteredLists, setFilteredLists] = useState([])
  const [filter, setFilter] = useState({})
  const [isModuleFirst, setIsModuleFirst] = useState(false)

  const token = localStorage.getItem("x-auth-token")
  const WS_URL = process.env.WS_URL

  const socketOptions = {
    transports: ["polling", "webtransport"],
    extraHeaders: { authorization: token },
    reconnection: true,
  }
  const realtimes = useSocket(`${WS_URL}/company_status_events`, socketOptions)
  const realtimeData = useSocket(`${WS_URL}/sensor_events`, socketOptions)

  const handleFilter = (data) => {
    const checkIsModuleFisrt = data?.module && !data?.company
    setIsModuleFirst(!!checkIsModuleFisrt)
    setFilter(data)
  }

  const getSiteActive = (data, isModule) => {
    let newData
    if (isModule) {
      newData = data?.flatMap(({ companies }) => companies)
    } else {
      newData = data
    }
    const totalSites = newData?.reduce((accumulator, item) => accumulator + item?.total_sites, 0)
    const totalActiveSites = newData?.reduce((accumulator, item) => accumulator + item?.count_active_sites, 0)
    return `${totalActiveSites}/${totalSites}`
  }

  useEffect(() => {
    const mergeData = (existingData, newData) => {
      const index = existingData?.findIndex((item) => item?.uuid === newData?.uuid)
      if (index !== -1) {
        existingData[index] = newData
      } else {
        existingData.push(newData)
      }
      return existingData
    }

    if (realtimes) {
      realtimes.on("activeIndicateEvent", (realtimeData) => {
        realtimeData?.forEach((data) => {
          setLists((prevList) => mergeData([...prevList], data))
        })
      })

      realtimes.emit("filterEvent", {})
      // for debugin
      // const listener = (eventName, ...args) => {
      //   console.log(eventName, args)
      // }
      // realtimes.onAny(listener)
    }
  }, [realtimes])

  useEffect(() => {
    const filteredCompany = lists?.filter((list) => list?.uuid === filter?.company || !filter?.company)

    const filteredModule = filteredCompany
      ?.map((company) => ({
        ...company,
        modules: company?.modules?.filter((module) => module?.uuid === filter?.module || !filter?.module),
      }))
      ?.filter((company) => company?.modules?.length > 0)

    const filteredSite = filteredModule
      ?.map((company) => ({
        ...company,
        modules: company?.modules
          ?.map((module) => ({
            ...module,
            sites: module?.sites?.filter((site) => site?.uuid === filter?.site || !filter?.site),
          }))
          ?.filter((module) => module?.sites?.length > 0),
      }))
      ?.filter((company) => company?.modules?.length > 0)

    if (isModuleFirst) {
      const mappedData = filteredSite?.map((company) => {
        const { modules = [], ...restCompany } = company
        const { sites = [], ...restModule } = modules?.[0]
        return {
          ...restModule,
          companies: {
            ...restCompany,
            sites,
          },
        }
      })

      let totalParams = 0
      let countActiveParams = 0
      let moduleId = ""
      let moduleName = ""
      const moduleCompanies = []
      mappedData?.forEach((module) => {
        totalParams += module?.total_params
        countActiveParams += module?.count_active_params
        moduleId = module?.uuid
        moduleName = module?.name
        moduleCompanies?.push(module?.companies)
      })

      const transformedData = [
        {
          uuid: moduleId,
          name: moduleName,
          total_params: totalParams,
          count_active_params: countActiveParams,
          companies: moduleCompanies,
        },
      ]

      setFilteredLists(transformedData)
    } else {
      setFilteredLists(filteredSite)
    }
  }, [filter, lists, isModuleFirst])

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.dashboard")} link="" />
        <Breadcrumb name={t("breadcrumb.realtime")} link="/dashboard/realtime" />
      </TopBar>
      <Spinner show={!lists?.length} />
      <Container>
        <div className="row align-items-stretch">
          <RealtimeFilter mils={companies} cems={cems} modules={modules} onShow={handleFilter} />
        </div>
        <div className={style.legend_wrapper}>
          <div className={style.legend}>
            <div className={style.site_active}>
              {t("site_active")} {getSiteActive(filteredLists, isModuleFirst)}
            </div>
            <div className={style.site_status}>
              <div className={style.exceeded}>{t("page.realtime.error")}</div>
              <div className={style.good}>{t("page.realtime.good")}</div>
            </div>
          </div>
        </div>
        {filteredLists?.map((list) => (
          <Fragment key={list?.uuid}>
            {isModuleFirst ? (
              <DetailsSummaryModule module={list} status={filter?.status} socket={realtimeData} />
            ) : (
              <DetailsSummaryCompany company={list} status={filter?.status} socket={realtimeData} />
            )}
          </Fragment>
        ))}
      </Container>
    </div>
  )
}

export default DashboardRealtime
