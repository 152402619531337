import { FunctionComponent, h } from "preact"
import { useState, useRef } from "preact/hooks"
import { route } from "preact-router"
import moment from "moment"
import Flatpickr from "react-flatpickr"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import useAuth from "../../../hooks/useAuth"
import useSites from "../../../hooks/useSite"
import useObjectState from "../../../hooks/useObjectState"
import "flatpickr/dist/flatpickr.css"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type AddNCRProps = h.JSX.HTMLAttributes<HTMLDivElement>

const defaultBody = {
  lk_number: "",
  date: moment().format("YYYY-MM-DD"),
  site: { uuid: "" },
  parameter: "",
  condition: "",
  description: "",
  problem: "",
  action: "",
  completion_date_target: "",
  abnormal_type: "",
  pic_problem: "",
  pic_solve: "",
}

const flatpickrOptions = {
  dateFormat: "Y-m-d",
  altInput: true,
  altFormat: "d/m/Y",
}

const AddNCR: FunctionComponent<AddNCRProps> = () => {
  const { t } = useTranslation()
  const { data: cems } = useSites()
  const [body, setBody] = useObjectState(defaultBody)
  const [disable, setDisable] = useState(false)
  const { fetcher } = useAuth()
  const parent = `/maintenance/ncr`
  const imageUrl = "/ncr/upload"
  const header = { headers: { "Content-Type": "multipart/form-data" } }
  const onBack = () => route(parent)
  const problemImage = useRef()
  const solveImage = useRef()

  const onSiteNameChange = (currentTarget) => {
    const value = currentTarget.value
    const siteName = cems?.find(({ uuid }) => uuid === value)?.name
    const lkNumber = siteName ? `${siteName}/${body.date}` : ""
    setBody({
      site: { uuid: value },
      lk_number: lkNumber,
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    try {
      const imgProblem = new FormData()
      const imgsolve = new FormData()
      imgProblem.append("image", problemImage.current.files[0])
      imgsolve.append("image", solveImage.current.files[0])
      const { data: picProblem } = await fetcher().post(imageUrl, imgProblem, header)
      const { data: picSolve } = await fetcher().post(imageUrl, imgsolve, header)
      const { abnormal_type, condition, ...data } = body
      const abnormal = condition === "Abnormal Condition" ? `${condition} - ${abnormal_type}` : condition
      await fetcher().post("/ncr", {
        ...data,
        condition: abnormal,
        pic_problem: picProblem?.data?.path,
        pic_solve: picSolve?.data?.path,
      })
      await onBack()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  return (
    <div>
      <TopBar>
        <Breadcrumb name={t("breadcrumb.maintenance")} link="" />
        <Breadcrumb name={t("breadcrumb.ncr")} link="/maintenance/ncr" />
        <Breadcrumb name={t("breadcrumb.ncr_add")} link="/maintenance/ncr/add" />
      </TopBar>
      <Container>
        <div class="card">
          <div className="card-title pt-4">
            <div className="d-flex gap-4 align-items-center">
              <p className={style.page_title}>{t("page.ncr.add_new_report")}</p>
            </div>
          </div>
          <form className="card-body" onSubmit={(event) => handleSubmit(event)}>
            <div className="mb-4">
              <label class="form-label">{t("label.lk_number")}</label>
              <input class="form-control rounded mw-100" type="text" value={body.lk_number} readOnly />
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.date")}</label>
              <input class="form-control rounded mw-100" type="text" value={moment(body.date).format("DD MMMM YYYY")} readOnly />
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.site_name")}</label>
              <select
                className="form-control rounded mw-100"
                required
                value={body.site.uuid}
                onChange={({ currentTarget }) => onSiteNameChange(currentTarget)}
              >
                <option value="">{t("label.select_site")}</option>
                {cems?.map((cem) => (
                  <option key={cem.uuid} value={cem.uuid}>
                    {cem.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.parameter")}</label>
              <select
                required
                value={body.parameter}
                className="form-control rounded mw-100"
                onChange={({ currentTarget: e }) => setBody({ parameter: e.value })}
              >
                <option value="">{t("label.select_parameter")}</option>
                {cems
                  ?.find(({ uuid }) => uuid == body.site.uuid)
                  ?.parameters?.filter(({ is_active }) => is_active)
                  ?.map((param) => (
                    <option value={param.name} key={param.uuid}>
                      {param.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.condition")}</label>
              <select
                required
                className="form-control rounded mw-100"
                value={body.condition}
                onChange={({ currentTarget: e }) => setBody({ condition: e.value })}
              >
                <option value="Abnormal Condition">Abnormal Condition</option>
                <option value="Emergency Condition">Emergency Condition</option>
              </select>
            </div>
            {body.condition == "Abnormal Condition" && (
              <div className="mb-4">
                <label class="form-label">{t("label.abnormal_condition_type")}</label>
                <select
                  required
                  className="form-control rounded mw-100"
                  value={body.abnormal_type}
                  onChange={({ currentTarget: e }) => setBody({ abnormal_type: e.value })}
                >
                  <option value="Maintenance">Maintenance</option>
                  <option value="Calibration">Calibration</option>
                  <option value="Abnormal Site">Abnormal Site</option>
                  <option value="Abnormal SPARING">Abnormal SPARING</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            )}
            <div className="mb-4">
              <label class="form-label">{t("label.description")}</label>
              <textarea
                required
                rows="5"
                value={body.description}
                onChange={({ currentTarget: e }) => setBody({ description: e.value })}
                className="form-control rounded-3 mw-100"
              />
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.root_problem_analytics")}</label>
              <textarea
                required
                rows="5"
                value={body.problem}
                onChange={({ currentTarget: e }) => setBody({ problem: e.value })}
                className="form-control rounded-3 mw-100"
              />
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.root_problem_analytic_image")}</label>
              <input ref={problemImage} class="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" required />
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.repair_action_prevention")}</label>
              <textarea
                required
                rows="5"
                value={body.action}
                onChange={({ currentTarget: e }) => setBody({ action: e.value })}
                className="form-control rounded-3 mw-100"
              />
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.repair_action_prevention_image")}</label>
              <input ref={solveImage} class="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" required />
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.completion_date_target")}</label>
              <Flatpickr
                value={body.completion_date_target}
                options={flatpickrOptions}
                class="form-control rounded mw-100"
                onChange={(date: Date) => setBody({ completion_date_target: moment(date[0]).format("YYYY-MM-DD") })}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button type="submit" className="mx-1" variant="primary" disabled={disable}>
                {t("buttons.add_report")}
              </Button>
              <Button onClick={onBack} type="button" className="mx-1" variant="tertiary" disabled={disable}>
                {t("buttons.cancel")}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  )
}

export default AddNCR
