import { FunctionComponent, h } from "preact"
import AuthTemplate from "./auth-template"
import { setLocalStorage, landingPage } from "../../utils/local-storage"
import style from "./style.scss"
import LoginForm from "./login-form"
import templates from "../../utils/template-configs"
import usePlatformApp from "../../hooks/usePlatformApp"
import { useTranslation } from "react-i18next"

type SignUpProps = h.JSX.HTMLAttributes<HTMLDivElement>

const SignUp: FunctionComponent<SignUpProps> = () => {
  const { platform } = usePlatformApp()
  const { t } = useTranslation()
  setLocalStorage(landingPage, "")

  return (
    <AuthTemplate>
      <div className={style.login_card}>
        <div className={style.login_title}>
          <div dangerouslySetInnerHTML={{ __html: t("page.login.title", { platform_name: templates[platform].login.title }) }}></div>
          <div className={style.desc}>{t("page.login.description")}</div>
        </div>
        <LoginForm buttonClass={style.login} forgotClass={style.forgot_password} />
      </div>
    </AuthTemplate>
  )
}

export default SignUp
