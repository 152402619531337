import { ApiResponse } from "../types/api-response"
import { TopFilterOutput } from "./useTopFilter"
import useAuth from "./useAuth"
import useSWR from "swr"

export interface NotificationsResponse {
  uuid: string
  type: "Alert" | "Announcement"
  message: string
  created_at: string
  updated_at: string
  deleted_at: any
  mil: Mil
  cem: Cem
}

interface NotificationCount {
  data: number
}

interface Mil {
  uuid: string
  name: string
}

interface Cem {
  uuid: string
  name: string
}

const useNotifications = (filter: TopFilterOutput) => {
  const { fetcher } = useAuth()
  const { _type, time, ...data } = filter
  const url = `/notifications/${_type}`
  const output = useSWR([url, JSON.stringify(data)], (url, data) =>
    fetcher()
      .get<ApiResponse<NotificationsResponse[]>>(url, {
        params: JSON.parse(data),
      })
      .then(({ data }) => data.data)
  )
  return output
}

export const useNotificationCount = () => {
  const { fetcher } = useAuth()
  const url = `/daily-notification/count`
  const output = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<NotificationCount>>(url)
      .then(({ data }) => data.data)
  )
  return output
}

export const useGetNotification = (id: string) => {
  const { fetcher } = useAuth()
  const url = `/daily-notification/${id}`
  const output = useSWR(url, (url) => {
    if (!id) {
      return
    }
    return fetcher()
      .get(url)
      .then(({ data }) => data.data)
  })
  return output
}

export default useNotifications
