import { FunctionComponent, h, Fragment } from "preact"
import { useState, useEffect } from "preact/hooks"
import { Link } from "preact-router"
import { BsTrash, BsEye, BsPlayFill, BsStopFill } from "react-icons/bs"
import useAuth from "../../../hooks/useAuth"
import { useGetDummyData } from "../../../hooks/useDummyData"
import useSites from "../../../hooks/useSite"
import usePagination from "../../../hooks/usePagination"
import { useConfirm } from "../../../context/useConfirm"
import { can, canOneOf } from "../../../utils/access"
import { formatDate } from "../../../utils/dateformat"
import { Permission } from "../../../enum/permissions.enum"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import Pagination from "../../../components/pagination"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import Spinner from "../../../components/spinner"
import SearchBox from "../../../components/TopFilter/search-box"
import { useSortHeader } from "../../../hooks/useSortHeader"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type DummyDataProps = h.JSX.HTMLAttributes<HTMLDivElement>

const DummyData: FunctionComponent<DummyDataProps> = () => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const { data, mutate } = useGetDummyData()
  const { data: cems } = useSites()
  const confirmation = useConfirm()

  const [filteredData, setFilteredData] = useState([])
  const [search, setSearch] = useState("")

  const showActionColumn = canOneOf([Permission.ToolsDummyDataDelete, Permission.ToolsDummyDataDetail, Permission.ToolsDummyDataStartStop])

  const pages = usePagination({ count: filteredData?.length || 0, step: 20 })
  const dataTable = filteredData?.slice(pages.output, pages.output + pages.step)

  const handleDelete = (id: string) => {
    confirmation(
      t("delete_dummy_data_confirm_msg", { id }),
      (isYes) => {
        if (isYes)
          fetcher()
            .delete(`/dummy-data/${id}`)
            .then(() => mutate())
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }

  const handleStart = (id: string, name: string) => {
    confirmation(
      `Are you sure you want to resume ${name} dummy data?`,
      (isYes) => {
        if (isYes)
          fetcher()
            .patch(`/dummy-data/resume/${id}`)
            .then(() => mutate())
      },
      { yes: "Resume", no: "Cancel" }
    )
  }

  const handleStop = (id: string, name: string) => {
    confirmation(
      `Are you sure you want to stop ${name} dummy data?`,
      (isYes) => {
        if (isYes)
          fetcher()
            .patch(`/dummy-data/stop/${id}`, { name })
            .then(() => mutate())
      },
      { yes: "Stop", no: "Cancel" }
    )
  }

  useEffect(() => {
    const mappedData = data?.map((item) => {
      const site = cems?.find(({ uuid }) => uuid === item?.logger_id)
      return {
        ...item,
        company: site?.company?.name,
        site: site?.name,
      }
    })

    let searchedData
    if (search) {
      searchedData = mappedData?.filter((item) => {
        return (
          item?.company?.toLowerCase()?.includes(search) ||
          item?.name?.toLowerCase()?.includes(search) ||
          item?.site?.toLowerCase()?.includes(search) ||
          item?.logger_uuid?.toLowerCase()?.includes(search) ||
          !search
        )
      })
    } else {
      searchedData = mappedData
    }
    setFilteredData(searchedData)
  }, [data, cems, search])

  // order
  const { sort, onOrder, sortIcon, resetSorting } = useSortHeader({
    initialFieldToSort: {
      company: null,
      site: null,
      name: null,
      interval: null,
      start: null,
      end: null,
      status: null,
    },
  })
  const onSortField = (field: string) => {
    const sortParamsData = onOrder(field)
    sortData(field, sortParamsData[0][`order[0]`])
  }

  const sortData = (field: string, order = "asc") => {
    const data = JSON.parse(JSON.stringify(filteredData))
    const stringTypeFields = ["company", "site", "name", "status", "start", "end"]
    const numberTypeFields = ["interval"]
    let sort = []
    if (stringTypeFields.includes(field)) {
      sort = data.sort((a, b) => {
        return order === "asc" ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field])
      })
    }
    if (numberTypeFields.includes(field)) {
      sort = data.sort((a, b) => {
        return order === "asc" ? a[field] - b[field] : b[field] - a[field]
      })
    }
    setFilteredData(sort)
  }
  // end order

  return (
    <div>
      <Spinner show={!Array.isArray(data) && !Array.isArray(cems)} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.tools")} link="" />
        <Breadcrumb name={t("breadcrumb.dummy_data")} link="/tools/dummy-data" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title pt-4">
            <SearchBox placeholder={t("placeholder.search")} onChange={(value) => setSearch(value?.toLowerCase())} />
            {can(Permission.ToolsDummyDataAdd) && (
              <Link href="/tools/dummy-data/add">
                <Button variant="primary" className="ms-2">
                  {t("buttons.add_new_dummy")}
                </Button>
              </Link>
            )}
          </div>
          <div className="card-body">
            <table className="table table-center table-data">
              <thead>
                <tr>
                  <th>No</th>
                  <th className={style.sort_able} onClick={() => onSortField("company")}>
                    {t("company")} {sortIcon("company")}
                  </th>
                  <th className={style.sort_able} onClick={() => onSortField("site")}>
                    {t("site")} {sortIcon("site")}
                  </th>
                  <th className={style.sort_able} onClick={() => onSortField("name")}>
                    {t("table.payload_name")} {sortIcon("name")}
                  </th>
                  <th className={style.sort_able} onClick={() => onSortField("interval")}>
                    {t("table.delivery_interval")} {sortIcon("interval")}
                  </th>
                  <th className={style.sort_able} onClick={() => onSortField("start")}>
                    {t("table.start_date")} {sortIcon("start")}
                  </th>
                  <th className={style.sort_able} onClick={() => onSortField("end")}>
                    {t("table.end_date")} {sortIcon("end")}
                  </th>
                  <th className={style.sort_able} onClick={() => onSortField("status")}>
                    {t("table.status")} {sortIcon("status")}
                  </th>
                  {showActionColumn && <th>{t("table.action")}</th>}
                </tr>
              </thead>
              <tbody>
                {dataTable?.length ? (
                  dataTable?.map((item, index) => (
                    <tr key={item?.uuid}>
                      <td>{index + 1 + pages.page * pages.step}</td>
                      <td>{item?.company}</td>
                      <td>{item?.site}</td>
                      <td>{item?.name}</td>
                      <td>{item?.interval} Seconds</td>
                      <td>{formatDate(item?.start, "DD-MM-YYYY HH:mm:ss")}</td>
                      <td>{formatDate(item?.end, "DD-MM-YYYY HH:mm:ss")}</td>
                      <td>
                        <div className={`${item?.status === "STARTED" ? style.running : style.stopped}`}>
                          {item?.status === "STARTED" ? t("Running") : t("Stopped")}
                        </div>
                      </td>
                      {showActionColumn && (
                        <td>
                          <div className="d-flex">
                            {can(Permission.ToolsDummyDataDetail) && (
                              <Link href={`/tools/dummy-data/detail/${item?.uuid}`}>
                                <Button variant="primary" title="Detail" className="m-1" icon={BsEye} />
                              </Link>
                            )}
                            {can(Permission.ToolsDummyDataStartStop) && (
                              <Fragment>
                                {item?.status === "STARTED" ? (
                                  <Button
                                    variant="secondary"
                                    title="Stop"
                                    className="m-1"
                                    onClick={() => handleStop(item?.uuid, item?.name)}
                                    icon={BsStopFill}
                                  />
                                ) : (
                                  <Button
                                    variant="secondary"
                                    title="Start"
                                    className="m-1"
                                    onClick={() => handleStart(item?.uuid, item?.name)}
                                    icon={BsPlayFill}
                                  />
                                )}
                              </Fragment>
                            )}
                            {can(Permission.ToolsDummyDataDelete) && (
                              <Button variant="tertiary" title="Delete" onClick={() => handleDelete(item?.uuid)} className="m-1" icon={BsTrash} />
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 9 : 8}>No data.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <Pagination hook={pages} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default DummyData
