import { createContext } from "preact"
import { useContext, useEffect, useState } from "preact/hooks"
import React from "react"
import { useNotificationCount } from "../hooks/useNotifications"

const NotificationContext = createContext()

export const NotificationProvider = ({ children }) => {
  const { data: notif } = useNotificationCount()
  const [count, setCount] = useState(2)

  const updateCount = (newCount) => {
    if (newCount === -1) {
      setCount(0)
    } else {
      setCount(newCount)
    }
  }

  useEffect(() => {
    if (notif) {
      setCount(notif?.count)
    }
  }, [notif])

  return <NotificationContext.Provider value={{ count, updateCount }}>{children}</NotificationContext.Provider>
}
export const useNotification = () => useContext(NotificationContext)
