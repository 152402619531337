import { h, FunctionComponent } from "preact"
import { Button } from "../../parts/buttons"
import { useTranslation } from "react-i18next"
type AlertModalProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  isSuccess: boolean
  onHide: () => void
}

const AlertModal: FunctionComponent<AlertModalProps> = ({ show, isSuccess, onHide }) => {
  const { t } = useTranslation()
  return (
    <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", zIndex: 999999 }}>
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content card">
          <div className="card-body text-center">
            {isSuccess ? (
              <svg width="201" height="201" viewBox="0 0 201 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100.501 183.833C54.4776 183.833 17.168 146.523 17.168 100.499C17.168 54.4756 54.4776 17.166 100.501 17.166C146.525 17.166 183.835 54.4756 183.835 100.499C183.835 146.523 146.525 183.833 100.501 183.833ZM100.501 167.166C137.32 167.166 167.168 137.319 167.168 100.499C167.168 63.6804 137.32 33.8327 100.501 33.8327C63.6823 33.8327 33.8346 63.6804 33.8346 100.499C33.8346 137.319 63.6823 167.166 100.501 167.166ZM97.8465 128.176C94.7223 131.3 89.657 131.3 86.5328 128.176L62.4913 104.134C59.3671 101.01 59.3671 95.945 62.4911 92.8208L62.9628 92.3491C66.087 89.2247 71.1525 89.2247 74.2767 92.3491L86.5328 104.606C89.657 107.73 94.7224 107.73 97.8466 104.606L133.674 68.7789C136.798 65.6547 141.863 65.6547 144.987 68.7789L145.459 69.2502C148.583 72.3744 148.583 77.4398 145.459 80.5639L97.8465 128.176Z"
                  fill="#026449"
                />
              </svg>
            ) : (
              <g transform="scale(5)">
                <svg width="160" height="160" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27.3,4.7c-6.2-6.2-16.4-6.2-22.6,0c-6.2,6.2-6.2,16.4,0,22.6c6.2,6.2,16.4,6.2,22.6,0C33.6,21.1,33.6,10.9,27.3,4.7z M24.5,7.5c4.2,4.2,4.6,10.7,1.3,15.4L9.1,6.2C13.7,2.9,20.3,3.3,24.5,7.5z M7.5,24.5C3.3,20.3,2.9,13.7,6.2,9.1l16.7,16.7C18.3,29.1,11.7,28.7,7.5,24.5z"
                    fill="#FF3939"
                  />
                </svg>
              </g>
            )}
            <p className="fw-bolder my-3">
              {isSuccess ? t("Payment confirmation successful") : t("Payment confirmation failed to save, please try again")}
            </p>
            <Button className="w-100" onClick={onHide} variant="primary">
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AlertModal
