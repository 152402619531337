import { FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { route } from "preact-router"
import { Button } from "../../parts/buttons"
import AuthTemplate from "./auth-template"
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs"
import useAxios from "../../hooks/useAxios"
import { AxiosApiError } from "../../types/api-response"
import useObjectState from "../../hooks/useObjectState"
import { getLocalStorage, landingPage } from "../../utils/local-storage"
import style from "./style.scss"

type ResetPasswordProps = h.JSX.HTMLAttributes<HTMLDivElement>

const ResetPassword: FunctionComponent<ResetPasswordProps> = () => {
  const authPage = getLocalStorage(landingPage) || "/login"
  const sendPasswordReset = useAxios()
  const [load, setLoad] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [alert, setAlert] = useObjectState({ value: "", type: "error" as "error" | "success" })
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')

  if (!token) route(authPage)

  const handleSubmit = (event: KeyboardEvent) => {
    event.preventDefault()
    if (!password || !confirmPassword) {
      setAlert({ value: "Field can't be blank!", type: "error" })
      return
    } else if (password !== confirmPassword) {
      setAlert({ value: "Passwords do not match", type: "error" })
      return
    }
    setLoad(true)
    const url = "/auth/reset-password"
    sendPasswordReset({
      method: "post",
      url,
      data: { new_password: password, confirm_password: confirmPassword },
      headers: { Authorization: token }
    })
    .then(() => {
      setAlert({ type: "success", value: "Password has been changed. Please Re-Login" })
      setTimeout((() => route("/")), 3000)
    })
    .catch((e: AxiosApiError) => {
      const message = e.response?.data.data.message?.toString() || "Something error!"
      setAlert({ type: "error", value: message })
    })
    .finally(() => {
      setLoad(false)
    })
  }
  const handleCancel = () => route(authPage)

  return (
    <AuthTemplate>
      <div className={style.login_card}>
        <div className={style.login_title}>
          <div>Reset Password</div>
        </div>
        {alert.value && <div className={`alert alert-${alert.type}`}>{alert.value}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                value={password}
                onChange={({ currentTarget }): void => {
                setPassword(currentTarget.value.trim())
                }}
              />
              <span
                className={style.eye_icon}
                role="button"
                onClick={() => setShowPassword((state) => !state)}>
                {showPassword ? <BsEyeSlashFill size="1.3rem" /> : <BsEyeFill size="1.3rem" />}
              </span>
            </div>
          </div>
          <div className="mb-2">
            <label className="form-label">Confirm Password</label>
            <div className="position-relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="form-control"
                value={confirmPassword}
                onChange={({ currentTarget }): void => {
                  setConfirmPassword(currentTarget.value.trim())
                }}
              />
              <span
                className={style.eye_icon}
                role="button"
                onClick={() => setShowConfirmPassword((state) => !state)}>
                {showConfirmPassword ? <BsEyeSlashFill size="1.3rem" /> : <BsEyeFill size="1.3rem" />}
              </span>
            </div>
          </div>
          <Button variant="primary" className="w-100 mt-2" type="submit" disabled={load}>
            Reset password
          </Button>
          <Button variant="tertiary" className="w-100 mt-2" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </form>
      </div>
    </AuthTemplate>
  )
}

export default ResetPassword
