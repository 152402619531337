import { FunctionComponent, h } from "preact"
import { useState, useRef, useEffect } from "preact/hooks"
import useObjectState from "../../hooks/useObjectState"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import { Cems } from "../../types/cems"
import { HourlyDatePicker, DailyDatePicker, MonthlyDatePicker } from "./period-date-picker"
import { Button } from "../../parts/buttons"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type TopFilterMultiSiteProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  cems: Cems
  onShow: viod
}

const TopFilterMultiSite: FunctionComponent<TopFilterMultiSiteProps> = ({ cems, onShow }) => {
  const { t } = useTranslation()
  const selectSite = useRef<HTMLDivElement>(null)
  const [avaliCem, setAvaliCem] = useState(cems)
  const [siteValue, setSiteValue] = useState("")
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [showAllCheckbox, setShowAllCheckbox] = useState(true)
  const [sensors, setSensor] = useState([])
  const [selectedSite, setSelectedSite] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [filter, setFilter] = useObjectState({})
  const [disable, setDisable] = useState(true)

  const onSiteInputChange = (value) => {
    if (value) {
      setShowAllCheckbox(false)
      setSiteValue(value)
      const lowerValue = value.toLowerCase()
      const result = cems?.filter(({ name }) => {
        const lowerName = name.toLocaleLowerCase()
        return lowerName.includes(lowerValue)
      })
      setAvaliCem(result)
    } else {
      setShowAllCheckbox(true)
      setSiteValue("")
      setAvaliCem(cems)
    }
  }

  const onDateChange = (date, type) => (type === "start" ? setFilter({ startDate: date }) : setFilter({ endDate: date }))

  const setCommonSensor = (data) => {
    if (!data?.length) return []
    return data.reduce((accumulator, currentArray) => accumulator.filter((value) => currentArray.includes(value)))
  }

  const getParams = (uuids, data) => {
    const params = data?.filter(({ uuid }) => uuids.includes(uuid))
    return params?.map(({ parameters }) => parameters?.map(({ name }) => name))
  }

  const checkAllSite = (data) => {
    const allUuid = data?.map(({ uuid }) => uuid)
    const params = data?.map(({ parameters }) => parameters?.map(({ name }) => name))
    const commonParams = setCommonSensor(params)

    setCheckAll((prevState) => {
      !prevState ? setSelectedSite(allUuid) : setSelectedSite([])
      !prevState ? setSensor(commonParams) : setSensor([])
      !prevState ? setFilter({ site: allUuid }) : setFilter({ site: [] })
      return !prevState
    })
  }

  const checkSite = (uuid, data) => {
    setCheckAll(false)
    setSiteValue("")
    setAvaliCem(cems)
    setShowAllCheckbox(true)
    const isChecked = selectedSite.find((item) => item === uuid)
    if (isChecked) {
      setSelectedSite((prevState) => {
        const result = prevState.filter((item) => item !== uuid)
        const params = getParams(result, data)
        const commonParams = setCommonSensor(params)
        setSensor(commonParams)
        setFilter({ site: result })
        return result
      })
    } else {
      setSelectedSite((prevState) => {
        const result = [...prevState, uuid]
        const params = getParams(result, data)
        const commonParams = setCommonSensor(params)
        setSensor(commonParams)
        setFilter({ site: result })
        return result
      })
    }
  }

  const renderDatePicker = ({ period, date, type, onDateChange }) => {
    if (period === "month") return <MonthlyDatePicker date={date} type={type} setDate={(newDate) => onDateChange(newDate, type)} />
    else if (period === "day") return <DailyDatePicker date={date} type={type} setDate={(newDate) => onDateChange(newDate, type)} />
    return <HourlyDatePicker date={date} type={type} setDate={(newDate) => onDateChange(newDate, type)} />
  }

  const onShowHandler = () => onShow(filter)

  useOnClickOutside(selectSite, () => setShowCheckbox(false))

  useEffect(() => {
    const { site, sensor, period, startDate, endDate } = filter
    site?.length && sensor && period && startDate && endDate ? setDisable(false) : setDisable(true)
  }, [filter])

  useEffect(() => {
    setAvaliCem(cems)
  }, [cems])

  return (
    <div className={style.filter_input}>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>Site</label>
        <div ref={selectSite} className="position-relative w-100">
          <input
            type="text"
            className="w-100 form-select rounded d-inline-block user-select-none border"
            onFocus={() => setShowCheckbox(true)}
            onChange={({ currentTarget: t }) => onSiteInputChange(t.value)}
            value={siteValue}
            placeholder={selectedSite?.length ? `${selectedSite?.length} ${t("placeholder.site_selected")}` : t("placeholder.select_site")}
          />
          <div class={style.multiselect} style={{ display: showCheckbox ? "inherit" : "none" }}>
            {showAllCheckbox && (
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="all" checked={checkAll} onChange={() => checkAllSite(cems)} />
                <label class="form-check-label" for="all">
                  {t("label.all_site")}
                </label>
              </div>
            )}
            {avaliCem?.length ? (
              avaliCem.map(({ name, uuid }) => (
                <div key={uuid} class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id={uuid}
                    value={uuid}
                    checked={selectedSite?.includes(uuid)}
                    onChange={() => checkSite(uuid, cems)}
                  />
                  <label class="form-check-label text-nowrap" for={uuid}>
                    {name}
                  </label>
                </div>
              ))
            ) : (
              <label class="form-check-label text-nowrap">{t("no_site_with_this_keyword")}</label>
            )}
          </div>
        </div>
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.sensor")}</label>
        <select
          className="w-100 form-select rounded border"
          onChange={(event) => setFilter({ sensor: event.currentTarget.value })}
          value={filter.sensor}
        >
          <option value="" disabled selected hidden>
            {t("label.select_sensor")}
          </option>
          {sensors?.map((sensor) => (
            <option key={sensor} value={sensor}>
              {sensor}
            </option>
          ))}
        </select>
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.interval")}</label>
        <select
          className="w-100 form-select rounded border"
          onChange={(event) => setFilter({ period: event.currentTarget.value })}
          value={filter.period}
        >
          <option value="" disabled selected hidden>
            {t("label.select_interval")}
          </option>
          <option value="raw">Raw</option>
          <option value="two-minutes">{t("two_minutes")}</option>
          <option value="hour">{t("hourly")}</option>
          <option value="day">{t("daily")}</option>
          <option value="month">{t("monthly")}</option>
        </select>
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.start_date")}</label>
        {renderDatePicker({ period: filter.period, date: filter.startDate, type: "start", onDateChange })}
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.end_date")}</label>
        {renderDatePicker({ period: filter.period, date: filter.endDate, type: "end", onDateChange })}
      </div>
      <div className="d-flex align-items-end">
        <Button variant="primary" className="py-2 w-100" onClick={() => onShowHandler()} disabled={disable}>
          {t("buttons.show")}
        </Button>
      </div>
    </div>
  )
}

export default TopFilterMultiSite
