import useSWR from "swr"
import useAuth from "./useAuth"

export const useCurrenVersion = () => {
  const { fetcher } = useAuth()
  const url = `/configs/mobile-version`
  const output = useSWR(url, (url) => {
    return fetcher()
      .get(url)
      .then(({ data }) => {
        return data?.data
      })
  })
  return output
}
