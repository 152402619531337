import { ApiResponse } from "../types/api-response"
import useAuth from "./useAuth"
import useSWR from "swr"

interface ReportResponse {
  uuid: string
  area: string
  cems_code: string
  monitoring_date: string
  operation_time: number
  test_lab: string
  accreditated_lab: boolean
  klhk_approved_envi_lab: boolean
  lab_certificate: string
  envi_lab_recomendation_letter: string
  sampling_photo: string
  workflow_status: string
  created_at: string
  updated_at: string
  deleted_at: any
  parameters: Parameter[]
  manual_report_satatus_history: ManualReportSatatusHistory[]
}

interface Parameter {
  uuid: string
  name: string
  uom: string
  bm: string
  value: string
  emission_load: string
  created_at: string
  updated_at: string
  deleted_at: any
}

interface ManualReportSatatusHistory {
  uuid: string
  status: string
  reason: string
  created_at: string
  updated_at: string
  deleted_at: any
}

interface AllReportPaginate {
  siteId: string
  range: string[]
  page: number
  limit: number
  orders?: { [field: string]: string }[]
}

const useAllReport = ({ siteId, range, page, limit, orders = [] }: AllReportPaginate) => {
  const url = `/manual-report`
  let body = JSON.stringify({
    site: siteId,
    start: range?.[0],
    end: range?.[1],
    page,
    limit,
  })

  if (orders.length) {
    let orderParams = {}
    const sort = orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    body = JSON.stringify({ site: siteId, start: range?.[0], end: range?.[1], page, limit, ...orderParams })
  }

  const { fetcher } = useAuth()
  const output = useSWR([url, body], (url, body) => {
    if (!siteId) return []
    return fetcher()
      .get<ApiResponse<ReportResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  })
  return output
}

export const useReportByID = (siteId: string) => {
  const url = `/manual-report/${siteId}`
  const { fetcher } = useAuth()
  const output = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<ReportResponse>>(url)
      .then(({ data }) => data.data)
  )
  return output
}

export default useAllReport
