import { Fragment, FunctionComponent, h } from "preact"
import { route } from "preact-router"
import moment from "moment"
import { HiChevronRight } from "react-icons/hi"
import { sortActiveParam, sortParam, isAvgSensor } from "../../../utils/sortParam"
import { toFixed } from "../../../utils/toFixed"
import { useFilterContext } from "../../../context/useFilter"
import { defaultParams } from "../../../utils/defaults"
import Spinner from "../../../components/spinner"
import { useRecordPaginate } from "../../../hooks/useSensor"
import { formatDateWithPeriod } from "../../../utils/dateformat"
import { can } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type RealtimeRecordProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  uuid: string
}

const RealtimeRecord: FunctionComponent<RealtimeRecordProps> = ({ uuid }) => {
  const { t } = useTranslation()
  const { setContextFilter } = useFilterContext()
  const type = "hour"
  const start = moment().startOf("day").format("X")
  const end = moment().startOf("hour").format("X")
  const { data } = useRecordPaginate({ uuid, type, start, end, page: 1, limit: 30 })

  const columns = sortActiveParam(data?.parameter) || defaultParams

  const getData = (data) => {
    return data?.record?.map((item) => {
      const filterPayloads = item?.data?.filter(({ name }) => columns?.map(({ name: colName }) => colName)?.includes(name))
      return {
        timestamp: item.timestamp,
        payloads: sortParam(filterPayloads),
      }
    })
  }

  const tableData = getData(data)

  const handleGoTo = () => {
    setContextFilter({ site: uuid, period: type, startDate: start, endDate: end })
    route("/record/sensor-data")
  }

  return (
    <div className="w-100 position-relative">
      <div className="d-flex justify-content-end">
        {can(Permission.RecordSensorDataView) && (
          <button className={style.menu_link_to_page} onClick={handleGoTo}>
            {t("page.realtime.See sensor data for more details")}
            <HiChevronRight />
          </button>
        )}
      </div>
      {data ? (
        <div className="overflow-auto">
          <table className="table table-data table-center">
            <thead>
              <tr>
                <th rowSpan={2}>{t("table.time")}</th>
                {columns?.map((col) => (
                  <th key={col?.name} colSpan={3}>
                    {col?.name}
                  </th>
                ))}
              </tr>
              <tr>
                {columns?.map((col) => (
                  <Fragment key={`uom-${col?.name}`}>
                    <th>
                      {t("table.value")} ({col?.uom || "-"})
                    </th>
                    <th>{t("table.battery")} (%)</th>
                    <th>{t("table.delay")} (ms)</th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.length ? (
                tableData?.map((raw, id) => (
                  <tr key={`raw-${id}`}>
                    <td className="text-nowrap">{formatDateWithPeriod(raw.timestamp, "hour")}</td>
                    {raw.payloads.map((value, idx) => (
                      <Fragment key={`payloads-${idx}`}>
                        <td>{isAvgSensor("hour", undefined) ? toFixed(value?.avg) : toFixed(value?.value)}</td>
                        <td>{toFixed(value?.avg_b_percentage, 2)}</td>
                        <td>{toFixed(value?.avg_delay, 2)}</td>
                      </Fragment>
                    ))}
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td className="text-center py-4" colSpan={3 * (columns?.length ?? 0) + 1}>
                    {t("no_data")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default RealtimeRecord
