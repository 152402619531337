import { useState, useEffect } from "preact/hooks"
import { FunctionComponent, h, ComponentChildren } from "preact"
import { Link } from "preact-router/match"
import { BiChevronUp } from "react-icons/bi"
import { useHelp } from "../../hooks/useHelp"
import { HelpBadge } from "../../parts/badge/help-badge"
import { Permission } from "../../enum/permissions.enum"
import { can } from "../../utils/access"
import { kebabCase } from "../../utils/string"
import style from "./index.scss"
import template from "../../utils/template-configs"
import usePlatformApp from "../../hooks/usePlatformApp"

type NavbarItemProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  item: {
    label: string
    link?: string
    icon: ComponentChildren
    sub: {
      label: string
      link: string
      icon: ComponentChildren
    }[]
  }
  showDropdown: {
    label: string
    status: boolean
  }
  setShowDropdown: void
}

const NavbarItem: FunctionComponent<NavbarItemProps> = ({ item, showDropdown, setShowDropdown }) => {
  const ParentIcon = item.icon
  const { data: help } = useHelp()
  const { platform } = usePlatformApp()
  const [unreadByAdmin, setUnreadByAdmin] = useState(0)
  const [unreadByUser, setUnreadByUser] = useState(0)
  const toggle = (label) => {
    const newLabel = kebabCase(label)
    setShowDropdown(newLabel)
  }

  const parentMenuClass = (label) => {
    const kebabLabel = kebabCase(label)
    const labelClass = showDropdown.status && showDropdown.label === kebabLabel ? style.show : ""
    return `${style.triangle} ${labelClass}`
  }
  const subMenuClass = (label) => {
    const kebabLabel = kebabCase(label)
    const labelClass = showDropdown.status && showDropdown.label === kebabLabel ? style.show : style.hide
    return labelClass
  }

  const downloadManual = () => {
    const manual = template[platform].manuals.user_manual
    if (manual.file != "" && manual.file_name != "") {
      const link = document.createElement("a")
      link.setAttribute("target", "_blank")
      link.setAttribute("href", template[platform].manuals.user_manual.file)
      link.setAttribute("download", template[platform].manuals.user_manual.file_name)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  useEffect(() => {
    const unreadBySender = help?.filter(({ isReadSender }) => !isReadSender)
    const unreadByReceiver = help?.filter(({ isReadReceiver }) => !isReadReceiver)
    setUnreadByAdmin(unreadByReceiver?.length || 0)
    setUnreadByUser(unreadBySender?.length || 0)
  }, [help])

  return (
    <div className={style.item}>
      {item.sub.length ? (
        <div>
          <div className={style.label} onClick={() => toggle(item?.label)}>
            <div className="d-flex w-100 align-items-center">
              <ParentIcon className={style.iconSize} />
              <span className="ms-3 me-auto">{item.label}</span>
              <span className={parentMenuClass(item.label)}>
                <BiChevronUp />
              </span>
            </div>
          </div>
          <div className={subMenuClass(item.label)}>
            {item.sub.map(({ icon: ChildrenIcon, ...sub }, id) => {
              if (sub.link == "/help/user-manual") {
                return (
                  <div onClick={() => downloadManual()} key={id} class={style.sub} activeClassName={style.active}>
                    <ChildrenIcon className={style.iconSize} />
                    <span className="ms-2">{sub.label}</span>
                  </div>
                )
              }
              return (
                <Link key={id} href={sub.link} class={style.sub} activeClassName={style.active}>
                  <ChildrenIcon className={style.iconSize} />
                  <span className="ms-2">{sub.label}</span>
                </Link>
              )
            })}
          </div>
        </div>
      ) : (
        <Link href={item.link} class={style.label} activeClassName={style.active} onClick={() => setShowDropdown(null)}>
          <ParentIcon className={style.iconSize} />
          <span className="ms-3">{item.label}</span>
          {item.label === "Help" && <>{can(Permission.HelpAdd) ? <HelpBadge value={unreadByUser} /> : <HelpBadge value={unreadByAdmin} />}</>}
        </Link>
      )}
    </div>
  )
}

export default NavbarItem
