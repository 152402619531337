import { FunctionComponent, h } from "preact"
import { useEffect, useState, useRef } from "preact/hooks"
import { mutate } from "swr"
import { useRole } from "../../../hooks/useUsman"
import { AxiosApiError } from "../../../types/api-response"
import { Button } from "../../../parts/buttons"
import SelectSearch from "../../../components/form/select-search"
import { User } from "../../../types/user"
import useSites from "../../../hooks/useSite"
import useCompany from "../../../hooks/useCompany"
import useVendor from "../../../hooks/useVendor"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type EditUserProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  userData: User
  onHide: () => void
  mutate: () => void
}

const EditUser: FunctionComponent<EditUserProps> = ({ userData, show, onHide, mutate }) => {
  const { t } = useTranslation()
  const selectSite = useRef<HTMLDivElement>(null)
  const { data: companies } = useCompany()
  const { data: sites } = useSites()
  const { data: vendors } = useVendor()
  const { data: roles } = useRole()
  const [avaliCem, setAvaliCem] = useState(null)
  const [filteredCompany, setFilteredCompany] = useState([])
  const [siteValue, setSiteValue] = useState("")
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [showAllCheckbox, setShowAllCheckbox] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const defaultState = {
    name: userData?.name,
    email: userData?.email,
    role: { uuid: userData?.role?.uuid },
    vendor: { uuid: userData?.vendor?.uuid },
    company: { uuid: userData?.company?.uuid },
    sites: userData?.sites?.map(({ uuid }) => uuid),
    notification: userData?.notification,
    // notification_interval: 6,
  }

  const [user, setUser] = useObjectState(defaultState)
  const { fetcher } = useAuth()

  const onRoleChange = (uuid) => {
    const prevSite = uuid === userData?.role?.uuid ? userData?.sites?.map(({ uuid }) => uuid) : null
    const prevCompany = uuid === userData?.role?.uuid ? userData?.company?.uuid : ""
    const prevVendor = uuid === userData?.role?.uuid ? userData?.vendor?.uuid : ""
    setUser({ role: { uuid }, vendor: { uuid: prevVendor }, company: { uuid: prevCompany }, sites: prevSite })
  }

  const getCompanyByVendor = (id) => companies?.filter(({ vendor }) => vendor?.uuid === id)
  const getSiteByCompany = (id) => sites?.filter(({ company }) => company?.uuid === id)

  const onSiteInputFocus = () => {
    setShowCheckbox(true)
    const filteredSite = getSiteByCompany(user?.company?.uuid)
    setAvaliCem(filteredSite)
    setShowAllCheckbox(filteredSite?.length > 1)
  }

  const onSiteInputChange = (value) => {
    if (value) {
      setShowAllCheckbox(false)
      setSiteValue(value)
      const lowerValue = value.toLowerCase()
      const result = avaliCem?.filter(({ name }) => {
        const lowerName = name.toLocaleLowerCase()
        return lowerName.includes(lowerValue)
      })
      setAvaliCem(result)
    } else {
      setSiteValue("")
      const filteredSite = getSiteByCompany(user?.company?.uuid)
      setAvaliCem(filteredSite)
      setShowAllCheckbox(filteredSite?.length > 1)
    }
  }

  const checkSite = (uuid) => {
    const filteredSite = getSiteByCompany(user?.company?.uuid)
    setCheckAll(false)
    setSiteValue("")
    setAvaliCem(filteredSite)
    setShowAllCheckbox(filteredSite?.length > 1)
    const isChecked = user?.sites?.find((item) => item === uuid)
    if (isChecked) {
      const prevSites = user?.sites?.filter((item) => item !== uuid)
      setUser({ sites: prevSites })
    } else {
      setUser({ sites: [...user?.sites, uuid] })
    }
  }

  const checkAllSite = (data) => {
    const allUuid = data?.map(({ uuid }) => uuid)

    setCheckAll((prevState) => {
      !prevState ? setUser({ sites: allUuid }) : setUser({ sites: null })
      return !prevState
    })
  }

  const onVendorChange = (uuid) => {
    setUser({ vendor: { uuid }, company: { uuid: "" }, sites: [] })
    const company = companies?.filter(({ vendor }) => vendor?.uuid === uuid)
    setFilteredCompany(company)
    setCheckAll(false)
  }

  const onCompanyChange = (uuid) => {
    const filteredSite = sites?.filter(({ company }) => company?.uuid === uuid)
    const siteList = uuid === userData?.company?.uuid ? userData?.sites?.map(({ uuid }) => uuid) : []
    setUser({ company: { uuid }, sites: siteList })
    setAvaliCem(filteredSite)
    setCheckAll(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const { company, vendor, sites, ...newUser } = user
    const data = {
      ...newUser,
      vendor: vendor?.uuid ? { uuid: vendor?.uuid } : null,
      company: company?.uuid ? { uuid: company.uuid } : null,
      sites: sites?.length ? sites : null,
    }
    fetcher()
      .patch(`/user/${userData.uuid}`, data)
      .then(() => {
        mutate("/user")
        setUser(defaultState)
        onHide()
        mutate()
      })
      .catch((e: AxiosApiError) => {
        const msg = e.response?.data?.data?.message || e.response?.data?.message || "Server Error"
        if (alert) {
          if (Array.isArray(msg)) alert(msg.map((t) => `- ${t}`).join("\n"))
          else alert(msg)
        }
      })
  }
  const handleCancel = () => {
    setUser(defaultState)
    if (onHide) onHide()
  }

  useOnClickOutside(selectSite, () => setShowCheckbox(false))

  useEffect(() => {
    setUser(defaultState)
    const filteredCompany = getCompanyByVendor(userData?.vendor?.uuid)
    const filteredSite = getSiteByCompany(userData?.company?.uuid)
    setFilteredCompany(filteredCompany)
    setAvaliCem(filteredSite)
    if (filteredSite?.length === userData?.sites?.length) setCheckAll(true)
  }, [userData])

  return (
    <div
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
    >
      <div class="modal-dialog modal-lg modal-dialog-centered " role="document">
        <div class="modal-content card">
          <div className="card-body d-flex justify-content-between px-5">
            <h5 class="card-title justify-content-center w-100">{t("edit_user")}</h5>
          </div>
          <form onSubmit={handleSubmit} className="card-body px-5">
            <div class="form-group mb-4">
              <label class="form-label">{t("label.name")}</label>
              <input
                value={user.name}
                required
                type="text"
                name="name"
                class="form-control rounded mw-100"
                onChange={({ currentTarget }) => setUser({ name: currentTarget.value })}
              />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("label.email")}</label>
              <input
                value={user.email}
                required
                type="email"
                name="email"
                class="form-control rounded mw-100"
                onChange={({ currentTarget }) => setUser({ email: currentTarget.value })}
              />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("label.role")}</label>
              <SelectSearch
                data={roles}
                required
                defaultValue={user?.role?.uuid}
                placeholder={t("placeholder.search_role")}
                onChange={onRoleChange}
              />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("label.vendor")}</label>
              <SelectSearch data={vendors} defaultValue={user?.vendor?.uuid} placeholder={t("placeholder.search_vendor")} onChange={onVendorChange} />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("company")}</label>
              <SelectSearch
                data={filteredCompany}
                defaultValue={user?.company?.uuid}
                placeholder={t("placeholder.search_company")}
                onChange={onCompanyChange}
              />
            </div>
            <div className="form-group mb-4">
              <label className="form-label">{t("site")}</label>
              <div ref={selectSite} className="position-relative w-100">
                <input
                  type="text"
                  className="rounded form-control mw-100"
                  onFocus={() => onSiteInputFocus()}
                  onChange={({ currentTarget: t }) => onSiteInputChange(t.value)}
                  value={siteValue}
                  placeholder={user?.sites?.length ? `${user?.sites?.length} ${t("placeholder.site_selected")}` : t("placeholder.select_site")}
                />
                <div class={style.multiselect} style={{ display: showCheckbox ? "inherit" : "none" }}>
                  {showAllCheckbox && (
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="all" checked={checkAll} onChange={() => checkAllSite(avaliCem)} />
                      <label class="form-check-label" for="all">
                        All Site
                      </label>
                    </div>
                  )}
                  {avaliCem?.length ? (
                    avaliCem.map(({ name, uuid }) => (
                      <div key={uuid} class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id={uuid}
                          value={uuid}
                          checked={user?.sites?.includes(uuid)}
                          onChange={() => checkSite(uuid)}
                        />
                        <label class="form-check-label text-nowrap" for={uuid}>
                          {name}
                        </label>
                      </div>
                    ))
                  ) : (
                    <label class="form-check-label text-nowrap">{siteValue ? t("no_site_with_this_keyword") : t("no_site_available")}</label>
                  )}
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="form-label">{t("daily_notification")}</label>
              <select
                className="rounded form-control mw-100"
                value={user?.notification ? "on" : "off"}
                onChange={({ currentTarget }) => setUser({ notification: currentTarget.value == "on" })}
              >
                <option value="on">On</option>
                <option value="off">Off</option>
              </select>
            </div>
            {/* <div class="form-group mb-4">
              <label class="form-label">Email Notification Interval</label>
              <input value={`${user.notification_interval} Hours`} required type="text" disabled class="form-control rounded mw-100" />
            </div> */}
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                {t("buttons.save")}
              </Button>
              <Button variant="tertiary" type="button" className="ms-2" onClick={handleCancel}>
                {t("buttons.cancel")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditUser
