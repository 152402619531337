import { OverviewParameter, Parameter } from "../../hooks/useRaw"
import Excel, { Borders, Alignment, Font } from "exceljs"
import { download } from "../../utils/excel"
import moment from "moment"
import { formatDateWithPeriod } from "../../utils/dateformat"
import { getUnitParameterDebit, isAvgSensor, isDebit } from "../../utils/sortParam"
import { toFixed } from "../../utils/toFixed"

const NOW = () => moment().format("x")

// STYLES
const alignmentCenter: Partial<Alignment> = { vertical: "middle", horizontal: "center" }
const boldFontHeader: Partial<Font> = { bold: true, color: { argb: 'FFFFFFFF' } }
const border: Partial<Borders> = {
  top: {style: "thin"},
  left: {style: "thin"},
  bottom: {style: "thin"},
  right: {style: "thin"}
}
const backgroundHeader: Partial<Fill> = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FF51BCDA" },
}
const backgroundYellow: Partial<Fill> = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FFE3AC54" },
}
const backgroundGreen: Partial<Fill> = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FF69CE95" },
}
const backgroundRed: Partial<Fill> = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FFF1906A" },
}

export const sensorDataToExcel = (
  columns: (Parameter | OverviewParameter)[],
  data: any[],
  period: string,
  sensor: string,
  name: string
) => {
  const book = new Excel.Workbook()
  const sheet = book.addWorksheet("Sensor Data")
  const title = `${name} - Sensor Data - ${NOW()}`

  sheet.mergeCells("A1", "A2")
  sheet.getCell(1, 1).value = "Time"
  sheet.getCell(1, 1).alignment = alignmentCenter
  sheet.getCell(1, 1).border = border
  sheet.getCell(1, 1).font = boldFontHeader
  sheet.getCell(1, 1).fill = backgroundHeader

  columns?.forEach((param, idx) => {
    let col = 3
    let startColumn = 2 + idx * col
    let endColumn = 4 + idx * col
    if (period !== "raw") {
      col = 4
      startColumn = 2 + idx * col
      endColumn = 5 + idx * col
    }

    sheet.mergeCells(1, startColumn, 1, endColumn)
    sheet.getCell(1, 2 + idx * col).alignment = alignmentCenter
    sheet.getCell(1, 2 + idx * col).border = border
    sheet.getCell(1, 2 + idx * col).font = boldFontHeader
    sheet.getCell(1, 2 + idx * col).fill = backgroundHeader
    sheet.getCell(1, 2 + idx * col).value = param?.name

    if (period !== "raw") {
      sheet.getCell(2, 2 + idx * col).alignment = alignmentCenter
      sheet.getCell(2, 2 + idx * col).border = border
      sheet.getCell(2, 2 + idx * col).font = boldFontHeader
      sheet.getCell(2, 2 + idx * col).fill = backgroundHeader
      sheet.getCell(2, 2 + idx * col).value = "Count"
      
      sheet.getCell(2, 3 + idx * col).alignment = alignmentCenter
      sheet.getCell(2, 3 + idx * col).border = border
      sheet.getCell(2, 3 + idx * col).font = boldFontHeader
      sheet.getCell(2, 3 + idx * col).fill = backgroundHeader
      sheet.getCell(2, 3 + idx * col).value = `Value (${isDebit(param?.name) ? getUnitParameterDebit(period) : param?.uom || "-"})`
      
      sheet.getCell(2, 4 + idx * col).alignment = alignmentCenter
      sheet.getCell(2, 4 + idx * col).border = border
      sheet.getCell(2, 4 + idx * col).font = boldFontHeader
      sheet.getCell(2, 4 + idx * col).fill = backgroundHeader
      sheet.getCell(2, 4 + idx * col).value = "Battery (%)"
      
      sheet.getCell(2, 5 + idx * col).alignment = alignmentCenter
      sheet.getCell(2, 5 + idx * col).border = border
      sheet.getCell(2, 5 + idx * col).font = boldFontHeader
      sheet.getCell(2, 5 + idx * col).fill = backgroundHeader
      sheet.getCell(2, 5 + idx * col).value = "Delay (ms)"
    } else {
      sheet.getCell(2, 2 + idx * col).alignment = alignmentCenter
      sheet.getCell(2, 2 + idx * col).border = border
      sheet.getCell(2, 2 + idx * col).font = boldFontHeader
      sheet.getCell(2, 2 + idx * col).fill = backgroundHeader
      sheet.getCell(2, 2 + idx * col).value = `Value (${isDebit(param?.name) ? getUnitParameterDebit(period) : param?.uom || "-"})`
      
      sheet.getCell(2, 3 + idx * col).alignment = alignmentCenter
      sheet.getCell(2, 3 + idx * col).border = border
      sheet.getCell(2, 3 + idx * col).font = boldFontHeader
      sheet.getCell(2, 3 + idx * col).fill = backgroundHeader
      sheet.getCell(2, 3 + idx * col).value = "Battery (%)"
      
      sheet.getCell(2, 4 + idx * col).alignment = alignmentCenter
      sheet.getCell(2, 4 + idx * col).border = border
      sheet.getCell(2, 4 + idx * col).font = boldFontHeader
      sheet.getCell(2, 4 + idx * col).fill = backgroundHeader
      sheet.getCell(2, 4 + idx * col).value = "Delay (ms)"
    }
  })

  data?.forEach((row, idx) => {
    sheet.getCell(3 + idx, 1).value = formatDateWithPeriod(row.timestamp, period)
    sheet.getCell(3 + idx, 1).border = border

    row.payloads?.forEach((value, colIdx) => {
      const values = isAvgSensor(period, value.name.toLowerCase()) ? toFixed(value?.avg) : toFixed(value?.value)
      const battery = period === "raw" ? toFixed(value?.battery_percentage, 2) : toFixed(value?.avg_b_percentage, 2)
      const delay = period === "raw" ? toFixed(value?.delay, 2) : toFixed(value?.avg_delay, 2)

      if (period !== "raw") {
        sheet.getCell(3 + idx, 2 + colIdx * 4).value = value?.count
        sheet.getCell(3 + idx, 2 + colIdx * 4).border = border
        sheet.getCell(3 + idx, 2 + colIdx * 4).alignment = alignmentCenter
        
        sheet.getCell(3 + idx, 3 + colIdx * 4).value = values ?? 0
        sheet.getCell(3 + idx, 3 + colIdx * 4).border = border
        sheet.getCell(3 + idx, 3 + colIdx * 4).alignment = alignmentCenter
        
        sheet.getCell(3 + idx, 4 + colIdx * 4).value = battery ?? 0
        sheet.getCell(3 + idx, 4 + colIdx * 4).border = border
        sheet.getCell(3 + idx, 4 + colIdx * 4).alignment = alignmentCenter
        
        sheet.getCell(3 + idx, 5 + colIdx * 4).value = delay ?? 0
        sheet.getCell(3 + idx, 5 + colIdx * 4).border = border
        sheet.getCell(3 + idx, 5 + colIdx * 4).alignment = alignmentCenter
      } else {
        sheet.getCell(3 + idx, 2 + colIdx * 3).value = values ?? 0
        sheet.getCell(3 + idx, 2 + colIdx * 3).border = border
        sheet.getCell(3 + idx, 2 + colIdx * 3).alignment = alignmentCenter
        
        sheet.getCell(3 + idx, 3 + colIdx * 3).value = battery ?? 0
        sheet.getCell(3 + idx, 3 + colIdx * 3).border = border
        sheet.getCell(3 + idx, 3 + colIdx * 3).alignment = alignmentCenter
        
        sheet.getCell(3 + idx, 4 + colIdx * 3).value = delay ?? 0
        sheet.getCell(3 + idx, 4 + colIdx * 3).border = border
        sheet.getCell(3 + idx, 4 + colIdx * 3).alignment = alignmentCenter
      }
    })
  })

  sheet?.columns?.forEach((column) => {
    let maxLength = 0
    column?.eachCell?.({ includeEmpty: true }, (cell) => {
        const columnLength = cell?.value ? cell?.value?.toString()?.length : 10
        if (columnLength > maxLength ) {
            maxLength = columnLength
        }
    })
    column.width = maxLength < 10 ? 12 : maxLength + 2
  })
  download(book, title)
}

export const sensorDetailToExcel = (
  data: any[],
  period: string,
  param: string,
  name: string
) => {
  const book = new Excel.Workbook()
  const sheet = book.addWorksheet(`Sensor ${param} Detail`)
  const title = `${name} - Sensor ${param} Detail - ${NOW()}`

  const cols = [
    "Time",
    "Value",
    "Lower Threshold",
    "Upper Threshold",
    "Battery Sensor Status (%)",
    "Sensor Delay Status (ms)",
  ];

  cols?.forEach((col, index) => {
    sheet.getCell(1, 1 + index).alignment = alignmentCenter
    sheet.getCell(1, 1 + index).border = border
    sheet.getCell(1, 1 + index).font = boldFontHeader
    sheet.getCell(1, 1 + index).fill = backgroundHeader
    sheet.getCell(1, 1 + index).value = col
  })

  data?.forEach((row, idx) => {
    sheet.getCell(2 + idx, 1).value = formatDateWithPeriod(row.timestamp, period)
    sheet.getCell(2 + idx, 1).border = border

    sheet.getCell(2 + idx, 2).value = row?.value ?? 0
    sheet.getCell(2 + idx, 2).border = border
    sheet.getCell(2 + idx, 2).alignment = alignmentCenter

    sheet.getCell(2 + idx, 3).value = row?.min ?? 0
    sheet.getCell(2 + idx, 3).border = border
    sheet.getCell(2 + idx, 3).alignment = alignmentCenter

    sheet.getCell(2 + idx, 4).value = row?.max ?? 0
    sheet.getCell(2 + idx, 4).border = border
    sheet.getCell(2 + idx, 4).alignment = alignmentCenter

    sheet.getCell(2 + idx, 5).value = row?.battery ?? 0
    sheet.getCell(2 + idx, 5).border = border
    sheet.getCell(2 + idx, 5).alignment = alignmentCenter

    sheet.getCell(2 + idx, 6).value = row?.delay ?? 0
    sheet.getCell(2 + idx, 6).border = border
    sheet.getCell(2 + idx, 6).alignment = alignmentCenter
  })
  
  sheet?.columns?.forEach((column) => {
    let maxLength = 0
    column?.eachCell?.({ includeEmpty: true }, (cell) => {
        const columnLength = cell?.value ? cell?.value?.toString()?.length : 10
        if (columnLength > maxLength ) {
            maxLength = columnLength
        }
    })
    column.width = maxLength < 10 ? 12 : maxLength + 2
  })
  download(book, title)
}

export const percentageComplianceToExcel = (
  data: any[],
  payload: any,
  name: string
) => {
  const book = new Excel.Workbook()
  const sheet = book.addWorksheet("Percentage Compliance")
  const title = `${name} - Percentage Compliance - ${payload.sensor} - ${NOW()}`

  sheet.getCell(1, 1).value = "No"
  sheet.getCell(1, 1).alignment = alignmentCenter
  sheet.getCell(1, 1).border = border
  sheet.getCell(1, 1).font = boldFontHeader
  sheet.getCell(1, 1).fill = backgroundHeader

  sheet.getCell(1, 2).value = "Time"
  sheet.getCell(1, 2).alignment = alignmentCenter
  sheet.getCell(1, 2).border = border
  sheet.getCell(1, 2).font = boldFontHeader
  sheet.getCell(1, 2).fill = backgroundHeader
  
  sheet.getCell(1, 3).alignment = alignmentCenter
  sheet.getCell(1, 3).border = border
  sheet.getCell(1, 3).font = boldFontHeader
  sheet.getCell(1, 3).fill = backgroundHeader
  sheet.getCell(1, 3).value = "Value"
  
  const column = payload.period === "day" ? 4 : 3
  if (payload.period === "day") {
    sheet.getCell(1, 4).alignment = alignmentCenter
    sheet.getCell(1, 4).border = border
    sheet.getCell(1, 4).font = boldFontHeader
    sheet.getCell(1, 4).fill = backgroundHeader
    sheet.getCell(1, 4).value = "Percentage of Valid Data (%)"
  }
  
  sheet.getCell(1, column + 1).alignment = alignmentCenter
  sheet.getCell(1, column + 1).border = border
  sheet.getCell(1, column + 1).font = boldFontHeader
  sheet.getCell(1, column + 1).fill = backgroundHeader
  sheet.getCell(1, column + 1).value = "Percentage of Comply Data (%)"
  
  sheet.getCell(1, column + 2).alignment = alignmentCenter
  sheet.getCell(1, column + 2).border = border
  sheet.getCell(1, column + 2).font = boldFontHeader
  sheet.getCell(1, column + 2).fill = backgroundHeader
  sheet.getCell(1, column + 2).value = "Status"
  
  data?.forEach((row, idx) => {
    sheet.getCell(2 + idx, 1).value = idx + 1
    sheet.getCell(2 + idx, 1).border = border
    sheet.getCell(2 + idx, 1).alignment = alignmentCenter
    
    sheet.getCell(2 + idx, 2).value = formatDateWithPeriod(row.timestamp, payload.period)
    sheet.getCell(2 + idx, 2).border = border

    sheet.getCell(2 + idx, 3).value = row?.value
    sheet.getCell(2 + idx, 3).border = border
    sheet.getCell(2 + idx, 3).alignment = alignmentCenter
    
    if (payload.period === "day") {
      sheet.getCell(2 + idx, 4).value = row.valid
      sheet.getCell(2 + idx, 4).border = border
      sheet.getCell(2 + idx, 4).alignment = alignmentCenter
    }

    sheet.getCell(2 + idx, column + 1).value = row?.comply
    sheet.getCell(2 + idx, column + 1).border = border
    sheet.getCell(2 + idx, column + 1).alignment = alignmentCenter

    sheet.getCell(2 + idx, column + 2).value = payload.period === "day" ? row?.compliance : row?.validity
    sheet.getCell(2 + idx, column + 2).border = border
    sheet.getCell(2 + idx, column + 2).alignment = alignmentCenter
  })

  sheet?.columns?.forEach((column) => {
    let maxLength = 0
    column?.eachCell?.({ includeEmpty: true }, (cell) => {
        const columnLength = cell?.value ? cell?.value?.toString()?.length : 4
        if (columnLength > maxLength ) {
            maxLength = columnLength
        }
    })
    column.width = maxLength < 4 ? 6 : maxLength + 2
  })

  download(book, title)
}

type accumulatedLoadType = {
  columns: (Parameter | OverviewParameter)[]
  data: any[]
  period: string
  name: string
  aggregate: any[]
}

export const accumulatedLoadToExcel = ({
  columns,
  data,
  period,
  name,
  aggregate,
}: accumulatedLoadType) => {
  const book = new Excel.Workbook()
  const sheet = book.addWorksheet("Parameter Accumulated Load")
  const title = `${name} - Parameter Accumulated Load - ${NOW()}`

  sheet.getCell(1, 1).value = "No"
  sheet.getCell(1, 1).alignment = alignmentCenter
  sheet.getCell(1, 1).border = border
  sheet.getCell(1, 1).font = boldFontHeader
  sheet.getCell(1, 1).fill = backgroundHeader

  sheet.getCell(1, 2).value = "Time"
  sheet.getCell(1, 2).alignment = alignmentCenter
  sheet.getCell(1, 2).border = border
  sheet.getCell(1, 2).font = boldFontHeader
  sheet.getCell(1, 2).fill = backgroundHeader

  columns?.forEach((param, idx) => {
    sheet.getCell(1, 3 + idx).alignment = alignmentCenter
    sheet.getCell(1, 3 + idx).border = border
    sheet.getCell(1, 3 + idx).font = boldFontHeader
    sheet.getCell(1, 3 + idx).fill = backgroundHeader
    sheet.getCell(1, 3 + idx).value = `${param?.name} (${param?.uom || "-"})`
  })
  
  data?.forEach((row, idx) => {
    sheet.getCell(2 + idx, 1).value = idx + 1
    sheet.getCell(2 + idx, 1).border = border
    sheet.getCell(2 + idx, 1).alignment = alignmentCenter

    sheet.getCell(2 + idx, 2).value = formatDateWithPeriod(row.timestamp, period)
    sheet.getCell(2 + idx, 2).border = border

    row.payloads?.forEach((value, col) => {
      const accumulatedValue = isAvgSensor(period, value?.name) ? toFixed(value?.avg) : toFixed(value?.value)
      
      sheet.getCell(2 + idx, 3 + col).value = accumulatedValue ?? 0
      sheet.getCell(2 + idx, 3 + col).border = border
      sheet.getCell(2 + idx, 3 + col).alignment = alignmentCenter
    })
  })

  sheet.mergeCells(data?.length + 2, 1, data?.length + 4, 1)
  sheet.getCell(data?.length + 2, 1).value = "Aggregate"
  sheet.getCell(data?.length + 2, 1).alignment = alignmentCenter
  sheet.getCell(data?.length + 2, 1).border = border
  sheet.getCell(data?.length + 2, 1).font = boldFontHeader
  sheet.getCell(data?.length + 2, 1).fill = backgroundHeader

  sheet.getCell(data?.length + 2, 2).value = "AVG"
  sheet.getCell(data?.length + 2, 2).alignment = alignmentCenter
  sheet.getCell(data?.length + 2, 2).border = border
  sheet.getCell(data?.length + 2, 2).font = boldFontHeader
  sheet.getCell(data?.length + 2, 2).fill = backgroundYellow

  columns?.forEach((_, idx) => {
    sheet.getCell(data?.length + 2, 3 + idx).value = toFixed(aggregate?.[idx]?.avg ?? 0)
    sheet.getCell(data?.length + 2, 3 + idx).border = border
    sheet.getCell(data?.length + 2, 3 + idx).alignment = alignmentCenter
    sheet.getCell(data?.length + 2, 3 + idx).font = boldFontHeader
    sheet.getCell(data?.length + 2, 3 + idx).fill = backgroundYellow
  })

  sheet.getCell(data?.length + 3, 2).value = "MIN"
  sheet.getCell(data?.length + 3, 2).alignment = alignmentCenter
  sheet.getCell(data?.length + 3, 2).border = border
  sheet.getCell(data?.length + 3, 2).font = boldFontHeader
  sheet.getCell(data?.length + 3, 2).fill = backgroundGreen

  columns?.forEach((_, idx) => {
    sheet.getCell(data?.length + 3, 3 + idx).value = toFixed(aggregate?.[idx]?.min ?? 0)
    sheet.getCell(data?.length + 3, 3 + idx).border = border
    sheet.getCell(data?.length + 3, 3 + idx).alignment = alignmentCenter
    sheet.getCell(data?.length + 3, 3 + idx).font = boldFontHeader
    sheet.getCell(data?.length + 3, 3 + idx).fill = backgroundGreen
  })

  sheet.getCell(data?.length + 4, 2).value = "MAX"
  sheet.getCell(data?.length + 4, 2).alignment = alignmentCenter
  sheet.getCell(data?.length + 4, 2).border = border
  sheet.getCell(data?.length + 4, 2).font = boldFontHeader
  sheet.getCell(data?.length + 4, 2).fill = backgroundRed

  columns?.forEach((_, idx) => {
    sheet.getCell(data?.length + 4, 3 + idx).value = toFixed(aggregate?.[idx]?.max ?? 0)
    sheet.getCell(data?.length + 4, 3 + idx).border = border
    sheet.getCell(data?.length + 4, 3 + idx).alignment = alignmentCenter
    sheet.getCell(data?.length + 4, 3 + idx).font = boldFontHeader
    sheet.getCell(data?.length + 4, 3 + idx).fill = backgroundRed
  })

  sheet?.columns?.forEach((column) => {
    let maxLength = 0
    column?.eachCell?.({ includeEmpty: true }, (cell) => {
        const columnLength = cell?.value ? cell?.value?.toString()?.length : 10
        if (columnLength > maxLength ) {
            maxLength = columnLength
        }
    })
    column.width = maxLength < 10 ? 12 : maxLength + 2
  })
  download(book, title)
}
