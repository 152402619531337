import { FunctionComponent, h } from "preact"
import { useEffect, useState, useRef } from "preact/hooks"
import { route } from "preact-router"
import Flatpickr from "react-flatpickr"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import Spinner from "../../../components/spinner"
import useAuth from "../../../hooks/useAuth"
import useSites from "../../../hooks/useSite"
import { useNCRByID } from "../../../hooks/useNcr"
import useObjectState from "../../../hooks/useObjectState"
import { formatDate } from "../../../utils/dateformat"
import "flatpickr/dist/flatpickr.css"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type EditNCRProps = h.JSX.HTMLAttributes<HTMLDivElement>

const defaultBody = {
  lk_number: "",
  date: "",
  site: { uuid: "" },
  parameter: "",
  condition: "",
  description: "",
  problem: "",
  action: "",
  completion_date_target: "",
  abnormal_type: "",
  pic_problem: "",
  pic_solve: "",
}

const flatpickrOptions = {
  dateFormat: "Y-m-d",
  altInput: true,
  altFormat: "d/m/Y",
}

const EditNCR: FunctionComponent<EditNCRProps> = () => {
  const { t } = useTranslation()
  const pathname = window.location.pathname
  const uuid = pathname.split("/").length === 5 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""
  const { data: cems } = useSites()
  const [body, setBody] = useObjectState(defaultBody)
  const [disable, setDisable] = useState(false)
  const { data } = useNCRByID(uuid)
  const { fetcher } = useAuth()
  const parent = `/maintenance/ncr`
  const imageUrl = "/ncr/upload"
  const header = { headers: { "Content-Type": "multipart/form-data" } }
  const onBack = () => route(parent)
  const problemImage = useRef()
  const solveImage = useRef()

  const onSiteNameChange = (currentTarget) => {
    const value = currentTarget.value
    const siteName = cems?.find(({ uuid }) => uuid === value)?.name
    const lkNumber = siteName ? `${siteName}/${body.date}` : ""
    setBody({
      cem: { uuid: value },
      lk_number: lkNumber,
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    try {
      const { abnormal_type, pic_problem, pic_solve, condition, ...data } = body
      const abnormal = condition === "Abnormal Condition" ? `${condition} - ${abnormal_type}` : condition
      let problemFile = pic_problem
      let solveFile = pic_solve
      if (problemImage.current?.files?.length) {
        const imgProblem = new FormData()
        imgProblem.append("image", problemImage.current.files[0])
        const { data: picProblem } = await fetcher().post(imageUrl, imgProblem, header)
        problemFile = picProblem?.data?.path
      }
      if (solveImage.current?.files?.length) {
        const imgsolve = new FormData()
        imgsolve.append("image", solveImage.current.files[0])
        const { data: picSolve } = await fetcher().post(imageUrl, imgsolve, header)
        solveFile = picSolve?.data?.path
      }

      await fetcher().patch(`/ncr/${uuid}`, {
        ...data,
        condition: abnormal,
        pic_problem: problemFile,
        pic_solve: solveFile,
      })
      await onBack()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  const showImage = async (image) => {
    if (!image) return
    const { data } = await fetcher().get(`/ncr/show-foto/${image}`, { responseType: "blob" })
    const imageObjectURL = URL.createObjectURL(data)
    window.open(imageObjectURL)
  }

  useEffect(() => {
    if (data) {
      const { lk_number, date, site, parameter, condition, description, problem, action, pic_problem, pic_solve, completion_date_target } = data
      setBody({
        lk_number,
        date,
        site: { uuid: site?.uuid },
        parameter,
        condition: condition.split(" - ")?.[0],
        abnormal_type: condition.split(" - ")?.[1],
        description,
        problem,
        action,
        pic_problem,
        pic_solve,
        completion_date_target,
      })
    }
  }, [data])

  return (
    <div>
      <Spinner show={!data} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.maintenance")} link="" />
        <Breadcrumb name={t("breadcrumb.ncr")} link={parent} />
        <Breadcrumb name={t("breadcrumb.edit")} link={`/maintenance/ncr/edit/${uuid}`} />
      </TopBar>
      <Container>
        <div class="card">
          <div className="card-title pt-4">
            <div className="d-flex gap-4 align-items-center">
              <p className={style.page_title}>{t("page.ncr.edit_ncr_report")}</p>
            </div>
          </div>
          <form className="card-body" onSubmit={(event) => handleSubmit(event)}>
            <div className="mb-4">
              <label class="form-label">{t("label.lk_number")}</label>
              <input class="form-control rounded mw-100" type="text" value={body.lk_number} readOnly />
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.date")}</label>
              <input class="form-control rounded mw-100" type="text" value={formatDate(body.date, "DD MMMM YYYY")} readOnly />
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.site_name")}</label>
              <select
                className="form-control rounded mw-100"
                required
                value={body.site.uuid}
                onChange={({ currentTarget }) => onSiteNameChange(currentTarget)}
              >
                <option value="">{t("label.select_site")}</option>
                {cems?.map((cem) => (
                  <option key={cem.uuid} value={cem.uuid}>
                    {cem.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.parameter")}</label>
              <select
                required
                value={body.parameter}
                className="form-control rounded mw-100"
                onChange={({ currentTarget: e }) => setBody({ parameter: e.value })}
              >
                <option value="">{t("label.select_parameter")}</option>
                {cems
                  ?.find(({ uuid }) => uuid == body.site.uuid)
                  ?.parameters?.filter(({ is_active }) => is_active)
                  ?.map((param) => (
                    <option value={param.name} key={param.uuid}>
                      {param.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.condition")}</label>
              <select
                required
                className="form-control rounded mw-100"
                value={body.condition}
                onChange={({ currentTarget: e }) => setBody({ condition: e.value })}
              >
                <option value="Abnormal Condition">Abnormal Condition</option>
                <option value="Emergency Condition">Emergency Condition</option>
              </select>
            </div>
            {body.condition == "Abnormal Condition" && (
              <div className="mb-4">
                <label class="form-label">{t("label.abnormal_condition_type")}</label>
                <select
                  required
                  className="form-control rounded mw-100"
                  value={body.abnormal_type}
                  onChange={({ currentTarget: e }) => setBody({ abnormal_type: e.value })}
                >
                  <option value="Maintenance">Maintenance</option>
                  <option value="Calibration">Calibration</option>
                  <option value="Abnormal Site">Abnormal Site</option>
                  <option value="Abnormal SPARING">Abnormal SPARING</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            )}
            <div className="mb-4">
              <label class="form-label">{t("label.description")}</label>
              <textarea
                required
                rows="5"
                value={body.description}
                onChange={({ currentTarget: e }) => setBody({ description: e.value })}
                className="form-control rounded-3 mw-100"
              />
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.root_problem_analytics")}</label>
              <textarea
                required
                rows="5"
                value={body.problem}
                onChange={({ currentTarget: e }) => setBody({ problem: e.value })}
                className="form-control rounded-3 mw-100"
              />
            </div>
            <div class="mb-4">
              <label class="form-label">
                {t("label.root_problem_analytic_image")}
                {body?.pic_problem && (
                  <Button
                    variant="primary"
                    type="button"
                    className="m-1 ms-4 btn-sm"
                    disabled={!body?.pic_problem}
                    onClick={() => showImage(body?.pic_problem)}
                  >
                    {t("buttons.view_file")}
                  </Button>
                )}
              </label>
              <input ref={problemImage} class="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" />
              {body?.pic_problem && <p className={style.file_input_desc}>{t("page.ncr.ignore_input_file")}</p>}
            </div>
            <div className="mb-4">
              <label class="form-label">{t("label.repair_action_prevention")}</label>
              <textarea
                required
                rows="5"
                value={body.action}
                onChange={({ currentTarget: e }) => setBody({ action: e.value })}
                className="form-control rounded-3 mw-100"
              />
            </div>
            <div class="mb-4">
              <label class="form-label">
                {t("label.repair_action_prevention_image")}
                {body?.pic_solve && (
                  <Button
                    variant="primary"
                    type="button"
                    className="m-1 ms-4 btn-sm"
                    disabled={!body?.pic_solve}
                    onClick={() => showImage(body?.pic_solve)}
                  >
                    {t("buttons.view_file")}
                  </Button>
                )}
              </label>
              <input ref={solveImage} class="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" />
              {body?.pic_solve && <p className={style.file_input_desc}>{t("page.ncr.ignore_input_file")}</p>}
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.completion_date_target")}</label>
              <Flatpickr
                value={body.completion_date_target}
                options={flatpickrOptions}
                class="form-control rounded mw-100"
                onChange={(date: Date) => setBody({ completion_date_target: formatDate(date[0], "YYYY-MM-DD") })}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button type="submit" className="mx-1" variant="primary" disabled={disable}>
                {t("buttons.save")}
              </Button>
              <Button onClick={onBack} type="button" className="mx-1" variant="tertiary" disabled={disable}>
                {t("buttons.cancel")}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  )
}

export default EditNCR
