import { FunctionComponent, h } from "preact"
import Container from "../../parts/container"
import PrivacyPolicyContent from "./privacy-policy-content"

type PrivacyPolicyProps = h.JSX.HTMLAttributes<HTMLDivElement>

const PrivacyPolicy: FunctionComponent<PrivacyPolicyProps> = () => {

  return (
    <div className="min-vh-100">
      <Container>
        <h1 className="fs-4 fw-bold mb-5 text-center">Kebijakan Privasi INTUSI</h1>
        <PrivacyPolicyContent />
      </Container>
    </div>
  )
}

export default PrivacyPolicy
