import { ApiResponse } from "../types/api-response"
import useAuth from "./useAuth"
import useSWR from "swr"

export interface KLHKResponse {
  uuid: string
  area: string
  parameter: string
  description: string
  completion_date: string
  file: string
  status: string
  created_at: string
  updated_at: string
  deleted_at: any
  mil: Mil
  cem: Cem
  ncr_klhk_status_history: NcrStatusHistory[]
}

export interface NCRResponse {
  uuid: string
  lk_number: string
  date: string
  area: string
  parameter: string
  condition: string
  description: string
  problem: string
  action: string
  completion_date_target: string
  first_approval: string
  second_approval: string
  validator: string
  created_at: string
  updated_at: string
  deleted_at: any
  mil: Mil
  cem: Cem
  ncr_status_history: NcrStatusHistory[]
}

interface Mil {
  uuid: string
  name: string
}

interface Cem {
  uuid: string
  name: string
}

interface NcrStatusHistory {
  uuid: string
  status: string
  created_at: string
  updated_at: string
  deleted_at: any
  created_by: CreatedBy
}

interface CreatedBy {
  uuid: string
  name: string
  email: string
  role: string
}

interface NcrListPaginate {
  site: string
  page: number
  range: string[]
  limit: number
  orders?: { [field: string]: string }[]
}

interface NcrKlhkListPaginate {
  site: string
  page: number
  range: string[]
  limit: number
  orders?: { [field: string]: string }[]
}

export const useNCRKLHK = ({ site, page, range, limit, orders = [] }: NcrKlhkListPaginate) => {
  const url = `/ncr-klhk/`
  let body = JSON.stringify({
    site,
    start: range?.[0],
    end: range?.[1],
    page,
    limit,
  })

  if (orders.length) {
    let orderParams = {}
    const sort = orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    body = JSON.stringify({ site, start: range?.[0], end: range?.[1], page, limit, ...orderParams })
  }

  const { fetcher } = useAuth()
  const output = useSWR([url, body], (url, body) => {
    if (!site) return []
    return fetcher()
      .get<ApiResponse<KLHKResponse[]>>(url, { params: JSON.parse(body) })
      .then(({ data }) => data.data)
  })
  return output
}
export const useNCRKLHKByID = (uuid: string) => {
  const url = `/ncr-klhk/${uuid}`
  const { fetcher } = useAuth()
  const output = useSWR([url], (url) => {
    return fetcher()
      .get<ApiResponse<KLHKResponse[]>>(url)
      .then(({ data }) => data.data)
  })
  return output
}

export const useNcrList = ({ site, page, range, limit, orders = [] }: NcrListPaginate) => {
  const url = `/ncr/`
  let body = JSON.stringify({
    site,
    start: range?.[0],
    end: range?.[1],
    page,
    limit,
  })

  if (orders.length) {
    let orderParams = {}
    const sort = orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    body = JSON.stringify({ site, start: range?.[0], end: range?.[1], page, limit, ...orderParams })
  }

  const { fetcher } = useAuth()
  const output = useSWR([url, body], (url, body) => {
    if (!site) return []
    return fetcher()
      .get<ApiResponse<NCRResponse[]>>(url, { params: JSON.parse(body) })
      .then(({ data }) => data.data)
  })
  return output
}

export const useNCRByID = (uuid: string) => {
  const url = `/ncr/${uuid}`
  const { fetcher } = useAuth()
  const output = useSWR([url], (url) => {
    return fetcher()
      .get<ApiResponse<KLHKResponse[]>>(url)
      .then(({ data }) => data.data)
  })
  return output
}
