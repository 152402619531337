import { h } from "preact"
import { useState } from "preact/hooks"
import moment from "moment"
import { useRecordGraph } from "../../../hooks/useSensor"
import RecordChart from "../../../components/chart/record"
import { chartDateWithPeriod, getDateDifference } from "../../../utils/dateformat"
import { isAvgSensor } from "../../../utils/sortParam"
import { toFixed } from "../../../utils/toFixed"
import Spinner from "../../../components/spinner"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type ChartData = {
  name: string
  lower_threshold: number
  upper_threshold: number
  min?: number
  max?: number
  avg?: number
  last: number
  label: Date[]
  dataset: number[]
}

type Props = {
  uuid: string
  params: string[]
}

const RealtimeGraph = ({ uuid, params }: Props) => {
  const { t } = useTranslation()
  const [activePeriod, setActivePeriod] = useState("hour")
  const [activeParam, setActiveParam] = useState(params?.[0])
  const startHourly = moment().startOf("day").format("X")
  const endHourly = moment().startOf("hour").format("X")
  const startMonthly = moment().startOf("month").format("X")
  const endMonthly = moment().startOf("day").format("X")
  const { data } = useRecordGraph({
    uuid,
    type: activePeriod,
    start: activePeriod === "hour" ? startHourly : startMonthly,
    end: activePeriod === "hour" ? endHourly : endMonthly,
  })
  const date =
    data?.record
      ?.map(({ timestamp }) => timestamp)
      .slice()
      ?.reverse() || []
  const isMoreThanOneDay = !!getDateDifference({ firstDate: date?.[0], secondDate: date?.at(-1) })
  const label = date?.map((item) => chartDateWithPeriod(item, "hour", isMoreThanOneDay))
  const parameter = data?.parameter?.find(({ name }) => name === activeParam)
  const calculation = data?.calculation?.find(({ name }) => name === activeParam)
  let dataset = []
  if (isAvgSensor(activePeriod, activeParam)) {
    dataset =
      data?.record
        ?.map(({ data }) => data?.find(({ name }) => name === activeParam)?.avg)
        ?.map((item) => toFixed(item))
        ?.reverse() || []
  } else {
    dataset =
      data?.record
        ?.map(({ data }) => data?.find(({ name }) => name === activeParam)?.value)
        ?.map((item) => toFixed(item))
        ?.reverse() || []
  }

  return (
    <div className="position-relative">
      <Spinner show={!data} />
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex">
          <button
            onClick={() => setActivePeriod("hour")}
            className={`${activePeriod === "hour" ? style.graph_switch_button_active : style.graph_switch_button}`}
          >
            {t("hourly")}
          </button>
          <button
            onClick={() => setActivePeriod("day")}
            className={`${activePeriod === "day" ? style.graph_switch_button_active : style.graph_switch_button}`}
          >
            {t("daily")}
          </button>
        </div>
        <div className="d-flex gap-2">
          {params?.map((param, index) => (
            <button
              onClick={() => setActiveParam(param)}
              className={`${activeParam === param ? style.param_button_active : style.param_button_normal}`}
              key={index}
            >
              {param}
            </button>
          ))}
        </div>
      </div>
      <RecordChart
        height={80}
        avg={calculation?.avg}
        min={calculation?.min}
        max={calculation?.max}
        data={dataset}
        labels={label}
        upper={parameter?.upper_threshold}
        lower={parameter?.lower_threshold}
        showMin={false}
        showMax={false}
        showAvg={false}
        showLower={false}
        showUpper={false}
      />
    </div>
  )
}

export default RealtimeGraph
