import { can, canOneOf } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import { MdInsertChartOutlined, MdSensors, MdHelpOutline } from "react-icons/md"
import { BsFileEarmarkSpreadsheet, BsGrid, BsFileEarmarkRuled, BsFileRuled, BsUiRadiosGrid } from "react-icons/bs"
import { AiOutlineFileDone, AiOutlineSchedule, AiOutlineSetting, AiOutlineLineChart, AiOutlineMobile } from "react-icons/ai"
import { TbFileCheck, TbStack2, TbShieldLock, TbFilePencil, TbTools, TbClockCheck, TbDeviceMobileCog } from "react-icons/tb"
import { PiAddressBookLight } from "react-icons/pi"
import { BiBuildings, BiListPlus } from "react-icons/bi"
import { PiGavel, PiFolderSimpleStarLight } from "react-icons/pi"
import { HiOutlineKey } from "react-icons/hi"
import { HiOutlineWrenchScrewdriver, HiOutlineBuildingStorefront } from "react-icons/hi2"
import { FiUser, FiLock } from "react-icons/fi"
import { LiaChartAreaSolid, LiaChartLineSolid, LiaFileInvoiceDollarSolid } from "react-icons/lia"
import { RiDashboardLine, RiRoadMapLine, RiComputerLine, RiDeviceLine, RiBatteryLine } from "react-icons/ri"
import RecordIcon from "./icons/record"
import SensorDataIcon from "./icons/sensor-data"
import SensorDetailIcon from "./icons/sensor-detail"
import ErrorLogIcon from "./icons/error-log"
import SiteDataIcon from "./icons/site-data"
import { useTranslation } from "react-i18next"

type MenuItem = {
  label: string
  link?: string
  sub: {
    label: string
    link: string
  }[]
}[]

export const menuList = (): MenuItem => {
  const { t } = useTranslation()
  return [
    ...(canOneOf([Permission.DashboardMapView, Permission.DashboardMonitoringView, Permission.DashboardParameterComplianceView])
      ? [
          {
            label: t("navigation.dashboard"),
            icon: RiDashboardLine,
            sub: [
              ...(can(Permission.DashboardMapView)
                ? [
                    {
                      label: t("navigation.map"),
                      link: "/dashboard/map",
                      icon: RiRoadMapLine,
                    },
                  ]
                : []),
              ...(can(Permission.DashboardMonitoringView)
                ? [
                    {
                      label: t("navigation.monitoring"),
                      link: "/dashboard/monitoring",
                      icon: RiComputerLine,
                    },
                  ]
                : []),
              ...(can(Permission.DashboardRealtimeView)
                ? [
                    {
                      label: t("navigation.realtime"),
                      link: "/dashboard/realtime",
                      icon: TbClockCheck,
                    },
                  ]
                : []),
              ...(can(Permission.DashboardParameterComplianceView)
                ? [
                    {
                      label: t("navigation.parameter_compliance"),
                      link: "/dashboard/parameter-compliance",
                      icon: AiOutlineFileDone,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(canOneOf([
      Permission.RecordGraphView,
      Permission.RecordSensorDataView,
      Permission.RecordSensorDetailView,
      Permission.RecordPercentageComplianceDataView,
      Permission.RecordParameterAccumulatedLoadView,
    ])
      ? [
          {
            label: t("navigation.record"),
            icon: RecordIcon,
            sub: [
              ...(can(Permission.RecordGraphView)
                ? [
                    {
                      label: t("navigation.graph"),
                      link: "/record/graph",
                      icon: MdInsertChartOutlined,
                    },
                  ]
                : []),
              ...(can(Permission.RecordSensorDataView)
                ? [
                    {
                      label: t("navigation.sensor_data"),
                      link: "/record/sensor-data",
                      icon: SensorDataIcon,
                    },
                  ]
                : []),
              ...(can(Permission.RecordSensorDetailView)
                ? [
                    {
                      label: t("navigation.sensor_detail"),
                      link: "/record/sensor-detail",
                      icon: SensorDetailIcon,
                    },
                  ]
                : []),
              ...(can(Permission.RecordPercentageComplianceDataView)
                ? [
                    {
                      label: t("navigation.percentage_compliance_data"),
                      link: "/record/percentage-compliance-data",
                      icon: TbFileCheck,
                    },
                  ]
                : []),
              ...(can(Permission.RecordParameterAccumulatedLoadView)
                ? [
                    {
                      label: t("navigation.parameter_accumulated_load"),
                      link: "/record/parameter-accumulated-load",
                      icon: BsFileEarmarkSpreadsheet,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(canOneOf([Permission.AnalyticMultiSiteView, Permission.AnalyticPeriodView])
      ? [
          {
            label: t("navigation.analytic"),
            icon: AiOutlineLineChart,
            sub: [
              ...(can(Permission.AnalyticMultiSiteView)
                ? [
                    {
                      label: t("navigation.multi_site"),
                      link: "/analytic/multi-site",
                      icon: LiaChartAreaSolid,
                    },
                  ]
                : []),
              ...(can(Permission.AnalyticPeriodView)
                ? [
                    {
                      label: t("navigation.period"),
                      link: "/analytic/period",
                      icon: LiaChartLineSolid,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(can(Permission.ErrorLogView)
      ? [
          {
            label: t("navigation.error_log"),
            link: "/error-log",
            icon: ErrorLogIcon,
            sub: [],
          },
        ]
      : []),
    ...(canOneOf([Permission.DeviceSensorDelayView, Permission.DeviceBatteryStatusView])
      ? [
          {
            label: t("navigation.device"),
            icon: RiDeviceLine,
            sub: [
              ...(can(Permission.DeviceSensorDelayView)
                ? [
                    {
                      label: t("navigation.sensor_delay"),
                      link: "/device/sensor-delay",
                      icon: MdSensors,
                    },
                  ]
                : []),
              ...(can(Permission.DeviceBatteryStatusView)
                ? [
                    {
                      label: t("navigation.battery_status"),
                      link: "/device/battery-status",
                      icon: RiBatteryLine,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(canOneOf([Permission.MaintenanceNcrView, Permission.MaintenanceNcrKlhkView, Permission.MaintenanceCalibrationReportView])
      ? [
          {
            label: t("navigation.maintenance"),
            icon: HiOutlineWrenchScrewdriver,
            sub: [
              ...(can(Permission.MaintenanceNcrView)
                ? [
                    {
                      label: t("navigation.ncr"),
                      link: "/maintenance/ncr",
                      icon: BsFileRuled,
                    },
                  ]
                : []),
              ...(can(Permission.MaintenanceNcrKlhkView)
                ? [
                    {
                      label: t("navigation.ncr_klhk"),
                      link: "/maintenance/ncr-klhk",
                      icon: BsFileEarmarkRuled,
                    },
                  ]
                : []),
              ...(can(Permission.MaintenanceCalibrationReportView)
                ? [
                    {
                      label: t("navigation.calibration_report"),
                      link: "/maintenance/calibration-report",
                      icon: TbFilePencil,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(can(Permission.RegulationView)
      ? [
          {
            label: t("navigation.regulation"),
            link: "/regulation",
            icon: PiGavel,
            sub: [],
          },
        ]
      : []),
    ...(can(Permission.SubscriptionView)
      ? [
          {
            label: t("navigation.subscription"),
            link: "/subscription",
            icon: AiOutlineSchedule,
            sub: [],
          },
        ]
      : []),
    ...(canOneOf([Permission.SettingsCompanyView, Permission.SettingsSiteDataView, Permission.SettingsUserView])
      ? [
          {
            label: t("navigation.settings"),
            icon: AiOutlineSetting,
            sub: [
              ...(can(Permission.SettingsModuleView)
                ? [
                    {
                      label: t("navigation.module"),
                      link: "/settings/module",
                      icon: TbStack2,
                    },
                  ]
                : []),
              ...(can(Permission.SettingsPlatformView)
                ? [
                    {
                      label: t("navigation.platform"),
                      link: "/settings/platform",
                      icon: BsUiRadiosGrid,
                    },
                  ]
                : []),
              ...(can(Permission.SettingsVendorView)
                ? [
                    {
                      label: t("navigation.vendor"),
                      link: "/settings/vendor",
                      icon: HiOutlineBuildingStorefront,
                    },
                  ]
                : []),
              ...(can(Permission.SettingsCompanyView)
                ? [
                    {
                      label: t("navigation.company"),
                      link: "/settings/company",
                      icon: BiBuildings,
                    },
                  ]
                : []),
              ...(can(Permission.SettingsSiteDataView)
                ? [
                    {
                      label: t("navigation.site_data"),
                      link: "/settings/site-data",
                      icon: SiteDataIcon,
                    },
                  ]
                : []),
              ...(can(Permission.SettingsUserView)
                ? [
                    {
                      label: t("navigation.user"),
                      link: "/settings/user",
                      icon: FiUser,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(canOneOf([Permission.FinanceView])
      ? [
          {
            label: t("navigation.finance"),
            link: "/finance",
            icon: LiaFileInvoiceDollarSolid,
            sub: [],
          },
        ]
      : []),
    ...(canOneOf([Permission.ToolsDummyDataView, Permission.ToolsMobileVersionView])
      ? [
          {
            label: t("navigation.tools"),
            icon: TbTools,
            sub: [
              ...(can(Permission.ToolsDummyDataView)
                ? [
                    {
                      label: t("navigation.dummy_data"),
                      link: "/tools/dummy-data",
                      icon: BiListPlus,
                    },
                  ]
                : []),
              ...(can(Permission.ToolsMobileVersionView)
                ? [
                    {
                      label: t("navigation.mobile_version"),
                      link: "/tools/mobile-version",
                      icon: TbDeviceMobileCog,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(canOneOf([Permission.AccessFeatureView, Permission.AccessMenuView, Permission.AccessPermissionView, Permission.AccessRoleView])
      ? [
          {
            label: t("navigation.access"),
            icon: FiLock,
            sub: [
              ...(can(Permission.AccessFeatureView)
                ? [
                    {
                      label: t("navigation.feature"),
                      link: "/access/feature",
                      icon: PiFolderSimpleStarLight,
                    },
                  ]
                : []),
              ...(can(Permission.AccessMenuView)
                ? [
                    {
                      label: t("navigation.menu"),
                      link: "/access/menu",
                      icon: BsGrid,
                    },
                  ]
                : []),
              ...(can(Permission.AccessPermissionView)
                ? [
                    {
                      label: t("navigation.permission"),
                      link: "/access/permission",
                      icon: HiOutlineKey,
                    },
                  ]
                : []),
              ...(can(Permission.AccessRoleView)
                ? [
                    {
                      label: t("navigation.role"),
                      link: "/access/role",
                      icon: TbShieldLock,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(can(Permission.HelpView)
      ? [
          {
            label: t("navigation.help"),
            icon: MdHelpOutline,
            sub: [
              ...(can(Permission.HelpView)
                ? [
                    {
                      label: t("navigation.user_manual"),
                      link: "/help/user-manual",
                      icon: PiAddressBookLight,
                    },
                  ]
                : []),
              ...(can(Permission.HelpView)
                ? [
                    {
                      label: t("navigation.contact_support"),
                      link: "/help/support",
                      icon: MdHelpOutline,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(can(Permission.MobileAppView)
      ? [
          {
            label: t("navigation.download_mobile_app"),
            link: "/mobile-app",
            icon: AiOutlineMobile,
            sub: [],
          },
        ]
      : []),
  ]
}
