export const generateRandomColor = () => {
  const color = Math.floor(Math.random()*16777215).toString(16); // generate six hex code
  if (color === '000000' || color === 'ffffff') generateRandomColor();
  return `#${color}`;
};

export const analyticChartColor = (index: number): string => {
  const colors = [
    "#3D75CA", // graph-5
    "#E96447", // graph-2
    "#5FB986", // graph-3
    "#51BCDA", // graph-1
    "#F1BA61", //graph-4
    "#AB5994", //graph-6
    "#E49263", // graph-7
    "#B22222",
    "#FF69B4",
    "#065535",
    "#133337",
    "#ffc0cb",
    "#c6e2ff",
    "#666666",
    "#003366",
    "#c39797",
    "#00ff00",
    "#420420",
    "#a0db8e",
    "#999999",
  ]
  const newIndex = +index >= colors.length ? Math.floor(Math.random()*colors.length) : index
  return  colors[newIndex]
}
