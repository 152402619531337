import useAuth from "./useAuth"
import useSWR from "swr"

export const useFeature = () => {
  const url = "/list-feature"
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, error, mutate }
}

export const useMenu = () => {
  const url = "/menu-list"
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, error, mutate }
}

export const usePermission = () => {
  const url = "/permission"
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, error, mutate }
}

export const useRole = () => {
  const url = "/role"
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, error, mutate }
}

export const useAccess = () => {
  const url = "/list-feature/list-feature"
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, error, mutate }
}

export const useGetPermissionById = (id) => {
  const url = `/permission/assign-permission/${id}`
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, error, mutate }
}
