import React from "react"
import { useEffect, useState } from "preact/hooks"

import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import Container from "../../../parts/container"
import { Button } from "../../../parts/buttons"
import { can } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import { useCurrenVersion } from "../../../hooks/useMobileVersion"
import { getErrorResponse } from "../../../utils/handler-errors"
import useAuth from "../../../hooks/useAuth"
import { useTranslation } from "react-i18next"

type MobileVersionProps = h.JSX.HTMLAttributes<HTMLDivElement>
const MobileVersion: FunctionComponent<MobileVersionProps> = () => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const { data, mutate } = useCurrenVersion()
  const [version, setVersion] = useState<String>("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const body = {
      value: version,
    }
    try {
      await fetcher().patch(`/configs/mobile-version`, body)
      await mutate()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const error = e.response
      alert(getErrorResponse(error))
    }
  }

  useEffect(() => {
    setVersion(data?.value)
  }, [data])

  return (
    <div>
      <TopBar>
        <Breadcrumb name={t("breadcrumb.tools")} link="" />
        <Breadcrumb name={t("breadcrumb.mobile_version")} link="/tools/mobile-version" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-column">
              <div className="fs-4">{t("Mobile Version")}</div>
              <div className="text-sm-start">{t("Update release version APK")}</div>
              <form onSubmit={(event) => handleSubmit(event)}>
                <div className="row mt-4 mb-2 ">
                  <label className="form-label col-2">{t("label.current_version")}</label>
                  <div className="col-10">
                    <input
                      placeholder={t("placeholder.version")}
                      type="text"
                      className="form-control rounded mw-100"
                      required
                      value={version}
                      onChange={({ currentTarget: e }) => {
                        setVersion(e.value)
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  {can(Permission.ToolsMobileVersionEdit) && (
                    <Button type="submit" variant="primary" disabled={loading}>
                      {t("buttons.save")}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default MobileVersion
