import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import SelectSearch from "../form/select-search"
import { Cems, Mil } from "../../types/cems"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type Module = {
  uuid: string
  name: string
}
type RealtimeFilterProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  mils: Mil
  cems: Cems
  modules: Module
  onShow: void
}

const RealtimeFilter: FunctionComponent<RealtimeFilterProps> = ({ mils, cems, modules, onShow }) => {
  const { t } = useTranslation()
  const [filteredModule, setFilteredModule] = useState(modules)
  const [filteredSite, setFilteredSite] = useState(cems)
  const [filter, setFilter] = useState({ company: "", site: "", module: "", status: "" })

  const isFilterExist = (data, key) => {
    const isExist = data?.find(({ uuid }) => uuid === key)
    return !!isExist
  }

  const onCompanyChange = (uuid) => {
    const sites = cems?.filter(({ company }) => company?.uuid === uuid || !uuid)
    const module = mils?.find(({ uuid: milUuid }) => milUuid === uuid)?.modules || modules
    setFilteredSite(sites)
    setFilteredModule(module)
    setFilter((prevState) => {
      const isSiteExist = isFilterExist(sites, prevState?.site)
      const isModuleExist = isFilterExist(module, prevState?.module)
      const newState = {
        ...prevState,
        company: uuid,
        site: isSiteExist ? prevState?.site : "",
        module: isModuleExist ? prevState?.module : "",
      }
      onShow(newState)
      return newState
    })
  }

  const onModuleChange = (uuid) => {
    const module = modules?.find(({ uuid: moduleUuid }) => moduleUuid === uuid || !uuid)
    setFilter((prevState) => {
      const isSiteExist = isFilterExist(module?.sites, prevState?.site)
      const isCompanyExist = isFilterExist(module?.companys, prevState?.company)
      const state = {
        ...prevState,
        module: uuid,
        site: isSiteExist ? prevState?.site : "",
        company: isCompanyExist ? prevState?.company : "",
      }
      onShow(state)
      return state
    })
  }

  const onSiteChange = (uuid) => {
    setFilter((prevState) => {
      const state = { ...prevState, site: uuid }
      onShow(state)
      return state
    })
  }

  const onSatusChange = (event) => {
    const status = event?.target?.value
    setFilter((prevState) => {
      const state = { ...prevState, status }
      onShow(state)
      return state
    })
  }

  useEffect(() => setFilteredModule(modules), [modules])
  useEffect(() => setFilteredSite(cems), [cems])

  return (
    <div className="w-100">
      <div className="d-flex gap-4 mb-3">
        <div className="w-100">
          <label className={style.filter_label}>{t("company_name")}</label>
          <SelectSearch placeholder={t("placeholder.search_company")} data={mils} defaultValue={filter?.company} onChange={onCompanyChange} />
        </div>
        <div className="w-100">
          <label className={style.filter_label}>{t("label.module")}</label>
          <SelectSearch placeholder={t("placeholder.search_module")} data={filteredModule} defaultValue={filter?.module} onChange={onModuleChange} />
        </div>
        <div className="w-100">
          <label className={style.filter_label}>{t("site_status")}</label>
          <select className="w-100 form-select rounded border" onChange={onSatusChange}>
            <option value="" disabled selected hidden>
              {t("label.select_status")}
            </option>
            <option value="">{t("page.realtime.all")}</option>
            <option value="error">{t("page.realtime.error")}</option>
            <option value="good">{t("page.realtime.good")}</option>
          </select>
        </div>
      </div>
      <div className={style.filter_input}>
        <div className="w-100">
          <label className={style.filter_label}>{t("site_name")}</label>
          <SelectSearch placeholder={t("placeholder.search_site_name")} data={filteredSite} defaultValue={filter?.site} onChange={onSiteChange} />
        </div>
      </div>
    </div>
  )
}

export default RealtimeFilter
