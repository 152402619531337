import { h } from "preact"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type Props = {
  data: any
  mutate: void
  onClose: void
}

const EditRole = ({ data, mutate, onClose }: Props) => {
  const { t } = useTranslation()
  const defaultBody = {
    name: data?.data?.name,
    description: data?.data?.description,
  }
  const { fetcher } = useAuth()
  const [body, setBody] = useObjectState(defaultBody)

  const onRoleChange = (event) => {
    const value = event.currentTarget.value
    const name = value.toUpperCase().replace(/[^0-9A-Z]+/g, "_")
    setBody({ name })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetcher()
      .patch(`/role/${data?.data?.uuid}`, body)
      .then(() => {
        mutate()
        onClose()
      })
  }

  return (
    <form
      className="modal-backdrop"
      style={{
        display: data?.show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("Edit Role")}</div>
          <div className="card-body">
            <div class="mb-4">
              <label class="form-label">{t("label.role_name")}</label>
              <input type="text" class="form-control rounded mw-100" required value={body.name} onChange={onRoleChange} />
              <small className={style.file_input_desc}>
                {t("Role Name should be in uppercase and sparated with underscore")}
                <br />
                (e.g: ROLE_NAME)
              </small>
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.description")}</label>
              <input
                type="text"
                class="form-control rounded mw-100"
                required
                value={body.description}
                onChange={({ currentTarget }) => setBody({ description: currentTarget.value })}
              />
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onClose} type="button" variant="tertiary" className="m-1">
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1">
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditRole
