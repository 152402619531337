import { FunctionComponent, h } from "preact"
import style from "./style.scss"
import usePlatformApp from "../../hooks/usePlatformApp"

type TNCProps = h.JSX.HTMLAttributes<HTMLDivElement>

const TermsAndConditionContent: FunctionComponent<TNCProps> = () => {
  const { origin, hostname } = window.location
  const { platformName } = usePlatformApp()
  const TermCondConfig = {
    platformNameTextUpper: platformName.toUpperCase(),
    platformNameText: platformName,
  }

  return (
    <div className={style.tc}>
      <h2>Ketentuan Umum</h2>
      <p>
        Selamat datang di Platform Sparing {TermCondConfig.platformNameTextUpper}, perangkat lunak akuisisi dan visualisasi data online yang dibuat untuk membantu integrator perangkat keras
        mengirimkan pemantauan data terkirim untuk sistem SPARING. Ketentuan penggunaan ini menjelaskan kewajiban {TermCondConfig.platformNameTextUpper} sebagai penyedia layanan dan kewajiban Anda
        sebagai Pengguna/Pengguna.
      </p>
      <p>
        Dokumen Syarat-syarat dan ketentuan-ketentuan ini ("S&K Layanan") dapat diterima oleh Pengguna pada saat (a) mengeluarkan pesanan pembelian atau permintaan serupa untuk Layanan atau Perangkat
        Lunak (b) mengunduh, mengakses atau menggunakan Situs Web dan/atau Perangkat Lunak atau (c) menawarkan penawaran counter atau syarat dan ketentuan dari Pengguna. Untuk menghindari keraguan,
        penawaran balik Pengguna atau syarat dan ketentuan (jika ada) tidak merupakan bagian dari Kontrak.
      </p>
      <p>
        S&K Layanan berlaku untuk dan antara PT Mitra Mega Intisolusi ("{TermCondConfig.platformNameTextUpper}") dengan individu atau badan hukum yang mendaftarkan diri dan atau didaftarkan untuk
        membuat akun pada Aplikasi atau Website dan menggunakan Layanan ("Pengguna", "Anda") yang disediakan oleh {TermCondConfig.platformNameTextUpper} kepada Pengguna. Layanan{" "}
        {TermCondConfig.platformNameTextUpper} termasuk tetapi tidak terbatas pada point of sale, fitur - fitur, teknologi, produk-produk, perangkat lunak, perangkat keras, layanan-layanan , atau
        fungsi lain yang disediakan oleh produk-produk atau layanan-layanan tersebut, termasuk {TermCondConfig.platformNameTextUpper} PLATFORM SPARING dan aplikasi Android ("Aplikasi") dan {hostname}{" "}
        ("Website") (untuk selanjutnya seluruh layanan-layanan tersebut disebut sebagai "Layanan").
      </p>
      <p>
        Mohon memahami S&K Layanan ini secara seksama. Dengan mendaftarkan diri dan atau didaftarkan dan membuat akun pada Aplikasi atau Website, Anda menyatakan bahwa Anda telah memahami, menerima,
        menyetujui dan menyatakan bersedia terikat terhadap S&K Layanan ini. Apabila akun dibuat seseorang yang bekerja untuk Anda maka {TermCondConfig.platformNameTextUpper} akan menganggap bahwa
        pendaftaran dan penggunaan akun tersebut telah mendapatkan persetujuan dari Anda, selanjutnya Anda yang akan bertanggung jawab atas segala penggunaan Layanan. S&K Layanan dapat diubah dan/atau
        ditambahkan dari waktu ke waktu.
      </p>
      <h2 className={style.mt}>Definisi</h2>
      <ol>
        <li>"Biaya Berlangganan atau Biaya Akses" - adalah biaya tahunan (tidak termasuk pajak) yang harus dibayarkan oleh Pengguna sesuai dengan harga yang telah disepakati.</li>
        <li>"Tanggal Aktivasi Akun" - adalah tanggal dimana Pengguna memulai langganan berbayar ke Situs Web atau Perangkat Lunak.</li>
        <li>"Perjanjian" - berarti Ketentuan ini dan segala kebijakan yang dirujuk.</li>
        <li>"Pengguna" - adalah pemegang akun Perusahaan yang memiliki hubungan keuangan langsung dengan Perusahaan.</li>
        <li>
          "Data" - termasuk (tetapi tidak terbatas pada) data sensor, deret waktu, algoritma, file kml, gambar, paket, laporan, dan konten lain yang diunggah atau dikirim ke Situs Web atau Perangkat
          Lunak.
        </li>
        <li>"Sumber Data" - berarti situs tunggal (umumnya aset yang dipantau, atau data logger).</li>
        <li>
          "Daftar Harga" - adalah halaman harga Perusahaan yang menunjukkan biaya berlangganan, domain khusus, SMS atau fitur lainnya seperti dukunganlanjutan, pelatihan, dan pengembangan fitur
          berbayar.
        </li>
        <li>
          "Hak Kekayaan Intelektual" - berarti semua hak cipta, hak merek dagang, hak paten dan hak desain baik terdaftar atau tidak terdaftar dan semua hak lain atas kekayaan intelektual sebagaimana
          didefinisikan dalam Undang-undang Hak Cipta Republik Indonesia, dan semua hak untuk menegakkan segala dari hak tersebut di atas.
        </li>
        <li>"Pengguna yang Diundang" - berarti setiap orang atau entitas yang diberikan akses ke akun Pengguna, termasuk tetapi tidak terbatas pada Akun Tambahan yang dibuat oleh Pengguna.</li>
        <li>"Akun Tambahan" - adalah sub-akun yang dibuat oleh Pengguna untuk Pengguna yang Diundang.</li>
        <li>
          "Tanggal Pembayaran" - berarti tanggal pembayaran untuk berlangganan tahunan yang sedang berlangsung, yang terjadi pada peringatan Tanggal Aktivasi Akun untuk masing-masing paket tahunan.
        </li>
        <li>"Layanan" - adalah layanan akuisisi dan visualisasi data yang disediakan (sebagaimana diubah atau diperbarui dari waktu ke waktu oleh Perusahaan) melalui Situs Web {origin}.</li>
        <li>
          "Perangkat Lunak" - adalah perangkat lunak atau modul berbasis cloud yang dikembangkan dan di hosting oleh Perusahaan sebagaimana telah diubah dan ditingkatkan oleh Perusahaan dari waktu ke
          waktu dan, jika diperlukan konteksnya, hanya merujuk pada Perangkat Lunak yang dilisensikan kepada Pengguna.
        </li>
        <li>"Situs web" - adalah situs internet di domain {hostname} atau situs lain yang dioperasikan oleh Perusahaan.</li>
      </ol>
      <p>
        Dengan mendaftarkan diri dan menggunakan Layanan, Pengguna secara tegas menyatakan dan menjamin bahwa Pengguna berwenang untuk menerima dan menyepakati S&K Layanan dengan menyatakan dan
        menjamin bahwa:
      </p>
      <ol>
        <li>
          Pengguna merupakan individu yang telah berusia sekurang-kurangnya 21 (dua puluh satu) tahun atau pernah menikah dan memiliki kapasitas hukum untuk mengikatkan diri dalam suatu perjanjian
          berdasarkan Hukum Republik Indonesia. Jika Pengguna tidak memenuhi syarat tersebut, Pengguna menyatakan dan menjamin bahwa pembuatan Akun dan penggunaan Layanan telah disetujui oleh orang
          tua, wali atau pengampu Pengguna yang akan ikut mengikatkan diri di dalam S&K Layanan ini dan ikut bertanggung jawab atas segala konsekuensi hukum darinya.
        </li>
        <li>
          Pengguna merupakan badan hukum yang didirikan secara sah berdasarkan hukum dimana badan hukum didirikan dan memiliki domisili hukum, yang diwakili oleh perwakilan yang sah dan memiliki
          kapasitas hukum untuk mengikatkan diri dalam perjanjian yang sah.
        </li>
        <li>
          Pengguna mengakui dan sepakat bahwa S&K Layanan ini adalah merupakan suatu perjanjian yang sah dan mengikat {TermCondConfig.platformNameTextUpper} dan Pengguna berdasarkan hukum negara
          Republik Indonesia dan mendahului semua perjanjian lisan maupun tertulis yang pernah dibuat sebelumnya, kecuali jika telah disepakati sebaliknya secara tertulis antara Perusahaan dan
          Pengguna.
        </li>
      </ol>
      <h2 className={style.mt}>Penggunaan Platform Sparing</h2>
      <ol>
        <li>
          Pengguna mengakui dan menyetujui bahwa Pengguna mengakses Perangkat Lunak berdasarkan perangkat lunak sebagai layanan (SaaS) (melalui mana Perangkat Lunak dan/atau data apa pun dimasukkan ke
          dalam Perangkat Lunak oleh atau atas nama Pengguna di-hosting oleh Perusahaan atau pihak ketiga mana pun yang disetujui oleh Perusahaan) dan Ketentuan ini berlaku untuk atas Kontrak
          terdahulu dari ketentuan yang bertentangan dari Kontrak.
        </li>
        <li>Pengguna hanya dapat mengakses Perangkat Lunak melalui antarmuka, sesuai dengan kebijakan penggunaan wajar Perusahaan dan instruksi apa pun yang diberikan oleh Perusahaan.</li>
        <li>Pengguna mengakui dan menyetujui bahwa, tunduk pada perjanjian tertulis apa pun yang berlaku antara Pengguna dan setiap Pengguna yang Diundang, atau hukum lain yang berlaku:</li>
        <li>Pengguna menentukan siapa yang merupakan Pengguna yang Diundang dan tingkat peran peran pengguna apa yang dimiliki oleh Pengguna yang Diundang;</li>
        <li>Pengguna bertanggung jawab untuk semua Pengguna yang diundang menggunakan Layanan;</li>
        <li>
          Pengguna mengontrol setiap tingkat akses Pengguna yang Diundang ke organisasi dan Layanan yang relevan setiap saat dan dapat mencabut atau mengubah akses Pengguna yang Diundang, atau tingkat
          akses, kapan saja dan dengan alasan apapun, dalam hal ini orang tersebut atau entitas akan berhenti menjadi Pengguna yang Diundang atau akan memiliki tingkat akses yang berbeda, seperti
          halnya;
        </li>
        <li>
          Jika ada perselisihan antara Pengguna dan Pengguna yang Diundang mengenai akses ke organisasi atau Layanan, Pengguna harus memutuskan apa akses atau tingkat akses ke Data atau Layanan yang
          relevan yang akan dimiliki oleh Pengguna yang Diundang, jika ada.
        </li>
        <li>Pengguna tidak boleh, dan harus memastikan bahwa setiap Pengguna yang Diundang tidak, menyalah-gunakan Layanan.</li>
        <li>
          Perusahaan dapat menangguhkan atau menghentikan penyediaan Layanan jika terjadi pelanggaran terhadap Ketentuan atau kebijakan Perusahaan ini, atau dalam hal Perusahaan mencurigai adanya
          pelanggaran.
        </li>
      </ol>
      <h2 className={style.mt}>Akun {TermCondConfig.platformNameText}</h2>
      <ol>
        <li>
          Untuk dapat menggunakan Layanan, Anda harus terlebih dahulu mendaftarkan diri dan atau didaftarkan dan membuat akun {TermCondConfig.platformNameTextUpper} ("Akun") melalui pihak terkait.
        </li>
        <li>Anda setuju untuk menyediakan segala informasi pada Akun dengan benar, akurat, terkini dan lengkap, dan terus menjaga agar informasi Akun tetap benar, akurat, terkini, dan lengkap.</li>
        <li>
          Anda setuju untuk selalu menjaga Akun milik Anda untuk tidak digunakan oleh pihak lain selain Anda termasuk menjaga keamanan dan kerahasiaan Akun dan kata sandi. Anda juga setuju untuk tidak
          menggunakan akun pihak lain tanpa izin dari pihak yang bersangkutan. Segala akibat dari digunakannya Akun baik oleh pihak lain yang diizinkan oleh Anda atau tanpa izin Anda akibat dari
          kelalaian Anda sehingga Akun digunakan oleh pihak lain adalah tanggung jawab Anda sendiri.
        </li>
        <li>
          Anda memahami dan menyetujui bahwa Akun {TermCondConfig.platformNameTextUpper} hanya boleh digunakan untuk 1 (satu) nama usaha Anda dengan maksimum penggunaan sebanyak 1 (satu) <i>user</i>.
          Penambahan Akun <i>user</i> dapat dilakukan dengan mendaftarkan Akun baru dan membayar biaya berlangganan tambahan.
        </li>
        <li>
          Anda harus segera memberitahu {TermCondConfig.platformNameTextUpper} apabila terdapat pelanggaran keamanan atau penggunaan Akun secara tidak sah. Anda juga diharuskan untuk segera melakukan
          perubahan atas kata sandi Anda. Selanjutnya Anda memahami dan setuju bahwa {TermCondConfig.platformNameTextUpper} berhak untuk memberhentikan akun Anda atau memblokir akses Anda terhadap
          Akun anda dan Layanan apabila Anda melanggar ketentuan dalam menjaga keamanan kata sandi Anda.
        </li>
      </ol>
      <h2 className={style.mt}>Kebijakan Privasi</h2>
      <p>
        Penggunaan dan akses Layanan akan tunduk kepada ketentuan yang diatur di dalam Kebijakan Privasi yang diatur di dalam Website atau Aplikasi, yang dapat diakses di{" "}
        <a href="/privacy-policy" target="_blank">
          {origin}/privacy-policy
        </a>
        . Dengan menyetujui S&K Layanan ini, Anda setuju bahwa Anda telah memahami dan menyetujui isi dari Kebijakan Privasi. S&K Layanan ini dan Kebijakan Privasi adalah satu kesatuan yang tidak
        terpisahkan yang harus Anda pahami dan setujui sebelum anda dapat membuat Akun dan menggunakan Layanan.
      </p>
      <h2 className={style.mt}>Biaya Dan Pajak</h2>
      <ol>
        <li>
          Atas Layanan yang disediakan oleh {TermCondConfig.platformNameTextUpper} kepada Pengguna, maka Pengguna sepakat untuk membayar sejumlah biaya kepada {TermCondConfig.platformNameTextUpper}{" "}
          dengan jangka waktu langganan selama 1 (satu) tahun.
        </li>
        <li>
          {TermCondConfig.platformNameTextUpper} dapat memberikan Anda kesempatan untuk dapat menggunakan Layanan dengan jangka waktu tertentu secara gratis ("Masa Percobaan Gratis") berdasarkan
          kebijakan tunggal {TermCondConfig.platformNameTextUpper}.
        </li>
        <li>
          Jangka waktu langganan Layanan 12 (dua belas) bulan ("Periode Berlanggan"), dari sejak Pengguna mulai berlangganan Layanan terkait, atau, apabila Masa Percobaan Gratis diberikan kepada
          Pengguna, maka perhitungan Periode Berlanggan dimulai pada hari berikutnya setelah berakhirnya Masa Percobaan Gratis.
        </li>
        <li>
          Apabila Anda berhenti berlangganan Layanan sebelum berakhirnya Periode Berlanggan, Anda memahami dan menyetujui bahwa Anda tidak akan menerima pengembalian atas setiap pembayaran yang
          terkait dengan Periode Berlanggan tersebut.
        </li>
        <li>
          Harga dan ketentuan mengenai Periode Berlanggan yang tertera di dalam Aplikasi dan Website dapat berubah sewaktu-waktu dengan pemberitahuan sebelum perubahan biaya tersebut melalui sarana
          apapun. Apabila Anda tidak setuju dengan perubahan tersebut, Anda dapat memutuskan untuk berhenti berlangganan.
        </li>
        <li>Seluruh biaya yang dibayarkan Pengguna atas semua Layanan di luar PPN (Pajak Pertambahan Nilai) yang akan dibebankan secara terpisah.</li>
        <li>
          Para Pihak memahami dan sepakat bahwa masing-masing Pihak akan bertanggung jawab untuk pembayaran pajak masing-masing sesuai dengan peraturan pajak yang berlaku di Indonesia. Selanjutnya
          Anda menjamin dan membebaskan {TermCondConfig.platformNameTextUpper} dari segala tuntutan dari otoritas pajak manapun untuk segala kurang bayar atas pajak penjualan, penggunaan, barang dan
          jasa, pajak pertambahan nilai atau pajak atau pungutan lainnya, dan segala denda dan/atau bunga.
        </li>
        <li>Anda memahami dan menyetujui bahwa semua pembayaran akan dibuat tanpa adanya pemotongan, khususnya pemotongan pajak atau jenis pajak lainnya.</li>
      </ol>
      <h2 className={style.mt}>Hak Dan Kewajiban</h2>
      <ol>
        <li>
          {TermCondConfig.platformNameTextUpper} berhak mendapatkan pembayaran atas Layanan yang dikenakan biaya atas penggunaannya dan disetujui oleh Pengguna. Selanjutnya Pengguna memahami dan
          setuju untuk menjamin dilakukannya pembayaran atas Layanan yang dikenakan biaya atas penggunaannya dan disetujui oleh Pengguna.
        </li>
        <li>
          Pengguna berhak untuk memilih jenis Layanan yang akan digunakan berdasarkan ketersediaan Layanan yang disediakan oleh {TermCondConfig.platformNameTextUpper} dan{" "}
          {TermCondConfig.platformNameTextUpper} berhak, atas kebijakannya sendiri, dan tanpa perlu kewajiban untuk memberikan alasan kepada Pengguna (apabila ada penolakan), untuk menentukan apakah
          Pengguna berhak untuk diberikan Layanan. {TermCondConfig.platformNameTextUpper} wajib untuk menyediakan Layanan sesuai dengan jenis layanan yang disetujui oleh{" "}
          {TermCondConfig.platformNameTextUpper} untuk digunakan oleh Pengguna.
        </li>
        <li>
          {TermCondConfig.platformNameTextUpper} berhak untuk mengalihkan hak dan kewajibannya, sebagian maupun keseluruhan kepada pihak ketiga atau kepada afiliasinya tanpa persetujuan tertulis
          terlebih dahulu dari Pengguna.
        </li>
        <li>
          {TermCondConfig.platformNameTextUpper} setiap saat berhak untuk memodifikasi, mengubah, mengurangi, menambah atau melakukan apapun terhadap Layanan untuk alasan apapun, tanpa persetujuan
          sebelumnya dari Pengguna. Selanjutnya {TermCondConfig.platformNameTextUpper} dapat sewaktu-waktu memberikan update atas Layanan.
        </li>
        <li>
          Pengguna berhak untuk diberikan dukungan teknis serta edukasi terkait penggunaan Layanan oleh {TermCondConfig.platformNameTextUpper}. {TermCondConfig.platformNameTextUpper} menyediakan
          dukungan teknis dalam bentuk dokumen yang dapat didownload, tampilan di situs {TermCondConfig.platformNameTextUpper}, maupun pelayanan Pengguna (Customer Support).
        </li>
        <li>
          Pengguna setiap saat memiliki hak untuk membatalkan dan menghapus Akun, dengan cara menghubungi atau mengirimkan email kepada bagian pelayanan Pengguna (Customer Support) dari{" "}
          {TermCondConfig.platformNameTextUpper}. Apabila Anda menghentikan atau membatalkan Akun, anda memahami dan menyetujui bahwa anda tidak akan dapat lagi melakukan akses atau menggunakan
          Layanan. Selanjutnya Anda memahami dan menyetujui apabila Anda membatalkan dan menghapus Akun sebelum berakhirnya Periode Berlanggan Layanan Pro yang telah dipilih dan disetujui oleh Anda,
          maka pembatalan tersebut akan berlaku pada saat itu juga dan Pengguna tidak berhak atas pengembalian biaya penggunaan Layanan Pro yang telah dibayarkan di muka.
        </li>
        <li>Dalam hal pengakhiran Layanan, Pengguna wajib untuk mengembalikan seluruh perangkat keras beserta peralatan pendukung lainnya (apabila ada) yang diberikan kepada Pengguna.</li>
        <li>
          Anda memahami dan setuju bahwa {TermCondConfig.platformNameTextUpper} berhak untuk memberhentikan akun Anda atau memblokir akses Anda terhadap Akun anda dan Layanan apabila Anda melanggar
          ketentuan apapun yang diatur di dalam S&K Layanan ini. Selanjutnya Anda memahami dan setuju bahwa Anda bersedia untuk bertanggung jawab untuk memberikan ganti rugi atas setiap kerugian yang
          diderita oleh {TermCondConfig.platformNameTextUpper} akibat dari dilakukannya hal-hal yang dilarang di dalam S&K Layanan ini.
        </li>
      </ol>
      <h2 className={style.mt}>Larangan Penggunaan</h2>
      <ol>
        <li>
          Pengguna dilarang menggunakan Layanan untuk kegiatan yang ilegal atau tidak sah atau yang sejenisnya atau untuk tujuan yang melanggar hukum, undang-undang dan ketentuan apapun yang diatur di
          negara Republik Indonesia (termasuk namun tidak terbatas pada setiap peraturan perundang-undangan dan hukum terkait hak atas kekayaan intelektual).
        </li>
        <li>Pengguna setuju untuk tidak menjiplak, mereproduksi, menduplikasi, menyalin, menjual, atau mengeksploitasi bagian manapun dari Layanan.</li>
        <li>Pengguna dilarang melanggar hak kekayaan intelektual {TermCondConfig.platformNameTextUpper} atau pihak ketiga manapun sehubungan dengan penggunaan Layanan;</li>
        <li>
          Pengguna dilarang menggunakan Layanan selain dari cara yang telah ditentukan oleh {TermCondConfig.platformNameTextUpper} atau dengan cara yang dapat merusak, melumpuhkan, membebani, membuat
          cacat, atau melemahkan sistem atau keamanan Layanan atau mengintervensi pengguna lainnya;
        </li>
        <li>
          Pengguna dilarang memperoleh atau mengumpulkan informasi atau data apapun dari layanan atau sistem {TermCondConfig.platformNameTextUpper} atau mencoba untuk mengurai segala transmisi menuju
          atau dari server {TermCondConfig.platformNameTextUpper} yang menjalankan Layanan;
        </li>
        <li>
          Pengguna dilarang mengungkapkan atau mendistribusikan informasi terkait Pengguna Layanan lainnya kepada pihak ketiga manapun, atau menggunakan informasi Pengguna lain manapun untuk tujuan
          pemasaran kecuali anda telah memperoleh persetujuan tertulis dari {TermCondConfig.platformNameTextUpper} untuk melakukan hal tersebut; atau
        </li>
        <li>Pengguna dilarang mengakses atau membuat Akun melalui bots atau metode terotomasi lainnya.</li>
      </ol>
      <h2 className={style.mt}>Pembatasan Ganti Rugi dan Tanggung Jawab</h2>
      <ol>
        <li>
          {TermCondConfig.platformNameTextUpper} tidak bertanggung jawab atas segala kerugian langsung dan/atau tidak langsung termasuk namun tidak terbatas pada, kerugian atas kehilangan atau
          berkurangnya laba, potensi keuntungan, penggunaan, hilangnya data atau kehilangan tidak berwujud lainnya, yang diakibatkan oleh:
          <ol type="a">
            <li>penggunaan atau ketidakmampuan penggunaan Layanan (secara keseluruhan atau sebagian);</li>
            <li>akses yang tidak sah pada, atau perubahan dari, komunikasi atau data Anda;</li>
            <li>tindakan pihak ketiga sehubungan dengan Layanan;</li>
            <li>pelanggaran S&K Layanan oleh Anda;</li>
            <li>tuntutan atas pelanggaran hak kekayaan intelektual pihak ketiga atau hak-hak lainnya, pencemaran nama baik;</li>
            <li>pelanggaran oleh Anda atas hukum yang berlaku atau perjanjian manapun terkait ketentuan dengan pihak ketiga, dalam hal mana Anda terikat; dan/atau</li>
            <li>pelanggaran hal lainnya sehubungan dengan serta penggunaan Layanan.</li>
          </ol>
        </li>
        <li>
          {TermCondConfig.platformNameTextUpper} tidak bertanggung jawab atas klaim, pengeluaran, kewajiban, ganti rugi atau biaya lainnya yang muncul akibat dari pelanggaran apapun oleh Pengguna
          terhadap S&K Layanan ini termasuk namun tidak terbatas pada penggunaan perangkat keras (apabila ada) dan perangkat lunak yang disediakan oleh {TermCondConfig.platformNameTextUpper} terkait
          dengan penggunaan Layanan, perangkat lunak lain, atau akibat dari modifikasi atau perubahan perangkat keras dan perangkat lunak yang disediakan oleh {TermCondConfig.platformNameTextUpper}{" "}
          yang tidak sah.
        </li>
        <li>
          Dalam hal Pengguna menuntut tanggung jawab atas terjadinya kerugian akibat dari kelemahan atas Layanan yang dapat dibuktikan secara nyata oleh Pengguna, maka batas tanggung jawab maksimal
          dari {TermCondConfig.platformNameTextUpper} adalah sebesar biaya Layanan yang telah dibayarkan oleh pengguna untuk Layanan {TermCondConfig.platformNameTextUpper}.
        </li>
      </ol>
      <h2 className={style.mt}>Hak Kekayaan Intelektual</h2>
      <ol>
        <li>
          {TermCondConfig.platformNameTextUpper}, termasuk nama dan logonya, kode, desain, teknologi, dilindungi oleh hak cipta, merek dan hak kekayaan intelektual lainnya yang tersedia berdasarkan
          hukum Republik Indonesia. {TermCondConfig.platformNameTextUpper}
          memiliki seluruh hak dan kepentingan atas seluruh hak kekayaan intelektual yang berhubungan dengannya. S&K Layanan ini tidak dan dengan cara apapun tidak akan dianggap sebagai pemberian izin
          kepada Anda untuk menggunakan hak kekayaan intelektual {TermCondConfig.platformNameTextUpper} apapun sebagaimana disebutkan diatas.
        </li>
        <li>
          Anda tidak diperkenankan:
          <ol type="a">
            <li>
              menyalin, memodifikasi, mengubah, menambahkan, mengurangi, mengadaptasi, menerjemahkan, membuat karya turunan dari, mendistribusikan, menjual, menampilkan di muka umum, membuat ulang,
              mentransmisikan, memindahkan, memberikan lisensi, mengalihkan, menyiarkan, menguraikan, atau membongkar bagian manapun dari atau dengan cara lain mengeksploitasi sistem{" "}
              {TermCondConfig.platformNameTextUpper} (termasuk fitur-fitur Layanan di dalamnya) yang dilisensikan kepada Anda, kecuali secara jelas diperbolehkan dalam S&K Layanan ini;
            </li>
            <li>
              memberikan lisensi, mensublisensikan, menjual, menjual kembali, memindahkan, mengalihkan, mendistribusikan atau mengeksploitasi secara komersial atau membuat tersedia kepada pihak ketiga
              dan/atau dengan cara merekayasa ulang atau mengakses perangkat lunak {TermCondConfig.platformNameTextUpper} untuk (i) membangun produk atau layanan tandingan, (ii) membangun produk
              dengan menggunakan ide, fitur, fungsi, layanan, atau grafis sejenis, atau (iii) menyalin ide, fitur, fungsi , layanan atau grafis sejenis;
            </li>
            <li>
              meluncurkan program otomatis atau script, termasuk, namun tidak terbatas pada, web spiders, web crawlers, web robots, web ants, web indexers, bots, virus atau worm, atau segala program
              apapun yang mungkin membuat beberapa permintaan server per detik, atau menciptakan beban berat atau menghambat operasi dan/atau kinerja aplikasi,
            </li>
            <li>
              menggunakan robot, spider, pencarian situs/aplikasi pengambilan kembali, atau perangkat manual atau otomatis lainnya atau proses untuk mengambil, indeks, "tambang data" (data mine), atau
              dengan cara apapun memperbanyak atau menghindari struktur navigasi atau presentasi dari aplikasi atau isinya;
            </li>
            <li>menghapus Hak Kekayaan Intelektual apapun milik {TermCondConfig.platformNameTextUpper} yang terkandung dalam Website atau Aplikasi.</li>
          </ol>
        </li>
      </ol>
      <h2 className={style.mt}>Layanan {TermCondConfig.platformNameTextUpper} Apa Adanya</h2>
      <ol>
        <li>
          Anda memahami dan setuju bahwa Layanan yang disediakan oleh {TermCondConfig.platformNameTextUpper} adalah bersifat "apa adanya" (as is) dan "sebagaimana tersedia" (as available), dan
          Pengguna memahami segala risiko dari Penggunaan Layanan.
        </li>
        <li>
          Anda memahami dan setuju bahwa {TermCondConfig.platformNameTextUpper} tidak memberikan jaminan apapun bahwa:
          <ol type="a">
            <li>Layanan akan memenuhi kebutuhan spesifik dari Anda;</li>
            <li>Layanan akan selalu tersedia kapanpun, aman dan bebas dari kesalahan (error), gangguan, kejahatan dari pihak ketiga atau kerusakan;</li>
            <li>Layanan akan selalu akurat, sesuai ekspektasi, dan dapat diandalkan</li>
            <li>setiap kesalahan (error) pada Layanan akan diperbaiki sesuai yang Anda harapkan;</li>
            <li>Layanan dapat diakses dan/atau akan kompatibel dengan setiap perangkat komputer, peripheral komputer, sistem operasi atau perangkat lainnya;</li>
            <li>
              Layanan yang disediakan sepenuhnya aman dan bebas dari bugs, virus, trojans dan komponen perangkat lunak berbahaya lainnya. Anda wajib untuk memastikan bahwa sistem komputer dan
              perangkat Anda memiliki anti-virus dan secara rutin diperbaharui, dan bahwa Anda melakukan penyimpanan cadangan (back-up) secara rutin atas sistem komputer dan perangkat Anda.
            </li>
          </ol>
          <li>
            Anda memahami dan setuju bahwa dalam menyediakan perangkat keras (apabila ada), perangkat lunak, jaringan, konektivitas , penyimpanan dan teknologi lainnya yang digunakan untuk mendukung
            penyediaan Layanan, {TermCondConfig.platformNameTextUpper} dapat menggunakan pemasok dari pihak ketiga. {TermCondConfig.platformNameTextUpper} akan selalu memastikan bahwa penyediaan
            perangkat keras, perangkat lunak, jaringan, konektivitas (jaringan), penyimpanan (storage), dan teknologi lainnya yang digunakan untuk mendukung penyediaan Layanan telah sesuai dengan
            standar yang ditetapkan oleh {TermCondConfig.platformNameTextUpper}, tetapi segala tindakan, perbuatan, kualitas barang, dan apapun yang disediakan dari pemasok mungkin dapat di luar
            kendali {TermCondConfig.platformNameTextUpper}, dan {TermCondConfig.platformNameTextUpper} tidak bertanggung jawab atas adanya kerugian atau kerusakan yang diderita oleh Pengguna akibat
            dari tindakan atau perbuatan pemasok tersebut, sejauh mana diperbolehkan oleh peraturan perundang-undangan dan hukum yang berlaku.
          </li>
        </li>
      </ol>
      <h2 className={style.mt}>Keadaan Memaksa (Force Majeure)</h2>
      <ol>
        <li>
          Layanan dapat diinterupsi oleh kejadian atau hal tertentu di luar kewenangan dan kontrol {TermCondConfig.platformNameTextUpper}, termasuk namun tidak terbatas pada bencana alam, gangguan
          listrik, gangguan telekomunikasi, kebijakan pemerintah, gangguan keamanan nasional, kerusuhan, huru-hara dan hal-hal lainnya yang di luar kewenangan dan kontrol Kami ("Keadaan Memaksa").
          Anda oleh karenanya setuju untuk melepaskan {TermCondConfig.platformNameTextUpper} dari setiap klaim, jika {TermCondConfig.platformNameTextUpper} tidak dapat menyediakan Layanan sebagaimana
          yang seharusnya karena terjadinya Keadaan Memaksa.
        </li>
        <li>
          Dalam hal terjadi Keadaan Memaksa, maka {TermCondConfig.platformNameTextUpper} akan menyampaikan pemberitahuan kepada Pengguna mengenai terjadinya Keadaan Memaksa tersebut selambat-lambatnya
          10 (Sepuluh) hari terhitung sejak tanggal terjadinya Keadaan Memaksa.
        </li>
      </ol>
      <h2 className={style.mt}>Yurisdiksi</h2>
      <ol>
        <li>
          Anda memahami dan menyetujui bahwa hukum Indonesia berlaku untuk S&K Layanan ini, termasuk setiap permasalahan atau perselisihan yang timbul dari atau sehubungan dengan S&K Layanan ini,
          akses dan penggunaan Layanan oleh Anda, dan hubungan antara {TermCondConfig.platformNameTextUpper} dengan Anda;
        </li>
        <li>
          Setiap konflik yang timbul dari atau sehubungan dengan Layanan atau konflik antara {TermCondConfig.platformNameTextUpper} dengan Anda sehubungan dengan Layanan akan diajukan dan diselesaikan
          pertama-tama melalui musyawarah untuk mufakat dalam waktu 30 (tiga puluh) hari terhitung sejak diterimanya pemberitahuan timbulnya konflik.
        </li>
        <li>Apabila setelah jangka waktu dimaksud tidak tercapai kesepakatan, maka Anda mengakui dan sepakat bahwa penyelesaian konflik akan diselesaikan di Pengadilan Negeri Jakarta Timur.</li>
      </ol>
    </div>
  )
}

export default TermsAndConditionContent
