import { Fragment, FunctionComponent, h } from "preact"
import { useEffect } from "preact/hooks"
import moment from "moment"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import TopFilter from "../../../components/TopFilter"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import useObjectState from "../../../hooks/useObjectState"
import { sortActiveParam, isAvgSensor } from "../../../utils/sortParam"
import { useFilterContext } from "../../../context/useFilter"
import { defaultParams } from "../../../utils/defaults"
import Spinner from "../../../components/spinner"
import { useRecordGraph } from "../../../hooks/useSensor"
import useSites from "../../../hooks/useSite"
import { analyticChartColor } from "../../../utils/color"
import LineChart from "../lineChart"
import BarChart from "../bar-chart"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type DashboardParameterComplianceProps = h.JSX.HTMLAttributes<HTMLDivElement>

const DashboardParameterCompliance: FunctionComponent<DashboardParameterComplianceProps> = () => {
  const { t } = useTranslation()
  const { contextFilter, setContextFilter } = useFilterContext()
  const { data: cems } = useSites()
  const startDate = moment().subtract(30, "days").startOf("day").format("X")
  const endDate = moment().format("X")
  const defaultValue = {
    site: undefined,
    ...contextFilter,
    startDate,
    endDate,
    period: "day",
    sensor: contextFilter?.sensor || cems?.find(({ uuid }) => contextFilter?.site === uuid)?.parameters?.[0]?.name,
  }
  const [payload, setPayload] = useObjectState(defaultValue)

  const { data } = useRecordGraph({
    uuid: payload.site,
    type: payload.period,
    start: payload.startDate,
    end: payload.endDate,
  })
  const columns = sortActiveParam(data?.parameter) || defaultParams
  const label = data?.record?.map(({ timestamp }) => timestamp)

  const chart = columns?.map((item) => {
    const value = data?.record?.map(({ data }) => data?.find(({ name }) => name === item.name)?.value)
    const avg = data?.record?.map(({ data }) => data?.find(({ name }) => name === item.name)?.avg)
    return {
      name: item.name,
      lower_threshold: item?.lower_threshold,
      upper_threshold: item?.upper_threshold,
      label,
      dataset: isAvgSensor(payload.period, payload.sensor) ? avg : value,
    }
  })

  const getChartBySensor = (chartData, sensor) => {
    return chartData?.find(({ name }) => name === sensor)
  }

  const getStackbarData = (data, type, label, colorIndex) => {
    if (!data?.record) return []
    const payloadData = data?.record?.map(({ data }) => data?.find(({ name }) => name === payload.sensor))

    const firstData = payloadData?.map((item) => item?.[type[0] as keyof typeof item])
    const secondData = payloadData?.map((item) => item?.[type[1] as keyof typeof item])
    const firstCount = payloadData?.map((item) => item?.[type[2] as keyof typeof item])
    const secondCount = payloadData?.map((item) => item?.[type[3] as keyof typeof item])
    return [
      {
        data: firstData,
        label: label[0],
        count: firstCount,
        borderColor: analyticChartColor(colorIndex[0]),
        backgroundColor: analyticChartColor(colorIndex[0]),
      },
      {
        data: secondData,
        label: label[1],
        count: secondCount,
        borderColor: analyticChartColor(colorIndex[1]),
        backgroundColor: analyticChartColor(colorIndex[1]),
      },
    ]
  }

  const complyType = ["percentage_comply", "percentage_incomply", "count_comply", "count_incomply"]
  const validType = ["percentage_valid", "percentage_invalid", "count_valid", "count_invalid"]
  const compilanceChartData = getStackbarData(data, complyType, [t("comply"), t("incomply")], [2, 1])
  const validityChartData = getStackbarData(data, validType, [t("valid"), t("invalid")], [3, 6])

  const handleFilterData = (data) => {
    setPayload(data)
    setContextFilter(data)
  }

  useEffect(() => {
    setContextFilter({ periodType: 10 }) // default last 30 days
  }, [])

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.dashboard")} link="" />
        <Breadcrumb name={t("breadcrumb.parameter_compliance")} link="/dashboard/parameter-compliance" />
      </TopBar>
      <Container>
        {data ? (
          <Fragment>
            <div className="row align-items-stretch">
              <TopFilter cems={cems} inputSensor="select" period defaultValue={payload} onShow={handleFilterData} />
            </div>
            <div className="mt-3">
              <LineChart data={getChartBySensor(chart, payload.sensor)} color="#AB5894" height={80} period={payload.period} />
            </div>
            <div className="row mt-4">
              <div className="col-6 mb-3">
                <div className="card">
                  <div className="card-title">
                    <p class={style.title}>{t("compliance")}</p>
                  </div>
                  <div className="card-body">
                    <BarChart height={100} labels={label} period={payload.period} data={compilanceChartData} />
                  </div>
                </div>
              </div>
              <div className="col-6 mb-3">
                <div className="card">
                  <div className="card-title">
                    <p class={style.title}>{t("validity")}</p>
                  </div>
                  <div className="card-body">
                    <BarChart height={100} labels={label} period={payload.period} data={validityChartData} />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <Spinner />
        )}
      </Container>
    </div>
  )
}

export default DashboardParameterCompliance
