import { FunctionComponent, h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import { route, Link } from "preact-router"
import { HiArrowLeft } from "react-icons/hi"
import useAuth from "../../../hooks/useAuth"
import { useAccess, usePermission, useGetPermissionById } from "../../../hooks/useUsman"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import { Button } from "../../../parts/buttons"
import Spinner from "../../../components/spinner"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type EditRolePermissionProps = h.JSX.HTMLAttributes<HTMLDivElement>

const EditRolePermission: FunctionComponent<EditRolePermissionProps> = () => {
  const { t } = useTranslation()
  const { hash } = window.location
  const pathname = window.location.pathname
  const roleUuid = pathname.split("/").length === 5 ? pathname.substring(pathname.lastIndexOf("/") + 1) : ""
  const { data: access } = useAccess()
  const { data: permissionList } = usePermission()
  const { data } = useGetPermissionById(roleUuid)
  const ref = useRef(document)
  const elementsRef = useRef([])
  const { fetcher } = useAuth()
  const [permission, setPermission] = useState([])
  const [active, setActive] = useState()
  const [modules, setModules] = useState([])
  const [moduleRef, setModuleRef] = useState([])
  const [disableButton, setDisableButton] = useState(false)

  const onPermissionChange = (uuid) => {
    setPermission((prevState) => {
      const permissionChanged = prevState?.findIndex(({ permission }) => permission?.uuid === uuid)
      prevState[permissionChanged].status = !prevState?.[permissionChanged]?.status
      return prevState
    })
  }

  const isChecked = (permission, uuid) => {
    return permission?.find(({ permission }) => permission?.uuid === uuid)?.status ?? false
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisableButton(true)
    try {
      const permissionBody = {
        role_permissions: permission,
      }
      await fetcher().patch(`/permission/assign-permission/${roleUuid}`, permissionBody)
      setDisableButton(false)
      route("/access/role")
    } catch (error) {
      setDisableButton(false)
    }
  }

  useEffect(() => {
    const permissionContent = permissionList?.map(({ uuid }) => {
      const assigned = data?.role_permissions?.find(({ permission }) => permission?.uuid === uuid)
      if (assigned) {
        return {
          uuid: assigned?.uuid,
          status: assigned?.status ?? false,
          permission: { uuid },
        }
      }
      return {
        status: false,
        permission: { uuid },
      }
    })
    setPermission(permissionContent)
  }, [permissionList, data])

  useEffect(() => {
    const newModule = access?.map(({ name, uuid: id }) => ({ name, id }))
    const newModuleRef = access?.map(({ uuid }) => uuid)
    elementsRef.current = elementsRef?.current?.slice(0, newModuleRef?.length)
    setModules(newModule)
    setModuleRef(newModuleRef)
    setActive(`#${newModule?.[0]?.id}`)
  }, [access])

  useEffect(() => {
    setActive(hash)
  }, [hash])

  useEffect(() => {
    const handleScroll = () => {
      const sections = elementsRef.current
      let index = sections.length

      // Decrements the index value each iteration.
      while (--index >= 0 && window.scrollY + 50 < sections[index].offsetTop) {}

      if (index >= 0 && window.scrollY - sections[index].offsetHeight < sections[index].offsetTop) {
        setActive(`#${moduleRef?.[index]}`)
      }
    }
    const element = ref.current

    element.addEventListener("scroll", handleScroll)

    return () => {
      element.removeEventListener("scroll", handleScroll)
    }
  }, [elementsRef, moduleRef])

  return (
    <div className="min-vh-100">
      <Spinner show={!Array.isArray(access)} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.access")} link="" />
        <Breadcrumb name={t("breadcrumb.role")} link="/access/role" />
        <Breadcrumb name={t("breadcrumb.edit_role_permission")} link={`/access/role/edit-permission/${roleUuid}`} />
      </TopBar>
      <Container>
        <div className="row g-3">
          <form className="col-lg-9 col-12" onSubmit={(event) => handleSubmit(event)}>
            <div className="card">
              <div className="card-body">
                <Link className={style.back_button} href="/access/role">
                  <HiArrowLeft size="1.2rem" />
                  {t("buttons.back")}
                </Link>
                <div className="d-flex justify-content-between w-100">
                  <p className={style.page_title}>{t("Edit Role Permission")}</p>
                  <Button variant="primary" type="submit" disabled={disableButton}>
                    {t("buttons.save")}
                  </Button>
                </div>
                <div className={style.detail}>
                  <div className={style.label}>{t("label.role_name")}</div>
                  <div className={style.value}>{data?.role?.name}</div>
                </div>
                <div className={style.detail}>
                  <div className={style.label}>{t("label.description")}</div>
                  <div className={style.value}>{data?.role?.description}</div>
                </div>
                <div className={style.module_title}>{t("Features")}</div>
                {access?.map((module, midx) => (
                  <div className={style.module} key={module?.uuid} ref={(element) => (elementsRef.current[midx] = element)} id={module?.uuid}>
                    <div className={style.title}>{t(`${module?.name}`)}</div>
                    <div className={style.desc}>{t(`${module?.name}`)} List</div>
                    <ul>
                      {module?.menu_lists?.map((sub) => (
                        <li key={sub?.uuid}>
                          <div className={style.submenu}>
                            <p>{t(`${sub?.menu_name}`)}</p>
                            <div className={style.permission}>
                              {sub?.permissions?.map((access) => (
                                <div key={access?.key} class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id={access?.key}
                                    checked={isChecked(permission, access?.uuid)}
                                    onChange={() => onPermissionChange(access?.uuid)}
                                  />
                                  <label htmlFor={access?.key}>{t(`${access?.name}`)}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
                <div className="d-flex justify-content-end w-100 mt-4">
                  <Button variant="primary" type="submit" disabled={disableButton}>
                    {t("buttons.save")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
          <div className="col-lg-3 col-12">
            <div className="card position-sticky top-0">
              <div className="card-body">
                <p className={style.page_title}>{t("Feature List")}</p>
                <ul className={style.section_of_page}>
                  {modules?.map((link, index) => (
                    <li key={index}>
                      <a href={`#${link?.id}`} className={active === `#${link?.id}` ? style.active : ""}>
                        {t(`${link?.name}`)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default EditRolePermission
