import { FunctionComponent, h } from "preact"
import { useState, useRef, useEffect } from "preact/hooks"
import { MdOutlineClose } from "react-icons/md"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import style from "./style.scss"

type Data = {
  uuid: string
  name: string
}
type SelectSearchProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: Data[]
  defaultValue?: string
  placeholder?: string
  required: boolean
  onChange: viod
}

const SelectSearch: FunctionComponent<SelectSearchProps> = ({
  data,
  defaultValue,
  placeholder,
  required = false,
  onChange
}) => {
  const selectSearchRef = useRef<HTMLDivElement>(null)
  const [value, setValue] = useState()
  const [tempData, setTempData] = useState({})
  const [filtered, setFiltered] = useState(data)
  const [showList, setShowList] = useState(false)

  const onFocus = () => {
    setFiltered(data)
    setShowList(true)
  }

  const onSearch = (value) => {
    setShowList(true)
    if (value) {
      const lowerValue = value.toLowerCase()
      const result = data?.filter(({ name }) => name?.toLocaleLowerCase()?.includes(lowerValue))
      setFiltered(result)
    } else {
      setFiltered(data)
    }
    setValue(value)
  }

  const onSelect = (list) => {
    setTempData(list)
    setShowList(false)
    onChange(list?.uuid)
  }

  const clearCompany = () => {
    onChange("")
    setTempData({})
  }

  useOnClickOutside(selectSearchRef, () => setShowList(false))

  useEffect(() => setValue(tempData?.name ||  ""), [showList, tempData])
  useEffect(() => {
    const defaultData = data?.find(({ uuid }) => uuid === defaultValue)
    setTempData(defaultData)
    setValue(defaultData?.name)
  }, [data, defaultValue])
  
  return (
    <div ref={selectSearchRef} className="d-flex position-relative w-100">
      <div className="d-flex position-relative w-100">
        <input
          type="text"
          className="form-control rounded mw-100 border"
          value={value}
          placeholder={placeholder}
          onFocus={onFocus}
          required={required}
          onChange={({ currentTarget: t }) => onSearch(t.value)} />
        <button type="button" title="Clear" className={style.clear_input} onClick={clearCompany}>
          <MdOutlineClose />
        </button>
      </div>
      {showList &&
        <div className={style.search_list}>
          <ul>
            {filtered?.map((mil) => (
              <li key={mil?.uuid} onClick={() => onSelect(mil)}>
                {mil?.name}
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  )
}

export default SelectSearch
