import { useState } from "preact/hooks"
const usePlatformApp = () => {
  const [platform, setPlatform] = useState(process.env.PLATFORM || "intusi")
  const [platformName, setPlatformName] = useState(process.env.PLATFORM_NAME || "Intusi")
  const [enviroment, setEnviroment] = useState(process.env.ENV || "dev")
  const [localLang, setLocalLang] = useState(process.env.LOCAL_LANG || "en")

  return { platform, platformName, enviroment, localLang }
}
export default usePlatformApp
