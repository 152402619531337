import useAuth from "./useAuth"
import useSWR from "swr"

export const useModule = () => {
  const { fetcher } = useAuth()
  const url = `/module`

  const response = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return response
}



