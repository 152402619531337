import { FunctionComponent, h } from "preact"

type ScreenProps = h.JSX.HTMLAttributes<HTMLDivElement>

const Screen: FunctionComponent<ScreenProps> = ({ children }) => {
  return (
    <div className="d-flex flex-row align-items-stretch min-vh-100">
      {children}
    </div>
  )
}

export default Screen
