import { FunctionComponent, h } from "preact"
import LoginForm from "../login/login-form"
import { setLocalStorage, landingPage } from "../../utils/local-storage"
import AuthCarousel from "../login/auth-carousel"
import style from "./style.scss"

type EnvirotamaProps = h.JSX.HTMLAttributes<HTMLDivElement>

const Envirotama: FunctionComponent<EnvirotamaProps> = () => {
  const { pathname } = window.location
  setLocalStorage(landingPage, pathname)

  const carouselImages = [
    "/assets/img/carousel/envirotama-1.png",
    "/assets/img/carousel/envirotama-2.png",
    "/assets/img/carousel/envirotama-3.png",
    "/assets/img/carousel/envirotama-4.png",
    "/assets/img/carousel/envirotama-5.png",
    "/assets/img/carousel/envirotama-6.png",
    "/assets/img/carousel/intusi-3.png",
  ]

  const imageDesc = [
    <div key={1} className={style.parallelogram}>
      Kami Hadir Untuk Membantu Pelaku Industri Melalui Platform Monitoring & Manajemen Data Lingkungan <span style={{ width: "106px" }}>Terpadu</span>
    </div>,
    <div key={2} className={style.parallelogram}>
      Platform Yang Dirancang Untuk Memenuhi Kebutuhan Pelaku Industri Untuk Melakukan <span style={{ width: "128px" }}>Pelaporan</span>
    </div>,
    <div key={3} className={style.parallelogram}>
      Beragam Komponen Sensor Dengan <span style={{ width: "248px" }}>Ketangguhan Akurasi</span>
    </div>,
    <div key={4} className={style.parallelogram}>
      Pembuat, Pengembang, Penyedia, dan Penyelenggara Aplikasi Platform Digital <span style={{ width: "150px" }}>Berbasis IoT</span>
    </div>,
    <div key={5} className={style.parallelogram}>
      Dirancang Oleh Tim Pengembang Berdedikasi <span style={{ width: "184px" }}>Ahli & Lengkap</span>
    </div>,
    <div key={6} className={style.parallelogram}>
      Platform Yang Menawarkan Kinerja Sistem Optimasi Terpadu Dengan <span style={{ width: "214px" }}>Berbagai Manfaat</span>
    </div>,
    <div key={7} className={style.parallelogram}>
      Platform Yang Memberikan <span style={{ width: "244px" }}>Dukungan Pelaporan</span> Sesuai Dengan Peraturan Menteri
    </div>,
  ]

  return (
    <div className={style.bg_envirotama}>
      <div className={style.container}>
        <div className={style.form}>
          <div className={style.logo}>
            <img src="/assets/img/logo-envirotama.png" alt="Envirotama" />
          </div>
          <div className={style.login_card}>
            <div className={style.login_title}>
              <div>Welcome to <p className={style.envirotama}>PT. Envirotama Integra Teknologi!</p></div>
              <div className={style.desc}>Please sign in to access admin panel</div>
            </div>
            <LoginForm buttonClass={style.envirotama_login} forgotClass={style.envirotama_forgot_password} />
          </div>
        </div>
        <div className={style.illustrate}>
          <AuthCarousel images={carouselImages} desc={imageDesc} />
        </div>
      </div>
    </div>
  )
}

export default Envirotama
