import { ApiResponse } from "../types/api-response"
import useAuth from "./useAuth"
import useSWR from "swr"

export interface AllCompanyResponse {
  uuid: string
  name: string
  created_at: string
  updated_at: string
  deleted_at: any
  mils: Mil[]
}

interface Mil {
  uuid: string
  name: string
  coordinate: Coordinate
  created_at: string
  updated_at: string
  deleted_at: any
  cems: Cem[]
}

interface Coordinate {
  lat: string
  long: string
}

interface Cem {
  uuid: string
  name: string
  code: string
  coordinate: Coordinate2
  area: string
  regulation_references: string[]
  created_at: string
  updated_at: string
  deleted_at: any
  parameters: Parameter[]
}

interface Coordinate2 {
  lat: string
  long: string
}

interface Parameter {
  uuid: string
  name: string
  uom: string
  type: string
  upper_threshold: number
  lower_threshold: number
  created_at: string
  updated_at: string
  deleted_at: any
}

const useCompany = () => {
  const { fetcher } = useAuth()
  const url = "/company"
  const output = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<AllCompanyResponse[]>>(url)
      .then(({ data }) => {
        if (data.statusCode == 200) return data.data
      })
  )
  return output
}

export const useCompanyById = (id: string) => {
  const url = `/company/${id}`
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) => {
    if (!id) return
    return fetcher()
      .get<Mil>(url)
      .then(({ data }) => data.data)
  })
  return { data, error, mutate }
}

export const usePublicAffiliate = () => {
  const url = "/company/public"
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get<Mil>(url)
      .then(({ data }) => data.data.sort((a, b) => a.name.localeCompare(b.name)))
  )
  return { data, error, mutate }
}

export default useCompany
