import { FunctionComponent, h } from "preact"

type ErrorLogIconProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  className: string
}
const ErrorLogIcon: FunctionComponent<ErrorLogIconProps> = ({ className }) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M11.9327 19.9995H4.5C3.92615 19.9995 3.43401 19.8829 3.0923 19.6092C2.77183 19.3526 2.5 18.8897 2.5 17.9995V3.99951C2.5 3.10932 2.77183 2.64643 3.0923 2.38978C3.43401 2.11612 3.92615 1.99951 4.5 1.99951H15.3171C15.8851 1.99951 16.4321 2.11593 16.826 2.40932C17.1935 2.68295 17.5 3.16419 17.5 4.06989V9.11155C17.4738 9.12295 17.4417 9.13547 17.4034 9.14841C17.2073 9.21461 16.9899 9.24589 16.9121 9.24589C16.8903 9.24589 16.861 9.24228 16.8295 9.23324C16.8076 9.22698 16.7889 9.21934 16.7741 9.21179V4.08089C16.7741 3.68829 16.6925 3.28885 16.4008 2.99721C16.1092 2.70557 15.7097 2.62389 15.3171 2.62389H4.85614C4.46365 2.62389 4.04399 2.70256 3.70709 2.93199C3.34436 3.179 3.12414 3.57122 3.12414 4.06989V17.9299C3.12414 18.4364 3.35669 18.8276 3.72275 19.0709C4.06316 19.2972 4.48559 19.3759 4.87814 19.3759H12.0278C12.0289 19.3773 12.03 19.3788 12.0312 19.3804C12.044 19.3982 12.0569 19.4227 12.0662 19.4507C12.0755 19.4789 12.0781 19.5015 12.0781 19.5139C12.0781 19.5321 12.073 19.5879 12.0528 19.6732C12.0337 19.7539 12.0059 19.8402 11.9732 19.9161C11.9586 19.9501 11.9448 19.9777 11.9327 19.9995ZM17.5883 9.06304C17.5885 9.06305 17.5869 9.06458 17.5828 9.06759C17.5861 9.06453 17.5882 9.06303 17.5883 9.06304ZM11.8929 20.0595C11.8909 20.0618 11.8898 20.0627 11.8898 20.0627C11.8898 20.0626 11.8909 20.0614 11.8929 20.0595Z" fill="currentColor" stroke="currentColor" />
      <path d="M17.0764 20.1412C19.5844 20.1412 21.5974 18.1172 21.5974 15.6202C21.5974 13.1232 19.5734 11.0992 17.0764 11.0992C14.5794 11.0992 12.5554 13.1232 12.5554 15.6202C12.5554 18.1172 14.5684 20.1412 17.0764 20.1412ZM17.0764 12.0562C19.0014 12.0562 20.6404 13.5962 20.6404 15.6202C20.6404 17.5452 19.1004 19.1842 17.0764 19.1842C15.1514 19.1842 13.5124 17.6442 13.5124 15.6202C13.5124 13.6952 15.1514 12.0562 17.0764 12.0562ZM5.42736 6.47923H14.4694C14.7554 6.47923 14.9534 6.28123 14.9534 5.99523C14.9534 5.70923 14.7554 5.51123 14.4694 5.51123H5.42736C5.14136 5.51123 4.94336 5.70923 4.94336 5.99523C4.94336 6.28123 5.13036 6.47923 5.42736 6.47923ZM8.99136 15.5212H5.42736C5.14136 15.5212 4.94336 15.7192 4.94336 16.0052C4.94336 16.2912 5.14136 16.4892 5.42736 16.4892H8.99136C9.27736 16.4892 9.47536 16.2912 9.47536 16.0052C9.47536 15.7192 9.27736 15.5212 8.99136 15.5212ZM10.6194 12.1552H5.42736C5.14136 12.1552 4.94336 12.3532 4.94336 12.6392C4.94336 12.9252 5.14136 13.1232 5.42736 13.1232H10.6194C10.9054 13.1232 11.1034 12.9252 11.1034 12.6392C11.1034 12.3532 10.9164 12.1552 10.6194 12.1552ZM14.4694 8.87723H5.42736C5.14136 8.87723 4.94336 9.07523 4.94336 9.36123C4.94336 9.64723 5.14136 9.84523 5.42736 9.84523H14.4694C14.7554 9.84523 14.9534 9.64723 14.9534 9.36123C14.9534 9.07523 14.7664 8.87723 14.4694 8.87723Z" fill="currentColor" />
      <path d="M17 16C17.276 16 17.5 15.8507 17.5 15.6667V13.3333C17.5 13.1493 17.276 13 17 13C16.724 13 16.5 13.1493 16.5 13.3333V15.6667C16.5 15.8507 16.724 16 17 16Z" fill="currentColor" />
      <path d="M16.5 17.4836C16.5 17.5471 16.5125 17.61 16.5368 17.6687C16.5611 17.7273 16.5967 17.7806 16.6416 17.8255C16.6865 17.8705 16.7399 17.9061 16.7985 17.9304C16.8572 17.9547 16.9201 17.9672 16.9836 17.9672C17.0471 17.9672 17.11 17.9547 17.1687 17.9304C17.2273 17.9061 17.2806 17.8705 17.3255 17.8255C17.3705 17.7806 17.4061 17.7273 17.4304 17.6687C17.4547 17.61 17.4672 17.5471 17.4672 17.4836C17.4672 17.4201 17.4547 17.3572 17.4304 17.2985C17.4061 17.2399 17.3705 17.1865 17.3255 17.1416C17.2806 17.0967 17.2273 17.0611 17.1687 17.0368C17.11 17.0125 17.0471 17 16.9836 17C16.9201 17 16.8572 17.0125 16.7985 17.0368C16.7399 17.0611 16.6865 17.0967 16.6416 17.1416C16.5967 17.1865 16.5611 17.2399 16.5368 17.2985C16.5125 17.3572 16.5 17.4201 16.5 17.4836Z" fill="currentColor" />
    </svg>
  )
}

export default ErrorLogIcon
