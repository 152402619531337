import { h, FunctionComponent } from "preact"
import { useState, useEffect } from "preact/hooks"
// import { BsTrash } from "react-icons/bs"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
// import style from "../style.scss"
import { useTranslation } from "react-i18next"

type EditModuleProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
  mutate: () => void
}

// const defaultConfig = { name: "", value: "" }

const EditModule: FunctionComponent<EditModuleProps> = ({ data, show, onHide, mutate }) => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const [body, setBody] = useObjectState({})
  const [disable, setDisable] = useState(false)

  // const addConfig = () => {
  //   const newConfig = [...body.config]
  //   newConfig.push(defaultConfig)
  //   setBody({ config: newConfig })
  // }

  // const deleteConfig = (idx: number) => {
  //   const newConfig = [...body.config]
  //   newConfig.splice(idx, 1)
  //   setBody({ config: newConfig })
  // }

  // const editConfig = (
  //   idx: number,
  //   data: Partial<{
  //     name: string
  //     value: string
  //   }>
  // ) => {
  //   const newConfig = [...body.config]
  //   newConfig[idx] = { ...newConfig[idx], ...data }
  //   setBody({ config: newConfig })
  // }

  const setConfig = (data) => {
    const { config } = body
    const newConfig = { ...config, ...data }
    setBody({ config: newConfig })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    try {
      await fetcher().patch(`/module/${data?.uuid}`, body)
      await mutate()
      await onHide()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  useEffect(() => {
    let defaultBody = {
      name: data?.name,
      desc: data?.desc,
      regulation_reference: data?.regulation_reference,
      config: {
        hourly: "",
        daily: "",
      },
    }

    if (data?.config) {
      defaultBody = {
        ...defaultBody,
        config: data.config,
      }
    }

    setBody(defaultBody)
  }, [data])

  return (
    <form
      className="modal-backdrop"
      style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("buttons.edit_module")}</div>
          <div className="card-body">
            <div className="mb-4">
              <label className="form-label">{t("label.name")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.name}
                onChange={({ currentTarget }) => setBody({ name: currentTarget?.value })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.description")}</label>
              <textarea
                className="form-control rounded mw-100"
                value={body?.desc}
                onChange={({ currentTarget }) => setBody({ desc: currentTarget?.value })}
                rows="3"
                required
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.regulation_reference")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.regulation_reference}
                onChange={({ currentTarget }) => setBody({ regulation_reference: currentTarget?.value })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.count_data_in_one_hour")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.config?.hourly}
                onChange={({ currentTarget }) => setConfig({ hourly: currentTarget?.value })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.count_data_in_one_day")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.config?.daily}
                onChange={({ currentTarget }) => setConfig({ daily: currentTarget?.value })}
              />
            </div>
            {/* <div className={style.param_title}>Configurations</div>
            <div className={style.config_wrapper}>
              <div className="row mb-1">
                <div className="col form-label">Name</div>
                <div className="col form-label">Value</div>
              </div>
              {body?.config?.map((item, idx) => (
                <div className="row mb-2" key={idx}>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      required
                      value={item?.name}
                      onChange={({ currentTarget: e }) => editConfig(idx, { name: e.value })}
                    />
                  </div>
                  <div className="col d-flex justify-content-between">
                    <input
                      className="form-control rounded"
                      required
                      type="text"
                      value={item?.value}
                      onChange={({ currentTarget: e }) => editConfig(idx, { value: e.value })}
                    />
                    {body?.config?.length > 1 && <Button
                      variant="tertiary"
                      title="Delete"
                      type="button"
                      onClick={() => deleteConfig(idx)}
                      className="ms-2" icon={BsTrash} />}
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-end">
                <Button type="button" variant="primary" onClick={() => addConfig()}>Add New Config</Button>
              </div>
            </div> */}
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onHide} type="button" variant="tertiary" className="m-1" disabled={disable}>
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1" disabled={disable}>
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditModule
