import { ApiResponse } from "../types/api-response"
import { Cems } from "../types/cems"
import useAuth from "./useAuth"
import useSWR from "swr"

interface SitePaginate {
  mil?: string
  page: string | number
  limit: string | number
  search: string
  orders?: { [field: string]: string }[]
}

const useSites = (uuid?: string) => {
  const { fetcher } = useAuth()
  const url = `/site/${uuid || ""}`

  const { data, mutate, error } = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<Cems[]>>(url)
      .then(({ data }) => {
        return data.data?.record
      })
  )

  return { data, mutate, error }
}

export const useSiteById = (uuid: string) => {
  const { fetcher } = useAuth()
  const url = `/site/${uuid}`

  const { data, mutate, error } = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<Cems[]>>(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, mutate, error }
}

export const useMapSite = () => {
  const { fetcher } = useAuth()
  const url = `/site/map`

  const { data, mutate, error } = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<Cems[]>>(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, mutate, error }
}

export const useAllSite = () => {
  const { fetcher } = useAuth()
  const url = `/site/list-company`

  const { data, mutate, error } = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<Cems[]>>(url)
      .then(({ data }) => {
        return data.data
      })
  )

  return { data, mutate, error }
}

export const useSiteByCompany = (payload) => {
  const { fetcher } = useAuth()
  const url = `/site`
  const body = JSON.stringify(payload)
  const result = useSWR([url, body], (url, body) =>
    fetcher()
      .get<ApiResponse<Cems[]>>(url, { params: JSON.parse(body) })
      .then(({ data }) => data.data?.record)
  )

  return result
}

export const useSitePaginate = ({ mil, orders = [], page, limit, search }: SitePaginate) => {
  const { fetcher } = useAuth()
  const url = `/site`
  let body = JSON.stringify({ mil, page, limit, search })

  if (orders.length) {
    let orderParams = {}
    const sort = orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    body = JSON.stringify({ mil, page, limit, search, ...orderParams })
  }

  const result = useSWR([url, body], (url, body) =>
    fetcher()
      .get<ApiResponse<Cems[]>>(url, { params: JSON.parse(body) })
      .then(({ data }) => data.data)
  )
  const exportExcel = async (newLimit, newPage = 1) => {
    const newBody = JSON.stringify({ page: newPage, limit: newLimit })
    const { data } = await fetcher().get<ApiResponse<Cems[]>>(url, { params: JSON.parse(newBody) })
    return data?.data?.record
  }
  return { ...result, exportExcel }
}

export default useSites
