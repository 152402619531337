import { Fragment, FunctionComponent, h } from "preact"
import { useState } from "preact/hooks"
import { FaRegFileExcel } from "react-icons/fa"
import Container from "../../parts/container"
import TopBar from "../../components/topbar"
import TopFilter from "../../components/TopFilter"
import Breadcrumb from "../../components/topbar/breadcrumb"
import ServerPagination from "../../components/pagination/server-pagination"
import { sortActiveParam, sortParam, isAvgSensor, isDebit, getUnitParameterDebit } from "../../utils/sortParam"
import { toFixed } from "../../utils/toFixed"
import { useFilterContext } from "../../context/useFilter"
import { defaultParams } from "../../utils/defaults"
import Spinner from "../../components/spinner"
import { useRecordPaginate } from "../../hooks/useSensor"
import useSites from "../../hooks/useSite"
import { formatDateWithPeriod } from "../../utils/dateformat"
import useObjectState from "../../hooks/useObjectState"
import { sensorDataToExcel } from "./toexcel"
import { can } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import { Button } from "../../parts/buttons"
import style from "./style.scss"
import { useSortHeader } from "../../hooks/useSortHeader"
import { useTranslation } from "react-i18next"

type RecordSensorDataProps = h.JSX.HTMLAttributes<HTMLDivElement>

const RecordSensorDataPage: FunctionComponent<RecordSensorDataProps> = () => {
  const { t } = useTranslation()
  const { contextFilter, setContextFilter } = useFilterContext()
  const { data: cems } = useSites()
  const [filtered, setFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const defaultValue = {
    site: undefined,
    period: undefined,
    startDate: undefined,
    endDate: undefined,
    orders: [],
    ...contextFilter,
    sensor: Array.isArray(contextFilter?.sensor) ? contextFilter?.sensor?.[0] : contextFilter?.sensor,
  }
  const [payload, setPayload] = useObjectState(defaultValue)
  const { data, exportExcel } = useRecordPaginate({
    uuid: payload.site,
    type: payload.period,
    start: payload.startDate,
    end: payload.endDate,
    page: currentPage,
    orders: payload.orders,
    limit: 20,
  })

  const [colSpan, setColSpan] = useState(3)
  const columns = sortActiveParam(data?.parameter) || defaultParams

  const getData = (data) => {
    return data?.record?.map((item) => {
      const filterPayloads = item?.data?.filter(({ name }) => columns?.map(({ name: colName }) => colName)?.includes(name))
      return {
        timestamp: item.timestamp,
        payloads: sortParam(filterPayloads),
      }
    })
  }

  const tableData = getData(data)

  const handleFilterData = (data) => {
    setColSpan(3)
    if (!isRawData(data.period)) setColSpan(4)

    resetSorting()
    setPayload({ ...data, orders: [] })
    setFiltered(true)
    setContextFilter(data)
    setCurrentPage(1)
  }

  const getSiteName = (siteId) => cems?.find(({ uuid }) => uuid === siteId)?.name

  const getExcelData = async () => {
    const exportExcelData = await exportExcel(data?.totalRecords)
    const excelData = getData(exportExcelData)
    if (excelData?.length) {
      sensorDataToExcel(columns, excelData, payload.period, payload.sensor, getSiteName(payload.site))
    }
  }

  const isRawData = (interval: string) => {
    return ["", "raw"].includes(interval)
  }

  // order
  const { sort, onOrder, sortIcon, resetSorting } = useSortHeader({
    initialFieldToSort: {
      timestamp: null,
    },
  })
  const onSortField = (field: string) => {
    const sortParamsData = onOrder(field)
    setPayload({ ...payload, orders: sortParamsData })
  }
  // end order

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.record")} link="" />
        <Breadcrumb name={t("breadcrumb.sensor_data")} link="/record/sensor-data" />
      </TopBar>
      <Container>
        {data ? (
          <Fragment>
            <div className="row align-items-stretch">
              <TopFilter cems={cems} interval defaultValue={payload} onShow={handleFilterData} />
            </div>
            <div className="mt-3">
              <div className="d-flex justify-content-end">
                {can(Permission.RecordSensorDataExport) && (
                  <Button
                    variant="primary"
                    className={style.export_btn}
                    icon={FaRegFileExcel}
                    onClick={() => getExcelData()}
                    disabled={!tableData?.length}
                  >
                    {t("buttons.export_to_excel")}
                  </Button>
                )}
              </div>
              <div className="card mt-3">
                <div className="card-body">
                  <div className="overflow-auto">
                    <table className="table table-data table-center">
                      <thead>
                        <tr>
                          <th rowSpan={2} onClick={() => onSortField("timestamp")} className={style.sort_able}>
                            {t("table.time")} {sortIcon("timestamp")}
                          </th>
                          {columns?.map((col) => (
                            <th key={col?.name} colSpan={colSpan}>
                              {col?.name}
                            </th>
                          ))}
                        </tr>
                        <tr>
                          {columns?.map((col) => (
                            <Fragment key={`uom-${col?.name}`}>
                              {!isRawData(payload.period) && <th>Count</th>}
                              <th>
                                {t("table.value")} ({isDebit(col?.name) ? getUnitParameterDebit(payload.period) : col?.uom || "-"})
                              </th>
                              <th>{t("table.battery")} (%)</th>
                              <th>{t("table.delay")} (ms)</th>
                            </Fragment>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.length ? (
                          tableData?.map((raw, id) => (
                            <tr key={`raw-${id}`}>
                              <td className="text-nowrap">{formatDateWithPeriod(raw.timestamp, payload.period)}</td>
                              {raw.payloads.map((value, idx) => (
                                <Fragment key={`payloads-${idx}`}>
                                  {!isRawData(payload.period) && <td>{value.count}</td>}
                                  <td>{isAvgSensor(payload.period, value.name.toLowerCase()) ? toFixed(value?.avg) : toFixed(value?.value)} </td>
                                  <td>{payload.period === "raw" ? toFixed(value?.battery_percentage, 2) : toFixed(value?.avg_b_percentage, 2)}</td>
                                  <td>{payload.period === "raw" ? toFixed(value?.delay, 2) : toFixed(value?.avg_delay, 2)}</td>
                                </Fragment>
                              ))}
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td className="text-center py-4" colSpan={colSpan * (columns?.length ?? 0) + 1}>
                              {!filtered ? t("fill_the_filter_first") : t("no_data")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="my-2 d-flex justify-content-end">
                    <ServerPagination current={currentPage} last={data?.totalPages} onPageChange={setCurrentPage} />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <Spinner />
        )}
      </Container>
    </div>
  )
}

export default RecordSensorDataPage
