import { FunctionComponent, h } from "preact"
import { useRef, useState } from "preact/hooks"
import { route } from "preact-router"
import { mutate } from "swr"
import Flatpickr from "react-flatpickr"
import { Button } from "../../../parts/buttons"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import Container from "../../../parts/container"
import useAuth from "../../../hooks/useAuth"
import useSites from "../../../hooks/useSite"
import useObjectState from "../../../hooks/useObjectState"
import { formatDate } from "../../../utils/dateformat"
import "flatpickr/dist/flatpickr.css"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type AddKLHKProps = h.JSX.HTMLAttributes<HTMLDivElement>

const defaultBody = {
  site: { uuid: "" },
  parameter: "",
  description: "",
  completion_date: "",
  file: "",
}

const flatpickrOptions = {
  dateFormat: "Y-m-d",
  altInput: true,
  altFormat: "d/m/Y",
}

const AddKLHK: FunctionComponent<AddKLHKProps> = () => {
  const { t } = useTranslation()
  const { data: cems } = useSites()
  const [body, setBody] = useObjectState(defaultBody)
  const [disable, setDisable] = useState(false)
  const { fetcher } = useAuth()
  const parent = `/maintenance/ncr-klhk`
  const onBack = () => route(parent)
  const file = useRef<HTMLInputElement>(null)
  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    const form = new FormData()
    if (file.current?.files?.length) {
      form.append("file", file.current?.files[0])
      fetcher()
        .post("/ncr-klhk/file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => data?.data?.path as string)
        .then((path) => {
          return fetcher().post("/ncr-klhk", { ...body, file: path || "" })
        })
        .finally(() => {
          setDisable(false)
          mutate("/ncr-klhk")
          onBack()
        })
    }
  }

  return (
    <div>
      <TopBar>
        <Breadcrumb name={t("breadcrumb.maintenance")} link="" />
        <Breadcrumb name={t("breadcrumb.ncr_klhk")} link="/maintenance/ncr-klhk" />
        <Breadcrumb name={t("breadcrumb.add")} link="/maintenance/ncr-klhk/add" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title pt-4">
            <div className="d-flex gap-4 align-items-center">
              <p className={style.page_title}>{t("page.ncr_klhk.add_new_klhk")}</p>
            </div>
          </div>
          <form onSubmit={(event) => handleSubmit(event)} className="card-body">
            <div className="mb-3">
              <label class="form-label">{t("label.site_name")}</label>
              <select
                className="form-control rounded mw-100"
                required
                value={body.site.uuid}
                onChange={({ currentTarget }) => setBody({ site: { uuid: currentTarget.value } })}
              >
                <option value="">{t("label.select_site")}</option>
                {cems?.map((cem) => (
                  <option key={cem.uuid} value={cem.uuid}>
                    {cem.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label class="form-label">{t("label.parameter")}</label>
              <select
                required
                value={body.parameter}
                className="form-control rounded mw-100"
                onChange={({ currentTarget: e }) => setBody({ parameter: e.value })}
              >
                <option value="">{t("label.select_parameter")}</option>
                {cems
                  ?.find(({ uuid }) => uuid == body.site.uuid)
                  ?.parameters?.filter(({ is_active }) => is_active)
                  ?.map((param) => (
                    <option value={param.name} key={param.uuid}>
                      {param.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-3">
              <label class="form-label">{t("label.problem_description")}</label>
              <textarea
                rows="5"
                required
                value={body.description}
                class="form-control rounded-3 mw-100"
                onChange={({ currentTarget: e }) => setBody({ description: e.value })}
              />
            </div>
            <div class="mb-4">
              <label for="expiration-date" class="form-label">
                {t("label.completion_date")}
              </label>
              <Flatpickr
                value={body.completion_date}
                options={flatpickrOptions}
                class="form-control rounded mw-100"
                onChange={(date: Date) => setBody({ completion_date: formatDate(date[0], "YYYY-MM-DD") })}
              />
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.upload_klhk_report")}</label>
              <input ref={file} class="form-control rounded mw-100" type="file" accept="application/pdf" required />
            </div>
            <div className="d-flex justify-content-end">
              <Button type="submit" className="mx-1" variant="primary" disabled={disable}>
                {t("buttons.save")} Report
              </Button>
              <Button onClick={onBack} type="button" className="mx-1" variant="tertiary" disabled={disable}>
                {t("buttons.cancel")}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  )
}

export default AddKLHK
