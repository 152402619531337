import useSWR from 'swr'

export const toggleNavbar = "toggle-navbar"
export const loggedinUser = "loggedin-user-data"

export default function useSharedState(key: string, initialValue: any) {
  const { data: state, mutate: setState } = useSWR(key, {
    fallbackData: initialValue,
  })
  return [state, setState]
}
