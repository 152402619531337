import { FunctionComponent, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { MapContainer, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet"
import { Link, route } from "preact-router"
import { FaChevronRight, FaSort, FaSortDown, FaSortUp } from "react-icons/fa"
import Container from "../../../parts/container"
import { LatLngTuple } from "leaflet"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import { iconMap, iconMapGreen, iconMapGray } from "./icon"
import useCompany from "../../../hooks/useCompany"
import { useMapSite, useSiteByCompany } from "../../../hooks/useSite"
import { useAllLastRecord } from "../../../hooks/useSensor"
import { useAllLastAlert } from "../../../hooks/useAlerts"
import { Permission } from "../../../enum/permissions.enum"
import { can } from "../../../utils/access"
import { toFixed } from "../../../utils/toFixed"
import { formatDate } from "../../../utils/dateformat"
import Spinner from "../../../components/spinner"
import Pagination from "../../../components/pagination"
import usePagination from "../../../hooks/usePagination"
import SearchBox from "../../../components/TopFilter/search-box"
import "leaflet/dist/leaflet.css"
import style from "../style.scss"
import moment from "moment"
import { useTranslation } from "react-i18next"

type DashboardMapProps = h.JSX.HTMLAttributes<HTMLDivElement>

const DashboardMap: FunctionComponent<DashboardMapProps> = () => {
  const { t } = useTranslation()
  let center: LatLngTuple = [-2, 117]
  const [isDone, setIsDone] = useState(false)
  const [payload, setPayload] = useState({})
  const { data: mils } = useCompany()
  const { data: allCems } = useMapSite()
  const { data: lastData } = useAllLastRecord()
  const { data: lastAlert } = useAllLastAlert()
  const { data: cems, mutate: mutateCems } = useSiteByCompany(payload)
  const [filteredData, setFilteredData] = useState([])
  const [filteredAlert, setFilteredAlert] = useState([])
  const [dataSearch, setDataSearch] = useState("")
  const [alertSearch, setAlertSearch] = useState("")
  const [order, setOrder] = useState<{ [field: string]: { sort: string | null; icon: Element } }>({
    "error-log": {
      sort: "desc",
      icon: <FaSortDown />,
    },
    "last-data-entry": {
      sort: "desc",
      icon: <FaSortDown />,
    },
  })

  const lastDataPages = usePagination({ count: filteredData?.length || 0, step: 10 })
  const lastDataTable = filteredData?.slice(lastDataPages.output, lastDataPages.output + lastDataPages.step)
  const lastAlertPages = usePagination({ count: filteredAlert?.length || 0, step: 10 })
  const lastAlertTable = filteredAlert?.slice(lastAlertPages.output, lastAlertPages.output + lastAlertPages.step)

  const canViewAllMapMarker = can(Permission.DashboardMapsViewAllMapMarker)

  const cemsWithRedMapMarker = allCems?.map((item) => ({ ...item, color: item?.last_data ? "green" : "red" }))
  const mergeMapMarker = cemsWithRedMapMarker?.map((item) => {
    if (cems?.length === 1) center = [cems?.[0]?.coordinate?.lat, cems?.[0]?.coordinate?.long]
    const site = cems?.find(({ uuid }) => item?.uuid === uuid)
    if (!site) {
      return { ...item, color: "gray" }
    }
    return item
  })

  const data = canViewAllMapMarker ? cemsWithRedMapMarker : mergeMapMarker
  const getParameters = (parameters: any[]): string => {
    return parameters
      ?.filter(({ is_active }) => is_active)
      ?.map(({ name }) => name)
      ?.join(", ")
  }

  const getLastDataEntry = (uuid: string): string => {
    if (lastData?.length) {
      const timestamp = lastData?.find(({ logger_id }) => logger_id === uuid)?.timestamp
      return timestamp ? formatDate(timestamp, "DD MMMM YYYY HH:mm") : "-"
    }
    return "-"
  }

  const getMarkerColor = (color: string) => {
    const marker = [
      { color: "red", component: iconMap },
      { color: "green", component: iconMapGreen },
      { color: "gray", component: iconMapGray },
    ]

    const mapColor = marker?.find((mapMarker) => mapMarker.color === color)
    return mapColor?.component
  }

  const lastDataColumn = [...new Set(lastData?.map(({ data }) => data?.map(({ name }) => name))?.flat())]

  const getParameterValue = (param, data) => {
    const value = data?.find(({ name }) => name === param)?.value ?? "-"
    return toFixed(value)
  }

  const goToMonitoring = (cemId: string) => {
    return route(`/dashboard/monitoring?site=${cemId}`)
  }

  const isLoading = !Array.isArray(allCems) || !Array.isArray(cems) || !Array.isArray(lastData) || !Array.isArray(lastAlert) || !isDone

  const compareTimestamps = (timestampA, timestampB, sortOrder = "asc") => {
    const timeA = moment(timestampA)
    const timeB = moment(timestampB)

    return sortOrder === "asc" ? timeA - timeB : timeB - timeA
  }

  const sortData = (sorting: { field: string; modul: string }) => {
    let tempOrder = "asc"
    if (order[sorting.modul].sort == "asc") {
      tempOrder = "desc"
      setOrder({
        ...order,
        [sorting.modul]: {
          sort: tempOrder,
          icon: <FaSortDown />,
        },
      })
    } else {
      tempOrder = "asc"
      setOrder({
        ...order,
        [sorting.modul]: {
          sort: tempOrder,
          icon: <FaSortUp />,
        },
      })
    }

    if (sorting.modul == "last-data-entry") {
      const data = JSON.parse(JSON.stringify(lastData))
      const sortData = data.sort((a, b) => compareTimestamps(a[sorting.field], b[sorting.field], order[sorting.modul].sort))
      setFilteredData(sortData)
    }
    if (sorting.modul == "error-log") {
      const data = JSON.parse(JSON.stringify(lastAlert))
      const sortData = data.sort((a, b) => compareTimestamps(a[sorting.field], b[sorting.field], order[sorting.modul].sort))
      setFilteredAlert(sortData)
    }
  }

  useEffect(() => {
    let searchedData
    if (Array.isArray(lastData)) {
      // lastData?.sort((a, b) => new Date(b?.timestamp) - new Date(a?.timestamp))
      setFilteredData(lastData)
      if (dataSearch) {
        searchedData = lastData?.filter(({ site, logger_id }) => {
          return site?.name?.toLowerCase()?.includes(dataSearch) || logger_id?.toLowerCase()?.includes(dataSearch) || !dataSearch
        })
      } else {
        searchedData = lastData
      }
    }
    setFilteredData(searchedData)
  }, [lastData, dataSearch])

  useEffect(() => {
    let searchedAlert
    if (Array.isArray(lastAlert)) {
      setFilteredAlert(lastAlert)
      // lastAlert?.sort((a, b) => new Date(b?.timestamp) - new Date(a?.timestamp))
      if (alertSearch) {
        searchedAlert = lastAlert?.filter(({ site }) => {
          return site?.name?.toLowerCase()?.includes(alertSearch) || !alertSearch
        })
      } else {
        searchedAlert = lastAlert
      }
    }
    setFilteredAlert(searchedAlert)
  }, [lastAlert, alertSearch])

  useEffect(() => {
    const getNewCems = async () => {
      await mutateCems()
      setIsDone(true)
    }
    getNewCems()
  }, [])

  useEffect(() => {
    mils?.length === 1 ? setPayload({ mil: mils?.[0]?.uuid }) : setPayload({})
  }, [mils])

  useEffect(() => {
    if (lastData && order["last-data-entry"].sort != null) {
      const data = JSON.parse(JSON.stringify(lastData))
      const sortData = data.sort((a, b) => compareTimestamps(a["timestamp"], b["timestamp"], order["last-data-entry"].sort))
      setFilteredData(sortData)
    }
  }, [lastData, order])

  useEffect(() => {
    if (lastAlert && order["error-log"].sort != null) {
      const data = JSON.parse(JSON.stringify(lastAlert))
      const sortData = data.sort((a, b) => compareTimestamps(a["timestamp"], b["timestamp"], order["error-log"].sort))
      setFilteredAlert(sortData)
    }
  }, [lastAlert, order])

  if (isLoading) return <Spinner />

  return (
    <div class="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.dashboard")} link="" />
        <Breadcrumb name={t("breadcrumb.map")} link="/dashboard/map" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <MapContainer
              center={center}
              zoom={cems?.length === 1 ? 7 : 5}
              style={{ height: "450px", zIndex: 1 }}
              zoomControl={false}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <ZoomControl position="bottomright" />
              {data?.map(({ coordinate, parameters, name: cemsName, uuid: uniqueID, color }) => {
                const position: LatLngTuple = [parseFloat(coordinate.lat) || 0, parseFloat(coordinate.long) || 0]
                return (
                  <Marker key={uniqueID} icon={getMarkerColor(color)} position={position} title={cemsName}>
                    <Popup maxWidth={512}>
                      <table style={{ fontSize: "small" }}>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>{t("site")}</td>
                          <td>{`: ${cemsName}`}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>{t("label.latitude")}</td>
                          <td>
                            {`: 
                              ${Math.abs(position[0]).toFixed(2)}
                              ${position[0] < 0 ? "LS" : "LU"}
                              `}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>{t("label.longitude")}</td>
                          <td>
                            {`: 
                              ${Math.abs(position[1]).toFixed(2)}
                              ${position[1] < 0 ? "BB" : "BT"}
                              `}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold", verticalAlign: "top" }}>{t("label.parameters")}</td>
                          <td>
                            {`: 
                              ${getParameters(parameters)}
                              `}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>{t("page.dashboard.last_data_entry")}</td>
                          <td>
                            {`: 
                              ${getLastDataEntry(uniqueID)}
                              `}
                          </td>
                        </tr>
                      </table>
                      {can(Permission.DashboardMonitoringView) && color !== "gray" && (
                        <p className="link" onClick={() => goToMonitoring(uniqueID)}>
                          {t("page.dashboard.click_here_for_overview_details")}
                        </p>
                      )}
                    </Popup>
                  </Marker>
                )
              })}
            </MapContainer>
          </div>
        </div>
        <div className="row align-items-stretch mt-4">
          <div className="col-xxl-8 col-12 mb-3 mh-100">
            <div className="card h-100">
              <div className="card-title">
                <p class={style.title}>{t("page.dashboard.last_data_entry")}</p>
                {can(Permission.DashboardMapLastDataSearch) && (
                  <SearchBox placeholder={t("placeholder.search_site_name")} onChange={(value) => setDataSearch(value?.toLowerCase())} />
                )}
              </div>
              <div className="card-body overflow-auto h-100">
                <table class={`table table-center ${style.table}`}>
                  <thead>
                    <tr>
                      <th>{t("table.site_name")}</th>
                      <th
                        onClick={() => {
                          sortData({ field: "timestamp", modul: "last-data-entry" })
                        }}
                      >
                        {t("table.time")} {order["last-data-entry"].icon}
                      </th>
                      {lastDataColumn?.map((col) => (
                        <th key={col}>{col}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {lastDataTable?.length ? (
                      lastDataTable?.map((record) => (
                        <tr key={record?.uuid_data}>
                          <td className="text-nowrap">{record?.site?.name}</td>
                          <td className="text-nowrap">{formatDate(record?.timestamp, "DD/MM/YYYY HH:mm")}</td>
                          {lastDataColumn?.map((col, idx) => (
                            <td key={idx}>{getParameterValue(col, record?.data)}</td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2 + lastDataColumn?.length} className="text-center">
                          No data.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between align-items-end">
                  <div>{can(Permission.DashboardMapLastDataPagination) && <Pagination hook={lastDataPages} simple />}</div>
                  <div>
                    {can(Permission.RecordSensorDataView) && (
                      <Link href="/record/sensor-data" className={style.link}>
                        {t("page.dashboard.see_more_detail")}
                        <FaChevronRight className="ms-2" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-12 mb-3 mh-100">
            <div className="card h-100">
              <div className="card-title d-flex justify-content-between gap-4">
                <p class={style.title}>{t("page.dashboard.last_error_logs")}</p>
                {can(Permission.DashboardMapLastDataSearch) && (
                  <SearchBox placeholder={t("placeholder.search_site_name")} onChange={(value) => setAlertSearch(value?.toLowerCase())} />
                )}
              </div>
              <div className="card-body overflow-auto h-100">
                <table class={`table table-center ${style.table}`}>
                  <thead>
                    <tr>
                      <th>{t("table.site_name")}</th>
                      <th
                        onClick={() => {
                          sortData({ field: "timestamp", modul: "error-log" })
                        }}
                      >
                        {t("table.time")} {order["error-log"].icon}
                      </th>
                      <th>{t("table.error")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastAlertTable?.length ? (
                      lastAlertTable?.map((alert) => (
                        <tr key={alert?.uuid}>
                          <td className="text-nowrap">{alert?.site?.name}</td>
                          <td className="text-nowrap">{formatDate(alert?.timestamp, "DD/MM/YYYY HH:mm")}</td>
                          <td className="text-nowrap">{alert?.description}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No data.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between align-items-end">
                  <div>{can(Permission.DashboardMapLastDataPagination) && <Pagination hook={lastAlertPages} simple />}</div>
                  <div>
                    {can(Permission.ErrorLogView) && (
                      <Link href="/error-log" className={style.link}>
                        {t("page.dashboard.see_more_detail")}
                        <FaChevronRight className="ms-2" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default DashboardMap
