import { FunctionComponent, h } from "preact"
import { useState, useRef } from "preact/hooks"
import { toPng } from "html-to-image"
import Container from "../../parts/container"
import { Button } from "../../parts/buttons"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import TopFilterPeriod from "../../components/TopFilter/period"
import Spinner from "../../components/spinner"
import { useRecordGraph } from "../../hooks/useSensor"
import useSites from "../../hooks/useSite"
import useObjectState from "../../hooks/useObjectState"
import { analyticChartColor } from "../../utils/color"
import { toFixed } from "../../utils/toFixed"
import { isAvgSensor } from "../../utils/sortParam"
import { formatDateWithPeriod } from "../../utils/dateformat"
import { can } from "../../utils/access"
import { Permission } from "../../enum/permissions.enum"
import AnalyticLineChart from "./analytic-chart"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type AnalyticsPeriodPageProps = h.JSX.HTMLAttributes<HTMLDivElement>

const defaultValue = {
  site: undefined,
  sensor: undefined,
  period: undefined,
  firstPeriod: [],
  secondPeriod: [],
}

const AnalyticsPeriodPage: FunctionComponent<AnalyticsPeriodPageProps> = () => {
  const { t } = useTranslation()
  const { data: cems } = useSites()
  const [payload, setPayload] = useObjectState(defaultValue)
  const [zoom, setZoom] = useState(true)
  const element = useRef<HTMLDivElement>(null)

  const onZoomChange = () => setZoom((prevState) => !prevState)

  const { data: firstData } = useRecordGraph({
    uuid: payload.site,
    type: payload.period,
    start: payload.firstPeriod?.[0],
    end: payload.firstPeriod?.[1],
  })
  const { data: secondData } = useRecordGraph({
    uuid: payload.site,
    type: payload.period,
    start: payload.secondPeriod?.[0],
    end: payload.secondPeriod?.[1],
  })

  const getPropertyData = (data, sensor, key) => {
    const value = data?.find(({ name }) => name === sensor)?.[key]
    return toFixed(value)
  }

  const getChartDatas = (data, colorIndex) => {
    if (!data?.record?.length) return []
    const sensorDataAvg = data?.record?.map(({ data }) => data?.find(({ name }) => name === payload.sensor)?.avg)
    const sensorDataValue = data?.record?.map(({ data }) => data?.find(({ name }) => name === payload.sensor)?.value)
    return [
      {
        dataset: {
          data: isAvgSensor(payload.period, payload.sensor) ? sensorDataAvg : sensorDataValue,
          borderColor: analyticChartColor(colorIndex),
          backgroundColor: analyticChartColor(colorIndex),
          tension: 0.4,
        },
        min: getPropertyData(data?.calculation, payload.sensor, "min"),
        max: getPropertyData(data?.calculation, payload.sensor, "max"),
        lower_threshold: getPropertyData(data?.parameter, payload.sensor, "lower_threshold"),
        upper_threshold: getPropertyData(data?.parameter, payload.sensor, "upper_threshold"),
      },
    ]
  }

  const getChartLabels = (data) => {
    if (!data?.record?.length) return []
    return data?.record?.map(({ timestamp }) => timestamp)
  }

  const firstChart = getChartDatas(firstData, 0)
  const firstLabels = getChartLabels(firstData)
  const secondChart = getChartDatas(secondData, 1)
  const secondLabels = getChartLabels(secondData)
  const firstStartDate = formatDateWithPeriod(firstLabels?.at(-1), payload.period)
  const firstEndDate = formatDateWithPeriod(firstLabels?.[0], payload.period)
  const secondStartDate = formatDateWithPeriod(secondLabels?.at(-1), payload.period)
  const secondEndDate = formatDateWithPeriod(secondLabels?.[0], payload.period)

  const handleExport = () => {
    if (element.current) {
      toPng(element.current, { cacheBust: true }).then((dataUrl) => {
        const link = document.createElement("a")
        link.download = `period_analytics_${new Date().getTime()}.png`
        link.href = dataUrl
        link.click()
      })
    }
  }

  const handleFilterData = (data) => setPayload(data)

  const getSiteName = () => {
    return cems?.find(({ uuid }) => uuid === payload.site)?.name
  }

  return (
    <div className="w-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.analytic")} link="" />
        <Breadcrumb name={t("breadcrumb.period")} link="/analytic/period" />
      </TopBar>
      <Spinner show={!!payload.site && !firstChart?.length && !secondChart?.length} />
      <Container>
        <TopFilterPeriod cems={cems} onShow={handleFilterData} />
        {can(Permission.AnalyticPeriodDownload) && (
          <div className="d-flex justify-content-end mt-3">
            <Button variant="primary" onClick={() => handleExport()}>
              {t("buttons.download")}
            </Button>
          </div>
        )}
        <div className="row mt-3" ref={element}>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-title">
                <div>
                  <div className={style.name}>{getSiteName()}</div>
                  <p class={style.title}>{payload.sensor}</p>
                </div>
                <label className="mb-3 me-2">
                  <input type="checkbox" class="form-check-input me-2" checked={zoom} onchange={onZoomChange} />
                  {t("zoom")}
                </label>
              </div>
              <div class={`d-flex justify-content-between`}>
                <p class={style.subtitle}>
                  BMAL:
                  {getPropertyData(firstData?.parameter, payload.sensor, "lower_threshold")}-
                  {getPropertyData(firstData?.parameter, payload.sensor, "upper_threshold")}
                </p>
              </div>
              <div className="card-body position-relative">
                <AnalyticLineChart data={firstChart} labels={firstLabels} period={payload.period} showAnnotation={true} zoom={zoom} height={80} />
              </div>
              <div className="mt-5 card-title">
                <p class={style.title}>{payload.sensor}</p>
              </div>
              <div class={`d-flex justify-content-between`}>
                <p class={style.subtitle}>
                  BMAL:
                  {getPropertyData(secondData?.parameter, payload.sensor, "lower_threshold")}-
                  {getPropertyData(secondData?.parameter, payload.sensor, "upper_threshold")}
                </p>
              </div>
              <div className="card-body position-relative">
                <AnalyticLineChart data={secondChart} labels={secondLabels} period={payload.period} showAnnotation={true} zoom={zoom} height={80} />
                {payload.site && (
                  <div className="mt-4 d-flex align-items-center gap-5">
                    <div className="d-flex align-items-center gap-2">
                      <div className={style.period_legend} style={{ backgroundColor: analyticChartColor(0) }} />
                      <p className={style.period_legend_text}>
                        {firstStartDate} - {firstEndDate}
                      </p>
                    </div>
                    <div className="ml-4 d-flex align-items-center gap-2">
                      <div className={style.period_legend} style={{ backgroundColor: analyticChartColor(1) }} />
                      <p className={style.period_legend_text}>
                        {secondStartDate} - {secondEndDate}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AnalyticsPeriodPage
