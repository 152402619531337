import { FunctionComponent, h, Fragment } from "preact"
import { useState, useEffect } from "preact/hooks"
import useToggleNavbar, { toggleNavbar } from "../../state"
import NavbarItem from "./item"
import NavbarItemCollapse from "./item-collapse"
import { menuList } from "./menu"
import Brand from "../brand"
import style from "./index.scss"
import templates from "../../utils/template-configs"
import usePlatformApp from "../../hooks/usePlatformApp"
import useTheme from "../../hooks/useTheme"
import { useTranslation } from "react-i18next"

type NavbarProps = h.JSX.HTMLAttributes<HTMLDivElement>

const Navbar: FunctionComponent<NavbarProps> = () => {
  const { t } = useTranslation()
  const { platform } = usePlatformApp()
  const { getPlatformSetting } = useTheme()
  const [platformSettings, setPlaformSettings] = useState({
    logo: getPlatformSetting()?.logo || templates[platform].navbar.brand.logo,
    alt_logo: templates[platform].navbar.brand.alt_logo,
    powered: templates[platform].navbar.brand.powered,
    tagline: templates[platform].navbar.brand.tagline,
    name: templates[platform].navbar.brand.name,
  })
  const [showDropdown, setShowDropdown] = useState({ label: t("navigation.dashboard"), status: true })
  const menus = menuList()

  const { pathname } = window.location
  const label = pathname.split("/")?.[1]
  const [hideNavbar] = useToggleNavbar(toggleNavbar, false)

  const sizeClass = hideNavbar ? style.hide : style.show

  const onSetShowDropdown = (label) => {
    setShowDropdown((prevState) => {
      if (prevState.label === label) {
        return { label, status: !prevState.status }
      }
      return { label, status: true }
    })
  }

  useEffect(() => {
    const transLabel = t(`navigation.${label}`).toLowerCase()
    setShowDropdown({ label: transLabel, status: true })
    if (getPlatformSetting()?.logo) {
      setPlaformSettings((prevState) => ({ ...prevState, tagline: "", name: "" }))
    }
  }, [pathname, label])

  return (
    <nav className={`bg-navbar text-white d-flex flex-column ${sizeClass} ${style.nav}`}>
      {hideNavbar ? (
        <Fragment>
          <div className="my-4 mx-3">
            <img
              className={style.logo}
              src={getPlatformSetting()?.logo || templates[platform].navbar.toggle_logo}
              alt={templates[platform].navbar.alt_toggle_logo}
              width={32}
            />
          </div>
          <div className={style.menu}>
            {menus.map((item, index) => (
              <NavbarItemCollapse item={item} key={index} />
            ))}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="my-4 mx-3">
            <Brand
              logo={platformSettings.logo}
              alt_logo={platformSettings.alt_logo}
              powered={platformSettings.powered}
              tagline={platformSettings.tagline}
              name={platformSettings.name}
            />
          </div>
          <div className={style.menu}>
            {menus.map((item, index) => (
              <NavbarItem item={item} key={index} showDropdown={showDropdown} setShowDropdown={onSetShowDropdown} />
            ))}
          </div>
        </Fragment>
      )}
    </nav>
  )
}

export default Navbar
