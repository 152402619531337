import { Fragment, FunctionComponent, h } from "preact"
import { route } from "preact-router"
import useAuth from "../../hooks/useAuth"
import useSharedState, { toggleNavbar, loggedinUser } from "../../state"
import { useNotificationCount } from "../../hooks/useNotifications"
import useModal from "../../hooks/useModal"
import { BiBell, BiMenu, BiMenuAltLeft } from "react-icons/bi"
import { MdTranslate } from "react-icons/md"
import { HiOutlineLogout } from "react-icons/hi"
import { titleCase } from "../../utils/string"
import Notification from "../../routes/notification"
import SiteLogo from "../brand/site-logo"
import { getLocalStorage, landingPage } from "../../utils/local-storage"
import style from "./style.scss"
import { useTranslation } from "react-i18next"
import { useNotification } from "../../context/useNotification"

type TopBarProps = h.JSX.HTMLAttributes<HTMLDivElement>

const TopBar: FunctionComponent<TopBarProps> = ({ children }) => {
  const { t, i18n } = useTranslation()

  const authPage = getLocalStorage(landingPage) || "/login"
  const { logout } = useAuth()
  const { show, onShow, onHide } = useModal()
  const [hideNavbar, setHideNavbar] = useSharedState(toggleNavbar, false)
  const [userData] = useSharedState(loggedinUser, undefined)
  const { count } = useNotification()
  const notifCount = count > 99 ? "99+" : count

  const onLogoutHandler = () => {
    logout()
    route(authPage)
  }

  return (
    <div className={style.topbar}>
      <Notification show={show} onHide={onHide} />
      <div className="bg-navbar d-flex px-5 py-2 justify-content-between">
        <div className={style.nav}>
          <div className={style.hamburger}>
            <button onClick={() => setHideNavbar(!hideNavbar)}>{hideNavbar ? <BiMenu size="2rem" /> : <BiMenuAltLeft size="2rem" />}</button>
          </div>
          <div className={style.breadcrumb}>{children}</div>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-4">
          <a href="#" className="text-white text-decoration-none" onClick={onShow}>
            <div className={`text-center ${style.notification}`}>
              <span className={style.icon}>
                <BiBell />
              </span>
              {count >= 1 ? <span className={style.badge}>{notifCount}</span> : ""}
            </div>
          </a>
          <div className="d-flex align-items-center text-white gap-3">
            <SiteLogo />
            <div>
              <div className="fw-bolder">{userData?.name ?? "-"}</div>
              <div className={style.fontSize14}>{userData?.mil?.name ?? "-"}</div>
              <div className={style.fontSize14}>{titleCase(userData?.role?.name) ?? "-"}</div>
            </div>
          </div>
          <div className={`d-flex flex-column text-white ${style.fontSize14}`}>
            <div>{t("label.notification")}:</div>
            <div className="fw-bolder d-flex align-items-center gap-1">
              {userData?.notification ? (
                <Fragment>
                  <div className={style.online} />
                  On
                </Fragment>
              ) : (
                <Fragment>
                  <div className={style.offline} />
                  Off
                </Fragment>
              )}
            </div>
          </div>
          <div className={style.logout} role="button" onClick={() => onLogoutHandler()}>
            <HiOutlineLogout size="1.5rem" />
            {t("buttons.logout")}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar
