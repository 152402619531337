import { h, FunctionComponent } from "preact"
import { Button } from "../../../parts/buttons"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type ModuleDetailProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
}

const ModuleDetail: FunctionComponent<ModuleDetailProps> = ({ data, show, onHide }) => {
  const { t } = useTranslation()
  return (
    <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}>
      <div className="modal-dialog modal-md modal-dialog-centered" role="document">
        <div className="modal-content card px-2 py-1">
          <div className="card-body text-center h5">{t("module_detail")}</div>
          <div className="card-body">
            <div className={style.detail}>
              <div className={style.label}>{t("label.name")}</div>
              <div className={style.value}>{data?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.description")}</div>
              <div className={style.value}>{data?.desc}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.regulation_reference")}</div>
              <div className={style.value}>{data?.regulation_reference}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.count_data_in_one_hour")}</div>
              <div className={style.value}>{data?.config?.hourly}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.count_data_in_one_day")}</div>
              <div className={style.value}>{data?.config?.daily}</div>
            </div>
            {/* <div className={style.detail}>
              <div className={style.label}>Configurations</div>
              {data?.config?.map((item, index) => (
                <div className="d-flex gap-2" key={index}>
                  <div className={style.sub_value}>{item?.name}</div>
                  <div className={style.sub_value_content}>{item?.value}</div>
                </div>
              ))}
            </div> */}
            <div className="d-flex justify-content-end">
              <Button onClick={onHide} type="button" variant="tertiary" className="m-1">
                {t("buttons.close")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModuleDetail
