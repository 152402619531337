import { FunctionComponent, h } from "preact"
import { useRef, useState } from "preact/hooks"
import { route } from "preact-router"
import { BsTrash } from "react-icons/bs"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { Button } from "../../parts/buttons"
import Container from "../../parts/container"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import useAuth from "../../hooks/useAuth"
import useObjectState from "../../hooks/useObjectState"
import useSites from "../../hooks/useSite"
import style from "./style.scss"
import "flatpickr/dist/flatpickr.css"
import { useTranslation } from "react-i18next"

type AddReportProps = h.JSX.HTMLAttributes<HTMLDivElement>
type Inputs = {
  site: string
  monitoring_date: Date
}
type Param = {
  name: string
  uom: string
  bm: number
  value: number
  emission_load: number
}
type Body = {
  site: string
  monitoring_date: Date | null
  operation_time: number
  test_lab: string
  accreditated_lab: boolean
  klhk_approved_envi_lab: boolean
  parameters: Param[]
  lab_certificate: string
  envi_lab_recomendation_letter: string
  sampling_photo: string
}
const defaultParam = {
  name: "",
  uom: "",
  bm: 0,
  value: 0,
  emission_load: 0,
}
const defaultBody: Body = {
  site: "",
  monitoring_date: moment().toISOString(true),
  operation_time: 0,
  test_lab: "",
  accreditated_lab: true,
  klhk_approved_envi_lab: true,
  parameters: [defaultParam],
  lab_certificate: "",
  envi_lab_recomendation_letter: "",
  sampling_photo: "",
}
const flatpickrOptions = {
  dateFormat: "Z",
  altInput: true,
  altFormat: "d/m/Y",
}

const AddReport: FunctionComponent<AddReportProps> = () => {
  const { t } = useTranslation()
  const { data: cems } = useSites()
  const parent = `/maintenance/calibration-report`
  const onBack = () => route(parent)
  const [body, setBody] = useObjectState(defaultBody)
  const { fetcher } = useAuth()

  const lab_certificate = useRef<HTMLInputElement>(null)
  const envi_lab_recomendation_letter = useRef<HTMLInputElement>(null)
  const sampling_photo = useRef<HTMLInputElement>(null)

  const handleEditParam = (
    idx: number,
    value: Partial<{
      name: string
      uom: string
      bm: number
      value: number
      emission_load: number
    }>
  ) => {
    const last = [...body.parameters]
    last[idx] = { ...last[idx], ...value }
    setBody({ parameters: last })
  }
  const handleDelParam = (idx: number) => {
    const last = [...body.parameters]
    last.splice(idx, 1)
    setBody({ parameters: last })
  }
  const handelAddParam = () => {
    const last = [...body.parameters]
    last.push(defaultParam)
    setBody({ parameters: last })
  }
  const setUom = (id, name) => {
    const param = cems?.find(({ uuid }) => uuid === body?.site)?.parameters
    const uom = param?.find(({ name: uomName }) => uomName === name)?.uom
    const currentParam = [...body.parameters]
    currentParam[id] = { ...currentParam[id], name, uom }
    setBody({ parameters: currentParam })
  }

  const [disableButton, setDisableButton] = useState(false)

  const handleSubmit = async (event: KeyboardEvent) => {
    event.preventDefault()
    setDisableButton(true)
    const paths = [lab_certificate, envi_lab_recomendation_letter, sampling_photo]
    const files = paths.map(({ current }) => {
      const form = new FormData()
      if (current?.files?.length) {
        form.append("file", current?.files[0])
        return fetcher()
          .post("/manual-report/file", form, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(({ data }) => data.data.path as string)
      }
    })
    try {
      const [lab, envi, sampling] = await Promise.all(files)
      await fetcher()
        .post("/manual-report", {
          ...body,
          lab_certificate: lab,
          envi_lab_recomendation_letter: envi,
          sampling_photo: sampling,
        })
        .then(() => {
          onBack()
          setDisableButton(false)
        })
    } catch (error) {
      const message = error?.message ?? "Size File Maximum 1 MB"
      alert(message)
      setDisableButton(false)
    }
  }

  return (
    <div className="min-vh-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.maintenance")} link="" />
        <Breadcrumb name={t("breadcrumb.calibration_report")} link="/maintenance/calibration-report" />
        <Breadcrumb name={t("breadcrumb.add")} link="/maintenance/calibration-report/add" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title pt-4">
            <div className="d-flex gap-4 align-items-center">
              <p className={style.page_title}>{t("page.calibration_report.calibration_report_entry")}</p>
            </div>
          </div>
          <form onSubmit={(event) => handleSubmit(event)} className="card-body">
            <div className="mb-3">
              <div>{t("site")}</div>
              <select
                className="form-control rounded mw-100"
                required
                value={body.site}
                onChange={({ currentTarget }) => setBody({ site: currentTarget.value })}
              >
                <option value="">{t("label.select_site")}</option>
                {cems?.map((cem) => (
                  <option key={cem.uuid} value={cem.uuid}>
                    {cem.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <div>{t("label.monitoring_date")}</div>
              <Flatpickr
                value={body.monitoring_date}
                options={flatpickrOptions}
                className="form-control rounded mw-100"
                onChange={(date: Date) => setBody({ monitoring_date: date[0] })}
              />
            </div>
            <div className="mb-3">
              <div>{t("label.operation_time")} (in Hour)</div>
              <input
                type="number"
                className="form-control rounded mw-100"
                value={body.operation_time}
                onChange={({ currentTarget: e }) => setBody({ operation_time: Number(e.value) })}
              />
            </div>
            <div className={style.parameter}>{t("label.parameters")}</div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">{t("label.parameter_name")}</div>
                  <div className="col">{t("label.unit")}</div>
                  <div className="col">BM</div>
                  <div className="col">{t("label.value")}</div>
                  <div className="col">{t("label.emission_load")} (kg/hour)</div>
                </div>
                {body.parameters.map((param, idx) => (
                  <div className="row my-2" key={idx}>
                    <div className="col">
                      <select required className="form-control rounded" value={param.name} onChange={({ currentTarget: e }) => setUom(idx, e.value)}>
                        <option value="">Select parameter</option>
                        {cems
                          ?.find(({ uuid }) => uuid === body.site)
                          ?.parameters?.filter(({ is_active }) => is_active)
                          ?.map((param, idx) => (
                            <option key={idx} value={param.name}>
                              {param.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col">
                      <input className="form-control rounded" type="text" disabled required value={param.uom} />
                    </div>
                    <div className="col">
                      <input
                        className="form-control rounded"
                        type="number"
                        required
                        value={param.bm}
                        onChange={({ currentTarget: e }) => handleEditParam(idx, { bm: Number(e.value) })}
                      />
                    </div>
                    <div className="col">
                      <input
                        className="form-control rounded"
                        type="number"
                        required
                        value={param.value}
                        onChange={({ currentTarget: e }) => handleEditParam(idx, { value: Number(e.value) })}
                      />
                    </div>
                    <div className="col d-flex">
                      <input
                        className="form-control rounded"
                        type="number"
                        required
                        value={param.emission_load}
                        onChange={({ currentTarget: e }) => handleEditParam(idx, { emission_load: Number(e.value) })}
                      />
                      <Button type="button" className="text-tertiary" icon={BsTrash} onClick={() => handleDelParam(idx)} />
                    </div>
                  </div>
                ))}
                <div className="my-4 d-flex justify-content-end">
                  <Button type="button" variant="primary" onClick={handelAddParam}>
                    {t("buttons.add_new_parameter")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="mb-3 mt-4">
              <div>{t("label.testing_lab")}</div>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body.test_lab}
                onChange={({ currentTarget: e }) => setBody({ test_lab: e.value })}
              />
            </div>
            <div className="mb-3">
              <div>{t("label.accreditated_lab")}?</div>
              <select
                className="form-control rounded mw-100"
                required
                value={body.accreditated_lab ? "yes" : "no"}
                onChange={({ currentTarget: e }) => setBody({ accreditated_lab: e.value == "yes" })}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="mb-3">
              <div>KLHK-{t("label.approved_environmental_lab")}?</div>
              <select
                className="form-control rounded mw-100"
                required
                value={body.klhk_approved_envi_lab ? "yes" : "no"}
                onChange={({ currentTarget: e }) => setBody({ klhk_approved_envi_lab: e.value == "yes" })}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className={style.parameter}>{t("label.attached_documents")}</div>
            <div className="mb-3 mt-2">
              <div>{t("label.lab_certificate")}</div>
              <input ref={lab_certificate} type="file" accept="application/pdf" className="form-control rounded mw-100" required />
              <p className={style.file_input_desc}>{t("max_attachment", { size: "1 mb" })}</p>
            </div>
            <div className="mb-3">
              <div>{t("label.environmental_lab_recommendation_letter")}</div>
              <input ref={envi_lab_recomendation_letter} type="file" accept="application/pdf" className="form-control rounded mw-100" required />
              <p className={style.file_input_desc}>{t("max_attachment", { size: "1 mb" })}</p>
            </div>
            <div className="mb-3">
              <div>{t("label.sampling_photo")}</div>
              <input ref={sampling_photo} type="file" accept=".jpg, .jpeg, .png" className="form-control rounded mw-100" />
              <p className={style.file_input_desc}>{t("max_attachment", { size: "1 mb" })}</p>
            </div>
            <div className="d-flex justify-content-end my-5 mb-4">
              <Button variant="tertiary" className="mx-1" onClick={onBack} type="button" disabled={disableButton}>
                {t("buttons.cancel")}
              </Button>
              <Button variant="primary" className="mx-1" type="submit" disabled={disableButton}>
                {t("buttons.submit_report")}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  )
}

export default AddReport
