import { h, FunctionComponent } from "preact"
import { useEffect, useState, useRef } from "preact/hooks"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import useVendor from "../../../hooks/useVendor"
import useObjectState from "../../../hooks/useObjectState"
import SelectSearch from "../../../components/form/select-search"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type EditCompanyProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
  mutate: () => void
}

const EditCompany: FunctionComponent<EditCompanyProps> = ({ data, show, onHide, mutate }) => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const { data: vendors } = useVendor()
  const logoElement = useRef()
  const [disable, setDisable] = useState(false)
  const [modules, setModules] = useState([])
  const [body, setBody] = useObjectState({})

  const onVendorChange = (id) => {
    setBody({ vendor: { uuid: id }, modules: [] })
    const modules = vendors?.find(({ uuid }) => uuid === id)?.modules
    setModules(modules)
  }

  const handleCoordinate = (target, field) => {
    const value = target.value.replace(/,/g, ".")
    setBody({
      coordinate: { ...body.coordinate, [field]: value },
    })
  }

  const onModuleChange = (uuid) => {
    const modules = body?.modules
    if (modules?.includes(uuid)) {
      setBody({ modules: modules?.filter((id) => id !== uuid) })
    } else {
      setBody({ modules: [...modules, uuid] })
    }
  }

  const isModuleChecked = (uuid) => body?.modules?.includes(uuid)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)

    try {
      const { logo, ...rest } = body
      let newLogo = logo
      if (logoElement.current?.files?.length) {
        const image = new FormData()
        image.append("file", logoElement.current.files[0])
        const header = { headers: { "Content-Type": "multipart/form-data" } }
        const { data: img } = await fetcher().post("/company/file", image, header)
        newLogo = img?.data?.path
      }
      await fetcher().patch(`/company/${data?.uuid}`, { ...rest, logo: newLogo })
      await mutate()
      await onHide()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  useEffect(() => {
    const modules = vendors?.find(({ uuid }) => uuid === data?.vendor?.uuid)?.modules
    setModules(modules)

    const defaultBody = {
      name: data?.name,
      address: data?.address,
      coordinate: { lat: data?.coordinate?.lat, long: data?.coordinate?.long },
      modules: data?.modules?.map(({ uuid }) => uuid),
      vendor: { uuid: data?.vendor?.uuid },
      logo: data?.logo,
      pic_finance_name: data?.pic_finance_name,
      pic_finance_email: data?.pic_finance_email,
      pic_finance_phone: data?.pic_finance_phone,
      pic_technical_name: data?.pic_technical_name,
      pic_technical_email: data?.pic_technical_email,
      pic_technical_phone: data?.pic_technical_phone,
    }
    setBody(defaultBody)
  }, [data, vendors])

  return (
    <form
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("edit_company")}</div>
          <div className="card-body">
            <div className="mb-4">
              <label className="form-label">{t("label.vendor")}</label>
              <SelectSearch data={vendors} defaultValue={body?.vendor?.uuid} placeholder="Search vendor" required onChange={onVendorChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.name")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.name}
                onChange={({ currentTarget }) => setBody({ name: currentTarget.value })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.address")}</label>
              <textarea
                className="form-control rounded mw-100"
                onChange={({ currentTarget }) => setBody({ address: currentTarget?.value })}
                rows="3"
                required
              >
                {body?.address}
              </textarea>
            </div>
            <div className="row mb-4">
              <div className="col-6">
                <label className="form-label">{t("label.latitude")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  required
                  value={body?.coordinate?.lat}
                  onChange={({ currentTarget }) => handleCoordinate(currentTarget, "lat")}
                />
              </div>
              <div className="col-6">
                <label className="form-label">{t("label.longitude")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  required
                  value={body?.coordinate?.long}
                  onChange={({ currentTarget }) => handleCoordinate(currentTarget, "long")}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.modules")}</label>
              {modules?.length ? (
                modules?.map((module) => (
                  <div class="form-check" key={module?.uuid}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id={module?.uuid}
                      checked={isModuleChecked(module?.uuid)}
                      onChange={() => onModuleChange(module?.uuid)}
                    />
                    <label class="form-check-label" for={module?.uuid}>
                      {module?.name}
                    </label>
                  </div>
                ))
              ) : (
                <div>{t("select_vendor_first")}</div>
              )}
            </div>
            <div className="row mb-4">
              <div className="fw-bold">{t("label.finance_contact_person")}</div>
              <div className="col-4">
                <label className="form-label">{t("label.name")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  value={body?.pic_finance_name}
                  onChange={({ currentTarget: e }) => setBody({ pic_finance_name: e.value })}
                />
              </div>
              <div className="col-4">
                <label className="form-label">{t("label.email")}</label>
                <input
                  type="email"
                  className="form-control rounded mw-100"
                  required
                  value={body?.pic_finance_email}
                  onChange={({ currentTarget: e }) => setBody({ pic_finance_email: e.value })}
                />
              </div>
              <div className="col-4">
                <label className="form-label">{t("label.phone")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  required
                  value={body?.pic_finance_phone}
                  onChange={({ currentTarget: e }) => setBody({ pic_finance_phone: e.value })}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="fw-bold">{t("label.technical_contact_person")}</div>
              <div className="col-4">
                <label className="form-label">{t("label.name")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  value={body?.pic_technical_name}
                  onChange={({ currentTarget: e }) => setBody({ pic_technical_name: e.value })}
                />
              </div>
              <div className="col-4">
                <label className="form-label">{t("label.email")}</label>
                <input
                  type="email"
                  className="form-control rounded mw-100"
                  required
                  value={body?.pic_technical_email}
                  onChange={({ currentTarget: e }) => setBody({ pic_technical_email: e.value })}
                />
              </div>
              <div className="col-4">
                <label className="form-label">{t("label.phone")}</label>
                <input
                  type="text"
                  className="form-control rounded mw-100"
                  required
                  value={body?.pic_technical_phone}
                  onChange={({ currentTarget: e }) => setBody({ pic_technical_phone: e.value })}
                />
              </div>
            </div>
            <div>
              <label className="form-label mb-0">
                Logo
                {body?.logo && (
                  <Button
                    variant="primary"
                    type="button"
                    className="m-1 ms-4 btn-sm"
                    disabled={!body?.logo}
                    onClick={() => {
                      window.open(body?.logo)
                    }}
                  >
                    View File
                  </Button>
                )}
              </label>
              <input ref={logoElement} className="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" />
              <p className={style.file_input_desc}>{t("page.settings.ignore_input_file")}</p>
              <p className={style.file_input_desc}>{t("page.settings.required_aspec_ratio_image_file")}</p>
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onHide} type="button" variant="tertiary" className="m-1" disabled={disable}>
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1" disabled={disable}>
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditCompany
