import { h, FunctionComponent } from "preact"
import { useState, useRef, useEffect } from "preact/hooks"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
import { useModule } from "../../../hooks/useModule"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type EditPlatformProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
  mutate: () => void
}

const EditPlatform: FunctionComponent<EditPlatformProps> = ({ data, show, onHide, mutate }) => {
  const { t } = useTranslation()
  const { data: modules } = useModule()
  const { fetcher } = useAuth()
  const logoElement = useRef()
  const [disable, setDisable] = useState(false)
  const [body, setBody] = useObjectState()

  const onModuleChange = (uuid) => {
    const modules = body?.modules
    if (modules?.includes(uuid)) {
      setBody({ modules: modules?.filter((id) => id !== uuid) })
    } else {
      setBody({ modules: [...modules, uuid] })
    }
  }

  const isModuleChecked = (uuid) => body?.modules?.includes(uuid)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    try {
      const { logo, uuid, ...rest } = body
      let newLogo = logo
      if (logoElement.current?.files?.length) {
        const image = new FormData()
        image.append("file", logoElement.current.files[0])
        const header = { headers: { "Content-Type": "multipart/form-data" } }
        const { data: img } = await fetcher().post("/platform/file", image, header)
        newLogo = img?.data?.path
      }

      await fetcher().patch(`/platform/${uuid}`, { ...rest, logo: newLogo })
      await mutate()
      await onHide()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  useEffect(() => {
    const { modules, ...restBody } = data
    const newModules = modules?.map(({ uuid }) => uuid)
    setBody({ ...restBody, modules: newModules })
  }, [data])

  return (
    <form
      className="modal-backdrop"
      style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("edit_platform")}</div>
          <div className="card-body">
            <div className="mb-4">
              <label className="form-label">{t("label.name")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.name}
                onChange={({ currentTarget }) => setBody({ name: currentTarget?.value })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.platform_code")}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.code}
                onChange={({ currentTarget }) => setBody({ code: currentTarget?.value?.toUpperCase() })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">{t("label.modules")}</label>
              {modules?.map((module) => (
                <div class="form-check" key={module?.uuid}>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id={module?.uuid}
                    checked={isModuleChecked(module?.uuid)}
                    onChange={() => onModuleChange(module?.uuid)}
                  />
                  <label class="form-check-label" for={module?.uuid}>
                    {module?.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="mb-4">
              <label className="form-label">Host URL</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.host}
                onChange={({ currentTarget }) => setBody({ host: currentTarget?.value })}
              />
            </div>
            <div className="mb-4">
              <label className="form-label mb-0">
                Logo
                {body?.logo && (
                  <Button
                    variant="primary"
                    type="button"
                    className="m-1 ms-4 btn-sm"
                    disabled={!body?.logo}
                    onClick={() => {
                      window.open(body?.logo)
                    }}
                  >
                    {t("buttons.view_file")}
                  </Button>
                )}
              </label>
              <input ref={logoElement} className="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" />
              <p className={style.file_input_desc}>{t("page.settings.ignore_input_file")}</p>
            </div>
            <div className="mb-4 d-flex flex-column">
              <label className="form-label" for="bg_color">
                {t("label.menu_background_color")}
              </label>
              <input
                id="bg_color"
                type="color"
                className={style.color_picker}
                onChange={({ target }) => setBody({ menu_background_color: target.value })}
                value={body?.menu_background_color || "#c6c6c6"}
              />
              <input
                type="text"
                className="mt-2 form-control rounded mw-100"
                placeholder={t("placeholder.hex_color_code")}
                value={body?.menu_background_color || ""}
                onChange={({ target }) => setBody({ menu_background_color: `#${target.value.replaceAll("#", "")}` })}
              />
            </div>
            <div className="mb-4 d-flex flex-column">
              <label className="form-label" for="accent_color">
                {t("label.accent_font_color")}
              </label>
              <input
                id="accent_color"
                type="color"
                className={style.color_picker}
                onChange={({ target }) => setBody({ accent_font_color: target.value })}
                value={body?.accent_font_color || "#c6c6c6"}
              />
              <input
                type="text"
                className="mt-2 form-control rounded mw-100"
                placeholder={t("placeholder.hex_color_code")}
                value={body?.accent_font_color || ""}
                onChange={({ target }) => setBody({ accent_font_color: `#${target.value.replaceAll("#", "")}` })}
              />
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onHide} type="button" variant="tertiary" className="m-1" disabled={disable}>
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1" disabled={disable}>
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditPlatform
