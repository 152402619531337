import { FunctionComponent, h } from "preact"

type DeviceIconProps = h.JSX.HTMLAttributes<HTMLDivElement>

export const SuccessDeviceIcon: FunctionComponent<DeviceIconProps> = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_354_24292)">
        <path
          d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H38C39.1046 0.5 40 1.39543 40 2.5V38.5C40 39.6046 39.1046 40.5 38 40.5H2C0.895431 40.5 0 39.6046 0 38.5V2.5Z"
          fill="#33A02C" />
        <path
          d="M19.337 23.4323C19.337 23.4329 19.3372 23.4334 19.3376 23.4338L20.2761 24.3723C21.0572 25.1533 22.3235 25.1533 23.1045 24.3723L34.622 12.8548C35.2728 12.2041 36.3279 12.2041 36.9787 12.8548C37.6294 13.5056 37.6294 14.5607 36.9787 15.2115L23.1045 29.0856C22.3235 29.8667 21.0572 29.8667 20.2761 29.0856L12.262 21.0715C11.6112 20.4207 11.6112 19.3656 12.262 18.7148C12.9128 18.0641 13.9679 18.0641 14.6187 18.7148L16.982 21.0782L19.3364 23.4309C19.3368 23.4313 19.337 23.4318 19.337 23.4323ZM20.5152 19.8947C19.8663 19.2458 19.8663 18.1938 20.5151 17.5448L26.4187 11.6401C27.0676 10.991 28.1198 10.991 28.7688 11.64C29.4177 12.2889 29.4177 13.3409 28.7689 13.9898L22.8653 19.8946C22.2164 20.5437 21.1642 20.5437 20.5152 19.8947ZM13.4512 26.9673C14.1015 27.6177 14.1015 28.672 13.4512 29.3223C12.8008 29.9727 11.7465 29.9727 11.0962 29.3223L2.84533 21.0715C2.19455 20.4207 2.19504 19.3651 2.84581 18.7144C3.49605 18.0641 4.55078 18.0636 5.20102 18.7139L6.37783 19.8907C6.37829 19.8911 6.37829 19.8919 6.37783 19.8923C6.37737 19.8928 6.37737 19.8935 6.37783 19.894L13.4512 26.9673Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_354_24292">
          <path
            d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H38C39.1046 0.5 40 1.39543 40 2.5V38.5C40 39.6046 39.1046 40.5 38 40.5H2C0.895431 40.5 0 39.6046 0 38.5V2.5Z"
            fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const WarningDeviceIcon: FunctionComponent<DeviceIconProps> = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_354_24352)">
        <path
          d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H38C39.1046 0.5 40 1.39543 40 2.5V38.5C40 39.6046 39.1046 40.5 38 40.5H2C0.895431 40.5 0 39.6046 0 38.5V2.5Z"
          fill="#FDB13E" />
        <path
          d="M19.9997 37.1663C10.7947 37.1663 3.33301 29.7047 3.33301 20.4997C3.33301 11.2947 10.7947 3.83301 19.9997 3.83301C29.2047 3.83301 36.6663 11.2947 36.6663 20.4997C36.6663 29.7047 29.2047 37.1663 19.9997 37.1663ZM19.9997 33.833C23.5359 33.833 26.9273 32.4282 29.4278 29.9278C31.9282 27.4273 33.333 24.0359 33.333 20.4997C33.333 16.9635 31.9282 13.5721 29.4278 11.0716C26.9273 8.5711 23.5359 7.16634 19.9997 7.16634C16.4635 7.16634 13.0721 8.5711 10.5716 11.0716C8.0711 13.5721 6.66634 16.9635 6.66634 20.4997C6.66634 24.0359 8.0711 27.4273 10.5716 29.9278C13.0721 32.4282 16.4635 33.833 19.9997 33.833ZM18.333 25.4997H21.6663V28.833H18.333V25.4997ZM18.333 12.1663H21.6663V22.1663H18.333V12.1663Z"
          fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_354_24352">
          <path
            d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H38C39.1046 0.5 40 1.39543 40 2.5V38.5C40 39.6046 39.1046 40.5 38 40.5H2C0.895431 40.5 0 39.6046 0 38.5V2.5Z"
            fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ErrorDeviceIcon: FunctionComponent<DeviceIconProps> = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_354_24322)">
        <path
          d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H38C39.1046 0.5 40 1.39543 40 2.5V38.5C40 39.6046 39.1046 40.5 38 40.5H2C0.895431 40.5 0 39.6046 0 38.5V2.5Z"
          fill="#FC1818" />
        <path
          d="M20.0002 18.1436L28.2502 9.89355L30.6069 12.2502L22.3569 20.5002L30.6069 28.7502L28.2502 31.1069L20.0002 22.8569L11.7502 31.1069L9.39355 28.7502L17.6436 20.5002L9.39355 12.2502L11.7502 9.89355L20.0002 18.1436Z"
          fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_354_24322">
          <path
            d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H38C39.1046 0.5 40 1.39543 40 2.5V38.5C40 39.6046 39.1046 40.5 38 40.5H2C0.895431 40.5 0 39.6046 0 38.5V2.5Z"
            fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ChargeDeviceIcon: FunctionComponent<DeviceIconProps> = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_355_24409)">
        <path
          d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H40V40.5H2C0.895431 40.5 0 39.6046 0 38.5V2.5Z"
          fill="#A6CEE3" />
        <path
          d="M21.667 30.4997V33.833H31.667V37.1663H21.667C20.7829 37.1663 19.9351 36.8152 19.31 36.19C18.6848 35.5649 18.3337 34.7171 18.3337 33.833V30.4997H13.3337C11.5655 30.4997 9.86986 29.7973 8.61961 28.5471C7.36937 27.2968 6.66699 25.6011 6.66699 23.833V12.1663C6.66699 11.7243 6.84259 11.3004 7.15515 10.9878C7.46771 10.6753 7.89163 10.4997 8.33366 10.4997H13.3337V3.83301H16.667V10.4997H23.3337V3.83301H26.667V10.4997H31.667C32.109 10.4997 32.5329 10.6753 32.8455 10.9878C33.1581 11.3004 33.3337 11.7243 33.3337 12.1663V23.833C33.3337 25.6011 32.6313 27.2968 31.381 28.5471C30.1308 29.7973 28.4351 30.4997 26.667 30.4997H21.667ZM13.3337 27.1663H26.667C27.551 27.1663 28.3989 26.8152 29.024 26.19C29.6491 25.5649 30.0003 24.7171 30.0003 23.833V18.833H10.0003V23.833C10.0003 24.7171 10.3515 25.5649 10.9766 26.19C11.6018 26.8152 12.4496 27.1663 13.3337 27.1663ZM30.0003 13.833H10.0003V15.4997H30.0003V13.833ZM20.0003 24.6663C19.5583 24.6663 19.1344 24.4907 18.8218 24.1782C18.5093 23.8656 18.3337 23.4417 18.3337 22.9997C18.3337 22.5576 18.5093 22.1337 18.8218 21.8212C19.1344 21.5086 19.5583 21.333 20.0003 21.333C20.4424 21.333 20.8663 21.5086 21.1788 21.8212C21.4914 22.1337 21.667 22.5576 21.667 22.9997C21.667 23.4417 21.4914 23.8656 21.1788 24.1782C20.8663 24.4907 20.4424 24.6663 20.0003 24.6663Z"
          fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_355_24409">
          <path d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H40V40.5H2C0.895431 40.5 0 39.6046 0 38.5V2.5Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
