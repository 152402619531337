import useSWR from "swr"
import useAuth from "./useAuth"

const useVendor = () => {
  const { fetcher } = useAuth()
  const url = "/vendor"
  const output = useSWR(url, (url) =>
    fetcher()
      .get(url)
      .then(({ data }) => {
        if (data.statusCode == 200) return data.data
      })
  )
  return output
}

export default useVendor
