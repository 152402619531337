import { FunctionComponent, h } from "preact"
import { Oval } from "react-loader-spinner"
import style from "./style.scss"

type SpinnerProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
}

const Spinner: FunctionComponent<SpinnerProps> = ({ show = true }) => {
  return (
  <>
    {show && (
      <div className={style.wrapper}>
        <div className={style.content}>
          <Oval
            height={50}
            width={50}
            color="#51bcda"
            wrapperClass={style.spinner}
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#85d0e5"
            strokeWidth={4}
            strokeWidthSecondary={4} />
        </div>
      </div>
    )}
  </>
  )
}

export default Spinner
