import { ApiResponse } from "../types/api-response"
import useAuth from "./useAuth"
import useSWR from "swr"

export type HelpResponse = {
  uuid: string
  email: string
  name: string
  subject: string
  message: string
  created_at: string
  updated_at: string
  deleted_at?: string
}[]

export const useHelp = () => {
  const { fetcher } = useAuth()
  const url = `/support-contact`
  const output = useSWR(url, (url) => {
    return fetcher()
      .get<ApiResponse<HelpResponse>>(url)
      .then(({ data }) => {
        return data.data
      })
  })
  return output
}
export const useHelpById = (id: string) => {
  const { fetcher } = useAuth()
  const url = `/support-contact/${id}`
  const output = useSWR(url, (url) => {
    return fetcher()
      .get<ApiResponse<HelpResponse>>(url)
      .then(({ data }) => {
        return data.data
      })
  })
  return output
}
