import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { BsPencil, BsTrash, BsEye } from "react-icons/bs"
import { usePlatform } from "../../../hooks/usePlatform"
import useAuth from "../../../hooks/useAuth"
import usePagination from "../../../hooks/usePagination"
import useModal from "../../../hooks/useModal"
import { useConfirm } from "../../../context/useConfirm"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import Pagination from "../../../components/pagination"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import Spinner from "../../../components/spinner"
import SearchBox from "../../../components/TopFilter/search-box"
import AddCompany from "./add"
import EditCompany from "./edit"
import CompanyDetail from "./detail"
import { useTranslation } from "react-i18next"

type SettingsPlatformProps = h.JSX.HTMLAttributes<HTMLDivElement>

const SettingsPlatform: FunctionComponent<SettingsPlatformProps> = () => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const confirmation = useConfirm()

  const { data, mutate } = usePlatform()
  const { show: showAdd, onHide: hideAdd, onShow: onShowAdd } = useModal()
  const { show: showEdit, onHide: hideEdit, onShow: onShowEdit } = useModal()
  const { show: showDetail, onHide: hideDetail, onShow: onShowDetail } = useModal()
  const [modalData, setModalData] = useState({})
  const [filteredData, setFilteredData] = useState(data)
  const [search, setSearch] = useState("")

  const showActionColumn = canOneOf([Permission.SettingsPlatformDelete, Permission.SettingsPlatformDetail, Permission.SettingsPlatformEdit])

  filteredData?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))

  const pages = usePagination({ count: filteredData?.length || 0, step: 20 })
  const dataTable = filteredData?.slice(pages.output, pages.output + pages.step)

  const handleEdit = (data) => {
    setModalData(data)
    onShowEdit()
  }
  const handleDetail = (data) => {
    setModalData(data)
    onShowDetail()
  }
  const handleDelete = (id: string, name: string) => {
    confirmation(
      t("delete_platform_confirm_msg", { module_name: name }),
      (out) => {
        if (out)
          fetcher()
            .delete(`/platform/${id}`)
            .then(() => mutate())
      },
      { yes: t("buttons.delete"), no: t("buttons.cancel") }
    )
  }
  const clearModalData = () => setModalData({})

  useEffect(() => {
    let searchedData
    if (search) {
      searchedData = data?.filter(({ name }) => {
        return name?.toLowerCase()?.includes(search) || !search
      })
    } else {
      searchedData = data
    }
    setFilteredData(searchedData)
  }, [data, search])

  return (
    <div>
      <AddCompany show={showAdd} onHide={hideAdd} mutate={mutate} />
      <EditCompany
        data={modalData}
        show={showEdit}
        onHide={() => {
          hideEdit()
          clearModalData()
        }}
        mutate={mutate}
      />
      <CompanyDetail data={modalData} show={showDetail} onHide={hideDetail} />
      <Spinner show={!Array.isArray(data)} />
      <TopBar>
        <Breadcrumb name={t("breadcrumb.settings")} link="" />
        <Breadcrumb name={t("breadcrumb.platform")} link="/settings/platform" />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title pt-4">
            <SearchBox placeholder={t("placeholder.search_platform")} onChange={(value) => setSearch(value?.toLowerCase())} />
            {can(Permission.SettingsPlatformAdd) && (
              <Button variant="primary" onClick={onShowAdd}>
                {t("buttons.add_new_module")}
              </Button>
            )}
          </div>
          <div className="card-body">
            <table className="table table-center table-data">
              <thead>
                <tr>
                  <th>No</th>
                  <th>{t("table.name")}</th>
                  <th>{t("table.code")}</th>
                  <th>{t("table.modules")}</th>
                  {showActionColumn && <th>{t("table.action")}</th>}
                </tr>
              </thead>
              <tbody>
                {dataTable?.length ? (
                  dataTable?.map((platform, index) => (
                    <tr key={platform?.uuid}>
                      <td>{index + 1 + pages.page * pages.step}</td>
                      <td>{platform?.name}</td>
                      <td>{platform?.code}</td>
                      <td>{platform?.modules?.map(({ name }) => name)?.join(", ")}</td>
                      {showActionColumn && (
                        <td>
                          {can(Permission.SettingsPlatformDetail) && (
                            <Button variant="primary" title="Detail" onClick={() => handleDetail(platform)} className="m-1" icon={BsEye} />
                          )}
                          {can(Permission.SettingsPlatformEdit) && (
                            <Button variant="secondary" title="Edit" onClick={() => handleEdit(platform)} className="m-1" icon={BsPencil} />
                          )}
                          {can(Permission.SettingsPlatformDelete) && (
                            <Button
                              variant="tertiary"
                              title="Delete"
                              onClick={() => handleDelete(platform?.uuid, platform?.name)}
                              className="m-1"
                              icon={BsTrash}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={showActionColumn ? 5 : 4}>No data.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <Pagination hook={pages} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default SettingsPlatform
