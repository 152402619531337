import { FunctionComponent, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useCompanyById } from "../../hooks/useCompany"
import useSharedState, { loggedinUser } from "../../state"
import { setLocalStorage, getLocalStorage, logoPathStorage } from "../../utils/local-storage"
import templates from "../../utils/template-configs"
import style from "./style.scss"
import usePlatformApp from "../../hooks/usePlatformApp"

type BrandProps = {
  logo: string
  alt_logo: string
  powered?: string
  name?: string
  tagline?: string
}

const Brand: FunctionComponent<BrandProps> = ({ powered, logo, alt_logo, name, tagline }) => {
  const { platform } = usePlatformApp()

  const [company, setCompany] = useState()
  const localLogoPath = getLocalStorage(logoPathStorage)
  const [path, setPath] = useState(localLogoPath)
  const [userData] = useSharedState(loggedinUser, undefined)
  const milUuid = userData?.mil?.uuid

  const { data: mil } = useCompanyById(milUuid)

  useEffect(() => {
    if (localLogoPath) setPath(localLogoPath)
  }, [localLogoPath])

  useEffect(() => {
    if (mil) {
      const { logo, name } = mil
      setLocalStorage(logoPathStorage, logo)
      setPath(logo)
      setCompany(name)
    }
  }, [mil])

  return (
    <>
      {path ? (
        <div className="d-flex flex-column align-items-center">
          <img className={style.logo} src={path} alt={company} />
          <p className={style.powered_by}>{powered}</p>
        </div>
      ) : (
        <div className={style.wrapper}>
          <img className={style.logo} src={logo} alt={alt_logo} />
          <div>
            <p className={style.title}>{name}</p>
            <p className={style.tagline}>{tagline}</p>
          </div>
        </div>
      )}
    </>
  )
}

export default Brand
