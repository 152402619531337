import { FunctionComponent, h } from "preact"
import { route } from "preact-router"
import AuthTemplate from "./auth-template"
import { AxiosApiError } from "../../types/api-response"
import { Button } from "../../parts/buttons"
import useAxios from "../../hooks/useAxios"
import useObjectState from "../../hooks/useObjectState"
import { useState } from "preact/hooks"
import { getLocalStorage, landingPage } from "../../utils/local-storage"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type ChangePasswordProps = h.JSX.HTMLAttributes<HTMLDivElement>

const ChangePassword: FunctionComponent<ChangePasswordProps> = () => {
  const { t } = useTranslation()
  const authPage = getLocalStorage(landingPage) || "/login"
  const axios = useAxios()
  const [email, setEmail] = useState("")
  const [load, setLoad] = useState(false)
  const [status, setStatus] = useObjectState({
    type: "" as "primary" | "tertiary" | "",
    message: "",
  })
  const handleCancel = (): void => {
    setEmail("")
    setStatus({ type: "", message: "" })
    route(authPage)
  }
  const handleSubmit = (): void => {
    setLoad(true)
    const url = "/auth/forgot-password"
    axios
      .post(url, {}, { params: { email } })
      .then(() => {
        setStatus({ type: "primary", message: t("page.change_password.link_sent") })
      })
      .catch((e: AxiosApiError) => {
        const message = e.response?.data.data.message?.toString() || "Something error!"
        setStatus({ type: "tertiary", message })
      })
      .finally(() => {
        setLoad(false)
      })
  }

  return (
    <AuthTemplate>
      <div className={style.login_card}>
        <div className={style.login_title}>
          <div>{t("page.change_password.title")}</div>
          <div className={style.desc}>{t("page.change_password.description")}</div>
        </div>
        {status.type && <div className={`alert alert-${status.type}`}>{status.message}</div>}
        <label className="form-label">Email</label>
        <input
          type="text"
          className="form-control"
          value={email}
          onChange={({ currentTarget }): void => {
            setEmail(currentTarget.value.trim())
          }}
        />
        <Button variant="primary" className="w-100 mt-2" onClick={handleSubmit} disabled={load}>
          {t("page.change_password.send_link")}
        </Button>
        <Button variant="tertiary" className="w-100 mt-2" onClick={handleCancel}>
          {t("buttons.cancel")}
        </Button>
      </div>
    </AuthTemplate>
  )
}

export default ChangePassword
