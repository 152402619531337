import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs"
import { FunctionComponent, h } from "preact"
import { AxiosApiError } from "../../types/api-response"
import { route } from "preact-router"
import useAuth from "../../hooks/useAuth"
import { useState, useEffect } from "preact/hooks"
import { getLocalStorage, landingPage } from "../../utils/local-storage"
import { useTranslation } from "react-i18next"
import style from "./style.scss"

type LoginFormProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  buttonClass: any
  forgotClass: any
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ buttonClass, forgotClass }) => {
  const { t } = useTranslation()
  const [error, setError] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const { login } = useAuth()
  const onSubmit = (event): void => {
    event.preventDefault()
    login(username, password)
      .then(() => route("/dashboard/map"))
      .catch((e: AxiosApiError) => {
        const message = e.response?.data.data.message
        if (Array.isArray(message)) return setError(message.join())
        if (message) return setError(message)
      })
  }

  useEffect(() => {
    const token = localStorage.getItem("x-auth-token")
    const authPage = getLocalStorage(landingPage) || "/login"
    token ? route("/dashboard/map") : route(authPage)
  }, [])

  return (
    <div className={style.login_card}>
      {error && <div className="alert alert-tertiary">{error}</div>}
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label className="form-label">{t("label.email")}</label>
          <input
            type="text"
            className="form-control"
            value={username}
            onChange={({ currentTarget }): void => {
              setUsername(currentTarget.value.trim())
            }}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">{t("label.password")}</label>
          <div className={style.password_wrapper}>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              value={password}
              onChange={({ currentTarget }): void => {
                setPassword(currentTarget.value.trim())
              }}
            />
            <span className={style.eye_icon} role="button" onClick={() => setShowPassword((state) => !state)}>
              {showPassword ? <BsEyeSlashFill size="1.3rem" /> : <BsEyeFill size="1.3rem" />}
            </span>
          </div>
        </div>
        <a href="/change-password" className={forgotClass}>
          {t("page.login.forgot_password")}
        </a>
        <button type="submit" className={buttonClass}>
          {t("buttons.login")}
        </button>
      </form>
    </div>
  )
}

export default LoginForm
