import { useState } from "preact/hooks"
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa"

export type SortHeaderData = {
  initialFieldToSort: { [field: string]: "asc" | "desc" | null }
}

const useSortHeader = ({ initialFieldToSort }: SortHeaderData) => {
  const [sort, setSort] = useState<{ [field: string]: "asc" | "desc" | null }>(initialFieldToSort)

  const resetSorting = () => {
    let tempOrder = {}
    Object.entries(sort).map(([key, value]) => {
      tempOrder = { ...tempOrder, [key]: null }
    })
    setSort(tempOrder)
  }

  const sortIcon = (field: string) => {
    if (sort[field] == null) {
      return <FaSort />
    }
    return sort[field] == "asc" ? <FaSortUp /> : <FaSortDown />
  }

  const onOrder = (field: string) => {
    let tempOrder = "asc"
    if (sort[field] == null) tempOrder = "asc"
    if (sort[field] == "asc") tempOrder = "desc"
    if (sort[field] == "desc") tempOrder = "asc"

    let sortDataNull = {} //allow sorting only 1 field.
    Object.entries(sort).map(([key, value]) => {
      if (key != field) {
        sortDataNull = { ...sortDataNull, key: null }
      }
    })

    setSort({
      ...sortDataNull,
      [field]: tempOrder,
    })

    const sortData = { ...sortDataNull, [field]: tempOrder }
    let formatSortParams = []
    Object.entries(sortData).map(([key, value], index) => {
      if (value != null) {
        formatSortParams.push({
          [`sort_by[${formatSortParams.length}]`]: key,
          [`order[${formatSortParams.length}]`]: value,
        })
      }
    })
    return formatSortParams
  }

  return { sort, resetSorting, onOrder, sortIcon, setSort }
}

export { useSortHeader }
