import { h, FunctionComponent } from "preact"
import { useState, useEffect } from "preact/hooks"
import { BsTrash } from "react-icons/bs"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
import { usePlatform } from "../../../hooks/usePlatform"
import SelectSearch from "../../../components/form/select-search"
import style from "../style.scss"
import {useTranslation} from 'react-i18next'

type EditVendorProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
  mutate: () => void
}
const defaultAccount = {
  name: "",
  description: "",
  account_name: "",
  account_number: ""
}

const EditVendor: FunctionComponent<EditVendorProps> = ({ data, show, onHide, mutate }) => {
  const {t}=useTranslation();
  const { data: platforms } = usePlatform()
  const { fetcher } = useAuth()
  const [disable, setDisable] = useState(false)
  const [body, setBody] = useObjectState({})
  const [modules, setModules] = useState(data?.modules)

  const onPlatformChange = (id) => {
    setBody({ platform: { uuid: id }, modules: [] })
    const modules = platforms?.find(({ uuid }) => uuid === id)?.modules
    setModules(modules)
  }

  const onModuleChange = (uuid) => {
    const modules = body?.modules
    if (modules?.includes(uuid)) {
      setBody({ modules: modules?.filter((id) => id !== uuid) })
    } else {
      setBody({ modules: [...modules, uuid] })
    }
  }

  const isModuleChecked = (uuid) => body?.modules?.includes(uuid)

  const addAccount = () => {
    const account = [...body?.payment_info]
    account.push(defaultAccount)
    setBody({ payment_info: account })
  }

  const editAccount = ( idx: number, data: Partial<typeof defaultAccount>) => {
    const account = [...body?.payment_info]
    account[idx] = { ...account[idx], ...data }
    setBody({ payment_info: account })
  }

  const deleteAccount = (index: number) => {
    const account = [...body?.payment_info]
    account.splice(index, 1)
    setBody({ payment_info: account })
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisable(true)
    try {
      const { uuid, ...restBody } = body
      await fetcher().patch(`/vendor/${uuid}`, restBody)
      await mutate()
      await onHide()
    } catch (error) {
      alert(error?.message)
    } finally {
      setDisable(false)
    }
  }

  useEffect(() => {
    const { modules, platform, ...rest } = data
    const newModules = modules?.map(({ uuid }) => uuid)
    const platformUuid = platform?.uuid
    const originalModule = platforms?.find(({ uuid }) => uuid === platformUuid)?.modules
    const newBody = {
      ...rest,
      modules: newModules,
      platform: { uuid: platformUuid }
    }
    setBody(newBody)
    setModules(originalModule)
  }, [data, platforms])

  return (
    <form
      className="modal-backdrop"
      style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}
      onSubmit={(event) => handleSubmit(event)}>
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t('edit_vendor')}</div>
          <div className="card-body">
            <div className="mb-4">
              <label className="form-label">{t('label.name')}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.name}
                onChange={({ currentTarget }) => setBody({ name: currentTarget?.value })} />
            </div>
            <div className="mb-4">
              <label className="form-label">{t('label.address')}</label>
              <textarea
                className="form-control rounded mw-100"
                onChange={({ currentTarget }) => setBody({ address: currentTarget?.value })}
                rows="3"
                required>
                {body?.address}
                </textarea>
            </div>
            <div className="mb-4">
              <label className="form-label">{t('label.platform')}</label>
              <SelectSearch
                data={platforms}
                defaultValue={body?.platform?.uuid}
                placeholder="Search company"
                required
                onChange={onPlatformChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">{t('label.modules')}</label>
              {modules?.length ?
                modules?.map((module) => (
                  <div class="form-check" key={module?.uuid}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id={module?.uuid}
                      checked={isModuleChecked(module?.uuid)}
                      onChange={() => onModuleChange(module?.uuid)} />
                    <label class="form-check-label" for={module?.uuid}>{module?.name}</label>
                  </div>
                )) : 
                <div>Select platform first</div>
              }
            </div>
            <div className="mb-4">
              <label className="form-label">{t('label.contact_person_name')}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.pic}
                onChange={({ currentTarget }) => setBody({ pic: currentTarget?.value })} />
            </div>
            <div className="mb-4">
              <label className="form-label">{t('label.NPWP_number')}</label>
              <input
                type="text"
                className="form-control rounded mw-100"
                required
                value={body?.npwp}
                onChange={({ currentTarget }) => setBody({ npwp: currentTarget?.value })} />
            </div>
            <div className="fw-bold">{t('label.bank_account_information')}</div>
            {body?.payment_info?.map((account, index) => (
              <div className="border border-primary rounded px-3 py-3 mb-2" key={index}>
                <div className="d-flex">
                  <div className={style.account_title}>{t('label.account',{number:index+1})}</div>
                  <div className="ms-3">
                    {body?.payment_info?.length > 1 && <Button
                      type="button"
                      variant="tertiary"
                      className="btn-sm px-2 py-1"
                      icon={BsTrash}
                      onClick={() => deleteAccount(index)}>
                      Delete
                    </Button>}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3">
                    <label className="form-label">{t('label.bank_name')}</label>
                  </div>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      value={account?.name}
                      onChange={({ currentTarget: e }) => editAccount(index, { name: e.value })} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3">
                    <label className="form-label">{t('label.branch')}</label>
                  </div>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      value={account?.description}
                      onChange={({ currentTarget: e }) => editAccount(index, { description: e.value })} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3">
                    <label className="form-label">{t('label.account_number')}</label>
                  </div>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      value={account?.account_number}
                      onChange={({ currentTarget: e }) => editAccount(index, { account_number: e.value })} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label">{t('label.account_name')}</label>
                  </div>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control rounded mw-100"
                      value={account?.account_name}
                      onChange={({ currentTarget: e }) => editAccount(index, { account_name: e.value })} />
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-end mt-1">
              <Button
                type="button"
                variant="primary"
                onClick={addAccount}>
                {t('add_new_account')}
              </Button>
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onHide} type="button" variant="tertiary" className="m-1" disabled={disable}>
              {t('buttons.cancel')}
            </Button>
            <Button variant="primary" type="submit" className="m-1" disabled={disable}>
              {t('buttons.save')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditVendor