import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import useObjectState from "../../hooks/useObjectState"
import { sortActiveParam } from "../../utils/sortParam"
import { Cems } from "../../types/cems"
import { HourlyDatePicker, DailyDatePicker, MonthlyDatePicker } from "./range-date-picker"
import { Button } from "../../parts/buttons"
import SelectSearch from "../form/select-search"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type TopFilterPeriodProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  cems: Cems
  onShow: viod
}

const TopFilterPeriod: FunctionComponent<TopFilterPeriodProps> = ({ cems, onShow }) => {
  const { t } = useTranslation()
  const [filteredSite, setFilteredSite] = useState(cems)
  const [sensors, setSensor] = useState([])
  const [filter, setFilter] = useObjectState({})
  const [disable, setDisable] = useState(true)

  const onDateChange = (date, type) => (type === "first" ? setFilter({ firstPeriod: date }) : setFilter({ secondPeriod: date }))

  const renderDatePicker = ({ period, date, type, onDateChange }) => {
    if (period === "month") return <MonthlyDatePicker date={date} setDate={(newDate) => onDateChange(newDate, type)} />
    else if (period === "day") return <DailyDatePicker date={date} setDate={(newDate) => onDateChange(newDate, type)} />
    return <HourlyDatePicker date={date} setDate={(newDate) => onDateChange(newDate, type)} />
  }

  const onSiteChange = (uuid) => {
    setFilter({ site: uuid })
    const parameter = cems?.find(({ uuid: cemsUuid }) => cemsUuid === uuid)?.parameters
    const param = sortActiveParam(parameter)?.map(({ name }) => name)
    setSensor(param)
  }

  const onShowHandler = () => onShow(filter)

  useEffect(() => {
    const cem = cems?.find(({ uuid }) => uuid === filter?.site)?.parameters
    const param = sortActiveParam(cem)?.map(({ name }) => name)
    setSensor(param)
  }, [])

  useEffect(() => setFilteredSite(cems), [cems])

  useEffect(() => {
    const { site, sensor, period, firstPeriod, secondPeriod } = filter
    site && sensor && period && !isNaN(firstPeriod?.[0]) && !isNaN(firstPeriod?.[1]) && !isNaN(secondPeriod?.[0]) && !isNaN(secondPeriod?.[1])
      ? setDisable(false)
      : setDisable(true)
  }, [filter])

  return (
    <div className={style.filter_input}>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("site")}</label>
        <SelectSearch placeholder={t("placeholder.search_site")} data={filteredSite} defaultValue={filter?.site} onChange={onSiteChange} />
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.sensor")}</label>
        <select
          className="w-100 form-select rounded border"
          onChange={(event) => setFilter({ sensor: event.currentTarget.value })}
          value={filter.sensor}
        >
          <option value="" disabled selected hidden>
            {t("label.select_sensor")}
          </option>
          {sensors?.map((sensor) => (
            <option key={sensor} value={sensor}>
              {sensor}
            </option>
          ))}
        </select>
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.interval")}</label>
        <select
          className="w-100 form-select rounded border"
          onChange={(event) => setFilter({ period: event.currentTarget.value })}
          value={filter.period}
        >
          <option value="" disabled selected hidden>
            {t("label.select_interval")}
          </option>
          <option value="raw">Raw</option>
          <option value="two-minutes">{t("two_minutes")}</option>
          <option value="hour">{t("hourly")}</option>
          <option value="day">{t("daily")}</option>
          <option value="month">{t("monthly")}</option>
        </select>
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.first_period_date")}</label>
        {renderDatePicker({ period: filter.period, date: filter.startDate, type: "first", onDateChange })}
      </div>
      <div className={style.filter_input_group}>
        <label className={style.filter_label}>{t("label.second_period_date")}</label>
        {renderDatePicker({ period: filter.period, date: filter.endDate, type: "second", onDateChange })}
      </div>
      <div className="d-flex align-items-end">
        <Button variant="primary" className="py-2 w-100" onClick={() => onShowHandler()} disabled={disable}>
          {t("buttons.show")}
        </Button>
      </div>
    </div>
  )
}

export default TopFilterPeriod
