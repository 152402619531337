import { h } from "preact"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
import { useTranslation } from "react-i18next"

type Props = {
  uuid: string
  type: number
  show: boolean
  mutate: void
  onClose: void
}

const ReportStatus = ({ uuid, type, mutate, show, onClose }: Props) => {
  const { t } = useTranslation()
  const defaultBody = {
    status: "",
    reason: "",
    type,
  }
  const { fetcher } = useAuth()
  const [body, setBody] = useObjectState(defaultBody)

  const handleSubmit = (event) => {
    event.preventDefault()
    const url = type === 3 ? `/ncr/validation/${uuid}` : `/ncr/approval/${uuid}`
    fetcher()
      .put(url, body)
      .then(() => {
        mutate()
        onClose()
      })
  }

  return (
    <form
      className="modal-backdrop"
      style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{type === 3 ? t("validation") : t("approval")}</div>
          <div className="card-body">
            <select className="form-control rounded mw-100" onChange={({ currentTarget: e }) => setBody({ status: e.value })} value={body.status}>
              <option value="">{t("label.select_status")}</option>
              <option value="Approve">Approve</option>
              <option value="Reject">Reject</option>
            </select>
            <div className="my-3">
              <textarea
                className="form-control mw-100 rounded-3"
                type="text"
                required
                rows={5}
                placeholder={t("placeholder.fill_with_reasons_of_approval")}
                value={body.reason}
                onChange={({ currentTarget }) => setBody({ reason: currentTarget.value })}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button onClick={onClose} type="button" variant="tertiary" className="m-1">
                {t("buttons.cancel")}
              </Button>
              <Button variant="primary" type="submit" className="m-1">
                {t("buttons.save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ReportStatus
