import { FunctionComponent, h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import { route } from "preact-router"
import useAuth from "../../../hooks/useAuth"
import useObjectState from "../../../hooks/useObjectState"
import { useAccess, usePermission } from "../../../hooks/useUsman"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import { Button } from "../../../parts/buttons"
import Spinner from "../../../components/spinner"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type AddRolePermissionProps = h.JSX.HTMLAttributes<HTMLDivElement>

const defaultBody = {
  name: "",
  description: "",
}

const AddRolePermission: FunctionComponent<AddRolePermissionProps> = () => {
  const { t } = useTranslation()
  const { data: access } = useAccess()
  const { data: permissionList } = usePermission()
  const { hash } = window.location
  const ref = useRef(document)
  const elementsRef = useRef([])
  const { fetcher } = useAuth()
  const [body, setBody] = useObjectState(defaultBody)
  const [permission, setPermission] = useState([])
  const [active, setActive] = useState()
  const [modules, setModules] = useState([])
  const [moduleRef, setModuleRef] = useState([])
  const [disableButton, setDisableButton] = useState(false)

  const onRoleChange = (event) => {
    const value = event.currentTarget.value
    const name = value.toUpperCase().replace(/[^0-9A-Z]+/g, "_")
    setBody({ name })
  }

  const onPermissionChange = (uuid) => {
    setPermission((prevState) => {
      const permissionChanged = prevState?.findIndex(({ permission }) => permission?.uuid === uuid)
      prevState[permissionChanged].status = !prevState?.[permissionChanged]?.status
      return prevState
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisableButton(true)
    try {
      const { data } = await fetcher().post("/role", body)
      const permissionBody = {
        role: { uuid: data?.data?.uuid },
        role_permissions: permission,
      }
      await fetcher().post("/permission/assign-permission", permissionBody)
      setDisableButton(false)
      route("/access/role")
    } catch (error) {
      setDisableButton(false)
    }
  }

  useEffect(() => {
    const permissionContent = permissionList?.map(({ uuid }) => ({ status: false, permission: { uuid } }))
    setPermission(permissionContent)
  }, [permissionList])

  useEffect(() => {
    const newModule = access?.map(({ name, uuid: id }) => ({ name, id }))
    const newModuleRef = access?.map(({ uuid }) => uuid)
    elementsRef.current = elementsRef?.current?.slice(0, newModuleRef?.length)
    setModules(newModule)
    setModuleRef(newModuleRef)
    setActive(`#${newModule?.[0]?.id}`)
  }, [access])

  useEffect(() => {
    setActive(hash)
  }, [hash])

  useEffect(() => {
    const handleScroll = () => {
      const sections = elementsRef.current
      let index = sections.length

      // Decrements the index value each iteration.
      while (--index >= 0 && window.scrollY + 50 < sections[index].offsetTop) {}

      if (index >= 0 && window.scrollY - sections[index].offsetHeight < sections[index].offsetTop) {
        setActive(`#${moduleRef?.[index]}`)
      }
    }
    const element = ref.current

    element.addEventListener("scroll", handleScroll)

    return () => {
      element.removeEventListener("scroll", handleScroll)
    }
  }, [elementsRef, moduleRef])

  return (
    <div className="min-vh-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.access")} link="" />
        <Breadcrumb name={t("breadcrumb.role")} link="/access/role" />
        <Breadcrumb name={t("breadcrumb.add_role_permission")} link="/access/role/add" />
      </TopBar>
      <Spinner show={!Array.isArray(access)} />
      <Container>
        <div className="row g-3">
          <form className="col-lg-9 col-12" onSubmit={(event) => handleSubmit(event)}>
            <div className="card">
              <div className="card-title pt-4">
                <p className={style.page_title}>{t("Add Role Permission")}</p>
                <div className="d-flex justify-content-end w-100">
                  <Button variant="primary" type="submit" disabled={disableButton}>
                    {t("buttons.save")}
                  </Button>
                </div>
              </div>
              <div className="card-body">
                <div class="mb-4">
                  <label class="form-label">{t("label.role_name")}</label>
                  <input type="text" class="form-control rounded mw-100" required value={body.name} onChange={onRoleChange} />
                  <small className={style.file_input_desc}>
                    {t("Role Name should be in uppercase and sparated with underscore")}
                    <br />
                    (e.g: ROLE_NAME)
                  </small>
                </div>
                <div class="mb-4">
                  <label class="form-label">{t("label.description")}</label>
                  <input
                    type="text"
                    class="form-control rounded mw-100"
                    required
                    value={body.description}
                    onChange={({ currentTarget }) => setBody({ description: currentTarget.value })}
                  />
                </div>
                <div className={style.module_title}>{t("Features")}</div>
                {access?.map((module, midx) => (
                  <div className={style.module} key={module?.uuid} ref={(element) => (elementsRef.current[midx] = element)} id={module?.uuid}>
                    <div className={style.title}>{t(`${module?.name}`)}</div>
                    <div className={style.desc}>{t(`${module?.name}`)} List</div>
                    <ul>
                      {module?.menu_lists?.map((sub) => (
                        <li key={sub?.uuid}>
                          <div className={style.submenu}>
                            <p>{t(`${sub?.menu_name}`)}</p>
                            <div className={style.permission}>
                              {sub?.permissions?.map((access) => (
                                <div key={access?.key} class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id={access?.key}
                                    onChange={() => onPermissionChange(access?.uuid)}
                                  />
                                  <label htmlFor={access?.key}>{t(`${access?.name}`)}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
                <div className="d-flex justify-content-end w-100 mt-4">
                  <Button variant="primary" type="submit" disabled={disableButton}>
                    {t("buttons.save")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
          <div className="col-lg-3 col-12">
            <div className="card position-sticky top-0">
              <div className="card-body">
                <p className={style.page_title}>{t("Feature List")}</p>
                <ul className={style.section_of_page}>
                  {modules?.map((link, index) => (
                    <li key={index}>
                      <a href={`#${link?.id}`} className={active === `#${link?.id}` ? style.active : ""}>
                        {t(`${link?.name}`)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AddRolePermission
