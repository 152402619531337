import { h } from "preact"
import { useState } from "preact/hooks"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import { useFeature, useMenu } from "../../../hooks/useUsman"
import useObjectState from "../../../hooks/useObjectState"
import style from "../style.scss"
import { useTranslation } from "react-i18next"

type Props = {
  data: any
  mutate: void
  onClose: void
}

const EditPermission = ({ data, mutate, onClose }: Props) => {
  const { t } = useTranslation()
  const defaultBody = {
    name: data?.data?.name,
    key: data?.data?.key,
    menu_list: { uuid: data?.data?.menu_list?.uuid },
  }
  const { data: features } = useFeature()
  const { data: menus } = useMenu()
  const [AvailableMenu, setAvailableMenu] = useState(menus)
  const [selectedModule, setSelectedModule] = useState(data?.data?.menu_list?.list_feature?.uuid)
  const { fetcher } = useAuth()
  const [body, setBody] = useObjectState(defaultBody)

  const onModuleChange = (event) => {
    const moduleUuid = event.currentTarget.value
    setSelectedModule(moduleUuid)
    setBody({ menu_list: { uuid: "" } })
    const filteredMenu = menus?.filter(({ list_feature }) => list_feature?.uuid === moduleUuid)
    setAvailableMenu(filteredMenu)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetcher()
      .patch(`/permission/${data?.data?.uuid}`, body)
      .then(() => {
        mutate()
        onClose()
      })
  }

  return (
    <form
      className="modal-backdrop"
      style={{
        display: data?.show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
      onSubmit={(event) => handleSubmit(event)}
    >
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content card px-3 py-3">
          <div className="card-body text-center h5">{t("Edit Permission")}</div>
          <div className="card-body">
            <div class="mb-4">
              <label class="form-label">{t("label.feature")}</label>
              <select class="form-control rounded mw-100" required value={selectedModule} onChange={onModuleChange}>
                <option value="">{t("label.select_feature")}</option>
                {features?.map(({ uuid, name }) => (
                  <option key={uuid} value={uuid}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.menu")}</label>
              <select
                class="form-control rounded mw-100"
                required
                value={body.menu_list.uuid}
                onChange={({ currentTarget: e }) => setBody({ menu_list: { uuid: e.value } })}
              >
                <option value="">{t("label.select_menu")}</option>
                {AvailableMenu?.map(({ uuid, menu_name }) => (
                  <option key={uuid} value={uuid}>
                    {menu_name}
                  </option>
                ))}
              </select>
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.permission_name")}</label>
              <input
                type="text"
                class="form-control rounded mw-100"
                required
                value={body.name}
                onChange={({ currentTarget }) => setBody({ name: currentTarget.value })}
              />
            </div>
            <div class="mb-4">
              <label class="form-label">{t("label.permission_key")}</label>
              <input
                type="text"
                class="form-control rounded mw-100"
                readOnly
                value={body.key}
                onChange={({ currentTarget }) => setBody({ key: currentTarget.value })}
              />
              <small className={style.file_input_desc}>
                {t("Key should be in Pascalcase and separated with hyphen")}
                <br />
                (e.g: TechnicalData-SiteData-View)
              </small>
            </div>
          </div>
          <div className="card-body d-flex justify-content-end">
            <Button onClick={onClose} type="button" variant="tertiary" className="m-1">
              {t("buttons.cancel")}
            </Button>
            <Button variant="primary" type="submit" className="m-1">
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditPermission
