import { FunctionComponent, h, Fragment } from "preact"
import { BsTrash } from "react-icons/bs"
import { Button } from "../../../parts/buttons"
import { useTranslation } from "react-i18next"
type PayloadFormProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  showDelete: boolean
  showStatus: boolean
  onDataChange: () => void
  onDelete?: () => void
}

const PayloadForm: FunctionComponent<PayloadFormProps> = ({ data, showDelete = false, onDataChange, onDelete }) => {
  const { t } = useTranslation()
  return (
    <div className="row mb-4">
      <div className="col-3">
        <input
          type="text"
          required
          value={data?.name}
          onChange={({ currentTarget: e }) => onDataChange({ name: e.value })}
          className="form-control rounded mw-100"
        />
      </div>
      <div className="col-3">
        <select
          required
          value={data?.type}
          onChange={({ currentTarget: e }) => onDataChange({ type: e.value })}
          className="form-control rounded mw-100"
        >
          <option value="">{t("label.select_type")}</option>
          {/* <option value="String">String</option> */}
          <option value="Float">Float</option>
          <option value="Integer">Integer</option>
          {/* <option value="Number">Number</option> */}
          {/* <option value="Boolean">Boolean</option> */}
        </select>
      </div>
      <div className="col-2">
        <input
          type="text"
          required
          value={data?.min}
          onChange={({ currentTarget: e }) => onDataChange({ min: e.value })}
          className="form-control rounded mw-100"
        />
      </div>
      <div className="col-2">
        <input
          type="text"
          required
          value={data?.max}
          onChange={({ currentTarget: e }) => onDataChange({ max: e.value })}
          className="form-control rounded mw-100"
        />
      </div>
      <div className="col-2">
        {showDelete ? (
          <Button type="button" variant="tertiary" className="btn-sm px-1 ms-1" icon={BsTrash} onClick={onDelete} />
        ) : (
          <Fragment>
            <input className="form-check-input" type="checkbox" onChange={() => onDataChange({ status: !data?.status })} checked={data?.status} />
            <label className="form-check-label ms-2">{data?.status ? t("label.active") : t("label.inactive")}</label>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default PayloadForm
