import "./index.scss"

import { FunctionComponent, h } from "preact"
import { IconType } from "react-icons"

type ButtonProps = {
  className?: string
  variant?: "primary" | "secondary" | "tertiary" | "lines"
  icon?: IconType
  disabled?: boolean
  onClick?: () => void
  type?: string
}

export const Button: FunctionComponent<ButtonProps> = ({
  variant,
  children,
  className,
  icon,
  ...props
}) => {
  const Icon = icon
  if (children)
    return (
      <button
        className={`btn rounded shadow-md btn-${variant} ${className}`}
        {...props}
      >
        {Icon && (
          <span className="pe-2">
            <Icon />
          </span>
        )}
        <span>{children}</span>
      </button>
    )
  return (
    <button
      className={`btn p-0 shadow-md btn-${variant} ${className}`}
      {...props}
    >
      <span className="d-flex align-item-center p-2">{Icon && <Icon />}</span>
    </button>
  )
}
