import { ApiResponse } from "../types/api-response"
import { TopFilterOutput } from "./useTopFilter"
import useAuth from "./useAuth"
import useSWR from "swr"

export interface AlertResponse {
  uuid: string
  date: string
  time: string
  activity: string
  description: string
  created_at: string
  updated_at: string
  deleted_at: any
  mil: Mil
  cem?: Cem
}

interface Mil {
  uuid: string
  name: string
  coordinate: Coordinate
  created_at: string
  updated_at: string
  deleted_at: any
}

interface Coordinate {
  lat: string
  long: string
}

interface Cem {
  uuid: string
  name: string
  code: string
  coordinate: Coordinate2
  area: string
  regulation_references: string[]
  created_at: string
  updated_at: string
  deleted_at: any
}

interface Coordinate2 {
  lat: string
  long: string
}

const useAlerts = (query: TopFilterOutput, mils?: string) => {
  const { _type, ...q } = query
  const { fetcher } = useAuth()
  const url = `/alerts/${  _type}`
  const queryString = mils ? { ...q, mils } : q
  const { data, mutate, error } = useSWR([url, JSON.stringify(queryString)], (url, q) =>
    fetcher()
      .get<ApiResponse<AlertResponse[]>>(url, { params: JSON.parse(q) })
      .then(({ data }) => data.data)
  )
  return { data, mutate, error }
}

export const useAllLastAlert = () => {
  const { fetcher } = useAuth()
  const url = `/alerts/geovos/last-data`
  const { data, mutate, error } = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<AlertResponse[]>>(url)
      .then(({ data }) => data.data), { refreshInterval: 30000 }
  )
  return { data, mutate, error }
}

export default useAlerts
