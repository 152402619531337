import { useEffect, useState } from "preact/hooks"
import { io } from "socket.io-client"

const useSocket = (url, options) => {
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    const newSocket = io(url, options)
    // newSocket.on("connect", () => {
    //   console.log("Socket connected")
    // })

    // newSocket.on("disconnect", () => {
    //   console.log("Socket disconnected")
    // })

    newSocket.on("error", (error) => {
      console.error("Socket error:", error)
    })

    setSocket(newSocket)

    return () => {
      newSocket.disconnect()
    }
  }, [])

  return socket
}

export default useSocket
