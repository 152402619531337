import { ApiResponse } from "../types/api-response"
import { User } from "../types/user"
import useAuth from "./useAuth"
import useSWR from "swr"

interface userListPagination {
  page: number
  limit: number
  search: string
  orders: { [field: string]: string }[]
}

const useUsers = () => {
  const url = "/user"
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<User[]>>(url, { params: { limit: 200 } })
      .then(({ data }) => {
        return data.data.data
      })
  )

  return { data, error, mutate }
}

export const useUsersPaginate = ({ page, limit, search, orders = [] }: userListPagination) => {
  const url = "/user"
  let body = JSON.stringify({
    page,
    limit,
    search,
  })

  if (orders.length) {
    let orderParams = {}
    const sort = orders.map((order) => {
      orderParams = { ...orderParams, ...order }
    })
    body = JSON.stringify({ page, limit, search, ...orderParams })
  }

  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR([url, body], (url, body) =>
    fetcher()
      .get<ApiResponse<User[]>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  )
  const exportExcel = async (newLimit, newPage = 1) => {
    const newBody = JSON.stringify({ page: newPage, limit: newLimit })
    const { data } = await fetcher().get<ApiResponse<Cems[]>>(url, { params: JSON.parse(newBody) })
    return data?.data?.data
  }

  return { data, error, mutate, exportExcel }
}

export default useUsers
