import { h, FunctionComponent } from "preact"
import { useState, useRef } from "preact/hooks"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import useAuth from "../../hooks/useAuth"
import { Button } from "../../parts/buttons"
import ConfirmModal from "./confirm-modal"
import AlertModal from "./alert-modal"
import { useTranslation } from "react-i18next"

type ProformaModalProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  subcription_uuid: string
  invoice_uuid: string
  mutate: () => void
  onHide: () => void
}

const flatpickrOptions = {
  dateFormat: "Y-m-d",
  altInput: true,
  altFormat: "d/m/Y",
}

const ConfirmationModal: FunctionComponent<ProformaModalProps> = ({ show, subcription_uuid, invoice_uuid, mutate, onHide }) => {
  const { t } = useTranslation()
  const { fetcher } = useAuth()
  const [disable, setDisable] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [alert, setAlert] = useState({ show: false, isSuccess: false })
  const [paymentDate, setPaymentDate] = useState(moment().format("YYYY-MM-DD"))
  const paymentElement = useRef()

  const handleSubmit = async (event) => {
    event.preventDefault()
    setConfirm(true)
    setDisable(true)
  }

  const handleHide = () => {
    setConfirm(false)
    setDisable(false)
  }

  const handleAlert = () => {
    setAlert((prev) => {
      prev?.isSuccess && onHide()
      return { show: false, isSuccess: false }
    })
    setDisable(false)
  }

  const handleConfirm = async () => {
    setConfirm(false)
    setDisable(true)
    try {
      const image = new FormData()
      image.append("file", paymentElement.current.files[0])
      const header = { headers: { "Content-Type": "multipart/form-data" } }
      const { data: img } = await fetcher().post("/subscribtion/file", image, header)
      const body = { payment_date: paymentDate, receipt: img?.data?.path }
      await fetcher().patch(`/subscribtion/confirm/invoice/${subcription_uuid}/${invoice_uuid}`, body)
      await mutate()
      setAlert({ show: true, isSuccess: true })
    } catch (error) {
      setAlert({ show: true, isSuccess: false })
    } finally {
      setDisable(false)
    }
  }
  return (
    <div>
      {confirm && <ConfirmModal show={confirm} onConfirm={handleConfirm} onHide={handleHide} />}
      {alert?.show && <AlertModal show={alert?.show} isSuccess={alert?.isSuccess} onHide={handleAlert} />}
      <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", zIndex: 99996 }}>
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content card">
            <div className="card-body">
              <p className="text-center fw-bolder fs-5">{t("payment_confirmation")}</p>
              <form onSubmit={(event) => handleSubmit(event)} className="mt-4">
                <div className="mb-4">
                  <label className="form-label">{t("label.payment_date")}</label>
                  <Flatpickr
                    value={paymentDate}
                    options={flatpickrOptions}
                    className="form-control rounded mw-100"
                    onChange={(date: Date) => setPaymentDate(date[0])}
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label">{t("label.payment_receipt")}</label>
                  <input ref={paymentElement} class="form-control rounded mw-100" type="file" accept=".jpg, .jpeg, .png" required />
                </div>
                <div className="d-flex justify-content-end">
                  <Button type="button" variant="tertiary" onClick={onHide} className="m-1" disabled={disable}>
                    {t("buttons.cancel")}
                  </Button>
                  <Button type="submit" variant="primary" className="m-1" disabled={disable}>
                    {t("buttons.confirm_payment")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ConfirmationModal
