import { defaultParameters } from "./parameters"

export const sortParam = (data) => {
  // return data?.sort((a, b) => defaultParameters.indexOf(a.name) - defaultParameters.indexOf(b.name)) //not support for dynamic parameter
  return data?.sort((a, b) => a.name.localeCompare(b.name))
}

export const sortActiveParam = (data) => {
  const activeParam = data?.filter(({ is_active }) => is_active)
  return activeParam?.sort((a, b) => a.name.localeCompare(b.name))
}

export const isDebit = (string: string) => {
  if (!string) return false
  const alfabet = string.replace(/[-_ ]/g, "").toLowerCase()
  const debits = ["debit", "flowrate"]
  return debits.includes(alfabet)
}

export const getUnitParameterDebit = (interval: string) => {
  const units = {
    raw: "m3/minute",
    "two-minutes": "m3/minute",
    hour: "m3/hour",
    day: "m3/day",
    week: "m3/week",
    month: "m3/month",
  }
  return units[interval]
}

/**
 * @valueOrAvgSensor
 * to set sensor to use value or avg property
 */
export const isAvgSensor = (period, sensor) => period !== "raw" && !isDebit(sensor)
