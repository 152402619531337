import Excel, { Borders, Alignment, Font, Fill } from "exceljs"
import { download } from "../../utils/excel"
import moment from "moment"

const NOW = moment().format("x")

// STYLES
const alignmentCenter: Partial<Alignment> = { vertical: "middle", horizontal: "center" }
const alignmentLeftCenter: Partial<Alignment> = { vertical: "middle", horizontal: "left" }
const boldFontHeader: Partial<Font> = { bold: true, color: { argb: 'FFFFFFFF' } }
const border: Partial<Borders> = {
  top: {style: "thin"},
  left: {style: "thin"},
  bottom: {style: "thin"},
  right: {style: "thin"}
}
const backgroundHeader: Partial<Fill> = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FF51BCDA" },
}

export const exportNcrExcel = (data: any[]) => {
  const book = new Excel.Workbook()
  const sheet = book.addWorksheet("NCR")
  const title = `NCR - ${NOW}`

  sheet.getCell(1, 1).value = "No"
  sheet.getCell(1, 2).value = "LK Number"
  sheet.getCell(1, 3).value = "Date"
  sheet.getCell(1, 4).value = "Site"
  sheet.getCell(1, 5).value = "First Approval"
  sheet.getCell(1, 6).value = "Second Approval"
  sheet.getCell(1, 7).value = "Validation Status"
  sheet.getCell(1, 8).value = "Parameter"
  sheet.getCell(1, 9).value = "Condition"
  sheet.getCell(1, 10).value = "Description"
  sheet.getCell(1, 11).value = "Root Problem Analytics"
  sheet.getCell(1, 12).value = "Repair Action & Prevention"
  sheet.getCell(1, 13).value = "Refinement Due Date"

  sheet.getCell(1, 1).alignment = alignmentCenter
  sheet.getCell(1, 2).alignment = alignmentCenter
  sheet.getCell(1, 3).alignment = alignmentCenter
  sheet.getCell(1, 4).alignment = alignmentCenter
  sheet.getCell(1, 5).alignment = alignmentCenter
  sheet.getCell(1, 6).alignment = alignmentCenter
  sheet.getCell(1, 7).alignment = alignmentCenter
  sheet.getCell(1, 8).alignment = alignmentCenter
  sheet.getCell(1, 9).alignment = alignmentCenter
  sheet.getCell(1, 10).alignment = alignmentCenter
  sheet.getCell(1, 11).alignment = alignmentCenter
  sheet.getCell(1, 12).alignment = alignmentCenter
  sheet.getCell(1, 13).alignment = alignmentCenter

  sheet.getCell(1, 1).font = boldFontHeader
  sheet.getCell(1, 2).font = boldFontHeader
  sheet.getCell(1, 3).font = boldFontHeader
  sheet.getCell(1, 4).font = boldFontHeader
  sheet.getCell(1, 5).font = boldFontHeader
  sheet.getCell(1, 6).font = boldFontHeader
  sheet.getCell(1, 7).font = boldFontHeader
  sheet.getCell(1, 8).font = boldFontHeader
  sheet.getCell(1, 9).font = boldFontHeader
  sheet.getCell(1, 10).font = boldFontHeader
  sheet.getCell(1, 11).font = boldFontHeader
  sheet.getCell(1, 12).font = boldFontHeader
  sheet.getCell(1, 13).font = boldFontHeader

  sheet.getCell(1, 1).fill = backgroundHeader
  sheet.getCell(1, 2).fill = backgroundHeader
  sheet.getCell(1, 3).fill = backgroundHeader
  sheet.getCell(1, 4).fill = backgroundHeader
  sheet.getCell(1, 5).fill = backgroundHeader
  sheet.getCell(1, 6).fill = backgroundHeader
  sheet.getCell(1, 7).fill = backgroundHeader
  sheet.getCell(1, 8).fill = backgroundHeader
  sheet.getCell(1, 9).fill = backgroundHeader
  sheet.getCell(1, 10).fill = backgroundHeader
  sheet.getCell(1, 11).fill = backgroundHeader
  sheet.getCell(1, 12).fill = backgroundHeader
  sheet.getCell(1, 13).fill = backgroundHeader

  sheet.getCell(1, 1).border = border
  sheet.getCell(1, 2).border = border
  sheet.getCell(1, 3).border = border
  sheet.getCell(1, 4).border = border
  sheet.getCell(1, 5).border = border
  sheet.getCell(1, 6).border = border
  sheet.getCell(1, 7).border = border
  sheet.getCell(1, 8).border = border
  sheet.getCell(1, 9).border = border
  sheet.getCell(1, 10).border = border
  sheet.getCell(1, 11).border = border
  sheet.getCell(1, 12).border = border
  sheet.getCell(1, 13).border = border

  data?.forEach((row, idx) => {
    sheet.getCell(2 + idx, 1).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 2).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 3).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 4).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 5).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 6).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 7).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 8).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 9).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 10).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 11).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 12).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 13).alignment = alignmentLeftCenter

    sheet.getCell(2 + idx, 1).border = border
    sheet.getCell(2 + idx, 2).border = border
    sheet.getCell(2 + idx, 3).border = border
    sheet.getCell(2 + idx, 4).border = border
    sheet.getCell(2 + idx, 5).border = border
    sheet.getCell(2 + idx, 6).border = border
    sheet.getCell(2 + idx, 7).border = border
    sheet.getCell(2 + idx, 8).border = border
    sheet.getCell(2 + idx, 9).border = border
    sheet.getCell(2 + idx, 10).border = border
    sheet.getCell(2 + idx, 11).border = border
    sheet.getCell(2 + idx, 12).border = border
    sheet.getCell(2 + idx, 13).border = border

    sheet.getCell(2 + idx, 1).value = idx + 1
    sheet.getCell(2 + idx, 2).value = row.lk_number
    sheet.getCell(2 + idx, 3).value =row.date
    sheet.getCell(2 + idx, 4).value =row.site.name
    sheet.getCell(2 + idx, 5).value =row.first_approval
    sheet.getCell(2 + idx, 6).value =row.second_approval
    sheet.getCell(2 + idx, 7).value =row.validator
    sheet.getCell(2 + idx, 8).value =row.parameter
    sheet.getCell(2 + idx, 9).value =row.condition
    sheet.getCell(2 + idx, 10).value =row.description
    sheet.getCell(2 + idx, 11).value =row.problem
    sheet.getCell(2 + idx, 12).value =row.action
    sheet.getCell(2 + idx, 13).value =row.completion_date_target
  })

  sheet?.columns?.forEach((column) => {
    let maxLength = 0
    column?.eachCell?.({ includeEmpty: true }, (cell) => {
        const columnLength = cell?.value ? cell?.value?.toString()?.length : 10
        if (columnLength > maxLength ) {
            maxLength = columnLength
        }
    })
    column.width = maxLength < 10 ? 12 : maxLength + 4
  })
  download(book, title)
}

export const exportNcrKlhkExcel = (data: any[]) => {
  const book = new Excel.Workbook()
  const sheet = book.addWorksheet("NCR KLHK")
  const title = `NCR KLHK - ${NOW}`

  sheet.getCell(1, 1).value = "No"
  sheet.getCell(1, 2).value = "Site"
  sheet.getCell(1, 3).value = "Parameter"
  sheet.getCell(1, 4).value = "Problem"
  sheet.getCell(1, 5).value = "Refinement Date"
  sheet.getCell(1, 6).value = "Status"

  sheet.getCell(1, 1).alignment = alignmentCenter
  sheet.getCell(1, 2).alignment = alignmentCenter
  sheet.getCell(1, 3).alignment = alignmentCenter
  sheet.getCell(1, 4).alignment = alignmentCenter
  sheet.getCell(1, 5).alignment = alignmentCenter
  sheet.getCell(1, 6).alignment = alignmentCenter

  sheet.getCell(1, 1).font = boldFontHeader
  sheet.getCell(1, 2).font = boldFontHeader
  sheet.getCell(1, 3).font = boldFontHeader
  sheet.getCell(1, 4).font = boldFontHeader
  sheet.getCell(1, 5).font = boldFontHeader
  sheet.getCell(1, 6).font = boldFontHeader

  sheet.getCell(1, 1).fill = backgroundHeader
  sheet.getCell(1, 2).fill = backgroundHeader
  sheet.getCell(1, 3).fill = backgroundHeader
  sheet.getCell(1, 4).fill = backgroundHeader
  sheet.getCell(1, 5).fill = backgroundHeader
  sheet.getCell(1, 6).fill = backgroundHeader

  sheet.getCell(1, 1).border = border
  sheet.getCell(1, 2).border = border
  sheet.getCell(1, 3).border = border
  sheet.getCell(1, 4).border = border
  sheet.getCell(1, 5).border = border
  sheet.getCell(1, 6).border = border

  data?.forEach((row, idx) => {
    sheet.getCell(2 + idx, 1).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 2).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 3).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 4).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 5).alignment = alignmentLeftCenter
    sheet.getCell(2 + idx, 6).alignment = alignmentLeftCenter

    sheet.getCell(2 + idx, 1).border = border
    sheet.getCell(2 + idx, 2).border = border
    sheet.getCell(2 + idx, 3).border = border
    sheet.getCell(2 + idx, 4).border = border
    sheet.getCell(2 + idx, 5).border = border
    sheet.getCell(2 + idx, 6).border = border

    sheet.getCell(2 + idx, 1).value = idx + 1
    sheet.getCell(2 + idx, 2).value = row?.site?.name
    sheet.getCell(2 + idx, 3).value =row.parameter
    sheet.getCell(2 + idx, 4).value =row.description
    sheet.getCell(2 + idx, 5).value =row.completion_date
    sheet.getCell(2 + idx, 6).value =row.status
  })

  sheet?.columns?.forEach((column) => {
    let maxLength = 0
    column?.eachCell?.({ includeEmpty: true }, (cell) => {
        const columnLength = cell?.value ? cell?.value?.toString()?.length : 10
        if (columnLength > maxLength ) {
            maxLength = columnLength
        }
    })
    column.width = maxLength < 10 ? 12 : maxLength + 4
  })
  download(book, title)
}
