import { h } from "preact"
import { useState } from "preact/hooks"
import Line from "../../components/chart/line"
import style from "./style.scss"
import { chartDateWithPeriod, getDateDifference } from "../../utils/dateformat"
import { toFixed } from "../../utils/toFixed"
import { useTranslation } from "react-i18next"

type ChartData = {
  name: string
  lower_threshold: number
  upper_threshold: number
  min?: number
  max?: number
  avg?: number
  last: number
  label: Date[]
  dataset: number[]
}

type Props = {
  data: ChartData
  height?: number | string
  color: string
  period: string
  calculation: boolean
}

const LineChart = ({ data, height, color, period, calculation }: Props) => {
  const { t } = useTranslation()
  const dataset =
    data?.dataset
      ?.map((item) => toFixed(item))
      ?.slice()
      ?.reverse() || []
  const date = data?.label?.slice()?.reverse() || []
  const isMoreThanOneDay = !!getDateDifference({ firstDate: date?.[0], secondDate: date?.at(-1) })
  const label = date?.map((item) => chartDateWithPeriod(item, period, isMoreThanOneDay))
  const [zoom, setZoom] = useState(true)

  const onZoomChange = () => setZoom((prevState) => !prevState)

  return (
    <div class="card h-100">
      <div class="card-title">
        <p class={style.title}>{data?.name}</p>
        {/* <p className={style.date}>Showing from {startDate}
          {startDate !== endDate && ` to ${endDate}`}</p> */}
      </div>
      <div class={`d-flex justify-content-between`}>
        <div className="d-flex">
          <p class={style.subtitle}>
            BMAL {data?.lower_threshold} - {data?.upper_threshold}
          </p>
          <label className="ms-3">
            <input type="checkbox" class="form-check-input me-2" checked={zoom} onchange={onZoomChange} />
            {t("zoom")}
          </label>
        </div>
        {calculation && (
          <div class="d-flex">
            <p class={style.subtitle}>
              {t("page.monitoring.avg")}: {data?.avg}
            </p>
            <p class={style.subtitle}>
              {t("page.monitoring.min")}: {data?.min}
            </p>
            <p class={`${style.subtitle} pe-4`}>
              {t("page.monitoring.max")}: {data?.max}
            </p>
          </div>
        )}
      </div>
      <div class="card-body">
        <Line
          height={height}
          lower={data?.lower_threshold}
          upper={data?.upper_threshold}
          bmal={data?.avg}
          data={dataset}
          labels={label}
          isZoom={zoom}
          color={color}
        />
      </div>
    </div>
  )
}

export default LineChart
