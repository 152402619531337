import { FunctionComponent, h } from "preact"

type SearchBoxProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  placeholder?: string
  onChange: viod
}

const SearchBox: FunctionComponent<SearchBoxProps> = ({ placeholder, onChange }) => {
  return (
    <input
      type="text"
      className="form-control rounded w-100 border"
      placeholder={placeholder}
      onChange={({ currentTarget }) => onChange(currentTarget.value)} />
)
}

export default SearchBox
