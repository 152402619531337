import { defaultParameters as paramName } from "./parameters"
const parameters = {
  uuid: "",
  // name: "pH",
  uom: "",
  type: "",
  has_load: false,
  upper_threshold: 0,
  lower_threshold: 0,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  last: 0,
  max: 0,
  min: 0,
  avg: 0,
  is_comply: 0,
  is_invalid: 0,
  amount_invalid: 0,
  amount_incomply: 0,
  ncr: 0,
}

export const defaultParams = [
  {
    ...parameters,
    name: paramName[0]
  },
  {
    ...parameters,
    name: paramName[1]
  },
  {
    ...parameters,
    name: paramName[2]
  },
  {
    ...parameters,
    name: paramName[3]
  },
  {
    ...parameters,
    name: paramName[4]
  },
  {
    ...parameters,
    name: paramName[5]
  },
  {
    ...parameters,
    name: paramName[6]
  },
]

export const klhkBaseUrl = "https://sparingengine.ppkl.menlhk.go.id" // "http://203.166.207.51"
