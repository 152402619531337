import { useState } from "preact/hooks"

const useModal = (initialState: boolean = false) => {
  const [show, setShow] = useState(initialState)

  const onShow = () => setShow(true)
  const onHide = () => setShow(false)
  const onToggle = () => setShow((n) => !n)

  return { show, onShow, onHide, onToggle }
}
export default useModal
