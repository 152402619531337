import { h, FunctionComponent } from "preact"
import { Button } from "../../../parts/buttons"
import style from "../style.scss"
import { t } from "i18next"

type PlatformDetailProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  data: any
  show: boolean
  onHide: () => void
}

const PlatformDetail: FunctionComponent<PlatformDetailProps> = ({ data, show, onHide }) => {
  return (
    <div className="modal-backdrop" style={{ display: show ? "inherit" : "none", backgroundColor: "#00000099", overflowY: "auto" }}>
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content card px-2 py-1">
          <div className="card-body text-center h5">{t("platform_detail")}</div>
          <div className="card-body">
            <div className={style.detail}>
              <div className={style.label}>{t("label.name")}</div>
              <div className={style.value}>{data?.name}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.platform_code")}</div>
              <div className={style.value}>{data?.code}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>{t("label.modules")}</div>
              <div className={style.value}>{data?.modules?.map(({ name }) => name)?.join(", ")}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>Host URL</div>
              <div className={style.value}>{data?.host}</div>
            </div>
            <div className={style.detail}>
              <div className={style.label}>Logo</div>
              <div className={style.value}>
                <img src={data?.logo} height={64} />
                {data?.logo && (
                  <Button
                    variant="primary"
                    type="button"
                    className="m-1 ms-4 btn-sm"
                    disabled={!data?.logo}
                    onClick={() => {
                      window.open(data?.logo)
                    }}
                  >
                    {t("buttons.open_in_new_tab")}
                  </Button>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button onClick={onHide} type="button" variant="tertiary" className="m-1">
                {t("buttons.close")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlatformDetail
