import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import moment from "moment"
import { useFilterContext } from "../../context/useFilter"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type PeriodDateListProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  type: string
  onSelect: viod
}

const PeriodDateList: FunctionComponent<PeriodDateListProps> = ({ type, onSelect }) => {
  const { t } = useTranslation()
  const { contextFilter, setContextFilter } = useFilterContext()
  const [value, setValue] = useState(contextFilter?.periodType)

  const periodList = [
    { id: 1, name: t("Last Hour", { hour: "" }), type: "raw", start: moment().subtract(30, "minutes").format("X"), end: moment().format("X") },
    { id: 2, name: t("Last Hour", { hour: 1 }), type: "raw", start: moment().subtract(1, "hour").format("X"), end: moment().format("X") },
    { id: 3, name: t("Last Hour", { hour: 3 }), type: "raw", start: moment().subtract(3, "hours").format("X"), end: moment().format("X") },
    { id: 4, name: t("Last Hour", { hour: 12 }), type: "raw", start: moment().subtract(12, "hours").format("X"), end: moment().format("X") },
    { id: 5, name: t("Last Hour", { hour: 24 }), type: "raw", start: moment().subtract(1, "day").format("X"), end: moment().format("X") },

    { id: 6, name: t("Today"), type: "hour", start: moment().startOf("day").format("X"), end: moment().format("X") },
    { id: 7, name: t("Last Hour", { hour: 24 }), type: "hour", start: moment().subtract(1, "day").format("X"), end: moment().format("X") },
    { id: 8, name: t("Last Hour", { hour: 48 }), type: "hour", start: moment().subtract(2, "day").format("X"), end: moment().format("X") },
    { id: 9, name: t("Last Hour", { hour: 72 }), type: "hour", start: moment().subtract(3, "day").format("X"), end: moment().format("X") },

    { id: 10, name: t("Last Days", { day: 30 }), type: "day", start: moment().subtract(30, "days").format("X"), end: moment().format("X") },
    { id: 11, name: t("Last Days", { day: 60 }), type: "day", start: moment().subtract(60, "days").format("X"), end: moment().format("X") },
    {
      id: 12,
      name: t("Last Month", { month: "1" }),
      type: "day",
      start: moment().subtract(1, "month").startOf("month").format("X"),
      end: moment().subtract(1, "month").endOf("month").format("X"),
    },
    {
      id: 13,
      name: t("Last Month", { month: "2" }),
      type: "day",
      start: moment().subtract(2, "month").startOf("month").format("X"),
      end: moment().subtract(1, "month").endOf("month").format("X"),
    },

    { id: 14, name: t("This Year"), type: "month", start: moment().startOf("year").format("X"), end: moment().format("X") },
    { id: 15, name: t("Last Year"), type: "month", start: moment().subtract(1, "year").format("X"), end: moment().format("X") },
  ]

  const periods = periodList?.filter((period) => period?.type === type)

  const handlePeriodChange = (event) => {
    const newValue = event.currentTarget.value
    setValue(newValue)
    setContextFilter({ periodType: newValue })
    const date = periodList?.find(({ id }) => id === +newValue)
    onSelect(date)
  }

  useEffect(() => {
    setValue(contextFilter?.periodType)
  }, [contextFilter?.periodType])

  return (
    <div className={style.filter_input_group}>
      <label className={style.filter_label}>{t("label.period")}</label>
      <select className="w-100 form-select rounded border" onChange={handlePeriodChange} value={value}>
        <option value="" disabled selected hidden>
          Select Period
        </option>
        {periods.map(({ name, id }) => (
          <option value={id} key={id}>
            {name}
          </option>
        ))}
        <option value="0" disabled>
          Custom
        </option>
      </select>
    </div>
  )
}

export default PeriodDateList
