import { FunctionComponent, h } from "preact"
import { route } from "preact-router"
import { mutate } from "swr"
import { Button } from "../../parts/buttons"
import TopBar from "../../components/topbar"
import Breadcrumb from "../../components/topbar/breadcrumb"
import useAuth from "../../hooks/useAuth"
import useObjectState from "../../hooks/useObjectState"
import style from "./style.scss"
import { useTranslation } from "react-i18next"

type AddHelpPageProps = h.JSX.HTMLAttributes<HTMLDivElement>

const AddHelpPage: FunctionComponent<AddHelpPageProps> = () => {
  const { t } = useTranslation()
  const defaultBody = {
    phone: "",
    name: "",
    subject: "",
    message: "",
  }
  const { fetcher } = useAuth()

  const [body, setBody] = useObjectState(defaultBody)
  const [alert, setAlert] = useObjectState({ status: "", msg: "" })
  const handleSubmit = (event) => {
    event.preventDefault()
    fetcher()
      .post("/support-contact", body)
      .then(() => {
        setBody(defaultBody)
        setAlert({ status: "primary", msg: t("Message sent successfully") })
        mutate("/support-contact")
        setTimeout(() => route("/help/support", true), 3000)
      })
      .catch(() => {
        setAlert({ status: "error", msg: t("Message failed to send") })
      })
  }

  return (
    <div className="h-100">
      <TopBar>
        <Breadcrumb name={t("breadcrumb.help")} link="" />
        <Breadcrumb name={t("breadcrumb.contact_support")} link="/help/support" />
        <Breadcrumb name={t("breadcrumb.add_new_help")} link="/help/support/add" />
      </TopBar>
      <div className="p-5 d-flex justify-content-center">
        <div className="card w-100">
          {alert.status && (
            <div className="card-body">
              <div className={`alert-${alert.status} px-3 py-2`}>{alert.msg}</div>
            </div>
          )}
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="card-body p-6">
              <p className={style.page_title}>{t("label.support_contact")}</p>
              <div className="my-4">
                <label className="form-label">{t("label.name")}</label>
                <input
                  value={body.name}
                  onChange={({ currentTarget }) => setBody({ name: currentTarget.value })}
                  required
                  type="text"
                  className="form-control rounded mw-100"
                />
              </div>
              <div className="mb-4">
                <label className="form-label">{t("label.phone")}</label>
                <input
                  value={body.phone}
                  onChange={({ currentTarget }) => setBody({ phone: currentTarget.value })}
                  required
                  type="number"
                  className="form-control rounded mw-100"
                />
              </div>
              <div className="mb-4">
                <label className="form-label">{t("label.subject")}</label>
                <input
                  value={body.subject}
                  onChange={({ currentTarget }) => setBody({ subject: currentTarget.value })}
                  required
                  type="text"
                  className="form-control rounded mw-100"
                />
              </div>
              <div className="mb-4">
                <label className="form-label">{t("label.message")}</label>
                <textarea
                  value={body.message}
                  onChange={({ currentTarget }) => setBody({ message: currentTarget.value })}
                  required
                  className="form-control rounded-3 mw-100"
                  rows={4}
                />
              </div>
              <div class="d-flex justify-content-end">
                <Button variant="primary">{t("buttons.submit")}</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddHelpPage
