import { FunctionComponent, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { BsPencil, BsPlusLg, BsTrash } from "react-icons/bs"
import { Button } from "../../../parts/buttons"
import Container from "../../../parts/container"
import TopBar from "../../../components/topbar"
import Breadcrumb from "../../../components/topbar/breadcrumb"
import useAuth from "../../../hooks/useAuth"
import { useFeature } from "../../../hooks/useUsman"
import { useConfirm } from "../../../context/useConfirm"
import Pagination from "../../../components/pagination"
import usePagination from "../../../hooks/usePagination"
import Spinner from "../../../components/spinner"
import SearchBox from "../../../components/TopFilter/search-box"
import useObjectState from "../../../hooks/useObjectState"
import { can, canOneOf } from "../../../utils/access"
import { Permission } from "../../../enum/permissions.enum"
import AddFeature from "./add"
import EditFeature from "./edit"

type AccessFeatureProps = h.JSX.HTMLAttributes<HTMLDivElement>

const AccessFeature: FunctionComponent<AccessFeatureProps> = () => {
  const { data, mutate } = useFeature()
  const [filteredData, setFilteredData] = useState(data)
  const confirmation = useConfirm()
  const { fetcher } = useAuth()
  const [editForm, setEditForm] = useObjectState({ show: false, name: "", uuid: "" })
  const [showAddForm, setShowAddForm] = useState(false)
  const [search, setSearch] = useState("")

  const showActionColumn = canOneOf([Permission.AccessFeatureDelete, Permission.AccessFeatureEdit])

  const pages = usePagination({ count: filteredData?.length || 0, step: 20 })
  const modules = filteredData?.slice(pages.output, pages.output + pages.step)

  const handleEdit = (uuid: string, name: string) => {
    setEditForm({ show: true, name, uuid })
  }
  const handleDelete = (id: string, name: string) => {
    confirmation(
      `Are you sure you want to delete ${name} feature?`,
      (remove) => {
        if (remove) fetcher().delete(`/list-feature/${id}`).then(() => mutate())
      },
      { yes: "Delete", no: "Cancel" }
    )
  }

  useEffect(() => {
    let searchedData
    if (search) {
      searchedData = data?.filter(({ name }) => {
        return name?.toLowerCase()?.includes(search) || !search
      })
    } else {
      searchedData = data
    }
    setFilteredData(searchedData)
  }, [data, search])

  return (
    <div className="min-vh-100">
      <TopBar>
        <Breadcrumb name="Access" link="" />
        <Breadcrumb name="Feature" link="/access/feature" />
      </TopBar>
      {showAddForm &&
        <AddFeature show={showAddForm} mutate={() => mutate()} onClose={() => setShowAddForm(false)} />}
      {editForm.show &&
        <EditFeature
          uuid={editForm.uuid}
          name={editForm.name}
          show={editForm.show}
          mutate={() => mutate()}
          onClose={() => setEditForm({ show: false})} />}
      <Spinner show={!Array.isArray(data)} />
      <Container>
        <div className="card">
          <div className="card-title align-items-center">
            <SearchBox placeholder="Search feature" onChange={(value) => setSearch(value?.toLowerCase())} />
            {can(Permission.AccessFeatureAdd) &&
              <Button variant="primary" className="ms-2" icon={BsPlusLg} onClick={() => setShowAddForm(true)}>
                Add Feature
              </Button>
            }
          </div>
          <div className="card-body">
            <table className="table table-data table-center">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Feature</th>
                  {showActionColumn && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {modules?.length ?
                  modules?.map(({ name, uuid }, idx) => (
                  <tr key={`user-${idx}`}>
                    <td>{idx + 1 + (pages.page * pages.step)}</td>
                    <td>{name}</td>
                    {showActionColumn && <td>
                      {can(Permission.AccessFeatureEdit) &&
                        <Button
                          variant="secondary"
                          title="Edit"
                          onClick={() => handleEdit(uuid, name)}
                          className="m-1" icon={BsPencil}
                        />
                      }
                      {can(Permission.AccessFeatureDelete) &&
                        <Button
                          variant="tertiary"
                          title="Delete"
                          onClick={() => handleDelete(uuid, name)}
                          className="m-1" icon={BsTrash}
                        />
                      }
                    </td>}
                  </tr>
                  )) :
                  <tr>
                    <td colSpan={showActionColumn ? 3 : 2}>No data.</td>
                  </tr>}
              </tbody>
            </table>
            <div className="d-flex">
              <div className="ms-auto">
                <Pagination hook={pages} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AccessFeature
